import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import BookingApi from "../../Api/BookingApi";
import Button from "../../Atoms/Button/Button";
import Checkbox from "../../Atoms/Checkbox";
import Form from "../../Atoms/Form";
import FormError from "../../Atoms/FormError";
import FormText from "../../Atoms/FormText";
import Input from "../../Atoms/Input/Input";
import Modal from "../../Atoms/Modal/Modal";
import Select from "../../Atoms/Select";
import TextLink from "../../Atoms/TextLink/TextLink";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

export default function ConfirmBookingModal({ show, onClose, booking }) {
  const startDate = dayjs.unix(booking.utc_start_at);

  const { loading, formErrors, callApi } = useApi(
    BookingApi.teacherConfirmBooking
  );
  const cancelState = useApi(BookingApi.teacherCancelBooking);

  const dateText = startDate.format("MMM, D h:mm a");
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      date: startDate.format("YYYY-MM-DD"),
      time: startDate.format("HH:mm"),
    },
  });

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);

  function onAccept(values) {
    callApi(booking.number, values).then(() => {
      onClose(true);
    });
  }

  function onCancel() {
    if (cancelState.loading) {
      return;
    }

    cancelState.callApi(booking.number).then(() => {
      onClose(true);
    });
  }

  return (
    <Modal show={show} onClose={onClose} className="sm:w-full sm:max-w-md">
      <div className="bg-gray-100 p-5 text-gray-600">
        Confirm 1:1 with{" "}
        <span className="text-black">{booking.student.full_name}</span> on{" "}
        <span className="text-black">{dateText}</span>?
      </div>

      <div className="p-5">
        <Form onSubmit={handleSubmit(onAccept)} errors={formErrors}>
          <FormText className="mb-5">
            Video link for students to join the 1:1 class
          </FormText>
          <div className="mt-8">
            <Select
              name="video_platform"
              label="Streaming platform"
              options={[
                { value: "zoom", label: "Zoom" },
                { value: "meet", label: "Google Meet" },
                { value: "skype", label: "Skype" },
              ]}
              required
              inputRef={register({ required: true })}
            />
          </div>

          <div className="mt-5">
            <Input
              name="video_link"
              label="Video link (url)"
              required
              inputRef={register({ required: true })}
            />
          </div>

          <div className="mt-5">
            <Input
              name="video_password"
              label="Password (if any)"
              inputRef={register()}
            />
          </div>

          <div className="mt-5">
            <Checkbox
              name="update_start_date"
              label="Propose a different date and time?"
              inputRef={register()}
            />
          </div>

          {watch("update_start_date") && (
            <div className="flex flex-row justify-between mt-5">
              <Input
                name="date"
                label="Date"
                type="date"
                inputWidth="w-40"
                required
                inputRef={register({ required: true })}
              />
              <Input
                name="time"
                label="Time"
                type="time"
                inputWidth="w-40"
                required
                inputRef={register({ required: true })}
              />
            </div>
          )}

          <FormError className="my-5" error={formErrors} alignRight={true} />

          {cancelState.formErrors && (
            <FormError
              className="my-5"
              error={cancelState.formErrors}
              alignRight={true}
            />
          )}

          <div className="mt-5 flex flex-row justify-end items-center">
            <TextLink className="mr-4" onClick={onCancel}>
              Cancel Booking
            </TextLink>
            <Button loading={loading || cancelState.loading} type="submit">
              Accept Booking
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
