import cx from "classnames";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CourseApi } from "../../Api/CourseApi";
import ConfirmationModal from "../../Atoms/ConfirmationModal/ConfirmationModal";
import CourseService from "../../Services/CourseService";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import dayjs from "dayjs";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

export default function CourseCard({ course, deleted, ...props }) {
  const [showModal, setShowModal] = React.useState(false);
  const { loading, callApi, errorMessage } = useApi(CourseApi.deleteCourse);

  const history = useHistory();

  const meta = [course.type_display, course.fee && `₹${course.fee}`]
    .filter((o) => o)
    .join(" | ");

  const repeatSchedule = CourseService.repeatScheduleText(course);

  function onEdit() {
    history.push(`/studio/edit-class/${course.id}`);
  }

  function onDelete() {
    setShowModal(true);
  }

  function onDeleteConfirm() {
    callApi(course.id).then(() => {
      setShowModal(false);
    });
  }

  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);

  return (
    <div
      className={cx(
        "flex flex-row justify-between items-center bg-white shadow-card-cream mb-5",
        "rounded-none sm:rounded-lg",
        "px-7 py-5 sm:py-5"
      )}
    >
      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={onDeleteConfirm}
        confirmLoading={loading}
        error={errorMessage}
        text="Are you sure you want to delete the class?"
      />

      <div className="flex-1">
        <h3>{course.title}</h3>
        <div className="text-y-gray text-xs mt-1">{meta}</div>
        {repeatSchedule && (
          <div className="text-y-gray text-xs mt-1">{repeatSchedule}</div>
        )}
      </div>
      {!deleted && (
        <div>
          <span
            className="text-y-yellow font-semibold px-4 cursor-pointer"
            onClick={onEdit}
          >
            Edit
          </span>

          <span
            className="text-y-yellow font-semibold px-4 cursor-pointer"
            onClick={onDelete}
          >
            Delete
          </span>
        </div>
      )}
      {deleted && (
        <div className="flex-1 justify-end items-end">
          <div className="text-center text-y-yellow font-semibold px-4">
            Deleted
          </div>
          <div className="text-y-gray text-xs mt-1 text-center">
            on {dayjs(course.deleted_at).format("DD/MM/YY")}
          </div>
        </div>
      )}
      <div></div>
    </div>
  );
}
