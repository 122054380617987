import dayjs from "dayjs";
import React, { useEffect } from "react";
import { StorefrontApi } from "../../Api/StorefrontApi";
import Button from "../../Atoms/Button/Button";
import FormError from "../../Atoms/FormError";
import Modal from "../../Atoms/Modal/Modal";
import TextLink from "../../Atoms/TextLink/TextLink";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

export default function ConfirmBookingModal({
  show,
  onClose,
  booking,
  ...props
}) {
  const startDate = dayjs.unix(booking.utc_start_at);

  const confirmState = useApi(StorefrontApi.confirmBooking);
  const cancelState = useApi(StorefrontApi.cancelBooking);

  const dateText = startDate.format("MMM, D h:mm a");

  function onAccept() {
    if (confirmState.loading) {
      return;
    }

    confirmState.callApi(booking.number).then(() => {
      onClose(true);
    });
  }

  function onCancel() {
    if (cancelState.loading) {
      return;
    }

    cancelState.callApi(booking.number).then(() => {
      onClose(true);
    });
  }

  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);

  return (
    <Modal show={show} onClose={onClose} className="sm:w-full sm:max-w-md">
      <div className="bg-gray-100 p-5 text-gray-600">
        Please Confirm your 1:1
      </div>

      <div className="text-gray-600 px-5 pt-8 pb-5">
        The teacher has proposed a new time,{" "}
        <span className="text-black">{dateText}</span>. Would you like to go
        confirm the 1:1?
      </div>

      {cancelState.formErrors && (
        <FormError
          className="m-5"
          error={cancelState.formErrors}
          alignRight={true}
        />
      )}

      {confirmState.formErrors && (
        <FormError
          className="m-5"
          error={confirmState.formErrors}
          alignRight={true}
        />
      )}

      <div className="p-5 flex flex-row justify-end items-center">
        <TextLink className="mr-4" onClick={onCancel}>
          Cancel Booking
        </TextLink>
        <Button
          loading={confirmState.loading || cancelState.loading}
          onClick={onAccept}
        >
          Confirm Booking
        </Button>
      </div>
    </Modal>
  );
}
