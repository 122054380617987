import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UserApi } from "../../../Api/UserApi";
import Button from "../../../Atoms/Button/Button";
import ErrorMessage from "../../../Atoms/ErrorMessage";
import Form from "../../../Atoms/Form";
import Input from "../../../Atoms/Input/Input";
import Select from "../../../Atoms/Select";
import useTemporaryMessage from "../../../Hooks/useTemporaryMessage";
import { useApi } from "../../../Utilities/Hooks/ApiHook";
import TextArea from "../../../Atoms/TextArea";
import CustomInput from "./CustomInput";

export default function ExperienceStage({ data, onSuccess }) {
  const { message, showMessage } = useTemporaryMessage({
    className: "text-green-500 mr-5",
  });
  const { callApi, formErrors, loading, errorMessage } = useApi(
    UserApi.updateTeacherProfile
  );

  const { register, handleSubmit } = useForm({
    defaultValues: data || {},
  });

  const [experiencesArr, setExperiencesArr] = useState(
    JSON.parse(data.experiances) || []
  );

  const experianceData = { title: "", institution: "" };

  function onSave(d) {
    callApi({ ...d, experiances: JSON.stringify(experiencesArr) }).then(
      (response) => {
        showMessage("Saved!");
        onSuccess(response.data);
      }
    );
  }

  const years = [];
  for (let i = 1; i <= 100; i++) {
    if (i === 100) {
      years.push({ value: 100, label: "100+" });
    } else {
      years.push({ value: `${i}`, label: `${i}` });
    }
  }

  const handleChange = (e, i) => {
    const formatData = experiencesArr.map((ele, idx) => {
      if (idx === i) {
        return { ...ele, [e.target.name]: e.target.value };
      } else {
        return ele;
      }
    });
    setExperiencesArr([...formatData]);
  };

  const handleDelete = (i) => {
    const formatData = experiencesArr
      .map((ele, idx) => {
        if (idx !== i) {
          return ele;
        }
      })
      .filter(Boolean);

    setExperiencesArr(
      formatData.length > 0 && formatData[0] !== undefined
        ? [...formatData]
        : []
    );
  };

  const handleCreate = () => {
    setExperiencesArr([...experiencesArr, experianceData]);
  };

  return (
    <Form onSubmit={handleSubmit(onSave)} errors={formErrors}>
      <div className="mt-8">
        <div className="flex flex-row">
          <div className="w-3/4 pr-3 text-gray-500 text-sm pt-4">
            How long have you been practicing Yoga?
          </div>
          <div className="w-1/4">
            <Select
              required
              name="practicing_years"
              options={years}
              inputRef={register({ required: true })}
            />
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <div className="w-3/4 pr-3 text-gray-500 text-sm pt-4">
            How long have you been teaching Yoga?
          </div>
          <div className="w-1/4">
            <Select
              required
              name="teaching_years"
              options={years}
              inputRef={register({ required: true })}
            />
          </div>
        </div>

        <div className="mt-4">
          <Input
            required
            name="style"
            label="What styles of yoga do you teach?"
            inputRef={register({ required: true })}
          />
        </div>

        <div className="mt-4">
          <TextArea
            required
            name="description"
            label="Describe yourself as a yoga teacher"
            inputRef={register({ required: true })}
          />
        </div>

        <div className="text-base text-gray-600">
          Yoga Learning & Teaching Experience
        </div>

        <div className="mt-4 text-sm text-gray-600">
          Showcase your yoga learnings, certifications & teaching experience.
          This helps establish credibility with students.
        </div>

        {experiencesArr &&
          experiencesArr.map((ele, idx) => (
            <div className="border p-5 py-4 rounded-lg border-gray-300">
              <div className="mt-4">
                <CustomInput
                  name="title"
                  label="Title"
                  value={ele.title}
                  onChange={(e) => {
                    handleChange(e, idx);
                  }}
                />
              </div>
              <div className="mt-4">
                <CustomInput
                  name="institution"
                  label="Institution"
                  value={ele.institution}
                  onChange={(e) => {
                    handleChange(e, idx);
                  }}
                />
              </div>
              <div
                className="text-right flex justify-end cursor-pointer"
                onClick={() => {
                  handleDelete(idx);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.8096 10.667C3.8096 11.4037 4.68304 12.0003 5.76147 12.0003H13.5689C14.6473 12.0003 15.5207 11.4037 15.5207 10.667V2.66699H3.8096V10.667Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M13.0809 0.666656L12.105 0H7.22538L6.24942 0.666656H2.83371V2H16.4966V0.666656H13.0809Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
            </div>
          ))}

        <div
          className="mt-4 text-lg bg-y-cream p-2 pl-0 text-y-yellow rounded-md cursor-pointer"
          onClick={handleCreate}
        >
          + Add an experience
        </div>

        <ErrorMessage>{errorMessage}</ErrorMessage>

        <div className="mt-2 flex flex-row justify-end items-center">
          {message}
          <Button type="submit" loading={loading}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}
