import React, { useEffect, useState } from "react";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { BlogApi } from "../../Api/BlogApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../Atoms/Button/Button";

export default function BlogPage() {
  const { callApi, loading, errorMessage, data } = useApi(
    BlogApi.blogListByUser
  );

  const history = useHistory();

  const { callApi: blogDeleteApi } = useApi(BlogApi.blogDelete);

  const blogData = data?.data.slice().reverse() || [];

  const OnDelete = (id) => {
    blogDeleteApi(id)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Blog Deleted Successfully",
          confirmButtonText: "Okay",
        }).then((result) => {
          callApi();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  };

  useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div>
      <div className="flex justify-start mb-7">
        <Button
          py="py-3"
          onClick={() => {
            history.push("/studio/blogs/create");
          }}
        >
          Create Blog
        </Button>
      </div>
      <div className="mx-0 mt-3 w-full flex flex-col items-center sm:flex-row sm:flex-wrap space-y-2 sm:space-y-0">
        {loading ? (
          <div>Loading...</div>
        ) : errorMessage ? (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        ) : blogData.length === 0 ? (
          <ErrorMessage>No Data Available</ErrorMessage>
        ) : (
          <>
            {blogData &&
              blogData.map((ele) => (
                <div>
                  <div className="sm:pr-5 sm:pb-5 w-full max-w-sm">
                    <div className="bg-white p-5 rounded-md shadow-card-cream-sm w-full max-w-sm ">
                      <div>
                        {ele.image && (
                          <img
                            className="h-48 w-64"
                            src={ele.image}
                            alt="cover"
                          />
                        )}
                      </div>
                      <div className="text-base pt-3 font-bold">
                        {ele.title}
                      </div>
                      <div className="text-base pt-3">
                        type: {ele.blog_type === "story" ? "Story" : "Asana"}
                      </div>
                      <div className="flex justify-end pt-4 pb-4 mb-0">
                        <div
                          className="mt-5 text-y-yellow cursor-pointer mr-5"
                          onClick={() => OnDelete(ele.id)}
                        >
                          <div>Delete</div>
                        </div>

                        <div
                          className="mt-5 text-y-yellow cursor-pointer"
                          onClick={() => {
                            history.push(
                              `/studio/blogs/${ele.title.replaceAll(
                                " ",
                                "-"
                              )}-${ele.id}`
                            );
                          }}
                        >
                          <div>View</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
}
