import MonthlyClassesGraphic from "../../../Graphics/MonthlyClassesGraphic";
import FilterClasses from "../FilterPages/Search";
import useIsMobile from "../../../Hooks/useMobile";
import cx from "classnames";
import pageTracking from "../../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import FilterDatasEnum from "../../../Utilities/FilterData";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Live(props) {
  const isMobile = useIsMobile();

  const filterArrData = {
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
    queryText: "",
  };

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  });

  return (
    <>
      <Helmet>
        <title>Monthly Yoga Courses for Afternoon</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Monthly Yoga Courses for Afternoon"></meta>
        <meta
          name="description"
          content="A regular practice helps you progress. Find a monthly yoga class that works for you."
        />
        <meta
          property="og:title"
          content="Monthly Yoga Courses for Afternoon"
        ></meta>
        <meta
          property="twitter:title"
          content="Monthly Yoga Courses for Afternoon"
        ></meta>
        <meta
          property="og:description"
          content="A regular practice helps you progress. Find a monthly yoga class that works for you."
        ></meta>
        <meta
          property="twitter:description"
          content="A regular practice helps you progress. Find a monthly yoga class that works for you."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="px-0 sm:px-2 flex flex-col w-full">
        <div
          className={cx(
            "flex flex-row justify-between items-center overflow-x-auto",
            "h-40 sm:h-full sm:w-4/5"
          )}
        >
          <div className="text-sm sm:text-lg text-gray-600 font-semibold pr-2 pt-0 sm:pt-5 sm:w-2/4">
            <p className="mt-2">Deepen your yoga practice.</p>
            <p className="mt-2">
              Classes designed for intermediate & advanced yoga practitioners.
            </p>
          </div>
          <div>
            <MonthlyClassesGraphic width={isMobile ? 170 : 300} height={130} />
          </div>
        </div>
        <FilterClasses
          name=""
          type="month"
          courseName="Monthly Yoga Courses for Afternoon"
          filterArr={{
            ...filterArrData,
            time: [
              {
                start: "13:00",
                end: "16:55",
                label: "Afternoon(01:00PM - 04:00PM)",
                value: "Afternoon(01:00PM - 04:00PM)",
                checked: false,
              },
            ],
          }}
          filter={{
            ...FilterDatasEnum,
            time: [
              {
                start: "05:00",
                end: "07:55",
                label: "Early Morning(05:00AM - 07:00AM)",
                value: "Early Morning(05:00AM - 07:00AM)",
                checked: false,
              },
              {
                start: "08:00",
                end: "12:55",
                label: "Morning(08:00AM - 12:00PM)",
                value: "Morning(08:00AM - 12:00PM)",
                checked: false,
              },
              {
                start: "13:00",
                end: "16:55",
                label: "Afternoon(01:00PM - 04:00PM)",
                value: "Afternoon(01:00PM - 04:00PM)",
                checked: true,
              },
              {
                start: "17:00",
                end: "21:00",
                label: "Evening(05:00PM - 09:00PM)",
                value: "Evening(05:00PM - 09:00PM)",
                checked: false,
              },
            ],
          }}
          {...props}
        />
      </div>
    </>
  );
}
