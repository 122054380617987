import dayjs from "dayjs";
import CourseService from "./CourseService";
import SessionService from "./SessionService";

export default class BookingService {
  /**
   * Get booking date text
   *
   * @param booking
   */
  static getDateText(booking) {
    if (booking.session) {
      return SessionService.getDateText(booking.session);
    }

    if (booking.course) {
      const startDate = dayjs.unix(booking.utc_start_at);
      const endDate = dayjs.unix(booking.utc_end_at);

      return [
        `${startDate.format("MMM D")} to ${endDate.format("MMM D")}`,
        CourseService.repeatScheduleText(booking.course),
      ]
        .filter((o) => o)
        .join(" | ");
    }

    if (booking.one_on_one) {
      return [
        dayjs.unix(booking.utc_start_at).format("MMM, D h:mm a"),
        `${booking.one_on_one.duration} min`,
      ].join(" | ");
    }

    return null;
  }

  /**
   * Get booking created at text
   *
   * @param booking
   * @return {string}
   */
  static getCreatedAtText(booking) {
    return dayjs(booking.created_at).format("MMM, D h:mm a");
  }
}
