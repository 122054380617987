import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Button from "../../Atoms/Button/Button";
import ErrorMessage from "../../Atoms/ErrorMessage";
import UserSelectors from "../../Selectors/user";
import PaymentService from "../../Services/PaymentService";
import StripeService from "../../Services/StripeService";
import TextService from "../../Services/TextService";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import Vent from "../../vent";
import { useHistory, useParams } from "react-router-dom";
import UrlService from "../../Services/UrlService";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

export default function NewBooking({ params, setBooking, props }) {
  const { callApi, errorMessage, loading } = useApi(params.api);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [error, setError] = useState("");
  const canPayWhatYouWant = useSelector((store) =>
    UserSelectors.canPayWhatYouWant(store)
  );
  const numBooked = useSelector((store) => UserSelectors.numBooked(store));

  const history = useHistory();

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      history.push(`/booking-failed`);
      setError(errorMessage);
    }
  }, [errorMessage]);

  console.log("errorMessage", errorMessage);

  const teacherDomain = UrlService.getTeacherDomain();

  function onJoin() {
    setError("");
    if (params.type === "session") {
      callApi(params.objectId, { domain: teacherDomain }).then((response) => {
        console.log("succ", response);
        // redirect to payment
        if (response.data.data.stripe_checkout_id) {
          if (response.data.data.stripe_checkout_id) {
            setBtnLoading(true);
            StripeService.checkout(response.data.data.stripe_checkout_id);
            return;
          }
          Vent.onBooking(params.trackType, params.currency_fee.fee_inr);
          setBooking(response.data.data);
        } else {
          if (params.type === "subscription") {
            if (!response.data.data.error) {
              history.push(
                `?pay=true&courseType=${params.type}&id=${params.objectId}&bookingId=${response.data.data.number}`
              );
            } else {
              setError("You already having booking for this teacher");
            }
          } else {
            if (response.data.data.razorypay_checkout_id) {
              history.push(
                `?pay=true&courseType=${params.urlType}&id=${params.objectId}&slug=${params.slug}&bookingId=${response.data.data.number}`
              );
            } else {
              history.push(
                `/booking-status?booking=${response.data.data.number}&booking_status=success`
              );
            }
          }
        }
      });
    } else {
      callApi(params.objectId, {
        bookThreeMonths: params.bookThreeMonths,
        book12Months: params.book12Months,
      }).then((response) => {
        // redirect to payment
        if (response.data.data.stripe_checkout_id) {
          if (response.data.data.stripe_checkout_id) {
            setBtnLoading(true);
            StripeService.checkout(response.data.data.stripe_checkout_id);
            return;
          }
          Vent.onBooking(params.trackType, params.currency_fee.fee_inr);
          setBooking(response.data.data);
        } else {
          if (params.type === "subscription") {
            if (!response.data.data.error) {
              history.push(
                `?pay=true&courseType=${params.type}&id=${params.objectId}&bookingId=${response.data.data.number}`
              );
            } else {
              setError("You already having booking for this teacher");
            }
          } else {
            if (response.data.data.razorypay_checkout_id) {
              history.push(
                `?pay=true&courseType=${params.urlType}&id=${params.objectId}&slug=${params.slug}&bookingId=${response.data.data.number}`
              );
            } else {
              history.push(
                `/booking-status?booking=${response.data.data.number}&booking_status=success`
              );
            }
          }
        }
      });
    }
  }

  let feeObject = params.currency_fee;
  if (params.type === "session") {
    feeObject = PaymentService.getSessionFeeObjectFromFee(
      feeObject,
      canPayWhatYouWant,
      numBooked
    );
  }

  useEffect(() => {
    if (params.objectId) {
      onJoin();
    }
  }, []);

  return (
    <>
      {/* <Header {...params.headerProps} />

      <div className="p-7">
        {(errorMessage || error) && (
          <ErrorMessage className="block mb-5">
            {errorMessage || error}
          </ErrorMessage>
        )}
        <div className="flex flex-row justify-between items-center text-lg">
          <div className="font-medium">
            {feeObject && feeObject.strike && !teacherDomain ? (
              <div>
                <div className="line-through">
                  {TextService.formatCurrencyFee(feeObject)}
                </div>
                <div className="text-y-gray text-sm">
                  {feeObject.strikeText}
                </div>
              </div>
            ) : (
              <span>{TextService.formatCurrencyFee(feeObject)}</span>
            )}

            {params.fee_india && <span>₹ {params.fee_india}</span>}
          </div>
          <Button onClick={onJoin} loading={loading || btnLoading}>
            {params.joinText}
          </Button>
        </div>
      </div> */}
    </>
  );
}

function Header({ title, dateText, timeText, image, expired, classes }) {
  return (
    <div className="p-5 bg-gray-100 flex flex-row justify-between">
      <div>
        <h2 className="text-lg font-medium">{title}</h2>

        <div className="text-sm mt-2">
          {dateText && (
            <div className="flex flex-row items-center">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4531 0.9375H9.375V0.375C9.375 0.167906 9.20712 0 9 0C8.79288 0 8.625 0.167906 8.625 0.375V0.9375H3.375V0.375C3.375 0.167906 3.20712 0 3 0C2.79288 0 2.625 0.167906 2.625 0.375V0.9375H1.54688C0.693914 0.9375 0 1.63141 0 2.48438V10.4531C0 11.3061 0.693914 12 1.54688 12H10.4531C11.3061 12 12 11.3061 12 10.4531V2.48438C12 1.63141 11.3061 0.9375 10.4531 0.9375ZM1.54688 1.6875H2.625V2.0625C2.625 2.26959 2.79288 2.4375 3 2.4375C3.20712 2.4375 3.375 2.26959 3.375 2.0625V1.6875H8.625V2.0625C8.625 2.26959 8.79288 2.4375 9 2.4375C9.20712 2.4375 9.375 2.26959 9.375 2.0625V1.6875H10.4531C10.8925 1.6875 11.25 2.04497 11.25 2.48438V3.375H0.75V2.48438C0.75 2.04497 1.10747 1.6875 1.54688 1.6875ZM10.4531 11.25H1.54688C1.10747 11.25 0.75 10.8925 0.75 10.4531V4.125H11.25V10.4531C11.25 10.8925 10.8925 11.25 10.4531 11.25Z"
                  fill="black"
                />
              </svg>
              <span className="pl-2 font-medium">{dateText}</span>
            </div>
          )}

          {classes && <div>Class {classes}</div>}

          {expired && expired[2] !== "3" && (
            <div className="flex flex-row items-center">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4531 0.9375H9.375V0.375C9.375 0.167906 9.20712 0 9 0C8.79288 0 8.625 0.167906 8.625 0.375V0.9375H3.375V0.375C3.375 0.167906 3.20712 0 3 0C2.79288 0 2.625 0.167906 2.625 0.375V0.9375H1.54688C0.693914 0.9375 0 1.63141 0 2.48438V10.4531C0 11.3061 0.693914 12 1.54688 12H10.4531C11.3061 12 12 11.3061 12 10.4531V2.48438C12 1.63141 11.3061 0.9375 10.4531 0.9375ZM1.54688 1.6875H2.625V2.0625C2.625 2.26959 2.79288 2.4375 3 2.4375C3.20712 2.4375 3.375 2.26959 3.375 2.0625V1.6875H8.625V2.0625C8.625 2.26959 8.79288 2.4375 9 2.4375C9.20712 2.4375 9.375 2.26959 9.375 2.0625V1.6875H10.4531C10.8925 1.6875 11.25 2.04497 11.25 2.48438V3.375H0.75V2.48438C0.75 2.04497 1.10747 1.6875 1.54688 1.6875ZM10.4531 11.25H1.54688C1.10747 11.25 0.75 10.8925 0.75 10.4531V4.125H11.25V10.4531C11.25 10.8925 10.8925 11.25 10.4531 11.25Z"
                  fill="black"
                />
              </svg>
              <span className="pl-2 font-medium">{expired}</span>
            </div>
          )}

          {timeText && (
            <div className="flex flex-row items-center mt-1">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 0C2.69166 0 0 2.69166 0 6C0 9.30834 2.69166 12 6 12C9.30834 12 12 9.30834 12 6C12 2.69166 9.30834 0 6 0ZM6 11.25C3.1051 11.25 0.750003 8.8949 0.750003 6C0.750003 3.1051 3.1051 0.750003 6 0.750003C8.8949 0.750003 11.25 3.1051 11.25 6C11.25 8.8949 8.8949 11.25 6 11.25Z"
                  fill="black"
                />
                <path
                  d="M6.375 2.25H5.625V6.15526L7.98485 8.51512L8.51513 7.98485L6.375 5.84472V2.25Z"
                  fill="black"
                />
              </svg>

              <span className="pl-2 font-medium">{timeText}</span>
            </div>
          )}
        </div>
      </div>

      {image && (
        <img
          style={{ height: "80px", width: "100px" }}
          className="object-cover rounded ml-5"
          src={image}
          alt="Placeholder"
        />
      )}
    </div>
  );
}
