import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { CourseApi } from "../../Api/CourseApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import CourseForm from "../SetupCourse/CourseForm";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function EditCourse(props) {
  const { callApi, loading, errorMessage, data } = useApi(CourseApi.details);
  const history = useHistory();
  const params = useParams();

  React.useEffect(() => {
    if (!params.id) {
      history.push("/studio/classes");
      return;
    }

    pageTracking(props);
    ReactPixel.pageView();

    callApi(params.id).catch(() => true);
  }, [params.id, history, callApi]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  const course = data?.data;
  if (!course) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Edit Course</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Edit Course"></meta>
        <meta name="description" content="Edit Course" />
        <meta property="og:title" content="Edit Course"></meta>
        <meta property="twitter:title" content="Edit Course"></meta>
        <meta property="og:description" content="Edit Course"></meta>
        <meta property="twitter:description" content="Edit Course"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <CourseForm isCourse={course.type === "course"} course={course} />
    </>
  );
}
