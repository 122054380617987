import { get, post, put } from "./api";

export const StorefrontApi = {
  //banners
  banners: () => get("/banners/"),

  createSearchUrl: (data) => post("/searchurls/", data),
  getsearchUrl: (id) => get(`/searchurls/${id}/`),

  // sessions
  sessions: (data) => get("/storefront/sessions/", data),
  sessionDetails: (id) => get(`/storefront/sessions/${id}/`),
  createSessionBooking: (id, data) =>
    post(`/storefront/sessions/${id}/book/`, data),

  // courses
  courseDetails: (id) => get(`/storefront/courses/${id}/`),
  fixedCourses: () => get("/storefront/courses/fixed-courses/"),
  monthlyCourses: () => get("/storefront/courses/monthly/"),
  UpcomingCourses: (id) =>
    get(`/storefront/courses/upcoming?type=monthly-course&id=${id}`),
  createCourseBooking: (id, data = {}) =>
    post(`/storefront/courses/${id}/book/`, data),

  // 1-1
  oneOnOnes: () => get("/storefront/courses/1-1/"),
  oneOnOneDetails: (id) => get(`/storefront/courses/1-1/${id}/`),
  createOneOnOneBooking: (id, data) =>
    post(`/storefront/courses/1-1/${id}/book/`, data),

  // bookings
  joinClass: (id) => get(`/storefront/bookings/join/${id}/`),
  bookingDetails: (number) => get(`/storefront/bookings/${number}/`),

  bookingUpdate: (number, data) => put(`/bookings/${number}/`, data),
  bookingDetailsReview: (number) => get(`/bookings/${number}/`),
  confirmBooking: (number) =>
    post(`/storefront/bookings/${number}/student-confirm/`),
  cancelBooking: (number) =>
    post(`/storefront/bookings/${number}/student-cancel/`),

  paymentSuccessApi: (id, data) => post(`/bookings/${id}/success/`, data),
  paymentFailureApi: (id) => post(`/bookings/${id}/fail/`),

  filterCourses: (data) => post(`/storefront/courses/filter/`, data),

  allTeachers: () => get(`users/teachers/`),
  allBookings: () => get(`users/teachers/booking/`),
};
