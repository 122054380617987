import React, { useEffect } from "react";
import UnderlineButton from "../../Atoms/UnderlineButton/UnderlineButton";
import HoveringGirl from "../../Graphics/HoveringGirl";
import { useLoggedInSection } from "../../Utilities/Hooks/LoggedInHook";
import BookingsList from "./BookingsList";
import SubscriptionList from "./SubscriptionList";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

const sections = ["Upcoming", "Completed", "Subscriptions"];

export default function MyLearnings({ showTabs = true, ...props }) {
  useLoggedInSection("/");

  const [section, setSection] = React.useState(sections[0]);

  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);

  return (
    <div className={`sm:flex ${showTabs ? "flex-row" : "flex-col"} p-0 sm:p-0`}>
      {showTabs && (
        <Helmet>
          <title>My Learnings</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="title" content="My Learnings"></meta>
          <meta name="description" content="My Learnings" />
          <meta property="og:title" content="My Learnings"></meta>
          <meta property="twitter:title" content="My Learnings"></meta>
          <meta property="og:description" content="My Learnings"></meta>
          <meta property="twitter:description" content="My Learnings"></meta>
          <meta property="og:url" content={window.location.href}></meta>
          <meta property="twitter:url" content={window.location.href}></meta>
        </Helmet>
      )}
      {showTabs && (
        <div className="w-full max-w-sm py-16 mr-16 hidden sm:block">
          <HoveringGirl width={250} height={250} />
        </div>
      )}

      <div className="flex-1">
        {showTabs ? (
          <div className="flex flex-row">
            {sections.map((s) => (
              <UnderlineButton
                key={s}
                selected={section === s}
                onClick={() => setSection(s)}
                className="mr-5"
                yellow
              >
                {s}
              </UnderlineButton>
            ))}
          </div>
        ) : null}

        <div className={`${showTabs ? "mt-8" : "mt-0"}`}>
          {section === "Subscriptions" ? (
            <SubscriptionList />
          ) : (
            <BookingsList section={section} showTabs={showTabs} />
          )}
        </div>
      </div>
    </div>
  );
}
