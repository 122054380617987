import MonthlyClassesGraphic from "../../../Graphics/MonthlyClassesGraphic";
import FilterClasses from "./Search";
import useIsMobile from "../../../Hooks/useMobile";
import cx from "classnames";
import pageTracking from "../../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import FilterDatasEnum from "../../../Utilities/FilterData";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function AllLevels(props) {
  const isMobile = useIsMobile();

  const filterArrData = {
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
    queryText: "",
  };

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  });

  return (
    <>
      <Helmet>
        <title>Monthly Yoga for Experienced Practitioners</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="title"
          content="Monthly Yoga for Experienced Practitioners"
        ></meta>
        <meta
          name="description"
          content="Deepen your yoga practice. Classes designed for intermediate & advanced yoga practitioners."
        />
        <meta
          property="og:title"
          content="Monthly Yoga for Experienced Practitioners"
        ></meta>
        <meta
          property="twitter:title"
          content="Monthly Yoga for Experienced Practitioners"
        ></meta>
        <meta
          property="og:description"
          content="Deepen your yoga practice. Classes designed for intermediate & advanced yoga practitioners."
        ></meta>
        <meta
          property="twitter:description"
          content="Deepen your yoga practice. Classes designed for intermediate & advanced yoga practitioners."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="px-0 sm:px-2 flex flex-col w-full">
        <div
          className={cx(
            "flex flex-row justify-between items-center overflow-x-auto",
            "h-40 sm:h-full sm:w-4/5"
          )}
        >
          <div className="text-sm sm:text-lg text-gray-600 font-semibold pr-2 pt-0 sm:pt-5 sm:w-2/4">
            <p className="mt-2">Deepen your yoga practice.</p>
            <p className="mt-2">
              Classes designed for intermediate & advanced yoga practitioners.
            </p>
          </div>
          <div>
            <MonthlyClassesGraphic width={isMobile ? 170 : 300} height={130} />
          </div>
        </div>
        <FilterClasses
          name=""
          type="month"
          courseName="Monthly Yoga for Experienced Practitioners"
          filterArr={{
            ...filterArrData,
            levels: [
              { value: "intermediate", label: "Intermediate", checked: false },
              { value: "advanced", label: "Advanced", checked: false },
              { value: "beginner", label: "Beginner", checked: false },
            ],
          }}
          filter={{
            ...FilterDatasEnum,
            levels: [
              { value: "beginner", label: "Beginner", checked: true },
              { value: "intermediate", label: "Intermediate", checked: true },
              { value: "advanced", label: "Advanced", checked: true },
            ],
          }}
          {...props}
        />
      </div>
    </>
  );
}
