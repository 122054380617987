import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import StepsCard from "../../Components/StepsCard";
import CreatedModal from "./CreatedModal";
import SubscriptionForm from "./SubscriptionForm";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function Subscription() {
  const [showCreated, setShowCreated] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);

  function onFinish(data) {
    const apiData = Object.values(data).reduce(
      (acc, d) => ({ ...acc, ...d }),
      {}
    );

    return SubscriptionApi.createSubscription(apiData).then((response) => {
      setShowCreated(true);

      return response;
    });
  }

  function onModalClose() {
    setShowCreated(false);
    setTimeout(() => {
      history.push("/studio/classes");
    }, 300);
  }

  return (
    <div>
      <Helmet>
        <title>Subscription Course Setup</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Subscription Course Setup"></meta>
        <meta name="description" content="Subscription Course Setup" />
        <meta property="og:title" content="Subscription Course Setup"></meta>
        <meta
          property="twitter:title"
          content="Subscription Course Setup"
        ></meta>
        <meta
          property="og:description"
          content="Subscription Course Setup"
        ></meta>
        <meta
          property="twitter:description"
          content="Subscription Course Setup"
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="max-w-lg px-7 sm:px-0">
        <span
          className="mr-2"
          onClick={() => history.push("/studio/setup-class")}
        >
          Setup Class
        </span>
        <span className="mr-2 text-gray-400">{">"}</span>
        <span className="text-y-gray">Subscription</span>
      </div>
      <div className="w-screen max-w-xl mt-5">
        <StepsCard
          onFinish={onFinish}
          steps={[
            {
              title: "Subscription",
              formFields: [
                "title",
                "classes_count",
                "expiry_date",
                "fee_india",
                "fee_intl",
              ],
              component: SubscriptionForm,
            },
          ]}
        />
      </div>

      <CreatedModal show={showCreated} onClose={onModalClose} />
    </div>
  );
}
