import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import UserSelectors from "../../Selectors/user";
import CourseSelectors from "../../Selectors/course";
import PaymentService from "../../Services/PaymentService";
import TextService from "../../Services/TextService";
import Vent from "../../vent";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import UrlService from "../../Services/UrlService";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import timeZoneFormat from "../../Utilities/TimeZoneFormat";

export default function SessionCard({
  session,
  booking,
  bookingDateText,
  actionRight,
  homeComponent = false,
  homeDesktop = false,
  dateBooking,
}) {
  const teacherDomain = UrlService.getTeacherDomain();
  const canPayWhatYouWant = useSelector((store) =>
    UserSelectors.canPayWhatYouWant(store)
  );
  const numBooked = useSelector((store) => UserSelectors.numBooked(store));
  const schoolName = session.course.teacher?.school_name;
  const teacherName = session.course.teacher?.first_name;
  const image = _.get(
    session,
    "course.teacher.profile_picture",
    _.get(session, "course.images[0].file")
  );

  const teacherId = session?.course?.teacher?.id;

  const teacherData = useSelector(CourseSelectors.streamDatas);

  //const startTime = dayjs.unix(session.utc_start_at).format("h:mm a");

  const userTimeZone = useSelector(UserSelectors.userTimeZone);

  const startTime = timeZoneFormat(
    userTimeZone,
    booking ? dateBooking : session.date,
    session.course.start_time
  );

  let metaLines = [];
  if (session.course.level) {
    metaLines.push(`for ${session.course.level}`);
  }

  const teacherMeta = [teacherName, schoolName].filter((o) => !!o).join(" | ");

  if (teacherMeta) {
    metaLines.push(`by ${teacherMeta}`);
  }

  function onDetails() {
    ReactGA.event({
      category: "Session Details",
      action: "Routing",
      label: JSON.stringify(session),
    });
    ReactPixel.track("track", {
      category: "Session Details",
      action: "Routing",
      label: JSON.stringify(session),
    });
    Vent.onClassDetails(Vent.TYPE_LIVE_CLASS, session.course.title);
  }

  const feeObject = PaymentService.getSessionFeeObjectFromFee(
    session.course.single_currency_fee || session.course.period_currency_fee,
    canPayWhatYouWant,
    numBooked
  );

  const customComponent = () => {
    return (
      <>
        <div
          className={`bg-white w-full p-4 rounded-md shadow-card-cream-sm max-w-sm ${
            !booking ? "cursor-pointer" : ""
          }`}
          style={{ width: homeComponent ? "130%" : homeDesktop ? "96%" : "" }}
        >
          <div className="flex flex-row justify-between">
            <div className="pr-5 flex-1">
              <h2 className="text-wrap-title">{session.course.title}</h2>
              <div className="text-xs text-gray-500 mt-1 leading-5">
                {metaLines.map((l) => (
                  <p className="text-wrap-title" key={l}>
                    {l}
                  </p>
                ))}
                {booking && bookingDateText && (
                  <>
                    {bookingDateText.split("|").map((ele) => (
                      <p>{ele}</p>
                    ))}
                  </>
                )}
                {booking && (
                  <p>
                    {session.type_display === "Course"
                      ? "Monthly Course"
                      : session.type_display}
                  </p>
                )}
              </div>
            </div>
            <div style={{ width: 90 }}>
              <div className="rounded overflow-hidden flex justify-center">
                <img
                  style={{ height: "90px" }}
                  className="object-cover rounded-full"
                  src={image || "/img/yoga-placeholder-01.jpg"}
                  alt={session.course.title}
                />
              </div>
              <div className="mt-2 text-gray-500">
                {teacherData[teacherId] && (
                  <>
                    {teacherData[teacherId].avgReviewsCount && (
                      <p className="pt-0 flex text-xs items-center">
                        <div>
                          <svg
                            viewBox="0 0 51 48"
                            class="widget-svg widget-selected"
                            style={{
                              width: "16px",
                              height: "16px",
                              transition: "transform 0.2s ease-in-out 0s",
                            }}
                          >
                            <path
                              class="widget"
                              d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                              style={{
                                fill: "orange",
                                transition: "fill 0.2s ease-in-out 0s",
                              }}
                            ></path>
                          </svg>
                        </div>
                        <p className="pl-1 pt-1">
                          {teacherData[teacherId].avgReviewsCount}
                        </p>
                      </p>
                    )}
                    {teacherData[teacherId].total && (
                      <p className="pt-3 text-xs">
                        {teacherData[teacherId].total} streams
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-center pt-3">
            <div className="flex flex-row justify-between items-center flex-1">
              <p cclassName="text-sm flex flex-col">
                <p className="flex flex-row justify-between items-center">
                  <span className="text-green-500 mr-1">{startTime}</span>{" "}
                  <span className="text-black mx-2">
                    <svg
                      width="12"
                      height="12"
                      fill="none"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 0C2.692 0 0 2.692 0 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6zm0 11.25A5.256 5.256 0 01.75 6 5.256 5.256 0 016 .75 5.256 5.256 0 0111.25 6 5.256 5.256 0 016 11.25z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.375 2.25h-.75v3.905l2.36 2.36.53-.53-2.14-2.14V2.25z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                  <span className="text-gray-400">
                    {session.course.duration} min
                  </span>
                </p>
                <p className="py-2">
                  {!booking && teacherDomain ? (
                    <div className="flex flex-row items-center">
                      <div className={""}>
                        {TextService.formatCurrencyFee(feeObject)}
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        transform: feeObject.strike ? "translateY(12px)" : null,
                      }}
                      className="flex flex-row items-center"
                    >
                      <div className={feeObject.strike ? "line-through" : null}>
                        {TextService.formatCurrencyFee(feeObject)}
                      </div>
                      {feeObject.strike && (
                        <div
                          className="text-y-gray text-center pl-3"
                          style={{ fontSize: 12 }}
                        >
                          {feeObject.strikeText}
                        </div>
                      )}
                    </div>
                  )}
                </p>
              </p>
            </div>

            <div style={{ width: 96 }}>
              {booking ? (
                <>{actionRight}</>
              ) : (
                <Button py="py-3" className="w-full">
                  Join
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {booking ? (
        <>{customComponent()}</>
      ) : (
        <Link
          to={`/sessions/${session.course.slug}-${session.id}`}
          onClick={onDetails}
        >
          {customComponent()}
        </Link>
      )}
    </>
  );
}
