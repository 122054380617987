import cx from "classnames";
import React, { useEffect, useState } from "react";
import BookingApi from "../../Api/BookingApi";
import Button from "../../Atoms/Button/Button";
import GlowingPicture from "../../Atoms/GlowingPicture";
import useApiPage from "../../Hooks/useApiPage";
import BookingService from "../../Services/BookingService";
import ConfirmBookingModal from "./ConfirmBookingModal";
import ReactWhatsapp from "react-whatsapp";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { UserApi } from "../../Api/UserApi";
import { useHistory } from "react-router-dom";
import Modal from "../../Atoms/Modal/Modal";
import Input from "../../Atoms/Input/Input";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { StorefrontApi } from "../../Api/StorefrontApi";
import Swal from "sweetalert2";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Passers } from "prop-passer";
import Form from "../../Atoms/Form";

export default function StudioBookings() {
  const history = useHistory();
  const {
    callApi,
    loading,
    data: teacherData,
    errorMessage,
    setSuccessResponse,
  } = useApi(UserApi.teacherProfile);
  const { data, view, empty, refresh } = useApiPage(BookingApi.teacherBookings);

  const url = `https://${teacherData?.data?.domain}.do.yoga`;

  const title = `Namaste. You can do yoga with me. Look forward to helping you build a regular yoga practice.`;

  const size = "2.5rem";

  const [showModal, setShowModal] = useState(false);

  const dataLinks = [
    { text: "Profile", link: "/studio/profile" },
    { text: "Courses", link: "/studio/setup-class" },
    { text: "Students", link: "/studio/bookings" },
  ];

  useEffect(() => {
    callApi();
    pageTracking();
    ReactPixel.pageView();
  }, []);
  if (view) {
    return view;
  }

  const ShareList = Passers({
    url: "",
    className: "network__share-button",
  })({
    className: "network cursor-pointer hover transition--default",
  })("li");

  return (
    <>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(!showModal);
        }}
        className="sm:w-full sm:max-w-md"
      >
        <div className="flex flex-wrap p-10">
          <div>
            <WhatsappShareButton
              url={url}
              title={title}
              separator="     "
              className="m-5"
            >
              <WhatsappIcon size={size} />
            </WhatsappShareButton>
          </div>
          <div>
            <ShareList>
              <FacebookShareButton quote={title} className="m-5">
                <FacebookIcon size={size} />
              </FacebookShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TwitterShareButton title={title} className="m-5">
                <TwitterIcon size={size} />
              </TwitterShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <LinkedinShareButton title={title} className="m-5">
                <LinkedinIcon size={size} />
              </LinkedinShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TelegramShareButton title={title} className="m-5">
                <TelegramIcon size={size} />
              </TelegramShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <EmailShareButton subject={title} body={title} className="m-5">
                <EmailIcon size={size} />
              </EmailShareButton>
            </ShareList>
          </div>
          <div></div>
          <div></div>
        </div>
      </Modal>
      <div className="w-full max-w-3xl">
        {empty ? (
          <div className="px-7 sm:px-0">You do not have any bookings yet.</div>
        ) : (
          <>
            <h2 className="px-7 text-lg md:text-xl text-y-yellow-dark font-semibold sm:px-0">
              Students
            </h2>

            <div className="mt-5">
              {data.data.map((o) => (
                <BookingCard
                  key={o.id}
                  booking={o}
                  onUpdated={refresh}
                  teacherData={teacherData}
                />
              ))}
            </div>
          </>
        )}

        <div className="px-5">
          <div
            className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0"
            onClick={() => {
              window.open(`https://${teacherData?.data?.domain}.do.yoga/`);
              ReactGA.event({
                category: "1:1 Details",
                action: "Routing",
                label: JSON.stringify(teacherData?.data?.domain),
              });
              ReactPixel.track("track", {
                category: "1:1 Details",
                action: "Routing",
                label: JSON.stringify(teacherData?.data?.domain),
              });
            }}
            role="button"
          >
            https://{teacherData?.data?.domain}.do.yoga
          </div>
          <div className="flex pt-4 pb-4 mb-0">
            <Button
              py="py-3"
              className="whitespace-nowrap"
              onClick={() => {
                window.open(`https://${teacherData?.data?.domain}.do.yoga/`);
                ReactGA.event({
                  category: "1:1 Details",
                  action: "Routing",
                  label: JSON.stringify(teacherData?.data?.domain),
                });
                ReactPixel.track("track", {
                  category: "1:1 Details",
                  action: "Routing",
                  label: JSON.stringify(teacherData?.data?.domain),
                });
              }}
            >
              Go to website
            </Button>
          </div>

          <div className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
            Tell the world to do yoga with you
          </div>
          <div className="flex pt-4 pb-4 mb-0">
            <Button
              py="py-3"
              className="whitespace-nowrap"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              Share
            </Button>
          </div>

          <div className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
            Manage your online yoga classes
          </div>
          <div className="flex pt-4 pb-5 mb-10">
            {dataLinks &&
              dataLinks.map((ele, i) => (
                <Button
                  py="py-3"
                  className={`${i > 0 ? "ml-1 md:ml-5" : ""}`}
                  onClick={() => {
                    history.push(ele.link);
                  }}
                >
                  {ele.text}
                </Button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}

function BookingCard({ booking, onUpdated, teacherData }) {
  const [defaultValues, setDefaultValues] = useState({ end_date: "" });
  const { register, handleSubmit } = useForm({
    defaultValues: {
      end_date: defaultValues.end_date,
    },
  });

  const { callApi } = useApi(StorefrontApi.bookingUpdate);

  const [showConfirm, setShowConfirm] = React.useState(false);

  const bookingDateText = BookingService.getDateText(booking);

  const [showEditBooking, setShowEditBooking] = useState(false);
  const [bookingCurrent, setBookingCurrent] = useState({});

  function onConfirmClose(result) {
    if (result === true) {
      onUpdated();
    }

    setShowConfirm(false);
  }

  const handleClick = (values) => {
    const val1 = new Date(values.end_date);
    const val2 = new Date(dayjs.unix(booking.utc_end_at));

    const diffTime = Math.abs(val1 - val2);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    callApi(booking.id, {
      utc_end_at: val1 > val2 ? diffDays : diffDays - 1,
      operator: val1 > val2 ? "plus" : "minus",
    })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data Saved Successfully",
        });
        onUpdated();
        setShowEditBooking(false);
        setBookingCurrent({});
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  };

  return (
    <div
      className={cx(
        "bg-white shadow-card-cream mb-5",
        "rounded-none sm:rounded-lg",
        "px-7 py-5 sm:py-5"
      )}
    >
      <Modal
        show={showEditBooking}
        onClose={() => {
          setShowEditBooking(!showEditBooking);
        }}
        className="sm:w-full sm:max-w-md"
      >
        <div className="p-10">
          <Form onSubmit={handleSubmit(handleClick)}>
            <Input
              name="end_date"
              label="End Date"
              type="date"
              required
              inputRef={register({ required: true, min: new Date() })}
            />

            <div className="mt-2 flex flex-row justify-end">
              <Button type="submit">Save</Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Helmet>
        <title>{booking.student.full_name}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={booking.student.full_name}></meta>
        <meta name="description" content={booking.student.full_name} />
        <meta property="og:title" content={booking.student.full_name}></meta>
        <meta
          property="twitter:title"
          content={booking.student.full_name}
        ></meta>
        <meta
          property="og:description"
          content={booking.student.full_name}
        ></meta>
        <meta
          property="twitter:description"
          content={booking.student.full_name}
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
        <div className="flex flex-row items-center flex-1">
          <GlowingPicture imgUrl={booking.student.picture} />
          <div className="ml-2">
            <div className="font-medium">{booking.student.full_name}</div>
            <div className="text-xs mt-1 text-y-gray">
              {booking.number} | {BookingService.getCreatedAtText(booking)}
            </div>
            {booking.status !== "success" && (
              <div className="mt-2 inline-flex items-center px-3 py-0.5 rounded-full text-xs bg-gray-100 text-gray-800">
                {booking.status_display}
              </div>
            )}
          </div>
        </div>

        <div className="flex-1 mt-2 ml-16 sm:mt-0 sm:ml-0">
          <div className="font-medium">{booking.title}</div>
          <div className="text-xs text-y-gray mt-1 leading-5">
            <p>
              {booking.type_display} | Booking ID {booking.number}
            </p>
            {bookingDateText && <p>{bookingDateText}</p>}
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        {booking.student.profile.mobile_number && (
          <div className="mt-5 mr-5 text-y-yellow cursor-pointer">
            <div>
              <ReactWhatsapp
                number={booking.student.profile.mobile_number}
                message={`Namaste ${booking.student.full_name}`}
                className="w-full justify-end items-end text-right"
              >
                Send Message
              </ReactWhatsapp>
            </div>
          </div>
        )}
        {booking.course && (
          <div
            className="mt-5 text-y-yellow cursor-pointer"
            onClick={() => {
              setShowEditBooking(!showEditBooking);
              setBookingCurrent({ ...booking });
              setDefaultValues({
                end_date: new Date(dayjs.unix(booking.utc_end_at)),
              });
            }}
          >
            <div>Edit</div>
          </div>
        )}
      </div>

      <div>
        {booking.status === "pending-teacher" && (
          <div className="mt-5 ml-16">
            <Button py="py-2" onClick={() => setShowConfirm(true)}>
              Confirm Booking
            </Button>
          </div>
        )}
      </div>

      <ConfirmBookingModal
        show={showConfirm}
        onClose={onConfirmClose}
        booking={booking}
      />
    </div>
  );
}
