export default class UrlService {
  /**
   * Are we on the booking success callback page
   * @return {boolean}
   */
  static isBookingSuccessCallback() {
    return UrlService.getParam('booking_status') === 'success'
  }

  /**
   * Get URL search parameter
   * @param {string} field
   * @param defaultValue
   * @return {string|null}
   */
  static getParam(field, defaultValue = null) {
    return (
      new URLSearchParams(window.location.search).get(field) || defaultValue
    )
  }

  /**
   * Get teacher subdomain if applicable
   * @return {string|null}
   */
  static getTeacherDomain() {
    // used for development, remove it if there
    const hostParts = window.location.hostname.split('.')
    if (hostParts.length !== 3) {
      return null
    }

    const isLocal = hostParts[1] === 'doyoga' && hostParts[2] === 'test'
    const isProduction = hostParts[1] === 'do' && hostParts[2] === 'yoga'

    if (isLocal || isProduction) {
      return hostParts[0]
    } else {
      return null
    }
  }

  /**
   * Get website root URL
   *
   * @return {string}
   */
  static getRootUrl() {
    return process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : 'https://do.yoga'
  }
}
