import React from "react";
import Button from "../../Atoms/Button/Button";
import Modal from "../../Atoms/Modal/Modal";
import YogaPersonInfrontOfLaptopArt from "../../Graphics/YogaPersonInfrontOfLaptopArt";
import { useDispatch, useSelector } from "react-redux";
import UserSelectors from "../../Selectors/user";
import UserAction from "../../Actions/user";
import { useHistory } from "react-router-dom";

export default function CreatedModal({ show, onClose }) {
  const history = useHistory();
  const showMenu = useSelector(UserSelectors.showMenu);
  const dispatch = useDispatch();
  return (
    <Modal show={show} onClose={onClose} className="sm:w-full sm:max-w-3xl">
      <div className="flex flex-col justify-center items-center py-24">
        <YogaPersonInfrontOfLaptopArt />
        <h2 className="mt-5 text-3xl font-semibold">Congratulations!</h2>
        <p className="mt-3">Your class is live on DoYoga.</p>

        <Button
          className="mt-5"
          onClick={() => {
            if (!showMenu) {
              history.push("/teacher-profile/complete");
              dispatch(UserAction.setShowMenu(true));
            } else {
              onClose();
            }
          }}
        >
          Go to My Classes
        </Button>
      </div>
    </Modal>
  );
}
