import { useEffect } from "react";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

const PaymentBot = (props) => {
  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);
  return (
    <div
      className="bg-y-cream-600 w-full h-full mx-auto p-2 md:p-8"
      style={{ height: "100vh" }}
    >
      <Helmet>
        <title>Do Yoga - Online Yoga Classes</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Do Yoga - Online Yoga Classes" />
        <meta
          name="description"
          content="Online yoga classes guided by the best yoga teachers in India."
        />
        <meta
          property="og:title"
          content="Do Yoga - Online Yoga Classes"
        ></meta>
        <meta
          property="twitter:title"
          content="Do Yoga - Online Yoga Classes"
        ></meta>
        <meta
          property="og:description"
          content="Online yoga classes guided by the best yoga teachers in India."
        ></meta>
        <meta
          property="twitter:description"
          content="Online yoga classes guided by the best yoga teachers in India."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <iframe
        className="w-full h-full"
        title="landbot"
        src="https://chats.landbot.io/v3/H-1039468-QI14MB819M5MZ9HQ/index.html"
      />
    </div>
  );
};
export default PaymentBot;
