import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../Atoms/Input/Input";
import Button from "../../Atoms/Button/Button";
import { LoginApi } from "../../Api/LoginApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import TextLink from "../../Atoms/TextLink/TextLink";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Checkbox from "../../Atoms/Checkbox";

function OtpSignupForm({ onLogin, onSignup, classes }) {
  const { register, handleSubmit, errors } = useForm();
  const { callApi, loading, formErrors } = useApi(LoginApi.otpSignup);
  const [state, setState] = React.useState("otpSignupSendOtp");
  const [phone, setPhone] = React.useState("");

  const [checkval, setCheckVal] = useState(true);

  function onSubmit(data) {
    console.log("calling api with phone " + phone);
    // if phone number is empty, dont call api
    if (phone.length < 5) {
      formErrors.form = "Phone number is required!";
      return;
    }
    if (state === "otpSignupSendOtp") {
      data.mobile_number = "+" + phone;
      console.log(`Calling otp signup with the body: ${JSON.stringify(data)}`);
      callApi(data)
        .then((response) => {
          setState("otpSignupVerifyOtp");
        })
        .catch((e) => {
          console.log(
            `Some error occurredn in sending otp: ${JSON.stringify(
              e.data.data
            )}`
          );
        });
    } else {
      data.mobile_number = "+" + phone;
      console.log(`Calling otp signup with the body: ${JSON.stringify(data)}`);
      callApi(data)
        .then((response) => {
          let profile = response.data.data.profile;
          let token = response.data.token;
          console.log(`User profile returned: ${JSON.stringify(profile)}`);
          console.log(`User token found: ${JSON.stringify(token)}`);
          onSignup(response.data.data, response.data.token);
          window.location.reload();
        })
        .catch((e) => {
          console.log(
            `Some error occured in verifying otp: ${JSON.stringify(e)}`
          );
        });
    }
  }

  let otpField = null;
  if (state === "otpSignupVerifyOtp") {
    otpField = (
      <div className="mt-3">
        <Input
          required
          name="otp"
          label="Otp"
          type="text"
          inputRef={register({ required: true })}
          errors={[errors.otp && "Field is required", formErrors.otp]}
        />
      </div>
    );
  }

  let submitButton = null;
  if (state === "otpSignupSendOtp") {
    submitButton = (
      <Button loading={loading} type="submit">
        Send Otp
      </Button>
    );
  } else if (state === "otpSignupVerifyOtp") {
    submitButton = (
      <Button loading={loading} type="submit" className={classes + "signup"}>
        Signup
      </Button>
    );
  }

  return (
    <div style={{ marginBottom: 50 }} className="max-h-96	">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: 40 }}>
          <PhoneInput
            country={"in"}
            onChange={(phone) => setPhone(phone)}
            preferredCountries={["in", "us"]}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              innerHeight: 100,
              outerHeight: 500,
            }}
            inputStyle={{
              fontSize: 16,
              lineHeight: "20px",
              fontFamily: [
                "Poppins",
                "ui-sans-serif",
                "system-ui",
                '"Segoe UI"',
                "Roboto",
              ],
              borderRadius: 9,
              paddingTop: 28,
              paddingBottom: 28,
              marginLeft: 10,
            }}
            buttonStyle={{
              borderTopLeftRadius: 9,
              borderBottomLeftRadius: 9,
              paddingLeft: 5,
              paddingRight: 5,
              backgroundColor: "#fff",
            }}
          />
        </div>

        <div>
          <Input
            required
            name="name"
            label="Name"
            type="text"
            inputRef={register({ required: true })}
            errors={[errors.name && "Field is required", formErrors.name]}
          />
        </div>

        <div className="mt-3">
          <Input
            required
            name="email"
            label="Email ID"
            type="email"
            inputRef={register({ required: true })}
            errors={[errors.email && "Field is required", formErrors.email]}
          />
        </div>
        {otpField}

        {formErrors.form && (
          <div className="mt-5 text-red-500 text-sm">{formErrors.form}</div>
        )}

        <div>
          <Checkbox
            label={"Receive Whatsapp messages"}
            onChange={(e) => {
              setCheckVal(e.target.checked);
            }}
            checked={checkval}
          />
        </div>

        <div className="text-right mt-8">{submitButton}</div>

        <div className="mt-8">
          Already have an account? <TextLink onClick={onLogin}>Login</TextLink>{" "}
        </div>
      </form>
    </div>
  );
}

export default OtpSignupForm;
