import React from 'react';
import cx from 'classnames';


export default function FormError({ error, alignRight, className }) {
  if (!error || !error.form) {
    return null;
  }

  const errors = Array.isArray(error.form)
    ? error.form
    : [error.form];

  return (
    <div className={cx("text-red-500 text-sm", alignRight && 'text-right', className)}>
      {errors.map(e => (
        <div key={e}>{e}</div>
      ))}
    </div>
  )
}
