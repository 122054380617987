import React from "react";
import { useLocation } from "react-router-dom";
import CourseDetailsPage from "../../Components/CourseDetailsPage";
import Sessions from "../TeacherDomainPage/Sessions";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { StorefrontApi } from "../../Api/StorefrontApi";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

export default function FixedCourseDetails(props) {
  const { search } = useLocation();

  let searchText = new URLSearchParams(search);

  let arr = props?.match?.params?.slug
    ? props?.match?.params?.slug.split("-")
    : [];

  const id = searchText.get("name")
    ? searchText.get("name")
    : arr[arr.length - 1];

  const { callApi, data } = useApi(StorefrontApi.UpcomingCourses);

  React.useEffect(() => {
    callApi(id);
    pageTracking(props);
    ReactPixel.pageView();
  }, [callApi, id]);

  //console.log(data);

  const sessions = data?.data?.sessions || [];

  return (
    <>
      <CourseDetailsPage
        id={id}
        backText="Back to all courses"
        backUrl="/courses"
      />
      <div class="max-w-screen-lg mx-auto p-8 relative">
        {data && <Sessions sessions={sessions} />}
      </div>
    </>
  );
}
