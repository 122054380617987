import cx from "classnames";
import React from "react";
import InputWrapper from "../InputWrapper/InputWrapper";

export default function Select({
  label,
  name,
  options,
  inputRef,
  required,
  errors,
  style,
  className,
  inputWidth,
  handleChange,
  wrapperStyle = "hover-label",
}) {
  const _style = style || {};
  const _className = className;

  return (
    <InputWrapper
      label={label}
      name={name}
      required={required}
      errors={errors}
      wrapperStyle={wrapperStyle}
      inputWidth={inputWidth}
    >
      {({ className, style = {}, ...props }) => (
        <div className="relative">
          <select
            {...props}
            name={name}
            ref={inputRef}
            style={{ ..._style, ...style }}
            className={cx(
              className,
              _className,
              "appearance-none pr-7 bg-white"
            )}
            onChange={(e) => {
              if (handleChange) {
                handleChange(e);
              }
            }}
          >
            {options.map((o) => (
              <option key={o.value} value={o.value}>
                {o.label || o.value}
              </option>
            ))}
          </select>
          <svg
            className="absolute top-3 right-2"
            width="8"
            height="5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 5L.536.5h6.928L4 5z" fill="#666" />
          </svg>
        </div>
      )}
    </InputWrapper>
  );
}
