import _ from 'lodash';
import React from 'react';

export const FormContext = React.createContext({
  errors: {},
});

export default function Form({ onSubmit, children, errors }) {

  let formattedErrors = {};
  if (_.isObject(errors)) {
    formattedErrors = errors;
  }

  return (
    <FormContext.Provider value={{ errors: formattedErrors }}>
      <form onSubmit={onSubmit}>
        {children}
      </form>
    </FormContext.Provider>
  );
}
