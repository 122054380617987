import cx from "classnames";
import React from "react";

function UnderlineButton({
  children,
  selected,
  className,
  onClick,
  yellow = false,
}) {
  return (
    <div
      className={cx(
        "inline-block cursor-pointer",
        selected && "font-semibold text-y-yellow-dark",
        yellow ? " text-gray-600" : "text-y-brown",
        className
      )}
      onClick={onClick}
    >
      {children}

      <div
        className={cx(
          yellow ? "bg-y-yellow-dark" : "bg-y-brown",
          selected ? "opacity-100" : "opacity-0"
        )}
        style={{ width: 14, height: 6, borderRadius: 20 }}
      />
    </div>
  );
}

export default UnderlineButton;
