import React from 'react';
import ErrorMessage from "../Atoms/ErrorMessage";
import { useApi } from "../Utilities/Hooks/ApiHook";

export default function useApiPage(api) {
  const { loading, data, errorMessage, callApi } = useApi(api);

  React.useEffect(() => {
    callApi()
      .catch(() => true);
  }, [callApi])

  if (loading) {
    const view = (
      <div>Loading..</div>
    );

    return { view, loading, data };
  }

  if (errorMessage) {
    const view = (
      <div>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );

    return { view, loading, data };
  }

  if (!data) {
    const view = (
      <div>
        Loading.
      </div>
    );

    return { view, loading, data };
  }

  return {
    view: null,
    loading,
    data,
    empty: Array.isArray(data.data) && data.data.length === 0,
    refresh: () => callApi(),
  }
}
