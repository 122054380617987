import GlowingPicture from "../../Atoms/GlowingPicture";
import React, { useState } from "react";
import Ratings from "react-ratings-declarative";
import TextArea from "../../Atoms/TextArea";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { ReviewsApi } from "../../Api/ReviewApi";
import { useForm } from "react-hook-form";
import Form from "../../Atoms/Form";
import Button from "../../Atoms/Button/Button";
import UserSelectors from "../../Selectors/user";
import { useSelector } from "react-redux";

const Reviews = ({ id, handleClose }) => {
  const isLoggedIn = useSelector(UserSelectors.isLoggedIn);
  const { callApi } = useApi(
    isLoggedIn ? ReviewsApi.createReviewApi : ReviewsApi.createReviewAnonymsApi
  );

  const { register, handleSubmit } = useForm({
    defaultValues: { comment: "" } || {},
  });

  const [formData, setFormData] = useState({
    rating: 0,
  });

  const handleClick = (d) => {
    callApi({ ...formData, ...d, booking: id });
    //setFormData({ rating: "" });
    handleClose();
  };

  const changeRating = (newRating) => {
    setFormData({
      rating: newRating,
    });
  };

  return (
    <div>
      <h2 className="text-lg md:text-xl text-y-yellow-dark font-semibold mb-2">
        Write Review
      </h2>

      <p className="text-gray-600 text-base font-bold pb-3">Rate the session</p>
      <Ratings
        rating={formData.rating}
        widgetRatedColors="blue"
        changeRating={changeRating}
      >
        <Ratings.Widget widgetRatedColor="orange" />
        <Ratings.Widget widgetRatedColor="orange" />
        <Ratings.Widget widgetRatedColor="orange" />
        <Ratings.Widget widgetRatedColor="orange" />
        <Ratings.Widget widgetRatedColor="orange" />
      </Ratings>
      <Form onSubmit={handleSubmit(handleClick)}>
        <div className="mt-8">
          <TextArea name="comment" label="Add Comment" inputRef={register()} />
        </div>
        <Button type="submit">Save</Button>
      </Form>
    </div>
  );
};

export default Reviews;
