import EditCourse from "../Pages/EditCourse";
// import MyCourses from "../Pages/MyCourses";
// import Students from "../Pages/Students";
import Setup from "../Pages/SetupCourse";
import NewCourse from "../Pages/SetupCourse/NewCourse";
import NewFixedCourse from "../Pages/SetupCourse/NewFixedCourse";
import NewOneOnOne from "../Pages/SetupCourse/NewOneOnOne";
import StudioBookings from "../Pages/StudioBookings";
import TeacherProfile from "../Pages/TeacherProfile";
import TeacherWebsite from "../Pages/TeacherProfile/TeacherWebsite";
import Upcoming from "../Pages/TeacherDomainPage/Upcoming";
import Subscription from "../Pages/SetupCourse/Subscription";
import BlogCreate from "../Pages/TeacherBlog/BlogCreate";
import BlogDetails from "../Pages/TeacherBlog/BlogDetails";
import Blogs from "../Pages/TeacherBlog/Blogs";

const studioRoutes = [
  {
    path: "/studio/setup-class/course",
    component: NewCourse,
  },
  {
    path: "/studio/setup-class/subscription",
    component: Subscription,
  },
  {
    path: "/studio/up",
    component: Upcoming,
  },
  {
    path: "/studio/setup-class/fixed-course",
    component: NewFixedCourse,
  },
  {
    path: "/studio/setup-class/1-1",
    component: NewOneOnOne,
  },
  {
    path: "/studio/setup-class",
    component: Setup,
  },
  {
    path: "/studio/edit-class/:id",
    component: EditCourse,
  },
  {
    path: "/studio/profile",
    component: TeacherProfile,
  },

  {
    path: "/studio/blogs/create",
    component: BlogCreate,
  },

  {
    path: "/studio/blogs/:id",
    component: BlogDetails,
  },

  {
    path: "/studio/blogs",
    component: Blogs,
  },

  {
    path: "/studio/website",
    component: TeacherWebsite,
  },
  // {
  //   path: "/studio/students",
  //   component: Students,
  // },
  {
    path: "/studio/bookings",
    component: StudioBookings,
  },
];

export default studioRoutes;
