import MonthlyClassesGraphic from "../../../Graphics/MonthlyClassesGraphic";
import FilterClasses from "./Search";
import useIsMobile from "../../../Hooks/useMobile";
import cx from "classnames";
import pageTracking from "../../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import FilterDatasEnum from "../../../Utilities/FilterData";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Live(props) {
  const isMobile = useIsMobile();

  const filterArrData = {
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
    queryText: "",
  };

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  });

  return (
    <div className="px-0 sm:px-2 flex flex-col w-full">
      <Helmet>
        <title>Monthly Yoga for Weight loss</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Monthly Yoga for Weight loss"></meta>
        <meta
          name="description"
          content="Yoga helps you manage your body better. Join weight loss programs designed for you."
        />
        <meta property="og:title" content="Monthly Yoga for Weight loss"></meta>
        <meta
          property="twitter:title"
          content="Monthly Yoga for Weight loss"
        ></meta>
        <meta
          property="og:description"
          content="Yoga helps you manage your body better. Join weight loss programs designed for you."
        ></meta>
        <meta
          property="twitter:description"
          content="Yoga helps you manage your body better. Join weight loss programs designed for you."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div
        className={cx(
          "flex flex-row justify-between items-center overflow-x-auto",
          "h-40 sm:h-full sm:w-4/5"
        )}
      >
        <div className="text-sm sm:text-lg text-gray-600 font-semibold pr-2 pt-0 sm:pt-5 sm:w-2/4">
          <p className="mt-2">Yoga helps you manage your body better.</p>
          <p className="mt-2">Join weight loss programs designed for you.</p>
        </div>
        <div>
          <MonthlyClassesGraphic width={isMobile ? 170 : 300} height={130} />
        </div>
      </div>
      <FilterClasses
        name=""
        type="month"
        courseName="Monthly Yoga for Weight loss"
        filterArr={{
          ...filterArrData,
          healthConditions: [
            { value: "Weight loss", label: "Weight loss", checked: false },
          ],
        }}
        filter={{
          ...FilterDatasEnum,
          healthConditions: [
            ...FilterDatasEnum.healthConditions,
            { value: "Weight loss", label: "Weight loss", checked: true },
          ],
        }}
        {...props}
      />
    </div>
  );
}
