import React, { useEffect } from "react";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import useApiPage from "../../Hooks/useApiPage";
import CourseCard from "./CourseCard";
import cx from "classnames";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function TeacherSubscriptionCourses(props) {
  const { data, view } = useApiPage(SubscriptionApi.subscriptionListTeacher);

  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);

  if (view) {
    return <div className="px-7 sm:px-0">{view}</div>;
  }

  if (data.data.length === 0) {
    return (
      <div className="px-7 sm:px-0 mt-2">You have not created any classes</div>
    );
  }

  return (
    <div className="w-full max-w-3xl">
      <Helmet>
        <title>Teacher Subscription Courses</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Teacher Subscription Courses"></meta>
        <meta name="description" content="Teacher Subscription Courses" />
        <meta property="og:title" content="Teacher Subscription Courses"></meta>
        <meta
          property="twitter:title"
          content="Teacher Subscription Courses"
        ></meta>
        <meta
          property="og:description"
          content="Teacher Subscription Courses"
        ></meta>
        <meta
          property="twitter:description"
          content="Teacher Subscription Courses"
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="mt-0">
        {data?.data &&
          data?.data.map((ele) => (
            <>
              <div
                className={cx(
                  "flex flex-row justify-between items-center bg-white shadow-card-cream mb-5",
                  "rounded-none sm:rounded-lg",
                  "px-7 py-5 sm:py-5"
                )}
              >
                <div className="flex-1">
                  <h3>{ele.title}</h3>
                  <div className="text-y-gray text-xs mt-1">
                    Classes Count: {ele.classes_count} | ₹{ele.fee_india}{" "}
                    <>
                      {ele.expiry_date[2] == "3" && (
                        <>| Expired: {ele.expiry_date}</>
                      )}
                    </>
                  </div>
                  {ele.expiry_date[2] !== "3" && (
                    <div className="text-y-gray text-xs mt-1">
                      Expired: {ele.expiry_date}
                    </div>
                  )}
                </div>
                {/* {!deleted && (
                  <div>
                    <span
                      className="text-y-yellow font-semibold px-4 cursor-pointer"
                      onClick={onEdit}
                    >
                      Edit
                    </span>

                    <span
                      className="text-y-yellow font-semibold px-4 cursor-pointer"
                      onClick={onDelete}
                    >
                      Delete
                    </span>
                  </div>
                )}
                {deleted && (
                  <div className="flex-1 justify-end items-end">
                    <div className="text-center text-y-yellow font-semibold px-4">
                      Deleted
                    </div>
                    <div className="text-y-gray text-xs mt-1 text-center">
                      on {dayjs(course.deleted_at).format("DD/MM/YY")}
                    </div>
                  </div>
                )} */}
                <div></div>
              </div>
            </>
          ))}
      </div>
    </div>
  );
}
