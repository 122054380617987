import React, { useState, useEffect } from "react";

import dayjs from "dayjs";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import Select from "react-select";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { UserApi } from "../../Api/UserApi";

import "./style.css";

const Settlement = () => {
  const [datas, setDatas] = useState([]);

  const [dates, setDates] = useState([
    new Date().setDate(new Date().getDate() - 30),
    new Date(),
  ]);

  const [amountData, setAmountData] = useState({});
  const [teachers, setTeachers] = useState([]);

  const [currentTeacher, setCurrentTeacher] = useState({});

  const [originalData, setOriginalData] = useState({});

  const getDatas = (a, b) => {
    UserApi.teacherSettlement({
      startDate: a,
      endDate: b,
    })
      .then((response) => {
        const { data } = response.data;

        const dataArr = data.data;
        //const dataArr = arr;

        const teacherObj = {};

        console.log("data", data);

        setOriginalData([...dataArr]);

        dataArr.map((ele) => {
          if (!teacherObj[ele.teacher.id]) {
            teacherObj[ele.teacher.id] = ele.teacher;
          }
        });

        const teacherArr = Object.keys(teacherObj).map((ele) => ({
          label: teacherObj[ele].full_name,
          value: teacherObj[ele].id,
        }));

        setTeachers([...teacherArr, { label: "All", value: "all" }]);

        setCurrentTeacher(teacherArr[0]);

        let amount = 0,
          count = 0;

        setDatas([
          ...dataArr
            .filter((ele) => ele.teacher.id === teacherArr[0].value)
            .map((item) => {
              amount += item.fee;
              count += 1;
              return {
                ...item,
                userName: item.student.full_name,
                userEmail: item.student.email,
                userMobile: item.student.profile.mobile_number,
                bookingDate: dayjs(item.created_at)
                  .tz("Asia/Kolkata")
                  .format("D-MMM-YYYY h:mm a"),
                courseStartDate: dayjs
                  .unix(item.utc_start_at)
                  .tz("Asia/Kolkata")
                  .format("D-MMM-YYYY"),
                courseEndDate: dayjs
                  .unix(item.utc_end_at)
                  .tz("Asia/Kolkata")
                  .format("D-MMM-YYYY"),
              };
            }),
        ]);

        let commission = Math.ceil((amount * 25) / 100);

        setAmountData({
          totalAmount: { label: "Total Amount", value: amount },
          commission: { label: "Commission Amount", value: commission },
          amountToPay: { label: "Amount To Pay", value: amount - commission },
          totalBookings: { label: "Total Bookings", value: count },
        });
      })
      .catch(() => {});
  };

  const handleFilter = (id) => {
    let amount = 0,
      count = 0;

    const filterData =
      id === "all"
        ? originalData
        : originalData.filter((ele) => ele.teacher.id === id);

    setDatas([
      ...filterData.map((item) => {
        amount += item.fee;
        count += 1;
        return {
          ...item,
          userName: item.student.full_name,
          userEmail: item.student.email,
          userMobile: item.student.profile.mobile_number,
          bookingDate: dayjs(item.created_at)
            .tz("Asia/Kolkata")
            .format("D-MMM-YYYY h:mm a"),
          courseStartDate: dayjs
            .unix(item.utc_start_at)
            .tz("Asia/Kolkata")
            .format("D-MMM-YYYY"),
          courseEndDate: dayjs
            .unix(item.utc_end_at)
            .tz("Asia/Kolkata")
            .format("D-MMM-YYYY"),
        };
      }),
    ]);

    let commission = Math.ceil((amount * 25) / 100);

    setAmountData({
      totalAmount: { label: "Total Amount", value: amount },
      commission: { label: "Commission Amount", value: commission },
      amountToPay: { label: "Amount To Pay", value: amount - commission },
      totalBookings: { label: "Total Bookings", value: count },
    });
  };

  useEffect(() => {
    getDatas(
      dayjs(dates[0]).format("YYYY-MM-DD"),
      dayjs(
        new Date(dates[1]).setDate(new Date(dates[1]).getDate() + 1)
      ).format("YYYY-MM-DD")
    );
  }, []);

  console.log("teachers", teachers);

  const headers = [
    { name: "Booking Id", selector: "id" },
    { name: "Course Title", selector: "title" },
    { name: "Course Type", selector: "type_display" },
    { name: "Student Name", selector: "userName" },
    { name: "Student Email", selector: "userEmail" },
    { name: "Student Mobile", selector: "userMobile" },
    {
      name: "Teacher Name",
      selector: "teacher",
      format: (row) => {
        return row.teacher.full_name;
      },
    },
    { name: "Booking Date", selector: "bookingDate" },
    { name: "Course Start Date", selector: "courseStartDate" },
    { name: "Course End Date", selector: "courseEndDate" },
    {
      name: "Fees",
      selector: "fee",
      format: (row) => {
        return row.fee;
      },
    },
  ];

  console.log("datas", datas);

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <div className="bg-y-cream-600 min-h-screen p-5">
      <div
        className="flex align-items-center flex-wrap md:flex-nowrap justify-between my-5"
        style={{ width: "100%" }}
      >
        {amountData &&
          Object.keys(amountData).map((ele) => (
            <div className="mr-3 card-amount" style={{ width: "100%" }}>
              <div className="pt-1 pb-4 text-gray-600 text-base font-bold">
                {amountData[ele].label}
              </div>
              <div className="text-lg md:text-xl text-y-yellow-dark font-bold">
                {amountData[ele].value}
              </div>
            </div>
          ))}
      </div>

      <div className="my-5" style={{ width: "100%" }}>
        {currentTeacher && (
          <div className="mr-3 card-amount" style={{ width: "100%" }}>
            <div className="pt-1 pb-3 text-gray-600 text-base font-bold">
              Teacher
            </div>
            <div className="flex align-items-center flex-wrap md:flex-nowrap justify-between">
              <div className="text-lg md:text-xl">
                Name: <span className="font-bold">{currentTeacher.label}</span>
              </div>
              <div className="text-lg md:text-xl">
                Email:{" "}
                <span className="font-bold">
                  {datas && datas.length > 0 && datas[0]?.teacher?.email}
                </span>
              </div>
              <div className="text-lg md:text-xl">
                Mobile:{" "}
                <span className="font-bold">
                  {datas && datas.length > 0 && datas[0]?.teacher?.mobile}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="card-amount flex align-center">
        <div className="mr-5">
          <DateRangePicker
            onChange={(value) => {
              setDates(value);
              getDatas(
                dayjs(value[0]).format("YYYY-MM-DD"),
                dayjs(
                  new Date(value[1]).setDate(new Date(value[1]).getDate() + 1)
                ).format("YYYY-MM-DD")
              );
            }}
            value={dates}
          />
        </div>

        <div style={{ width: "40%" }}>
          <Select
            options={teachers}
            value={currentTeacher}
            onChange={(val) => {
              setCurrentTeacher(val);
              handleFilter(val.value);
            }}
          />
        </div>
      </div>

      <DataTableExtensions exportHeaders columns={headers} data={datas}>
        <DataTable
          paginationComponentOptions={paginationComponentOptions}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
    </div>
  );
};

export default Settlement;
