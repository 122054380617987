import React from 'react';
import './landing.css';
import Logo from "../../Graphics/Logo";
import HoveringGirl from "../../Graphics/HoveringGirl";

function LandingTemplate({ children }) {
  return (
    <div
      className="md:flex flex-row justify-between items-center md:max-w-screen-xl mx-auto h-screen md:flex-row-reverse p-5">

      {/* Phone Header */}
      <div className="md:hidden flex flex-row justify-between">
        <Logo width={120} height={50} id={1} />
      </div>

      <div className="flex-1 justify-center items-center flex">
        {/* Phone Graphic */}
        <HoveringGirl width={250} height={250} className="md:hidden mt-3" id={1} />

        {/* Desktop Graphic */}
        <HoveringGirl className="hidden md:block" width={400} id={2} />
      </div>

      <div className="flex-1">
        <Logo className="hidden md:block" id={2} />

        <div className="leading-loose text-center md:text-left md:max-w-md mt-3 sm:mt-12">
          {children}
        </div>
      </div>
    </div>
  );
}

export default LandingTemplate;
