import _ from "lodash";

const UserSelectors = {
  userPayState: (store) => store.user.payment,
  showMenu: (store) => store.user.showMenu,
  userTimeZone: (store) => store.user.timeZone,
  userTime: (store) => store.user.time,
  user: (store) => store.user.user,
  isLoggedIn: (store) => !!store.user.user,
  profilePicture: (store) => _.get(store, "user.user.profile.picture"),
  email: (store) => _.get(store, "user.user.email"),
  canPayWhatYouWant: (store) =>
    _.get(store, "user.user.profile.can_pay_what_you_want", false),
  numBooked: (store) => _.get(store, "user.user.profile.num_booked", 0),
  showBottom: (store) => store.user.showBottom,
};

export default UserSelectors;
