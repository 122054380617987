import React from 'react';
import { useForm } from "react-hook-form";
import Button from "../../../Atoms/Button/Button";
import Checkbox from "../../../Atoms/Checkbox";
import DaySelectField from "../../../Atoms/DaysSelectField";
import Form from "../../../Atoms/Form";
import FormText from "../../../Atoms/FormText";
import Input from "../../../Atoms/Input/Input";
import RadioButton from "../../../Atoms/RadioButton";
import Select from "../../../Atoms/Select";

export default function TimingsStep({ data, onNext, formErrors, isCourse }) {
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: data,
  });

  const duration = [];
  for (let i = 30; i <= 120; i += 15) {
    duration.push({ value: i, label: `${i} min` })
  }

  const fieldProps = {
    register,
    duration,
    watch,
    control,
  };

  function _onNext(values) {
    values.join_after_start = !!values.join_after_start;
    onNext(values);
  }

  return (
    <div className="mt-8">
      <Form onSubmit={handleSubmit(_onNext)} errors={formErrors}>
        {isCourse ? <CourseFields {...fieldProps} /> : <ClassFields {...fieldProps} />}

        <div className="mt-2 flex flex-row justify-end">
          <Button type="submit">Next</Button>
        </div>
      </Form>
    </div>
  );
}

function ClassFields({ register, watch, control, duration }) {
  const recurringClass = watch('recurring_class');

  return (
    <div>
      <FormText className="mb-5">Class timing</FormText>
      <div className="flex flex-row justify-between">
        <Input
          name="start_time"
          label="Start Time"
          type="time"
          inputWidth="w-40 sm:w-48"
          required
          inputRef={register({ required: true })}
        />
        <Select
          name="duration"
          label="Duration"
          options={duration}
          inputWidth="w-40 sm:w-48"
          required
          inputRef={register({ required: true })}
        />
      </div>

      <FormText className="my-5">Weekly schedule</FormText>
      <RepeatSection register={register} watch={watch} control={control} />

      <FormText className="my-5">What kind of class is this?</FormText>

      <RadioButton
        name="recurring_class"
        label="A recurring class where students can join anytime"
        inputRef={register()}
        value="1"
      />

      <RadioButton
        name="recurring_class"
        label="A batch with a defined start date"
        inputRef={register()}
        value="0"
      />

      {(recurringClass === '0' || recurringClass === false) && (
        <div className="mt-5">
          <div className="flex flex-row justify-between">
            <Input
              name="start_date"
              label="Start Date"
              type="date"
              inputWidth="w-40 sm:w-48"
              required
              inputRef={register({ required: true })}
            />
            <Input
              name="end_date"
              label="End Date"
              type="date"
              inputWidth="w-40 sm:w-48"
              inputRef={register()}
            />
          </div>

          <Checkbox
            name="join_after_start"
            label="Students cannot join once the batch begins"
            inputRef={register()}
          />
        </div>
      )}
    </div>
  );
}

function CourseFields({ register, duration, watch, control }) {
  return (
    <div>
      <FormText className="mb-5">Course Dates</FormText>
      <div className="flex flex-row justify-between">
        <Input
          name="start_date"
          label="Start Date"
          type="date"
          inputWidth="w-40 sm:w-48"
          required
          inputRef={register({ required: true })}
        />
        <Input
          name="end_date"
          label="End Date"
          type="date"
          inputWidth="w-40 sm:w-48"
          required
          inputRef={register({ required: true })}
        />
      </div>
      <Checkbox
        name="join_after_start"
        label="Students cannot join the course once the course begins"
        inputRef={register()}
      />

      <FormText className="my-5">Class schedule</FormText>
      <RepeatSection register={register} watch={watch} control={control} />

      <FormText className="my-5">Daily class timing</FormText>
      <div className="flex flex-row justify-between">
        <Input
          name="start_time"
          label="Start Time"
          type="time"
          inputWidth="w-40 sm:w-48"
          required
          inputRef={register({ required: true })}
        />
        <Select
          name="duration"
          label="Duration"
          options={duration}
          inputWidth="w-40 sm:w-48"
          required
          inputRef={register({ required: true })}
        />
      </div>
    </div>
  );
}

function RepeatSection({ watch, control, register, isCourse }) {
  const repeatType = watch('repeat_type');

  let repeatField = null;
  if (repeatType === 'custom') {
    repeatField = (
      <div className="mt-4">
        <DaySelectField
          required
          control={control}
          name="repeat_days"
          label="Select the days you have classes on"
        />
      </div>
    )

  } else if (repeatType === 'none') {
    repeatField = (
      <div className="mt-4">
        <Input
          required
          name="repeat_day"
          label="Which day is the class on?"
          type="date"
          inputRef={register({ required: true })}
        />
      </div>
    );
  }

  return (
    <div>
      <Select
        name="repeat_type"
        label="Repeats"
        options={[
          { value: 'everyday', label: 'Everyday' },
          { value: 'custom', label: 'Specific Days' },
          { value: 'none', label: 'One Time' },
        ]}
        required
        inputRef={register({ required: true })}
      />

      {repeatField}
    </div>
  );
}
