import cx from "classnames";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { StorefrontApi } from "../../Api/StorefrontApi";
import Button from "../../Atoms/Button/Button";
import ErrorMessage from "../../Atoms/ErrorMessage";
import SessionDetailsComponents from "../../Components/SessionDetailsComponents";
import UserSelectors from "../../Selectors/user";
import CourseSelectors from "../../Selectors/course";
import PaymentService from "../../Services/PaymentService";
import TextService from "../../Services/TextService";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import CourseCard from "../../Atoms/CourseCard";
import Vent from "../../vent";
import UrlService from "../../Services/UrlService";
import Whatsapp from "../../Components/Whatsapp";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import UserAction from "../../Actions/user";
import Reviews from "../../Components/Reviews";
import timeZoneFormat from "../../Utilities/TimeZoneFormat";
import Faq from "../Storefront/FAQ";

import { Passers } from "prop-passer";

import Modal from "../../Atoms/Modal/Modal";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const { CoverImage, Teacher, School } = SessionDetailsComponents;

export default function SessionDetails(props) {
  const dispatch = useDispatch();
  const { callApi, data, errorMessage } = useApi(StorefrontApi.sessionDetails);

  const history = useHistory();
  const { search } = useLocation();

  let searchText = new URLSearchParams(search);

  let arr = props?.match?.params?.slug
    ? props?.match?.params?.slug.split("-")
    : [];

  const id = searchText.get("name")
    ? searchText.get("name")
    : arr[arr.length - 1];

  const teacherDomain = UrlService.getTeacherDomain();

  React.useEffect(() => {
    callApi(id);
    dispatch(UserAction.setPaymentLogin(false));
    pageTracking();
    ReactPixel.pageView();
  }, [callApi, id]);

  if (errorMessage) {
    return (
      <div className="p-8 pt-5">
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  function onJoin() {
    history.push(`/session/${id}/payment`);
  }

  function onCourseDetails(course) {
    Vent.onClassDetails(Vent.TYPE_MONTHLY_CLASS, course.title);
  }

  const session = data.data;

  //console.log("session", session);

  const courseDescription =
    session.course.description[0] === "["
      ? JSON.parse(session.course.description)
      : session.course.description;

  const el = document.querySelector("meta[property='og:url']");
  const el1 = document.querySelector("meta[property='og:title']");
  const el2 = document.querySelector("meta[property='og:description']");
  const el3 = document.querySelector("meta[property='og:image']");
  el.setAttribute("content", window.location.href);
  el1.setAttribute("content", session.course.title);
  el2.setAttribute("content", session.course.title);
  el3.setAttribute(
    "content",
    session.course.images.length > 0 ? session.course.images[0].file : ""
  );

  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Yoga Class",
    headline: session.course.title,
    description: session.course.title,
    image:
      session.course.images.length > 0 ? session.course.images[0].file : "",
    datePublished: new Date().toISOString(),
    mainEntityOfPage: { "@type": "Webpage", "@id": window.location.href },
    author: {
      "@type": "Person",
      name: session.course.teacher.full_name
        ? session.course.teacher.full_name
        : "",
      url: session.course.teacher.domain
        ? `https://${session.course.teacher.domain}.do.yoga/`
        : "",
    },
  };

  return (
    <div className="relative overflow-hidden 2xl:overflow-auto">
      <Helmet>
        <title>{session.course.title}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={session.course.title}></meta>
        <meta name="description" content={session.course.title} />

        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>

        {/* <meta property="og:title" content={session.course.title}></meta>
        <meta property="twitter:title" content={session.course.title}></meta>
        <meta property="og:description" content={session.course.title}></meta>
        <meta
          property="twitter:description"
          content={session.course.title}
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
        <meta
          property="og:image"
          content={
            session.course.images.length > 0
              ? session.course.images[0].file
              : ""
          }
        ></meta>
        <meta
          property="twitter:image"
          content={
            session.course.images.length > 0
              ? session.course.images[0].file
              : ""
          }
        ></meta> */}
      </Helmet>

      <div
        className="w-60 h-60 rounded-full absolute top-0 right-0 z-0 transform translate-x-40 translate-y-10"
        style={{ backgroundColor: "#F9F0E0" }}
      />
      <div
        className="w-44 h-44 rounded-full absolute left-0 right-0 z-0 transform -translate-x-40 translate-y-40"
        style={{ backgroundColor: "#F9F0E0" }}
      />

      <div className="max-w-screen-lg mx-auto p-8 pb-0 relative">
        <div
          className="hidden sm:block w-20 h-20 rounded-full absolute top-0 right-0 z-0 transform translate-y-96 z-0"
          style={{ backgroundColor: "#F9F0E0" }}
        />

        <div className="z-50">
          <div
            onClick={() => history.goBack()}
            className="text-y-yellow-dark font-semibold text-sm flex flex-row items-center cursor-pointer"
          >
            <svg
              className="mr-2"
              width="16"
              height="8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.375 3.25H2.139L4.33 1.068A.625.625 0 103.45.182L.184 3.432a.626.626 0 000 .885v.001l3.266 3.25a.625.625 0 00.881-.886L2.14 4.5h13.236a.625.625 0 100-1.25z"
                fill="currentColor"
              />
            </svg>
            Back to all classes
          </div>

          <div className="lg:flex flex-row justify-between mt-5">
            <CoverImage
              images={session.course.images}
              title={session.course.title}
            />
            <DetailsCard
              session={session}
              onJoin={onJoin}
              teacherDomain={teacherDomain}
            />
          </div>

          <div className="max-w-xl">
            <div className="mt-10">
              {courseDescription &&
              courseDescription.length > 0 &&
              courseDescription[0].title ? (
                <>
                  {courseDescription.map((ele) => (
                    <div className="mb-4">
                      <div className="font-bold">{ele.title}</div>
                      {ele.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ele.description.replaceAll(
                              "<p></p>",
                              `<p style="padding:15px 0;"></p>`
                            ),
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </>
              ) : courseDescription && courseDescription.length > 0 ? (
                <div></div>
              ) : (
                <div>{courseDescription}</div>
              )}
            </div>

            {session.course.videos && (
              <div className="mt-5">
                <video
                  className="object-cover w-full h-full"
                  controls
                  style={{
                    height: "30vh",
                  }}
                >
                  <source src={session.course.videos} type="video/mp4" />
                </video>
              </div>
            )}

            <div className="mt-10">
              <Teacher teacher={session.course.teacher} />
            </div>

            <div className="mt-10">
              <School teacher={session.course.teacher} />
            </div>
          </div>
        </div>
        <h2 className="font-semibold text-xl mb-2 mt-4">Monthly Course</h2>
        <CourseCard
          link={`/monthly-courses/${session.course.slug}-${session.course.id}`}
          course={session.course}
          onDetails={() => onCourseDetails(session.course)}
        />

        <div className="mt-10">
          <Reviews id={session.course.teacher.id} />
        </div>
      </div>

      <div class="max-w-screen-lg mx-auto py-8 pt-0 relative">
        <h2 className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0 pb-4">
          FAQs - Frequently Asked Questions
        </h2>
        <Faq />
      </div>
    </div>
  );
}

function DetailsCard({ session, onJoin, teacherDomain }) {
  const userTimeZone = useSelector(UserSelectors.userTimeZone);
  const canPayWhatYouWant = useSelector((store) =>
    UserSelectors.canPayWhatYouWant(store)
  );
  const numBooked = useSelector((store) => UserSelectors.numBooked(store));
  const teacherData = useSelector(CourseSelectors.streamDatas);

  const { course } = session;

  const [fields, setFields] = useState([]);

  const url = window.location.href;

  const title = `Hi. Check out this yoga course. Doing yoga regularly can transform you.`;

  const size = "2.5rem";

  const [showModal, setShowModal] = useState(false);

  const ShareList = Passers({
    url: "",
    className: "network__share-button",
  })({
    className: "network cursor-pointer hover transition--default",
  })("li");

  useEffect(() => {
    const fieldsData = [];
    if (course.level) {
      fieldsData.push({ key: "LEVEL", value: course.level });
    }

    if (course.style) {
      fieldsData.push({ key: "STYLE", value: course.style });
    }

    if (session.utc_start_at) {
      const startTime = timeZoneFormat(
        userTimeZone,
        session.date,
        session.course.start_time
      );

      fieldsData.push({
        key: "TIME",
        value: `${startTime} (${course.duration} min)`,
      });
    }

    if (course.single_currency_fee) {
      const feeObject = PaymentService.getSessionFeeObjectFromFee(
        course.single_currency_fee,
        canPayWhatYouWant,
        numBooked
      );

      fieldsData.push({
        key: "FEE",
        value: (
          <span className="flex flex-row items-center">
            <span
              className={
                feeObject.strike && !teacherDomain ? "line-through" : null
              }
            >
              {TextService.formatCurrencyFee(feeObject)}
            </span>
            {feeObject.strike && !teacherDomain && (
              <span className="text-sm text-y-gray pl-2">
                {feeObject.strikeText}
              </span>
            )}
          </span>
        ),
      });
    }

    const teacherId = session?.course?.teacher?.id;

    if (teacherData[teacherId]) {
      if (teacherData[teacherId].avgReviewsCount) {
        fieldsData.push({
          key: "Rating",
          value: teacherData[teacherId].avgReviewsCount,
        });
      }

      if (teacherData[teacherId].total) {
        fieldsData.push({
          key: "Streams",
          value: teacherData[teacherId].total,
        });
      }
    }

    setFields([...fieldsData]);
  }, [userTimeZone]);

  return (
    <>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(!showModal);
        }}
        className="sm:w-full sm:max-w-md"
      >
        <div className="flex flex-wrap p-10">
          <div>
            <WhatsappShareButton
              url={url}
              title={title}
              separator="     "
              className="m-5"
            >
              <WhatsappIcon size={size} />
            </WhatsappShareButton>
          </div>
          <div>
            <ShareList>
              <FacebookShareButton quote={title} className="m-5">
                <FacebookIcon size={size} />
              </FacebookShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TwitterShareButton title={title} className="m-5">
                <TwitterIcon size={size} />
              </TwitterShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <LinkedinShareButton title={title} className="m-5">
                <LinkedinIcon size={size} />
              </LinkedinShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TelegramShareButton title={title} className="m-5">
                <TelegramIcon size={size} />
              </TelegramShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <EmailShareButton subject={title} body={title} className="m-5">
                <EmailIcon size={size} />
              </EmailShareButton>
            </ShareList>
          </div>
          <div></div>
          <div></div>
        </div>
      </Modal>
      <div
        className={cx(
          "mt-5 lg:mt-0",
          "sm:max-w-xs h-full w-full lg:bg-white lg:p-8 lg:rounded-md lg:shadow-card-cream-sm z-10"
        )}
      >
        <h2 className="font-semibold text-xl">{session.course.title}</h2>
        <div className="mt-5">
          {fields.map((f) => (
            <div
              key={f.key}
              className="flex flex-row justify-start text-sm mb-2"
            >
              <p
                className="text-gray-400 tracking-wide"
                style={{ minWidth: 60 }}
              >
                {f.key}:
              </p>
              <p>{f.value}</p>
            </div>
          ))}
        </div>
        <Button className="mt-8 w-full" onClick={onJoin}>
          Join class
        </Button>

        <button
          className={cx(
            "w-full mt-5 focus:outline-none",
            `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
            "cursor-pointer text-y-yellow-dark flex items-center justify-center"
          )}
          style={{ backgroundColor: "#ffffff", border: "1px solid" }}
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          <img
            src="/img/share.png"
            height={30}
            width={30}
            alt="whatspp"
            style={{ marginRight: 20 }}
          />
          Share
        </button>

        <Whatsapp
          name={session.course.title}
          teacher={session.course.teacher.full_name}
        />
      </div>
    </>
  );
}
