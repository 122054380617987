import dayjs from 'dayjs'
const timeZoneFormat = (timeZone, courseDate = '', courseTime) => {
  let date = `${new Date(courseDate).toLocaleString('en-us', {
    weekday: 'short',
  })} ${new Date(courseDate).toLocaleString('default', {
    month: 'short',
  })} ${courseDate.slice(8)} ${courseDate.slice(
    0,
    4,
  )} ${courseTime} GMT+0530 (India Standard Time)`

  let newDate = new Date(
    new Date(date).toLocaleString('en-US', {
      timeZone: timeZone,
    }),
  )

  const convertedDate = dayjs(courseDate + ' ' + courseTime)
    .tz(timeZone)
    .format('h:mm a')
  //console.log("convert", convertedDate, timeZone);
  return dayjs(newDate).format('h:mm a')
}

export default timeZoneFormat
