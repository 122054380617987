import Modal from "../../Atoms/Modal/Modal";
import Button from "../../Atoms/Button/Button";
import ErrorMessage from "../../Atoms/ErrorMessage";

export default function SubscriptionJoinModal({
  show,
  onClose,
  data,
  error,
  onJoin,
  headarObj,
}) {
  return (
    <Modal show={show} onClose={onClose} className="sm:w-full sm:max-w-md">
      <Header {...headarObj} />

      <div className="p-7">
        {error && <ErrorMessage className="block mb-5">{error}</ErrorMessage>}
        <div className="flex flex-row justify-between items-center text-lg">
          <div className="font-medium"></div>
          <Button onClick={onJoin}>Join Class</Button>
        </div>
      </div>
    </Modal>
  );
}

function Header({ title, image, expired, classes }) {
  return (
    <div className="p-5 bg-gray-100 flex flex-row justify-between">
      <div>
        <h2 className="text-lg font-medium">{title}</h2>

        <h4 className="text-sm font-medium">
          You have subscription for this class
        </h4>

        <div className="text-sm mt-2">
          {classes && <div>Class Remaining {classes}</div>}

          {expired && expired[2] !== "3" && (
            <div className="flex flex-row items-center">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4531 0.9375H9.375V0.375C9.375 0.167906 9.20712 0 9 0C8.79288 0 8.625 0.167906 8.625 0.375V0.9375H3.375V0.375C3.375 0.167906 3.20712 0 3 0C2.79288 0 2.625 0.167906 2.625 0.375V0.9375H1.54688C0.693914 0.9375 0 1.63141 0 2.48438V10.4531C0 11.3061 0.693914 12 1.54688 12H10.4531C11.3061 12 12 11.3061 12 10.4531V2.48438C12 1.63141 11.3061 0.9375 10.4531 0.9375ZM1.54688 1.6875H2.625V2.0625C2.625 2.26959 2.79288 2.4375 3 2.4375C3.20712 2.4375 3.375 2.26959 3.375 2.0625V1.6875H8.625V2.0625C8.625 2.26959 8.79288 2.4375 9 2.4375C9.20712 2.4375 9.375 2.26959 9.375 2.0625V1.6875H10.4531C10.8925 1.6875 11.25 2.04497 11.25 2.48438V3.375H0.75V2.48438C0.75 2.04497 1.10747 1.6875 1.54688 1.6875ZM10.4531 11.25H1.54688C1.10747 11.25 0.75 10.8925 0.75 10.4531V4.125H11.25V10.4531C11.25 10.8925 10.8925 11.25 10.4531 11.25Z"
                  fill="black"
                />
              </svg>
              <span className="pl-2 font-medium">{expired}</span>
            </div>
          )}
        </div>
      </div>

      {image && (
        <img
          style={{ height: "80px", width: "100px" }}
          className="object-cover rounded ml-5"
          src={image}
          alt="Placeholder"
        />
      )}
    </div>
  );
}
