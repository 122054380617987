import cx from "classnames";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { StorefrontApi } from "../../Api/StorefrontApi";
import Button from "../../Atoms/Button/Button";
import ErrorMessage from "../../Atoms/ErrorMessage";
import DateAndTimeCard from "../../Components/OneDateAndTimeCard";
import SessionDetailsComponents from "../../Components/SessionDetailsComponents";
import TextService from "../../Services/TextService";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import UrlService from "../../Services/UrlService";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

const { Teacher } = SessionDetailsComponents;

export default function OneOnOneDetails(props) {
  const dateRef = React.useRef();
  const { callApi, data, errorMessage } = useApi(StorefrontApi.oneOnOneDetails);
  const [showBooking, setShowBooking] = React.useState(false);
  const [bookingProps, setBookingProps] = React.useState({});
  const history = useHistory();
  const { id } = useParams();

  React.useEffect(() => {
    callApi(id);
    pageTracking(props);
    ReactPixel.pageView();
  }, [callApi, id]);

  if (errorMessage) {
    return (
      <div className="p-8 pt-5">
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  function onHeaderJoinButton() {
    if (!dateRef.current) {
      return;
    }

    dateRef.current.scrollIntoView({
      block: "center",
      behaviour: "smooth",
    });
  }

  function onJoin(props) {
    // setBookingProps({ date: props.date, time: `${props.slot}:00` });
    // setShowBooking(true);

    history.push(`/1-1/${id}/payment?date=${props.date}&slot=${props.slot}`);
  }

  const one = data.data;

  return (
    <div className="relative overflow-hidden 2xl:overflow-auto">
      <Helmet>
        <title>{one.teacher.full_name}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={one.teacher.full_name}></meta>
        <meta name="description" content={one.teacher.full_name} />
        <meta property="og:title" content={one.teacher.full_name}></meta>
        <meta property="twitter:title" content={one.teacher.full_name}></meta>
        <meta property="og:description" content={one.teacher.full_name}></meta>
        <meta
          property="twitter:description"
          content={one.teacher.full_name}
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>

      <div
        className="w-60 h-60 rounded-full absolute top-0 right-0 z-0 transform translate-x-40 translate-y-10"
        style={{ backgroundColor: "#F9F0E0" }}
      />
      <div
        className="w-44 h-44 rounded-full absolute left-0 right-0 z-0 transform -translate-x-40 translate-y-40"
        style={{ backgroundColor: "#F9F0E0" }}
      />

      <div className="max-w-screen-lg mx-auto p-8 relative">
        <div
          className="hidden sm:block w-20 h-20 rounded-full absolute top-0 right-0 z-0 transform translate-y-96 z-0"
          style={{ backgroundColor: "#F9F0E0" }}
        />

        <div className="z-50">
          <p
            onClick={() => history.goBack()}
            className="text-y-yellow-dark font-semibold text-sm flex flex-row items-center cursor-pointer"
          >
            <svg
              className="mr-2"
              width="16"
              height="8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.375 3.25H2.139L4.33 1.068A.625.625 0 103.45.182L.184 3.432a.626.626 0 000 .885v.001l3.266 3.25a.625.625 0 00.881-.886L2.14 4.5h13.236a.625.625 0 100-1.25z"
                fill="currentColor"
              />
            </svg>
            Back to all teachers
          </p>

          <div className="lg:flex flex-row justify-between mt-5">
            <Teacher teacher={one.teacher} hideTitle />
            <DetailsCard one={one} onJoin={onHeaderJoinButton} />
          </div>

          <div className="max-w-xl">
            <div className="mt-10">
              <DateAndTimeCard one={one} domRef={dateRef} onJoin={onJoin} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DetailsCard({ one, onJoin }) {
  const fields = [];

  if (one.duration) {
    fields.push({ key: "TIME", value: `${one.duration} min` });
  }

  if (one.currency_fee) {
    fields.push({
      key: "FEE",
      value: TextService.formatCurrencyFee(one.currency_fee),
    });
  }

  return (
    <div
      className={cx(
        "mt-5 lg:mt-0",
        "sm:max-w-xs h-full w-full lg:bg-white lg:p-8 lg:rounded-md lg:shadow-card-cream-sm z-10"
      )}
    >
      <h2 className="font-semibold text-xl">1:1 Yoga Session</h2>
      <div className="mt-5">
        {fields.map((f) => (
          <div key={f.key} className="flex flex-row justify-start text-sm mb-2">
            <p className="text-gray-400 tracking-wide" style={{ minWidth: 60 }}>
              {f.key}:
            </p>
            <p>{f.value}</p>
          </div>
        ))}
      </div>

      <Button className="mt-8 w-full" onClick={onJoin}>
        Book 1:1 session
      </Button>
    </div>
  );
}
