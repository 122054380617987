import cx from "classnames";
import React from "react";
import { FormContext } from "../Form";
import useDimensions from "react-use-dimensions";

function InputWrapper({
  children,
  label,
  name,
  errors,
  required,
  wrapperStyle,
  inputWidth = "w-full",
  prefix,
  prefixWidth = "pl-10",
  suffix,
  show,
}) {
  const [focused, setFocused] = React.useState(false);
  const formCtx = React.useContext(FormContext);
  const fieldErrors = formCtx.errors[name] || [];

  const e = [...(errors || []), ...fieldErrors].filter((o) => !!o);

  let Wrapper = HoverLabelWrapper;

  if (wrapperStyle === "hover-label") {
    Wrapper = HoverLabelWrapper;
  } else if (wrapperStyle === "above-label") {
    Wrapper = AboveWrapper;
  } else if (wrapperStyle === "checkbox") {
    inputWidth = null;
    Wrapper = CheckboxWrapper;
  }

  return (
    <div>
      <Wrapper
        focused={focused}
        label={label}
        required={required}
        hasError={e.length > 0}
        prefix={prefix}
        suffix={suffix}
      >
        {children({
          className: cx(
            prefix ? `${prefixWidth} pr-2` : "px-2",
            "py-1 outline-none",
            inputWidth
          ),
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
        })}
      </Wrapper>

      <div className="mt-1 pl-3 text-red-500 text-sm text-right">
        {e.length === 0 && <div>&nbsp;</div>}
        {e.map((e) => (
          <div key={e}>{e}</div>
        ))}
      </div>
    </div>
  );
}

function HoverLabelWrapper({
  children,
  focused,
  label,
  required,
  hasError,
  prefix,
  suffix,
}) {
  const [ref, { height }] = useDimensions();

  let pTop = "pt-3";
  if (height >= 30) {
    pTop = "pt-8";
  }

  let thickBorder = false;
  let borderColor = "border-gray-300";
  let textColor = "text-y-gray";

  if (focused) {
    thickBorder = true;
    borderColor = "border-y-yellow";
    textColor = "text-y-yellow";
  }

  if (hasError) {
    thickBorder = true;
    borderColor = "border-red-500";
    textColor = "text-red-500";
  }

  return (
    <div
      className={cx("border-transparent", thickBorder ? "border-0" : "border")}
      style={{ background: "#fff" }}
    >
      <div
        className={cx(
          "rounded-lg px-1 pb-2 relative",
          thickBorder ? "border-2" : "border",
          borderColor,
          pTop
        )}
      >
        {label && (
          <div
            ref={ref}
            className={cx(
              `absolute font-light text-sm px-1 mr-3 top-0 bg-white transform -translate-y-2.5 translate-x-1`,
              textColor
            )}
          >
            {label} {required ? "*" : ""}
          </div>
        )}

        {prefix && (
          <div className="absolute text-y-gray top-4 left-3">{prefix}</div>
        )}

        {suffix && (
          <div className="absolute text-y-gray top-4 right-3">{suffix}</div>
        )}

        {children}
      </div>
    </div>
  );
}

function AboveWrapper({ children, focused, label, required, hasError }) {
  return (
    <div>
      {label && (
        <div className="text-y-gray mb-1 font-light text-sm">
          {label} {required ? "*" : ""}
        </div>
      )}
      <div
        className={cx("border-transparent", focused ? "border-0" : "border")}
      >
        <div
          className={cx(
            "rounded-lg p-2 relative",
            focused ? "border-2 border-y-yellow" : "border border-gray-400"
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

function CheckboxWrapper({ label, required, children }) {
  return (
    <div className="flex flex-row items-center">
      {children}
      {label && (
        <div className="text-y-gray ml-2 text-sm">
          {label} {required ? "*" : ""}
        </div>
      )}
    </div>
  );
}

export default InputWrapper;
