import cx from 'classnames';
import React from 'react';
import InputWrapper from "../InputWrapper/InputWrapper";


export default function RadioButton({ label, name, errors, value, inputRef }) {
  return (
    <InputWrapper name={name} label={label} errors={errors} wrapperStyle="checkbox">
      {({ className, ...props }) => (
        <input
          {...props}
          name={name}
          type="radio"
          value={value}
          className={cx(className, "appearance-none cursor-pointer ticked w-5 h-5 rounded-full border border-gray-300 checked:border-transparent checked:bg-y-yellow")}
          ref={inputRef}
        />
      )}
    </InputWrapper>
  );
}
