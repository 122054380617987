import GlowingPicture from "../../Atoms/GlowingPicture";
import React, { useState } from "react";
import Ratings from "react-ratings-declarative";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { ReviewsApi } from "../../Api/ReviewApi";
import dayjs from "dayjs";
import useIsMobile from "../../Hooks/useMobile";
import Slider from "react-slick";

const Reviews = ({ id }) => {
  const { callApi, data, loading } = useApi(ReviewsApi.list);

  const reviewsData = data?.data || [];

  const [showMore, setShowMore] = useState({});

  const isMobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    // beforeChange: (current, next) => {
    //   dispatch(CourseAction.setIndex("review", next));
    // },
  };

  useState(() => {
    callApi(id);
  }, [id]);

  const renderContent = (ele, mob = false) => {
    return (
      <div
        className={`p-5 bg-white lg:rounded-md lg:shadow-card-cream-sm sm:max-w-xs w-full h-60 ${
          mob ? "mr-5 px-5" : ""
        }`}
      >
        <div className="flex-col md:flex-row items-center">
          <div className="pt-0 pl-3 md:pl-0 pb-2 text-sm">
            <div
              style={{
                fontStyle: "italic",
                maxHeight: 85,
                overflow: "auto",
              }}
            >
              {showMore[ele.id] ? (
                <>“{ele.comment.replaceAll("?", ``)}”</>
              ) : (
                <>“{ele.comment.replaceAll("?", ``).substring(0, 140)}”</>
              )}
            </div>
            {ele.comment && ele.comment.length > 170 && (
              <div
                className="text-gray-600 pt-2 text-right"
                role="button"
                onClick={() => {
                  let values = { showMore };
                  const idValue = showMore[ele.id];
                  if (idValue) {
                    delete values[ele.id];
                  } else {
                    values[ele.id] = true;
                  }
                  setShowMore({ ...values });
                }}
              >
                {showMore[ele.id] ? "show less" : "show more"}
              </div>
            )}
          </div>
          <div className="flex pl-3 md:pl-0 items-center whitespace-nowrap">
            <div>
              <Ratings
                rating={Number(ele.rating)}
                widgetDimensions="20px"
                widgetSpacings="3px"
                widgetRatedColor="green"
              >
                <Ratings.Widget widgetRatedColor="orange" />
                <Ratings.Widget widgetRatedColor="orange" />
                <Ratings.Widget widgetRatedColor="orange" />
                <Ratings.Widget widgetRatedColor="orange" />
                <Ratings.Widget widgetRatedColor="orange" />
              </Ratings>
            </div>
          </div>
          <div className="flex items-center pt-2 pb-4">
            <div>
              {ele?.user?.picture ? (
                <GlowingPicture imgUrl={ele?.user?.picture} />
              ) : (
                <GlowingPicture imgUrl={""} />
              )}
            </div>
            <div className="font-xs pl-3 pr-5 whitespace-nowrap">
              <div>
                {ele?.user?.full_name
                  ? ele?.user?.full_name
                  : ele?.booking?.student?.full_name}
              </div>
              <div style={{ fontSize: 12, color: "grey  " }}>
                {dayjs(ele.created_at).format("DD-MMM-YYYY")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="">
      {!loading && reviewsData.length > 0 && (
        <h2 className="font-semibold text-xl mb-2">Reviews</h2>
      )}
      {isMobile ? (
        <div className="flex flex-nowrap w-full overflow-auto">
          {reviewsData &&
            reviewsData
              .slice(0)
              .reverse()
              .map((ele) => <>{renderContent(ele, true)}</>)}
        </div>
      ) : (
        <div className="px-5">
          <Slider {...settings}>
            {reviewsData &&
              reviewsData
                .slice(0)
                .reverse()
                .map((ele) => <>{renderContent(ele, false)}</>)}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default Reviews;
