import React, { useEffect, useState } from "react";
import cx from "classnames";
import { UserApi } from "../../Api/UserApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import StageCard from "../../Components/StageCard";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import ExperienceStage from "../TeacherProfile/Stages/ExperienceStage";
//import ProfilePicStage from "./Stages/ProfilePicStage";
import ProfileStage from "../TeacherProfile/Stages/ProfileStage";
import YogaSchoolStage from "../TeacherProfile/Stages/YogaSchoolStage";
import WebsiteStage from "../TeacherProfile/Stages/WebsiteStage";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import UserSelectors from "../../Selectors/user";
import { useDispatch, useSelector } from "react-redux";
import useApiPage from "../../Hooks/useApiPage";
import { CourseApi } from "../../Api/CourseApi";
import Setup from "../SetupCourse";
import { useHistory } from "react-router-dom";
import Button from "../../Atoms/Button/Button";
import UnderlineButton from "../../Atoms/UnderlineButton/UnderlineButton";

export default function TeacherProfile() {
  const history = useHistory();
  const { callApi, loading, data, errorMessage, setSuccessResponse } = useApi(
    UserApi.teacherProfile
  );

  const [showCourse, setShowCourse] = useState(false);

  const { data: coursListeData, view } = useApiPage(CourseApi.list);

  const profilePicture = useSelector(UserSelectors.profilePicture);

  const [current, setCurrent] = useState("");

  const [stageData, setStageData] = useState([]);

  const [showCourseForm, setShowCourseForm] = useState(false);

  React.useEffect(() => {
    callApi();
    pageTracking();
    ReactPixel.pageView();
  }, [callApi]);

  const getView = (teacher) => {
    if (!teacher.name || !teacher.location || !profilePicture) {
      setStageData([
        {
          title: "Profile",
          component: ProfileStage,
          props: {
            data: teacher,
            onSuccess,
          },
        },
      ]);
      setCurrent("Profile");
    } else if (
      !teacher.practicing_years ||
      !teacher.teaching_years ||
      !teacher.style ||
      !teacher.description
    ) {
      setStageData([
        {
          title: "Experience",
          component: ExperienceStage,
          props: {
            data: teacher,
            onSuccess,
          },
        },
      ]);
    } else if (!teacher.domain) {
      setStageData([
        {
          title: "Website",
          component: WebsiteStage,
          props: {
            data: teacher,
            onSuccess,
          },
        },
      ]);
    } else if (coursListeData?.data.length === 0) {
      setShowCourse(true);
    } else {
      history.push("/studio/bookings");
    }
  };

  useEffect(() => {
    if (data?.data) {
      const teacher = data?.data;
      getView(data?.data);
    }
  }, [data?.data]);

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (loading) {
    return <div>Loading Teacher Profile..</div>;
  }

  function onSuccess(data) {
    //console.log("successData", data);
    getView(data?.data);
    setSuccessResponse(data);
  }

  //console.log(stageData);

  const itemTypes = [
    {
      title: "Profile",
    },
    {
      title: "Experience",
    },
    {
      title: "Website",
    },
  ];

  //console.log(stageData);

  return (
    <div>
      <Helmet>
        <title>Profile</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Profile"></meta>
        <meta name="description" content="Profile" />
        <meta property="og:title" content="Profile"></meta>
        <meta property="twitter:title" content="Profile"></meta>
        <meta property="og:description" content="Profile"></meta>
        <meta property="twitter:description" content="Profile"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      {showCourseForm ? (
        <>
          <Setup showMyCourse={false} />
        </>
      ) : showCourse ? (
        <>
          <div className="pt-10 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
            Your teacher profile has been created.
          </div>
          <div className="pt-10 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
            Your personal yoga website is at
          </div>
          <div
            className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0"
            onClick={() => {
              window.open(`https://${data?.data?.domain}.do.yoga/`);
              ReactGA.event({
                category: "1:1 Details",
                action: "Routing",
                label: JSON.stringify(data?.data?.domain),
              });
              ReactPixel.track("track", {
                category: "1:1 Details",
                action: "Routing",
                label: JSON.stringify(data?.data?.domain),
              });
            }}
            role="button"
          >
            https://{data?.data?.domain}.do.yoga
          </div>
          <div className="pt-10 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
            Next, you should set up your courses you want to teach.
          </div>
          <div className="flex pt-6 pb-10 mb-10">
            <Button
              py="py-3"
              className="whitespace-nowrap"
              onClick={() => {
                setShowCourseForm(true);
              }}
            >
              Set up Course
            </Button>
          </div>
        </>
      ) : stageData && stageData.length > 0 ? (
        <>
          <div className="max-w-lg px-7 ml-10 mt-10 sm:px-0">
            {current === "Profile" ? (
              <>
                <div className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
                  Thanks for signing up.
                </div>
                <div className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
                  FIrst step is to create your teacher profile
                </div>
              </>
            ) : null}
          </div>
          <div className="w-screen max-w-xl ml-10 mt-5 bg-white">
            <div className="flex flex-row flex-nowrap overflow-x-auto pl-10 pt-10">
              {itemTypes.map((o) => (
                <div key={o.title} className="pr-7 whitespace-nowrap pb-3">
                  <UnderlineButton
                    yellow
                    selected={stageData[0].title === o.title}
                  >
                    {o.title}
                  </UnderlineButton>
                </div>
              ))}
            </div>
            <StageCard loading={loading} flow={true} stages={[...stageData]} />
          </div>
        </>
      ) : (
        <div>Loading Teacher Profile..</div>
      )}
    </div>
  );
}
