import React from 'react';
import { connect } from 'react-redux';
import ProfileModal from '../ProfileModal/ProfileModal';
import { bindActionCreators } from "redux";
import UiAction from '../../Actions/ui';

function ReduxProfileModal({ showProfileModal, onClose }) {
  return (
    <ProfileModal show={showProfileModal} onClose={onClose} />
  );
}

function mapStateToProps({ ui }) {
  return {
    showProfileModal: ui.showProfileModal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onClose: UiAction.hideProfile,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReduxProfileModal);