import MonthlyClassesGraphic from "../../../Graphics/MonthlyClassesGraphic";
import FilterClasses from "./Search";
import useIsMobile from "../../../Hooks/useMobile";
import cx from "classnames";
import pageTracking from "../../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import FilterDatasEnum from "../../../Utilities/FilterData";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import Button from "../../../Atoms/Button/Button";

export default function Live(props) {
  let countryCode = "";
  const isMobile = useIsMobile();

  const filterArrData = {
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
    queryText: "",
  };

  if (window.COUNTRY_CODE) {
    countryCode = window.COUNTRY_CODE;
  }

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  });

  return (
    <div className="px-0 sm:px-2 flex flex-col w-full">
      <Helmet>
        <title>Yoga classes for kids</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Yoga classes for kids"></meta>
        <meta
          name="description"
          content="Begin your yoga journey. Yoga is very helpful for physical, mental and emotional growth of
          children."
        />
        <meta property="og:title" content="Yoga classes for kids"></meta>
        <meta property="twitter:title" content="Yoga classes for kids"></meta>
        <meta
          property="og:description"
          content="Begin your yoga journey. Yoga is very helpful for physical, mental and emotional growth of
          children."
        ></meta>
        <meta
          property="twitter:description"
          content="Begin your yoga journey. Yoga is very helpful for physical, mental and emotional growth of
          children."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div
        className={cx(
          "flex flex-row justify-between items-center overflow-x-auto",
          "sm:h-full sm:w-4/5"
        )}
      >
        <div className="text-sm sm:text-lg text-gray-600 font-semibold pr-2 pt-0 sm:pt-5 sm:w-2/4">
          <p className="mt-2">Kids love yoga.</p>
          <p className="mt-2">
            We have curated a set of yoga classes your kids can join through the
            day.
          </p>
          <p className="mt-2">
            Yoga is very helpful for physical, mental and emotional growth of
            children.
          </p>
          <p className="mt-2">Sign up your kid to do yoga. 🧘‍♀️🧘‍♂️🙏</p>
        </div>
        <div>
          <MonthlyClassesGraphic width={isMobile ? 170 : 300} height={130} />
        </div>
      </div>
      <div
        className={cx(
          "px-0 flex flex-col-reverse md:flex-row justify-between items-start md:items-center sm:mt-10",
          "sm:h-full sm:w-4/5"
        )}
      >
        <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 w-3/6">
          <p className="mt-2 text-y-yellow-dark text-sm md:text-base">
            Monthly fees at ₹ 1000
          </p>

          <p
            className="pt-1 pb-4 text-gray-600 text-xs md:text-sm font-bold"
            //style={{ fontSize: "14px" }}
          >
            45 min classes, 5 days a week
          </p>

          <div className="flex pt-0">
            <Button
              py="py-3"
              className="whitespace-nowrap"
              onClick={() => {
                window.open(
                  "https://pages.razorpay.com/pl_LV1gYVkDFMuZx6/view",
                  "_blank"
                );
              }}
            >
              Book
            </Button>
          </div>
        </div>
        <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 w-3/6">
          <p className="mt-2 text-y-yellow-dark text-sm md:text-base">
            Join trial class at {countryCode === "IN" ? "₹ 50" : "$ 2"}
          </p>

          <p
            className="pt-1 pb-4 text-gray-600 text-xs md:text-sm font-bold"
            //style={{ fontSize: "14px" }}
          >
            Our yoga counselor will reach you to set up class
          </p>

          <div className="flex pt-0">
            <Button
              py="py-3"
              className="whitespace-nowrap"
              onClick={() => {
                window.open(
                  "https://pages.razorpay.com/pl_IUcnrCgQMSGz6a/view",
                  "_blank"
                );
              }}
            >
              Book
            </Button>
          </div>
        </div>
      </div>
      <FilterClasses
        name=""
        type="month"
        courseName="Monthly Yoga for Kids"
        filterArr={{
          ...filterArrData,
          levels: [{ value: "kids", label: "Kids", checked: false }],
        }}
        filter={{
          ...FilterDatasEnum,
          levels: [
            { value: "beginner", label: "Beginner", checked: false },
            { value: "intermediate", label: "Intermediate", checked: false },
            { value: "advanced", label: "Advanced", checked: false },
            { value: "kids", label: "Kids", checked: true },
          ],
        }}
        {...props}
      />
    </div>
  );
}
