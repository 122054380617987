import React, { useEffect } from "react";
import CourseForm from "./CourseForm";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function NewCourse() {
  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);
  return (
    <>
      <Helmet>
        <title>Monthly Course Setup</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Monthly Course Setup"></meta>
        <meta name="description" content="Monthly Course Setup" />
        <meta property="og:title" content="Monthly Course Setup"></meta>
        <meta property="twitter:title" content="Monthly Course Setup"></meta>
        <meta property="og:description" content="Monthly Course Setup"></meta>
        <meta
          property="twitter:description"
          content="Monthly Course Setup"
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <CourseForm isCourse={false} />
    </>
  );
}
