import Styles from "../../Constants/styles";
import { useForm } from "react-hook-form";
import Button from "../../Atoms/Button/Button";
import Form from "../../Atoms/Form";
import Input from "../../Atoms/Input/Input";
import { UserApi } from "../../Api/UserApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { useHistory } from "react-router-dom";
import RA from "../../Assets/RA.svg";
import MonthlyClasses from "../Storefront/MonthlyBeginnerFilter";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import useIsMobile from "../../Hooks/useMobile";
import FilterDatasEnum from "../../Utilities/FilterData";
import CoursesGraphic from "../../Graphics/CoursesGraphic";
import Swal from "sweetalert2";

const ViewAllComponent = ({ link, name }) => {
  const isMobile = useIsMobile();
  const history = useHistory();

  const handleClick = () => {
    ReactGA.event({
      category: "View All Course",
      action: "Routing",
      label: name,
    });
    ReactPixel.track("track", {
      category: "View All Course",
      action: "Routing",
      label: name,
    });
    history.push({
      pathname: link,
    });
  };
  return (
    <div
      className={`flex ${
        isMobile ? "justify-end" : "justify-center"
      } items-center px-0 pt-3 cursor-pointer`}
      onClick={handleClick}
    >
      <div className="pr-2 text-base text-y-yellow-dark font-bold">
        View All
      </div>
      <div>
        <svg
          width="25"
          height="25"
          viewBox="0 0 16 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.624989 4.50001L13.8612 4.50001L11.6686 6.68201C11.4239 6.9255 11.423 7.32122 11.6665 7.56588C11.91 7.81056 12.3057 7.81147 12.5504 7.568L15.8161 4.31801C15.8163 4.31782 15.8165 4.31761 15.8167 4.31742C16.0607 4.07392 16.0615 3.67692 15.8167 3.43261C15.8165 3.43242 15.8164 3.4322 15.8162 3.43201L12.5504 0.182024C12.3058 -0.0614138 11.9101 -0.0605698 11.6665 0.184148C11.423 0.428803 11.424 0.824521 11.6686 1.06802L13.8612 3.25001L0.624989 3.25001C0.279801 3.25001 -1.14441e-05 3.52983 -1.14441e-05 3.87501C-1.14441e-05 4.2202 0.279801 4.50001 0.624989 4.50001Z"
            fill="#E56F2C"
          />
        </svg>
      </div>
    </div>
  );
};

const Rheumatoid = () => {
  const { register, handleSubmit } = useForm({});
  const history = useHistory();
  const isMobile = useIsMobile();

  let countryCode = "";

  if (window.COUNTRY_CODE) {
    countryCode = window.COUNTRY_CODE;
  }

  const filterArrData = {
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
    queryText: "",
  };

  const { loading, callApi, errorMessage, data } = useApi(
    UserApi.createTherapyUser
  );

  function onSubmit(data) {
    //console.log(data);
    callApi({ ...data, course: "RA" })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data Saved Successfully",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/");
          }
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  }
  return (
    <div className="pt-5">
      <div className="text-lg md:text-xl text-y-yellow-dark font-bold">
        Yoga, Ayurveda & Diet Therapy for Rheumatoid Arthritis
      </div>
      <div className="pt-7 text-gray-800 text-base font-bold">
        Yoga & Ayurveda combined with the right diet & lifestyle offers a
        complete solution for people to manage and even reverse Rheumatoid
        Arthritis.
      </div>
      <div className="flex justify-center text-center w-full pt-5 h-full">
        <img className="w-full md:w-2/4 h-full md:h-2/4" src={RA} alt="logo" />
      </div>
      <div className="pt-7 text-lg md:text-xl text-y-yellow-dark font-bold">
        What is Rheumatoid Arthritis
      </div>
      <div className="pt-1 text-gray-700 text-base">
        Rheumatoid Arthritis / RA is a{" "}
        <span className="text-gray-800 font-bold">
          chronic condition that affects about 1-3% of the population
        </span>
        , with onset from early childhood to old age. Most{" "}
        <span className="text-gray-800 font-bold">
          obvious symptoms are pain at the joints, general body stiffness &
          lethargy.
        </span>
      </div>
      <div className="pt-7 text-lg md:text-xl text-y-yellow-dark font-bold">
        How does Yoga help manage RA
      </div>
      <div className="pt-1 text-gray-700 text-base">
        <span className="text-gray-800 font-bold">
          Yoga helps mobilize the body, ease stiffness at the joints and
          activate the Prana.
        </span>{" "}
        Prana represents the air element and activating its flow through the
        joints and through the body is very helpful.{" "}
      </div>

      <div className="text-lg md:text-xl text-y-yellow-dark font-bold mt-7">
        Book a free online consultation
      </div>
      <div className="pt-2">
        Talk to our yoga counselors who can understand your issue and suggest
        the right way to approach your condition. The consultation is offered
        free of charge.
      </div>
      <div className="flex mt-3 pt-3 pb-0">
        <a
          className=" text-base font-bold cursor-pointer py-3 px-7 rounded shadow-md text-center font-semibold text-white text-sm"
          style={{
            ...Styles.OrangeLinearGradient,
          }}
          href="https://calendly.com/doyoga/book-a-free-online-consultation"
          target="_blank"
        >
          Book
        </a>
      </div>

      <div className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-10">
        Monthly Yoga Courses for RA
      </div>

      <div>
        <MonthlyClasses
          filterArr={{
            ...filterArrData,
            healthConditions: [
              { value: "Arthritis", label: "Arthritis", checked: false },
            ],
            type: "month",
          }}
          name="Arthritis"
          typeData="ra"
        />
      </div>

      <ViewAllComponent
        link="/courses/health/Arthritis"
        name="Yoga for Arthritis"
        filterArrData={{
          ...filterArrData,
          healthConditions: [
            { value: "Arthritis", label: "Arthritis", checked: false },
          ],
        }}
        filterCheckDatas={{
          ...FilterDatasEnum,
          healthConditions: [
            ...FilterDatasEnum.healthConditions,
            { value: "Arthritis", label: "Arthritis", checked: true },
          ],
        }}
        bannerText={[
          "Yoga helps you manage your body better.",
          "Relieve physical discomforts and prepare for childbirth.",
        ]}
      />

      <div className="pt-10 md:pt-5 text-lg md:text-xl text-y-yellow-dark font-bold px-0 pb-0 sm:mt-10">
        Book trial class
      </div>

      <div className="flex flex-row items-center">
        <div>
          <div className="text-gray-600 text-base pt-5 px-0 font-bold">
            {countryCode === "IN" ? "Pay ₹50" : "Pay $5"} & Join any session
          </div>

          <div className="flex items-center pt-5">
            <Button
              py="py-3"
              className="whitespace-nowrap"
              onClick={() => {
                countryCode === "IN"
                  ? window.open("https://rzp.io/l/B2G3CH3", "_blank")
                  : window.open(
                      "https://buy.stripe.com/5kAbLl8aw5vC3ao5kk",
                      "_blank"
                    );
              }}
            >
              Sign Up
            </Button>
          </div>
        </div>
        <div>
          <CoursesGraphic
            width={!isMobile ? 280 : 162}
            height={!isMobile ? 160 : 98}
          />
        </div>
      </div>

      <div className="pt-7 text-lg md:text-xl text-y-yellow-dark font-bold">
        Holistic Yoga & Ayurveda Therapy for Rheumatoid Arthritis
      </div>
      <div>
        <span className="text-gray-800 font-bold">
          Ayurveda classifies Rheumatoid Arthritis as Amavat, imbalance of the
          Air element{" "}
        </span>{" "}
        in the body. Appropriate medicinal herbs and right lifestyle is
        recommended by Ayurveda to manage and reverse the condition.
      </div>
      <div className="mt-7">
        <span className="text-gray-800 font-bold">
          Allopathy does not have a real solution for Rheumatoid Arthritis.
          Here, RA is classifed as an auto immune disorder{" "}
        </span>{" "}
        with no real identification of the cause with a treatment methodology
        which works by chance.{" "}
        <span className="text-gray-800 font-bold">
          {" "}
          As the condition worsens allopathy shifts to harsher drugs like
          steroids with significant side effects.
        </span>
      </div>
      <div className="mt-7">
        <span className="text-gray-800 font-bold">
          Yoga, Ayurveda & Diet can transform you.{" "}
        </span>{" "}
        Not just help you with Rheumatoid Arthritis but improve you across -
        physically, mentally, emotionally & spiritually. Change your lifestyle.
        Change your energy.
      </div>
      <div className="text-gray-800 font-bold mt-7">
        What is included in the therapy?
      </div>
      <div className="mt-3">
        <div className="pl-3">
          <li className="text-gray-800 font-bold">
            Holistic program to improve your lifestyle.
          </li>
          <li>
            {" "}
            <span className="text-gray-800 font-bold">
              Daily Yoga Program{" "}
            </span>{" "}
            designed for Rheumatoid Arthritis
          </li>
          <li>
            <span className="text-gray-800 font-bold">Ayurveda </span>{" "}
            consultation with regular check ins
          </li>
          <li>
            <span className="text-gray-800 font-bold">Diet </span> consultation
            with regular check ins
          </li>
          <li>
            <span className="text-gray-800 font-bold">Regular checkins </span>{" "}
            with your health counselor.
          </li>
          <li>Medicines & Tests extra.</li>
        </div>
      </div>
      <div className="mt-7">
        You will be assigned a{" "}
        <span className="text-gray-800 font-bold">counselor </span>, who will
        coordinate your consultation with an{" "}
        <span className="text-gray-800 font-bold">ayurvedic physician </span>,
        set up your yoga classes with a{" "}
        <span className="text-gray-800 font-bold">yoga teacher </span>, and your
        <span className="text-gray-800 font-bold">diet consultation.</span>
      </div>

      <div className="pt-7 text-gray-700 text-base">
        <span className="text-gray-800 font-bold">
          6 months on this Therapy and you will see significant improvements
        </span>{" "}
        in your disease condition and your overall self.
      </div>
      <div className="pt-7 text-gray-800 text-base font-bold">Cost</div>
      <div className="pt-1 text-gray-700 text-base">Rs. 4000 per month.</div>
      <div className="pt-7 text-gray-700 text-base">
        <span className="text-gray-800 font-bold">30% off on 6 months </span>{" "}
        therapy.
      </div>
      <div className="pt-1 text-gray-700 text-base">6 months at Rs. 21000</div>
      <div className="text-lg md:text-xl text-y-yellow-dark font-bold mt-7">
        Book a free online consultation
      </div>
      <div className="pt-2">
        Talk to our yoga counselors who can understand your issue and suggest
        the right way to approach your condition. The consultation is offered
        free of charge.
      </div>
      <div className="flex mt-3 pt-3 pb-10">
        <a
          className=" text-base font-bold cursor-pointer py-3 px-7 rounded shadow-md text-center font-semibold text-white text-sm"
          style={{
            ...Styles.OrangeLinearGradient,
          }}
          href="https://calendly.com/doyoga/book-a-free-online-consultation"
          target="_blank"
        >
          Book
        </a>
      </div>

      <div className="text-gray-800 text-base font-bold">Get a Call Back</div>

      <div className="mt-5 bg-white px-7 sm:px-10 py-8 bg-white shadow-card-cream sm:rounded-md">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Input
              required
              name="name"
              label="Name"
              inputRef={register({ required: true })}
            />
          </div>

          <div className="">
            <Input
              required
              name="phone"
              label="Phone"
              inputRef={register({ required: true })}
            />
          </div>

          <div className="mt-2 flex flex-row justify-end">
            <Button type="submit">Submit</Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Rheumatoid;
