import cx from "classnames";
import React from "react";
import { MediaApi } from "../../Api/MediaApi";
import useImageUpload from "../../Hooks/useImageUpload";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import ErrorMessage from "../ErrorMessage";
import "./style.css";

export default function UploadPhotosGallery({
  type,
  label,
  required,
  images,
  onImage,
  video = false,
  courseCreate = false,
  setImages,
}) {
  return (
    <div className="flex flex-row flex-wrap">
      {images.map((i) => (
        <div className="mr-4 mb-4">
          <ImageBox
            key={i.uuid}
            id={i.uuid}
            image={i}
            images={images}
            setImages={setImages}
          />
        </div>
      ))}

      <div className="mr-4 mb-4">
        <TempUploadBox
          type={type}
          label={label}
          required={required}
          onImage={onImage}
          video={video}
          courseCreate={courseCreate}
        />
      </div>
    </div>
  );
}

function ImageBox({ image, id, images, setImages }) {
  return (
    <Box style={{ padding: 0 }} className={"img-wrap"}>
      <span
        onClick={() => {
          console.log("images", images, id);
          const filterData = images.filter((ele) => ele.uuid != id);
          console.log("call", filterData);
          setImages(filterData);
        }}
        class="close"
      >
        &times;
      </span>
      <img
        className="w-full h-full object-fit"
        src={image.file}
        alt="uploaded"
      />
    </Box>
  );
}

function TempUploadBox({
  type,
  label,
  required,
  onImage,
  video,
  courseCreate,
}) {
  const { callApi, loading, errorMessage } = useApi(MediaApi.uploadMedia);

  function onUpload(dataUrl, image) {
    callApi(type, image).then(({ data }) => {
      onImage(data.data);
    });
  }

  if (loading) {
    return (
      <Box>
        <svg
          className="animate-spin h-8 w-8 text-y-yellow"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </Box>
    );
  }

  return (
    <UploadBox
      label={label}
      required={required}
      onImage={onUpload}
      errorMessage={errorMessage}
      video={video}
      courseCreate={courseCreate}
    />
  );
}

function UploadBox({
  label,
  required,
  errorMessage,
  onImage,
  video,
  courseCreate,
}) {
  const { component, showImageUpload } = useImageUpload(onImage, video);

  return (
    <Box onClick={showImageUpload} courseCreate={courseCreate}>
      {courseCreate ? (
        <div className="flex flex-row items-center p-0 px-10">
          <div
            //onClick={onUploadClick}
            className="p-5 w-20 h-20 bg-white rounded-full shadow-card-cream-sm flex justify-center items-center cursor-pointer"
          >
            <svg
              width="60"
              height="60"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1702_11433)">
                <path
                  d="M26.4128 7.17294H23.2824L21.5541 4.56424C20.9019 3.58598 19.7606 2.99902 18.5867 2.99902H11.4128C10.2389 2.99902 9.09758 3.58598 8.44541 4.56424L6.71715 7.17294H3.58671C1.59758 7.17294 -0.000244141 8.77076 -0.000244141 10.7599V23.4121C-0.000244141 25.4012 1.59758 26.999 3.58671 26.999H26.4128C28.4019 26.999 29.9998 25.4012 29.9998 23.4121V10.7599C29.9998 8.77076 28.4019 7.17294 26.4128 7.17294ZM14.9998 24.0642C10.5324 24.0642 6.9128 20.4447 6.9128 15.9773C6.9128 11.5099 10.5324 7.92294 14.9998 7.92294C19.4671 7.92294 23.0867 11.5425 23.0867 16.0099C23.0867 20.4447 19.4671 24.0642 14.9998 24.0642ZM25.9563 12.2273C25.9237 12.2273 25.8911 12.2273 25.8258 12.2273H24.5215C23.9345 12.1947 23.478 11.7055 23.5106 11.1186C23.5432 10.5642 23.9671 10.1403 24.5215 10.1077H25.8258C26.4128 10.0751 26.9019 10.5316 26.9345 11.1186C26.9671 11.7055 26.5432 12.1947 25.9563 12.2273Z"
                  fill="#F8B045"
                />
                <path
                  d="M14.9998 11.5098C12.5215 11.5098 10.4998 13.5315 10.4998 16.0098C10.4998 18.488 12.5215 20.4772 14.9998 20.4772C17.478 20.4772 19.4998 18.4554 19.4998 15.9772C19.4998 13.4989 17.478 11.5098 14.9998 11.5098Z"
                  fill="#F8B045"
                />
              </g>
              <defs>
                <clipPath id="clip0_1702_11433">
                  <rect
                    width="30"
                    height="30"
                    fill="white"
                    transform="translate(-0.000244141 -0.000976562)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div className="mt-5 ml-5">
            <h3 className="text-sm">Upload course pictures *</h3>
            <p className="text-sm text-gray-400 mt-3">
              Pictures give students an idea of the practices they will be doing
              in the classes and who their peers are.
            </p>
          </div>
        </div>
      ) : (
        <svg
          width="30"
          height="30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.413 7.174h-3.13l-1.729-2.609C20.902 3.587 19.761 3 18.587 3h-7.174c-1.174 0-2.315.587-2.967 1.565L6.717 7.174h-3.13A3.577 3.577 0 000 10.76v12.652A3.577 3.577 0 003.587 27h22.826A3.577 3.577 0 0030 23.413V10.761a3.577 3.577 0 00-3.587-3.587zM15 24.065a8.086 8.086 0 01-8.087-8.087c0-4.467 3.62-8.054 8.087-8.054a8.086 8.086 0 018.087 8.087c0 4.435-3.62 8.054-8.087 8.054zm10.956-11.837H24.522a1.062 1.062 0 010-2.12h1.304a1.058 1.058 0 011.109 1.012 1.032 1.032 0 01-.979 1.108z"
            fill="#D8D8D8"
          />
          <path
            d="M15 11.51a4.509 4.509 0 00-4.5 4.5c0 2.479 2.022 4.468 4.5 4.468s4.5-2.022 4.5-4.5-2.022-4.467-4.5-4.467z"
            fill="#D8D8D8"
          />
        </svg>
      )}

      {errorMessage ? (
        <ErrorMessage className="mt-2 text-center text-xs">
          {errorMessage}
        </ErrorMessage>
      ) : !courseCreate ? (
        <div className="text-xs text-center mt-2 text-gray-700">
          {label}
          {required ? "*" : ""}
        </div>
      ) : (
        ""
      )}

      {component}
    </Box>
  );
}

function Box({ children, onClick, className, style, courseCreate }) {
  return (
    <div
      onClick={onClick}
      className={`${
        !courseCreate
          ? cx(
              "flex flex-col justify-center items-center",
              "w-44 h-32 border border-dashed border-gray-300 px-11",
              "cursor-pointer",
              className
            )
          : ""
      }`}
      style={style}
    >
      {children}
    </div>
  );
}
