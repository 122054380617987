import React from "react";
import GlowingPicture from "../../Atoms/GlowingPicture";
import useIsMobile from "../../Hooks/useMobile";
import TextLink from "../../Atoms/TextLink/TextLink";

export default function Teacher({ teacher, hideTitle }) {
  const isMobile = useIsMobile();

  if (!teacher) {
    return null;
  }

  function onPreview() {
    window.open(`https://${teacher.domain}.do.yoga/`);
  }

  return (
    <div className="w-full max-w-md">
      {!hideTitle && (
        <h2 className="font-semibold text-xl">About the teacher</h2>
      )}
      <div className="w-full">
        <div className="flex flex-col justify-center items-center mt-5">
          <div>
            {teacher.profile_picture && (
              <GlowingPicture
                width="15rem"
                height="15rem"
                imgUrl={teacher.profile_picture}
                name={teacher.full_name}
              />
            )}
          </div>
          <div className="flex flex-grow flex-col items-center">
            <h2 className="text-xl font-semibold mt-5">{teacher.full_name}</h2>
            {teacher.location && (
              <div className="leading-relaxed text-sm font-light text-gray-500 flex flex-row items-center mt-1">
                <svg
                  className="mr-2"
                  width="7"
                  height="10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.399 1.712A3.394 3.394 0 003.36.001 3.394 3.394 0 00.478 1.712a3.481 3.481 0 00-.046 3.44l2.48 4.54a.597.597 0 00.526.309.597.597 0 00.523-.304l.003-.006 2.48-4.539a3.481 3.481 0 00-.045-3.44zm-2.96 2.82a1.408 1.408 0 010-2.812c.775-.001 1.405.63 1.405 1.405s-.63 1.406-1.406 1.406z"
                    fill="currentColor"
                  />
                </svg>
                {teacher.location}
              </div>
            )}
            {teacher.teaching_years && (
              <p className="text-sm font-light text-gray-500 mt-1">
                Teaching for {teacher.teaching_years} years
              </p>
            )}
            {teacher.style && (
              <p className="text-sm font-light text-gray-500 mt-1">
                {teacher.style}
              </p>
            )}
            {!isMobile && teacher.description && (
              <div className="mt-8 text-gray-500 text-sm font-light bg-white p-5 rounded-lg w-full">
                “{teacher.description}”
              </div>
            )}
            {!isMobile && !hideTitle && teacher.domain && (
              <TextLink onClick={onPreview} className="text-y-yellow pt-4">
                <div className="flex items-center">
                  Go to teacher page
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="30"
                    height="30"
                    viewBox="0 0 172 172"
                    style={{ fill: "#000000", marginLeft: "10px" }}
                  >
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="1"
                      stroke-linecap="butt"
                      stroke-linejoin="miter"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style={{ mixBlendMode: "normal" }}
                    >
                      <path d="M0,172v-172h172v172z" fill="none"></path>
                      <g fill="#fb7124">
                        <path d="M131.81067,51.54401c-2.33302,0.00061 -4.43306,1.41473 -5.31096,3.57628c-0.8779,2.16155 -0.3586,4.6395 1.31331,6.26668l18.87969,18.87969h-135.22604c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h135.22604l-18.87969,18.87969c-1.49778,1.43802 -2.10113,3.5734 -1.57735,5.5826c0.52378,2.0092 2.09284,3.57826 4.10204,4.10204c2.0092,0.52378 4.14458,-0.07957 5.5826,-1.57735l28.16276,-28.16276c1.41918,-1.08154 2.25398,-2.76211 2.25837,-4.54643c0.00439,-1.78431 -0.82213,-3.46898 -2.23597,-4.55748c-0.01117,-0.0075 -0.02237,-0.01497 -0.03359,-0.02239l-28.15157,-28.15156c-1.07942,-1.10959 -2.56163,-1.73559 -4.10964,-1.73568z"></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </TextLink>
            )}
          </div>
        </div>

        {isMobile && teacher.description && (
          <div className="mt-8 text-gray-500 text-sm font-light bg-white p-5 rounded-lg">
            {teacher.description}
          </div>
        )}

        {teacher.profile_videos && teacher.profile_videos.length > 0 && (
          <div className="mt-5">
            <video
              className="object-cover w-full h-full"
              controls
              style={{
                height: isMobile ? "30vh" : "60vh",
              }}
            >
              <source src={teacher.profile_videos[0].file} type="video/mp4" />
            </video>
          </div>
        )}

        <div className="mt-4">
          {teacher.experiances &&
            JSON.parse(teacher.experiances) &&
            (teacher.experiances[0].title ||
              teacher.experiances[0].institution) && (
              <h2 className="font-semibold text-xl mb-3">Experiance</h2>
            )}
          {teacher.experiances &&
            JSON.parse(teacher.experiances).map((ele) => (
              <>
                {(ele.title || ele.institution) && (
                  <div className="p-5 mb-4 bg-white rounded-md shadow-card-cream-sm flex items-center">
                    <div>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_2073_12256)">
                          <path
                            d="M11.9784 19.0583C17.2411 19.0583 21.5075 14.7919 21.5075 9.52913C21.5075 4.26634 17.2411 0 11.9784 0C6.71556 0 2.44922 4.26634 2.44922 9.52913C2.44922 14.7919 6.71556 19.0583 11.9784 19.0583Z"
                            fill="#FFD15C"
                          />
                          <path
                            d="M4.25921 15.104L0.980469 20.7875L4.80724 20.5843L6.54582 24.0001L9.57889 18.7418C7.40567 18.1796 5.53952 16.8709 4.25921 15.104Z"
                            fill="#40596B"
                          />
                          <path
                            d="M19.7176 15.0757C18.4468 16.8473 16.5806 18.1607 14.4121 18.7324L17.4546 24.0001L19.1932 20.5843L23.02 20.7875L19.7176 15.0757Z"
                            fill="#40596B"
                          />
                          <path
                            d="M11.9784 16.4362C15.7931 16.4362 18.8855 13.3438 18.8855 9.52916C18.8855 5.71448 15.7931 2.62207 11.9784 2.62207C8.1637 2.62207 5.07129 5.71448 5.07129 9.52916C5.07129 13.3438 8.1637 16.4362 11.9784 16.4362Z"
                            fill="#F8B64C"
                          />
                          <path
                            d="M16.7128 8.78268C16.9254 8.57481 16.8073 8.21103 16.5144 8.16851L13.7884 7.77166C13.6703 7.75276 13.5711 7.68189 13.5191 7.57323L12.3002 5.10236C12.1679 4.83307 11.7853 4.83307 11.653 5.10236L10.4388 7.57323C10.3868 7.67717 10.2829 7.75276 10.1695 7.77166L7.44352 8.16851C7.15061 8.21103 7.0325 8.57481 7.2451 8.78268L9.21518 10.7055C9.30021 10.7906 9.33801 10.9087 9.31911 11.0221L8.85612 13.7339C8.80415 14.0268 9.11596 14.2536 9.38053 14.1118L11.8183 12.8315C11.9223 12.7748 12.0498 12.7748 12.1538 12.8315L14.5916 14.1118C14.8561 14.2488 15.1632 14.0268 15.116 13.7339L14.6482 11.0221C14.6293 10.9039 14.6671 10.7858 14.7522 10.7055L16.7128 8.78268Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2073_12256">
                            <rect width="24" height="24" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className="pl-5">
                      <div className="font-bold">{ele.title}</div>
                      <div>{ele.institution}</div>
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>

        {isMobile && !hideTitle && teacher.domain && (
          <div className="flex justify-end items-end">
            <TextLink onClick={onPreview} className="text-y-yellow pt-4">
              <div className="flex items-center">
                Go to teacher page
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 172 172"
                  style={{ fill: "#000000", marginLeft: "10px" }}
                >
                  <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style={{ mixBlendMode: "normal" }}
                  >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g fill="#fb7124">
                      <path d="M131.81067,51.54401c-2.33302,0.00061 -4.43306,1.41473 -5.31096,3.57628c-0.8779,2.16155 -0.3586,4.6395 1.31331,6.26668l18.87969,18.87969h-135.22604c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h135.22604l-18.87969,18.87969c-1.49778,1.43802 -2.10113,3.5734 -1.57735,5.5826c0.52378,2.0092 2.09284,3.57826 4.10204,4.10204c2.0092,0.52378 4.14458,-0.07957 5.5826,-1.57735l28.16276,-28.16276c1.41918,-1.08154 2.25398,-2.76211 2.25837,-4.54643c0.00439,-1.78431 -0.82213,-3.46898 -2.23597,-4.55748c-0.01117,-0.0075 -0.02237,-0.01497 -0.03359,-0.02239l-28.15157,-28.15156c-1.07942,-1.10959 -2.56163,-1.73559 -4.10964,-1.73568z"></path>
                    </g>
                  </g>
                </svg>
              </div>
            </TextLink>
          </div>
        )}
      </div>
    </div>
  );
}
