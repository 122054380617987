const CourseAction = {
  setLiveCourse: (data) => ({
    type: "live/set",
    payload: {
      data,
    },
  }),

  setIndex: (key, value) => ({
    type: "course/index",
    payload: {
      key,
      value,
    },
  }),

  setMonthlyCourse: (data) => ({
    type: "monthly/set",
    payload: {
      data,
    },
  }),

  setPersonalCourse: (data) => ({
    type: "personal/set",
    payload: {
      data,
    },
  }),

  setBeginnersCourse: (data) => ({
    type: "beginner/set",
    payload: {
      data,
    },
  }),

  setKidsCourse: (data) => ({
    type: "kids/set",
    payload: {
      data,
    },
  }),

  setWeightLossCourse: (data) => ({
    type: "weight/set",
    payload: {
      data,
    },
  }),

  setPregnancyCourse: (data) => ({
    type: "pregnancy/set",
    payload: {
      data,
    },
  }),

  setPCOSCourse: (data) => ({
    type: "pcos/set",
    payload: {
      data,
    },
  }),

  setRACourse: (data) => ({
    type: "ra/set",
    payload: {
      data,
    },
  }),

  setAdvancedCourse: (data) => ({
    type: "advanced/set",
    payload: {
      data,
    },
  }),

  setTermCourse: (data) => ({
    type: "term/set",
    payload: {
      data,
    },
  }),

  setSubscriptionCourse: (data) => ({
    type: "subscription/set",
    payload: {
      data,
    },
  }),

  SetReviewData: (data) => ({
    type: "review/set",
    payload: {
      data,
    },
  }),

  setBlogData: (data) => ({
    type: "blog/set",
    payload: {
      data,
    },
  }),

  setBlogAsanaData: (data) => ({
    type: "blogasana/set",
    payload: {
      data,
    },
  }),

  setFeaturedCourse: (data) => ({
    type: "featured/set",
    payload: {
      data,
    },
  }),

  setViewAll: (data) => ({
    type: "viewall/set",
    payload: {
      data,
    },
  }),

  setSelectedTime: (data) => ({
    type: "selectedTime/set",
    payload: {
      data,
    },
  }),

  setStreamDatas: (data) => ({
    type: "streamDatas/set",
    payload: {
      data,
    },
  }),

  setShowLoading: (data) => ({
    type: "loading/set",
    payload: {
      data,
    },
  }),
};

export default CourseAction;
