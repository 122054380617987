import cx from "classnames";
import React from "react";
import InputWrapper from "../InputWrapper/InputWrapper";

function Input({
  label,
  name,
  autoFocus,
  inputRef,
  required,
  errors,
  style,
  className,
  prefix,
  suffix,
  prefixWidth,
  inputWidth,
  type = "text",
  wrapperStyle = "hover-label",
  disabled,
  value = "",
  show = true,
}) {
  const _className = className;
  const _style = style || {};

  return (
    <InputWrapper
      label={label}
      name={name}
      required={required}
      errors={errors}
      wrapperStyle={wrapperStyle}
      prefix={prefix}
      prefixWidth={prefixWidth}
      suffix={suffix}
      inputWidth={inputWidth}
      show={show}
    >
      {({ className, style = {}, ...props }) => (
        <input
          {...props}
          name={name}
          type={type}
          autoFocus={autoFocus}
          ref={inputRef}
          style={{ ..._style, ...style }}
          className={cx(
            className,
            _className,
            `appearance-none ${show ? "bg-white" : "bg-y-cream-600"}`
          )}
          disabled={disabled}
        />
      )}
    </InputWrapper>
  );
}

export default Input;
