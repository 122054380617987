import React from "react";
import ReactWhatsapp from "react-whatsapp";
import cx from "classnames";

const Whatsapp = ({ name, teacher }) => (
  <ReactWhatsapp
    number="+917975322831"
    message={`Hi, I want to know more about the yoga class ${name} by ${teacher} on Do Yoga.`}
    className="w-full"
  >
    <button
      className={cx(
        "w-full mt-5 focus:outline-none",
        `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
        "cursor-pointer text-y-yellow-dark flex items-center justify-center"
      )}
      style={{ backgroundColor: "#ffffff", border: "1px solid" }}
    >
      <img
        src="/img/whatsapp.png"
        height={40}
        width={40}
        alt="whatspp"
        style={{ marginRight: 5 }}
      />
      Message Us
    </button>
  </ReactWhatsapp>
);

export default Whatsapp;
