import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import studioRoutes from "../../Routes/studioRoutes";
import { useLoggedInSection } from "../../Utilities/Hooks/LoggedInHook";
import SideMenu from "./SideMenu";
import StudioHeader from "./StudioHeader";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { UserApi } from "../../Api/UserApi";
import { useDispatch, useSelector } from "react-redux";
import useApiPage from "../../Hooks/useApiPage";
import { CourseApi } from "../../Api/CourseApi";
import UserSelectors from "../../Selectors/user";
import UserAction from "../../Actions/user";

function StudioTemplate() {
  useLoggedInSection("/");

  const { callApi, loading, data, errorMessage, setSuccessResponse } = useApi(
    UserApi.teacherProfile
  );

  const dispatch = useDispatch();

  const { data: coursListeData, view } = useApiPage(CourseApi.list);

  const profilePicture = useSelector(UserSelectors.profilePicture);

  const showMenu = useSelector(UserSelectors.showMenu);

  const [menuExpanded, setMenuExpanded] = React.useState(false);

  function toggleMenu() {
    setMenuExpanded((m) => !m);
  }

  const getView = (teacher) => {
    if (teacher) {
      if (
        !teacher.name ||
        !teacher.location ||
        !profilePicture ||
        !teacher.practicing_years ||
        !teacher.teaching_years ||
        !teacher.style ||
        !teacher.description ||
        !teacher.domain
      ) {
        dispatch(UserAction.setShowMenu(false));
      } else if (coursListeData?.data.length === 0) {
        dispatch(UserAction.setShowMenu(false));
      } else {
        dispatch(UserAction.setShowMenu(true));
      }
    }
  };

  useEffect(() => {
    if (data?.data) {
      getView(data?.data);
    }
  }, [data?.data, coursListeData?.data]);

  React.useEffect(() => {
    callApi();
  }, [callApi]);

  return (
    <div className={cx("flex flex-row relative")}>
      <SideMenu
        showMenu={showMenu}
        expanded={menuExpanded}
        toggleExpanded={toggleMenu}
        setExpanded={setMenuExpanded}
      />

      <div className="flex-1">
        <StudioHeader onMobileMenu={toggleMenu} />
        <div className="bg-y-cream-600 min-h-screen flex sm:px-14">
          <Switch>
            {studioRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
                show={showMenu}
              />
            ))}
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default StudioTemplate;
