import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CourseApi } from "../../Api/CourseApi";
import StepsCard from "../../Components/StepsCard";
import CreatedModal from "./CreatedModal";
import FeeStep from "./OneOnOneForm/FeeStep";
import TimingsStep from "./OneOnOneForm/TimingsStep";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function NewOneOnOne() {
  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);
  const [showCreated, setShowCreated] = React.useState(false);
  const history = useHistory();

  function onFinish(data) {
    const apiData = Object.values(data).reduce(
      (acc, d) => ({ ...acc, ...d }),
      {}
    );

    return CourseApi.createOneOnOne(apiData).then((response) => {
      setShowCreated(true);

      return response;
    });
  }

  function onModalClose() {
    setShowCreated(false);
    setTimeout(() => {
      history.push("/studio/classes");
    }, 300);
  }

  return (
    <div>
      <Helmet>
        <title>1:1 Setup</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="1:1 Setup"></meta>
        <meta name="description" content="1:1 Setup" />
        <meta property="og:title" content="1:1 Setup"></meta>
        <meta property="twitter:title" content="1:1 Setup"></meta>
        <meta property="og:description" content="1:1 Setup"></meta>
        <meta property="twitter:description" content="1:1 Setup"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="max-w-lg px-7 sm:px-0">
        <span
          className="mr-2"
          onClick={() => history.push("/studio/setup-class")}
        >
          Setup Class
        </span>
        <span className="mr-2 text-gray-400">{">"}</span>
        <span className="text-y-gray">1:1 Sessions</span>
      </div>
      <div className="w-screen max-w-xl mt-5">
        <StepsCard
          onFinish={onFinish}
          steps={[
            {
              title: "Timings",
              formFields: ["duration", "slots"],
              component: TimingsStep,
            },
            {
              title: "Fees",
              formFields: ["single_fee_india", "single_fee_intl"],
              component: FeeStep,
            },
          ]}
        />
      </div>

      <CreatedModal show={showCreated} onClose={onModalClose} />
    </div>
  );
}
