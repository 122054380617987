import React from "react";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

export default function SubscriptionList(props) {
  const { loading, callApi, errorMessage, data } = useApi(
    SubscriptionApi.userBookings
  );

  React.useEffect(() => {
    callApi();
    pageTracking(props);
    ReactPixel.pageView();
  }, [callApi]);

  if (loading) {
    return <div>Loading classes...</div>;
  }

  if (errorMessage) {
    return (
      <div>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }

  if (data?.data.length === 0) {
    return null;
  }

  return (
    <div>
      <div>
        {data?.data.map((d) => (
          <BookingCard key={d.number} booking={d} />
        ))}
      </div>
    </div>
  );
}

function BookingCard({ booking }) {
  return (
    <>
      <div className="bg-white rounded shadow-card-cream-lg p-5 flex flex-row justify-between items-center mt-5">
        <div>
          <div className="font-medium">{booking.subscription.title}</div>
          <div className="text-xs text-y-gray mt-1 leading-5">
            <p>
              {" "}
              Booking ID {booking.number} | Teacher{" "}
              {booking.subscription.teacher.full_name}
            </p>
            {booking.remaining_class_count && (
              <p>Remaining Classes {booking.remaining_class_count}</p>
            )}
            {booking?.subscription?.expiry_date[2] !== "3" && (
              <p>Expiring {booking.subscription.expiry_date}</p>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-end">{booking.actionRight}</div>
      </div>
    </>
  );
}
