import { useEffect } from "react";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
const Contact = (props) => {
  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);
  return (
    <div className="p-5 px-0">
      <Helmet>
        <title>Contact Us</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Contact Us"></meta>
        <meta name="description" content="Contact Us" />
        <meta property="og:title" content="Contact Us"></meta>
        <meta property="twitter:title" content="Contact Us"></meta>
        <meta property="og:description" content="Contact Us"></meta>
        <meta property="twitter:description" content="Contact Us"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="pb-3 text-base sm:text-xl text-y-yellow-dark font-bold">
        Contact Us
      </div>
      <div className="pb-2">
        Email:
        <span className="pl-2 text-y-yellow-dark">
          <a
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=hi@do.yoga"
            target="_blank"
          >
            hi@do.yoga
          </a>
        </span>
      </div>
      <div className="pb-2">
        Whatsapp:
        <span className="text-y-yellow-dark pl-2">
          <a
            href="https://wa.me/7975322831"
            title="Share on whatsapp"
            target="_blank"
          >
            +91 7975322831
          </a>
        </span>
      </div>
      <div>
        Instagram:
        <span className="pl-2 text-y-yellow-dark">
          <a
            href="https://www.instagram.com/d.o.y.o.g.a/"
            target="_blank"
            title="Instagram"
          >
            d.o.y.o.g.a
          </a>
        </span>
      </div>
      <div className="pt-2">
        Address:
        <span className="pl-2">
          L131, 4th Main Road, Sector 6, HSR Layout, Bangalore - 560102
        </span>
      </div>
    </div>
  );
};

export default Contact;
