const FilterDatas = {
  levels: [
    { value: "beginner", label: "Beginner", checked: false },
    { value: "intermediate", label: "Intermediate", checked: false },
    { value: "advanced", label: "Advanced", checked: false },
    { value: "kids", label: "Kids", checked: false },
  ],
  sort: [
    { value: "low", label: "Price Lower To Higher", checked: false },
    { value: "high", label: "Price Higher To Lower", checked: false },
    { value: "time", label: "Time", checked: false },
  ],
  price: [
    {
      type: "to",
      minValue: "1000",
      maxValue: "2000",
      label: "1000 to 2000",
      value: "1000 to 2000",
      checked: false,
    },
    {
      type: "to",
      minValue: "2000",
      maxValue: "3000",
      label: "2000 to 3000",
      value: "2000 to 3000",
      checked: false,
    },
    {
      type: "less",
      minValue: "1000",
      label: "<1000",
      value: "<1000",
      checked: false,
    },
    {
      type: "plus",
      minValue: "3000",
      label: ">3000",
      value: ">3000",
      checked: false,
    },
  ],
  healthConditions: [
    { value: "pregnancy", label: "Pregnancy", checked: false },
    { value: "diabetes", label: "Diabetes", checked: false },
    { value: "PCOS", label: "PCOS", checked: false },
    { value: "Blood Pressure", label: "Blood Pressure", checked: false },
    { value: "Back Pain", label: "Back Pain", checked: false },
    { value: "Hypertension", label: "Hypertension", checked: false },
    { value: "Arthritis", label: "Arthritis", checked: false },
    { value: "Weight loss", label: "Weight loss", checked: false },
  ],

  style: [
    { value: "Hatha Yoga", label: "Hatha Yoga", checked: false },
    { value: "Power Yoga", label: "Power Yoga", checked: false },
    { value: "Ashtanga Yoga", label: "Ashtanga Yoga", checked: false },
    { value: "Sivananda Yoga", label: "Sivananda Yoga", checked: false },
    { value: "Iyengar Yoga", label: "Iyengar Yoga", checked: false },
    { value: "Yin Yoga", label: "Yin Yoga", checked: false },
    { value: "Satyananda Yoga", label: "Satyananda Yoga", checked: false },
  ],
  time: [
    {
      start: "05:00",
      end: "07:55",
      label: "Early Morning(05:00AM - 07:00AM)",
      value: "Early Morning(05:00AM - 07:00AM)",
      checked: false,
    },
    {
      start: "08:00",
      end: "12:55",
      label: "Morning(08:00AM - 12:00PM)",
      value: "Morning(08:00AM - 12:00PM)",
      checked: false,
    },
    {
      start: "13:00",
      end: "16:55",
      label: "Afternoon(01:00PM - 04:00PM)",
      value: "Afternoon(01:00PM - 04:00PM)",
      checked: false,
    },
    {
      start: "17:00",
      end: "21:00",
      label: "Evening(05:00PM - 09:00PM)",
      value: "Evening(05:00PM - 09:00PM)",
      checked: false,
    },
  ],
};

export default FilterDatas;
