import FixedCourseDetails from "../Pages/FixedCourseDetails";
import MonthlyCourseDetails from "../Pages/MonthlyCourseDetails";
import MyLearnings from "../Pages/MyLearnings";
import OneOnOneDetails from "../Pages/OneOnOneDetails";
import SessionDetails from "../Pages/SessionDetails";
// import Storefront from "../Pages/Storefront";
// import LandBotPage from "../Pages/LandBotPage";
import TeacherDomainPage from "../Pages/TeacherDomainPage";
import About from "../Pages/About";
import Contact from "../Pages/Contact";
import Privacy from "../Pages/Policy/Privacy";
import Refund from "../Pages/Policy/Refund";
import TermsAndConditions from "../Pages/Policy/TermsAndConditions";
import FilterCourses from "../Pages/Storefront/FilterCourses";
import Home from "../Pages/Storefront/Home";
import OneOnOneUpdated from "../Pages/Storefront/OneOnOneUpdated";
import TeacherStatic from "../Pages/TeacherStatic";
import SubscriptionListAll from "../Pages/Storefront/SubscriptionListAll";
import SubscriptionDetails from "../Pages/SubscriptionDetails";

import LiveCourses from "../Pages/Storefront/FilterPages/Live";
import Advanced from "../Pages/Storefront/FilterPages/Advanced";
import AllLevels from "../Pages/Storefront/FilterPages/AllLevels";
import AllHealth from "../Pages/Storefront/FilterPages/AllHealth";
import Beginner from "../Pages/Storefront/FilterPages/Beginner";
import Kids from "../Pages/Storefront/FilterPages/Kids";
import Health from "../Pages/Storefront/FilterPages/Health";
import Monthly from "../Pages/Storefront/FilterPages/Monthly";
import Pregnancy from "../Pages/Storefront/FilterPages/Pregnancy";
import Term from "../Pages/Storefront/FilterPages/Term";
import Weightloss from "../Pages/Storefront/FilterPages/Weightloss";

import BookingModal from "../Components/BookingModal";
import SuccessFailback from "../Components/BookingModal/BookingFailed";

import AdvancedClass from "../Pages/Storefront/ExploreMenu/Advanced";
import Afternoon from "../Pages/Storefront/ExploreMenu/Afternoon";
import EarlyMorning from "../Pages/Storefront/ExploreMenu/EarlyMorning";
import CustomTime from "../Pages/Storefront/ExploreMenu/CustomTime";
import Evening from "../Pages/Storefront/ExploreMenu/Evening";
import Intermediate from "../Pages/Storefront/ExploreMenu/Intermediate";
import Morning from "../Pages/Storefront/ExploreMenu/Morning";
import Price1less from "../Pages/Storefront/ExploreMenu/Price1less";
import Price1to2 from "../Pages/Storefront/ExploreMenu/Price1to2";
import Price2to3 from "../Pages/Storefront/ExploreMenu/Price2to3";
import Price3high from "../Pages/Storefront/ExploreMenu/Price3high";
import Styles from "../Pages/Storefront/ExploreMenu/Styles";

import Rheumatoid from "../Pages/CourseBlogs/Rheumatoid";
import Pcos from "../Pages/CourseBlogs/Pcos";

import BlogListing from "../Pages/Blogs/BlogListing";
import BlogPage from "../Pages/Blogs/BlogPage";
import CreateBlog from "../Pages/Blogs/CreateBlog";
import Review from "../Pages/Review";

import Group50 from "../Pages/PaymentIframe/Group50";
import Personal300 from "../Pages/PaymentIframe/Personal300";

import PaymentFlow from "../Pages/PaymentFlow";

import TeacherFlowProfile from "../Pages/TeacherFlow/Profile";
import CompleteStage from "../Pages/TeacherFlow/CompleteStage";

import Diet from "../Pages/Diet";

export const STOREFRONT_HOME_PAGE = "/";

const storefrontRoutes = [
  {
    path: "/:type/:id/payment", //
    component: PaymentFlow,
  },
  {
    path: "/courses/intermediate",
    component: Intermediate,
  },
  {
    path: "/courses/advanced",
    component: AdvancedClass,
  },
  {
    path: "/courses/custom-time/:time", //
    component: CustomTime,
  },
  {
    path: "/courses/morning",
    component: Morning,
  },
  {
    path: "/courses/early-morning",
    component: EarlyMorning,
  },
  {
    path: "/courses/evening",
    component: Evening,
  },
  {
    path: "/courses/afternoon",
    component: Afternoon,
  },
  {
    path: "/courses/price-1000-to-2000",
    component: Price1to2,
  },
  {
    path: "/courses/price-less-than-1000",
    component: Price1less,
  },
  {
    path: "/courses/price-2000-to-3000",
    component: Price2to3,
  },
  {
    path: "/courses/price-greater-than-3000",
    component: Price3high,
  },
  {
    path: "/courses/style/:name", //
    component: Styles,
  },
  {
    path: "/booking-status",
    component: BookingModal,
  },
  {
    path: "/booking-failed",
    component: SuccessFailback,
  },
  {
    path: "/courses/live-classes",
    component: LiveCourses,
  },
  {
    path: "/courses/advanced-courses",
    component: Advanced,
  },
  {
    path: "/courses/all-levels",
    component: AllLevels,
  },
  {
    path: "/courses/all-health-conditions",
    component: AllHealth,
  },
  {
    path: "/courses/beginner-courses",
    component: Beginner,
  },
  {
    path: "/kids",
    component: Kids,
  },
  {
    path: "/courses/health/:name", //
    component: Health,
  },
  {
    path: "/courses/monthly-classes",
    component: Monthly,
  },
  {
    path: "/courses/pregnancy-classes",
    component: Pregnancy,
  },
  {
    path: "/courses/weightloss-classes",
    component: Weightloss,
  },
  {
    path: "/courses/term-classes",
    component: Term,
  },
  {
    path: "/sessions/:slug",
    component: SessionDetails,
  },
  {
    path: "/courses-all",
    component: FilterCourses,
  },
  {
    path: "/subscriptions/course",
    component: SubscriptionListAll,
  },
  {
    path: "/subscriptions/:id",
    component: SubscriptionDetails,
  },
  {
    path: "/fixed-courses/:slug", //
    component: FixedCourseDetails,
  },
  {
    path: "/monthly-courses/:slug", //
    component: MonthlyCourseDetails,
  },
  {
    path: "/1-1/:id/:slug",
    component: OneOnOneDetails,
  },
  {
    path: "/1-1",
    component: OneOnOneUpdated,
  },
  {
    path: "/my/learnings",
    component: MyLearnings,
  },
  {
    path: "/teachers/:domain", //
    component: TeacherDomainPage,
  },
  {
    path: "/teach",
    component: TeacherStatic,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/privacy-policy",
    component: Privacy,
  },
  {
    path: "/aahara",
    component: Diet,
  },
  {
    path: "/refund-policy",
    component: Refund,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    path: "/review/:id",
    component: Review,
  },
  {
    path: "/review/:id/:course",
    component: Review,
  },
  {
    path: "/blog/articles/create",
    component: CreateBlog,
  },
  {
    path: "/blog/articles/:name",
    component: BlogPage,
  },
  {
    path: "/blog/articles",
    component: BlogListing,
  },
  {
    path: "/blog/:name/:id",
    component: BlogPage,
  },
  {
    path: "/blog/:name",
    component: BlogListing,
  },
  {
    path: "/therapy/rheumatoid",
    component: Rheumatoid,
  },
  {
    path: "/therapy/pcos",
    component: Pcos,
  },
  {
    path: "/trial/group50",
    component: Group50,
  },
  {
    path: "/trial/personal300",
    component: Personal300,
  },
  {
    path: "/teacher-profile/complete",
    component: CompleteStage,
  },
  {
    path: "/teacher-profile",
    component: TeacherFlowProfile,
  },
  {
    path: "/",
    component: Home,
  },

  // {
  //   path: "/",
  //   component: Storefront,
  // },
];

export default storefrontRoutes;
