import { useEffect } from "react";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

const Refund = (props) => {
  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);
  return (
    <div className="p-5 ">
      <Helmet>
        <title>Refund</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Refund"></meta>
        <meta
          name="description"
          content="Do Yoga is a place where you can discover & join live yoga classes all through the day from yoga teachers across the world"
        />
        <meta property="og:title" content="Refund"></meta>
        <meta property="twitter:title" content="Refund"></meta>
        <meta
          property="og:description"
          content="Do Yoga is a place where you can discover & join live yoga classes all through the day from yoga teachers across the world"
        ></meta>
        <meta
          property="twitter:description"
          content="Do Yoga is a place where you can discover & join live yoga classes all through the day from yoga teachers across the world"
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="text-3xl pb-5 text-y-yellow-dark">Refund Policy</div>
      <div className="pb-3 text-justify">
        Cancellation received at least 24 hours before the scheduled class is
        refundable or adjusted for upcoming classes. Cancellation will have to
        be notified on the website corresponding to your booking or on email or
        whatsapp. Cancel requests received before the 24 hours of scheduled
        class is not refundable but can be adjustable to another class.
      </div>
      <div className="pb-3 text-justify">
        All classes which could not take place due to inconveniences faced by
        the teacher or the platform are also deemed to be canceled.
      </div>
      <div className="pb-3 text-lg font-semibold">Refunds</div>
      <div className="pb-3 text-justify">
        Once your cancelation request is received and reviewed, we will also
        notify you of the approval or rejection of your refund. If you are
        approved, then your refund will be processed, and a credit will
        automatically be applied to your credit card or original method of
        payment, within 5-7 working days.
      </div>
      <div className="pb-3 text-lg font-semibold">Late or missing refunds</div>
      <div className="pb-3 text-justify">
        If you haven’t received a refund yet, first check your bank account
        again. Then contact your credit card company, it may take some time
        before your refund is officially posted. Next contact your bank. There
        is often some processing time before a refund is posted. If you’ve done
        all of this and you still have not received your refund yet, please
        contact us at hi@do.yoga.
      </div>
      <div></div>
    </div>
  );
};

export default Refund;
