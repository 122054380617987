import { useEffect } from "react";
import { Link } from "react-router-dom";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
const About = (props) => {
  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);
  return (
    <div className="p-5 sm:px-4 md:px-0">
      <Helmet>
        <title>About</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="About"></meta>
        <meta
          name="description"
          content="Do Yoga is a place where you can discover & join live yoga classes all through the day from yoga teachers across the world"
        />
        <meta property="og:title" content="About"></meta>
        <meta property="twitter:title" content="About"></meta>
        <meta
          property="og:description"
          content="Do Yoga is a place where you can discover & join live yoga classes all through the day from yoga teachers across the world"
        ></meta>
        <meta
          property="twitter:description"
          content="Do Yoga is a place where you can discover & join live yoga classes all through the day from yoga teachers across the world"
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="text-3xl pb-5 text-y-yellow-dark">About Us</div>
      <div className="pb-2">
        <span className="text-y-yellow-dark pr-1">
          <Link to="/">{"  "}Do Yoga</Link>
        </span>{" "}
        helps people build a yoga practice.
      </div>
      <div className="pb-2">Our mission is to get more of us to Do Yoga</div>
      <div>
        Do Yoga is a place where you can discover & join live yoga classes all
        through the day from yoga teachers across the world.
      </div>
    </div>
  );
};

export default About;
