const Loader = () => {
  return (
    <div class="w-80 h-40 border-2 rounded-md mx-auto">
      <div class="flex animate-pulse flex-row items-center h-full justify-center space-x-5">
        <div class="w-20 bg-gray-300 h-20 rounded-full "></div>
        <div class="flex flex-col space-y-4">
          <div class="w-48 bg-gray-300 h-6 rounded-md "></div>
          <div class="w-36 bg-gray-300 h-6 rounded-md "></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
