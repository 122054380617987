import React from 'react';
import { useForm } from "react-hook-form";
import Button from "../../../Atoms/Button/Button";
import Form from "../../../Atoms/Form";
import FormError from "../../../Atoms/FormError";
import FormText from "../../../Atoms/FormText";
import Input from "../../../Atoms/Input/Input";

export default function FeeStep({ data, onNext, loading, formErrors }) {
  const { register, handleSubmit } = useForm({
    defaultValues: data,
  });

  function _onNext(values) {
    const numericFields = ['single_fee_india', 'single_fee_intl'];

    // cast invalid numbers to null
    Object.keys(values).forEach(k => {
      if (numericFields.includes(k) && !values[k]) {
        values[k] = null;
      }
    });

    onNext(values);
  }

  return (
    <div className="mt-8">
      <Form onSubmit={handleSubmit(_onNext)} errors={formErrors}>
        <FormText className="my-5">
          Fees per session
        </FormText>
        <div className="flex flex-row justify-between">
          <Input
            name="single_fee_india"
            label="For India"
            inputWidth="w-40 sm:w-48"
            prefix="Rs."
            required
            inputRef={register({ required: true })}
          />
          <Input
            name="single_fee_intl"
            label="For International"
            inputWidth="w-40 sm:w-48"
            prefix="$"
            inputRef={register()}
          />
        </div>

        <FormError className="my-5" error={formErrors} alignRight />

        <div className="mt-2 flex flex-row justify-end">
          <Button loading={loading} type="submit">Done</Button>
        </div>
      </Form>
    </div>
  );
}
