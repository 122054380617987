import React, { useState } from "react";
import dayjs from "dayjs";
import { StorefrontApi } from "../../../Api/StorefrontApi";
import CourseCard from "../../../Atoms/CourseCard";
import { useApi } from "../../../Utilities/Hooks/ApiHook";
import Vent from "../../../vent";
import CardGroup from "../CardGroup";
import Button from "../../../Atoms/Button/Button";
import { useHistory, useLocation } from "react-router-dom";
import cx from "classnames";
import Checkbox from "../../../Atoms/Checkbox";
import "../store.css";
import MonthlyClassesGraphic from "../../../Graphics/MonthlyClassesGraphic";
import useIsMobile from "../../../Hooks/useMobile";
import FilterDatasEnum from "../../../Utilities/FilterData";
import Loader from "../../../Atoms/Loader";
import CardTabs from "../CardTabs";
import axios from "axios";
import Modal from "../../../Atoms/Modal/Modal";
import Copy from "../../../Assets/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import pageTracking from "../../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { useEffect } from "react";
import CourseAction from "../../../Actions/course";
import CourseSelectors from "../../../Selectors/course";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";

export default function FilterClasses(props) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const { callApi, data, loading } = useApi(StorefrontApi.filterCourses);
  const { callApi: searchCreateUrl, loading: createUrlLoading } = useApi(
    StorefrontApi.createSearchUrl
  );
  const { callApi: searchGetUrl, loading: getUrlLoading } = useApi(
    StorefrontApi.getsearchUrl
  );
  const [queryText, setQueryText] = useState("");
  const [show, setShow] = useState(false);
  const teacherRatingData = useSelector(CourseSelectors.streamDatas);
  let courseData = useSelector(CourseSelectors.viewAllData);
  //let courseData = dataApp || [];
  let courses =
    courseData.map((ele) => ({
      ...ele,
      teacherRating:
        teacherRatingData[ele.teacher?.id] &&
        teacherRatingData[ele.teacher?.id].avgReviewsCount
          ? parseInt(teacherRatingData[ele.teacher?.id].avgReviewsCount)
          : 0,
      teacherCount:
        teacherRatingData[ele.teacher?.id] &&
        teacherRatingData[ele.teacher?.id].total
          ? parseInt(teacherRatingData[ele.teacher?.id].total)
          : 0,
    })) || [];
  let timeList = [];
  const [filterType, setFilterType] = useState("month");
  const [currentVal, setCurrentVal] = useState(null);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [link, setLink] = useState("");

  if (props?.showTime) {
    let newData = courses.map((ele) => {
      let date = `Wed Dec 07 2022 ${ele.start_time} GMT+0530 (India Standard Time)`;
      let newDate = new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
        })
      );

      return {
        ...ele,
        start_time_list: dayjs(newDate).format("h:mm a"),
        start_time_hour: parseInt(dayjs(newDate).format("H")),
        start_time_minute: dayjs(newDate).format("mm"),
      };
    });

    let obj = {};

    let newData1 = newData.map((ele) => {
      if (!obj[ele?.start_time_list]) {
        obj[ele?.start_time_list] = ele?.start_time_hour;
      }
    });

    const sortable = Object.fromEntries(
      Object.entries(obj).sort(([, a], [, b]) => a - b)
    );

    let newListArr = Object.keys(obj)
      .filter((ele) => {
        const newVal = ele.split(":");
        if (newVal[1][0] == 0 && newVal[1][1] == 0) {
          return ele;
        }
      })
      .map((item) => ({ name: item, value: obj[item] }))
      .sort((a, b) => a.value - b.value);

    courses = [...newData];
    timeList = [...newListArr];
  }

  const [liveDate, setLiveDate] = useState("");

  const [titleQuery, setTitleQuery] = useState("");

  const [filterArrData, setFilterArrData] = useState({
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
  });

  const [filterArrShow, setFilterArrShow] = useState({
    levels: true,
    price: false,
    healthConditions: false,
    time: false,
    style: false,
    sort: false,
  });

  const [filterData, setFilterData] = useState({ ...FilterDatasEnum });

  const handleApplyFilter = (reset = false) => {
    setShow(false);

    if (reset) {
      setQueryText("");
      setFilterArrData({
        levels: [],
        price: [],
        healthConditions: [],
        time: [],
        sort: [],
        style: [],
      });
      setFilterData({ ...FilterDatasEnum });

      const searchUrlData = `?name=&type=${filterType}&courseName=${titleQuery}`;

      searchCreateUrl({ url_string: searchUrlData })
        .then((res) => {
          history.replace({
            pathname: location.pathname,
            search: `?query=${res?.data?.data.url_id}`,
          });
        })
        .catch((err) => console.log(err));

      const formatData = {
        levels: [],
        price: [],
        healthConditions: [],
        time: [],
        style: [],
        sort: [],
        queryText: "",
        type: filterType,
        date: liveDate,
      };
      if (filterType === "live" && liveDate) {
        callApi(formatData);
      } else if (filterType !== "live") {
        callApi(formatData);
      }
    } else {
      const formatFilterArr = JSON.stringify(filterArrData);
      const formatFilter = JSON.stringify(filterData);

      const searchUrlData = `?name=${queryText}&courseName=${titleQuery}&type=${filterType}&filterArr=${formatFilterArr}&filter=${formatFilter}`;

      searchCreateUrl({ url_string: searchUrlData })
        .then((res) => {
          history.replace({
            pathname: location.pathname,
            search: `?query=${res?.data?.data.url_id}`,
          });
        })
        .catch((err) => console.log(err));

      const formatLevels = filterArrData.levels.map((ele) => ele.value);
      const formatData = {
        ...filterArrData,
        levels: [...formatLevels],
        queryText: queryText,
        type: filterType,
      };
      //callApi(formatData);
    }
  };

  React.useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
    const search = props?.location?.search;

    const params = search ? new URLSearchParams(search) : "";

    const queryStringUrl = params ? params.get("query") : "";

    if (queryStringUrl) {
      const params = new URLSearchParams(search);

      const queryStringUrl = params.get("query");

      searchGetUrl(queryStringUrl)
        .then((res) => {
          const paramsData = new URLSearchParams(res.data.data.url_string);
          const queryString = paramsData.get("name");
          const filterType = paramsData.get("type");
          const courseNameText = paramsData.get("courseName");
          setTitleQuery(courseNameText);

          if (queryString) {
            setQueryText(queryString);
          } else {
            setQueryText("");
          }
          if (filterType) {
            setFilterType(filterType);
          } else {
            setFilterType("month");
          }
          const filterItems = JSON.parse(paramsData.get("filterArr"));
          const filterCheckItems = JSON.parse(paramsData.get("filter"));

          if (filterItems) {
            setFilterArrData({ ...filterItems });
            if (filterCheckItems) {
              setFilterData({ ...filterCheckItems });
            } else {
              setFilterData({ ...FilterDatasEnum });
            }

            const formatLevels = filterItems.levels.map((ele) => ele.value);
            let healthData = [...filterItems.healthConditions];
            for (let i = 0; i < healthData.length; i++) {
              if (healthData[i].value === "Hypertension") {
                healthData.push({
                  value: "Blood pressure",
                  label: "Blood pressure",
                  checked: false,
                });
              } else if (healthData[i].value === "Diabetes") {
                healthData.push({
                  value: "Blood sugar",
                  label: "Blood sugar",
                  checked: false,
                });
              } else if (healthData[i].value === "Pregnancy") {
                healthData.push({
                  value: "Prenatal",
                  label: "Prenatal",
                  checked: false,
                });
              }
            }
            const formatData = {
              ...filterItems,
              levels: [...formatLevels],
              queryText: queryString,
              type: filterType,
              healthConditions: [...healthData],
              date: liveDate,
            };
            if (filterType === "live" && liveDate) {
              callApi(formatData)
                .then((res) => {
                  dispatch(CourseAction.setViewAll(res.data.data));
                })
                .catch((err) => console.log(err));
            } else if (filterType !== "live") {
              callApi(formatData)
                .then((res) => {
                  dispatch(CourseAction.setViewAll(res.data.data));
                })
                .catch((err) => console.log(err));
            }
          } else {
            const filterArr = {
              levels: [],
              price: [],
              healthConditions: [],
              time: [],
              style: [],
              queryText: queryString,
              type: filterType,
              sort: [],
              date: liveDate,
            };
            if (filterType === "live" && liveDate) {
              callApi(filterArr)
                .then((res) => {
                  dispatch(CourseAction.setViewAll(res.data.data));
                })
                .catch((err) => console.log(err));
            } else if (filterType !== "live") {
              callApi(filterArr)
                .then((res) => {
                  dispatch(CourseAction.setViewAll(res.data.data));
                })
                .catch((err) => console.log(err));
            }
          }
        })
        .catch((err) => console.log(err));
    } else {
      const queryString = props.name;
      const filterType = props.type;

      const filterItems = props.filterArr;
      const filterCheckItems = props.filter;

      setTitleQuery(props?.courseName || "");
      if (filterType) {
        setFilterType(filterType);
      } else {
        setFilterType("month");
      }

      if (queryString) {
        setQueryText(queryString);
      } else {
        setQueryText("");
      }

      if (filterItems) {
        setFilterArrData({ ...filterItems });
        if (filterCheckItems) {
          setFilterData({ ...filterCheckItems });
        } else {
          setFilterData({ ...FilterDatasEnum });
        }

        let healthData = [...filterItems.healthConditions];
        for (let i = 0; i < healthData.length; i++) {
          if (healthData[i].value === "Hypertension") {
            healthData.push({
              value: "Blood pressure",
              label: "Blood pressure",
              checked: false,
            });
          } else if (healthData[i].value === "Diabetes") {
            healthData.push({
              value: "Blood sugar",
              label: "Blood sugar",
              checked: false,
            });
          } else if (healthData[i].value === "Pregnancy") {
            healthData.push({
              value: "Prenatal",
              label: "Prenatal",
              checked: false,
            });
          }
        }

        const formatLevels = filterItems.levels.map((ele) => ele.value);
        const formatData = {
          ...filterItems,
          levels: [...formatLevels],
          queryText: queryString,
          type: filterType,
          healthConditions: [...healthData],
          date: liveDate,
        };
        if (filterType === "live" && liveDate) {
          callApi(formatData)
            .then((res) => {
              dispatch(CourseAction.setViewAll(res.data.data));
            })
            .catch((err) => console.log(err));
        } else if (filterType !== "live") {
          callApi(formatData)
            .then((res) => {
              dispatch(CourseAction.setViewAll(res.data.data));
            })
            .catch((err) => console.log(err));
        }
      } else {
        const filterArr = {
          levels: [],
          price: [],
          healthConditions: [],
          time: [],
          style: [],
          queryText: queryString,
          type: filterType,
          sort: [],
          date: liveDate,
        };
        if (filterType === "live" && liveDate) {
          callApi(filterArr)
            .then((res) => {
              dispatch(CourseAction.setViewAll(res.data.data));
            })
            .catch((err) => console.log(err));
        } else if (filterType !== "live") {
          callApi(filterArr)
            .then((res) => {
              dispatch(CourseAction.setViewAll(res.data.data));
            })
            .catch((err) => console.log(err));
        }
      }
    }
  }, [callApi, props?.location?.search, liveDate]);

  const showFilter = props?.showFilter ? props.showFilter : false;
  const filterText = props?.filterText ? props.filterText : "";

  function onCourseDetails(course) {
    Vent.onClassDetails(Vent.TYPE_MONTHLY_CLASS, course.title);
  }

  const handleChangeCheck = (name, value) => {
    const key = name;
    const originalDatas = filterData[key].find((ele) => ele.value === value);
    const queryListDatas = filterArrData[key].find(
      (ele) => ele.value === value
    );

    if (queryListDatas) {
      const queryFilterDatas = filterArrData[key].filter(
        (ele) => ele.value !== value
      );
      const newArr = filterData[key].map((ele) => {
        if (ele.value === value) {
          return { ...ele, checked: false };
        } else {
          return ele;
        }
      });
      setFilterData({
        ...filterData,
        [key]: [...newArr],
      });
      setFilterArrData({
        ...filterArrData,
        [key]: [...queryFilterDatas],
      });
    } else {
      const newArr = filterData[key].map((ele) => {
        if (ele.value === value) {
          return { ...ele, checked: true };
        } else {
          return ele;
        }
      });
      setFilterData({
        ...filterData,
        [key]: [...newArr],
      });
      setFilterArrData({
        ...filterArrData,
        [key]: [...filterArrData[key], originalDatas],
      });
    }
  };

  const checkBoxComponent = () => {
    return (
      <div className="w-full mx-2 md:mx-0 my-2">
        <div
          className="block flex justify-end pr-0 cursor-pointer"
          onClick={() => {
            if (props.showFilter) {
              props.setShowFilter();
            }
            setShow(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            viewBox="0 0 50 50"
            style={{ fill: "grey" }}
          >
            <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
          </svg>
        </div>
        <div className="block md:hidden flex mb-4 mt-1">
          <input
            class="pl-10 w-11/12 md:w-2/4 border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none"
            type="text"
            name="search"
            value={queryText}
            placeholder="Find your yoga practice"
            onChange={(e) => {
              setQueryText(e.target.value);
            }}
          />
          <button type="submit" class="absolute mt-2 ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25px"
              height="25px"
              viewBox="0 0 50 50"
              style={{ fill: "grey" }}
            >
              <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
            </svg>
          </button>
        </div>
        <div className="sm:flex flex-row w-11/12 justify-between">
          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    levels: !filterArrShow.levels,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Level</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.levels &&
                  filterData.levels &&
                  filterData.levels.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="levels"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.levels &&
                  filterData.levels.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="levels"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    time: !filterArrShow.time,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Time</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.time &&
                  filterData.time &&
                  filterData.time.map((ele) => (
                    <Checkbox
                      label={
                        <>
                          {ele.label.split("(").map((item, i) => {
                            return (
                              <p className="pb-1">
                                {i === 1 ? "(" : ""}
                                {item}
                              </p>
                            );
                          })}
                        </>
                      }
                      value={ele.value}
                      name="time"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.time &&
                  filterData.time.map((ele) => (
                    <Checkbox
                      label={
                        <>
                          {ele.label.split("(").map((item, i) => {
                            return (
                              <p className="pb-1">
                                {i === 1 ? "(" : ""}
                                {item}
                              </p>
                            );
                          })}
                        </>
                      }
                      value={ele.value}
                      name="time"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    healthConditions: !filterArrShow.healthConditions,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Health Conditions</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.healthConditions &&
                  filterData.healthConditions &&
                  filterData.healthConditions.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="healthConditions"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.healthConditions &&
                  filterData.healthConditions.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="healthConditions"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    style: !filterArrShow.style,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Style</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.style &&
                  filterData.style &&
                  filterData.style.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="style"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.style &&
                  filterData.style.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="style"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    price: !filterArrShow.price,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Price</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.price &&
                  filterData.price &&
                  filterData.price.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      name="price"
                      value={ele.value}
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.price &&
                  filterData.price.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      name="price"
                      value={ele.value}
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    sort: !filterArrShow.sort,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Sort</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.sort &&
                  filterData.sort &&
                  filterData.sort.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="sort"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.sort &&
                  filterData.sort.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="sort"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
        <div className="flex">
          {!showFilter && (
            <button
              className={cx(
                "focus:outline-none",
                `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
                "cursor-pointer text-y-yellow-dark"
              )}
              style={{ backgroundColor: "#ffffff" }}
              onClick={() => {
                setFilterArrData({
                  levels: [],
                  price: [],
                  healthConditions: [],
                  time: [],
                  style: [],
                  sort: [],
                });
                handleApplyFilter(true);
              }}
            >
              Reset
            </button>
          )}

          <Button
            py={`py-3 ml-2 ${!showFilter ? "md:ml-10" : "md:ml-0"}`}
            onClick={() => {
              if (showFilter) {
                const formatFilterArr = JSON.stringify(filterArrData);
                const formatFilter = JSON.stringify(filterData);
                const searchUrl = `?name=${
                  isMobile ? queryText : filterText
                }&type=${filterType}&filterArr=${formatFilterArr}&filter=${formatFilter}&courseName=${titleQuery}`;

                searchCreateUrl({ url_string: searchUrl })
                  .then((res) => {
                    history.push({
                      pathname: "/courses/monthly-classes",
                      search: `?query=${res?.data?.data.url_id}`,
                    });
                  })
                  .catch((err) => console.log(err));
              } else {
                handleApplyFilter();
              }
            }}
          >
            Search
          </Button>
          {showFilter && (
            <button
              className={cx(
                "focus:outline-none",
                `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
                "cursor-pointer text-y-yellow-dark ml-4 md:ml-10"
              )}
              style={{ backgroundColor: "#ffffff" }}
              onClick={() => {
                props.setShowFilter();
              }}
            >
              Close
            </button>
          )}
        </div>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 13,
    slidesToScroll: 12,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 13,
          slidesToScroll: 12,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <>
      <div className="block md:hidden">
        {(show || showFilter) && (
          <>
            <div
              className="fixed z-10 w-screen h-screen top-0 left-0"
              style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
            >
              <div className="fixed right-0 bg-y-cream-600 top-0 h-screen z-10 p-3 overflow-auto w-full">
                {checkBoxComponent()}
              </div>
            </div>
          </>
        )}
      </div>
      <>
        <div className="flex-1">
          <div className="px-0 mt-0 flex flex-col w-full">
            {!showFilter && (
              <>
                <div
                  className={cx(
                    "hidden sm:inline-block cursor-pointer text-lg",
                    "font-semibold",
                    "text-y-yellow-dark pl-0 md:pl-1 mt-10"
                  )}
                >
                  {titleQuery}
                </div>
                <div class="px-0 md:px-0 mx-0 flex flex-row justify-between items-center pb-0 relative md:w-4/5 mt-5">
                  {isMobile ? (
                    <div
                      className={cx(
                        "inline-block cursor-pointer text-lg",
                        "font-semibold",
                        "text-y-yellow-dark pl-0 md:pl-1 mt-0"
                      )}
                    >
                      {titleQuery}
                    </div>
                  ) : (
                    <div className="hidden md:block sm:w-4/5 pt-1">
                      <div className="flex flex-row mb-4 mt-1 ml-0">
                        <input
                          class="relative pl-10 w-full border-2 border-gray-300 bg-white h-12 px-5 pr-16 rounded-full text-sm focus:outline-none"
                          type="text"
                          name="search"
                          value={queryText}
                          placeholder="Find your yoga practice"
                          onKeyDown={(e) => {
                            if ((e.key === "Enter") | !e.key)
                              handleApplyFilter();
                          }}
                          onChange={(e) => {
                            setQueryText(e.target.value);
                          }}
                        />
                        <button
                          type="submit"
                          class="absolute mt-2 top-3 left-1 ml-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="25px"
                            height="25px"
                            viewBox="0 0 50 50"
                            style={{ fill: "grey" }}
                          >
                            <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setShow(!show)}
                  >
                    <div>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 20 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="1.052" x2="20" y2="1.052" stroke="#686868" />
                        <line
                          x1="5"
                          y1="6.052"
                          x2="15"
                          y2="6.052"
                          stroke="#686868"
                        />
                        <line
                          x1="8"
                          y1="11.052"
                          x2="13"
                          y2="11.052"
                          stroke="#686868"
                        />
                      </svg>
                    </div>
                    <div className="pl-2 font-semibold text-gray-600">
                      Filter
                    </div>
                  </div>
                </div>
              </>
            )}
            {(show || showFilter) && (
              <div className="hidden md:block w-full mx-0 my-3">
                {checkBoxComponent()}
              </div>
            )}

            {!showFilter && (
              <div className="px-1 md:px-3 pt-3">
                {loading || createUrlLoading || getUrlLoading ? null : (
                  <>
                    {timeList.length
                      ? courses.filter(
                          (ele) =>
                            ele?.start_time_hour >=
                            (currentVal ? currentVal : timeList[0].value)
                        ).length
                      : courses.length}{" "}
                    results{" "}
                    {queryText ||
                    filterArrData.levels.length > 0 ||
                    filterArrData.price.length > 0 ||
                    filterArrData.healthConditions.length > 0 ||
                    filterArrData.style.length > 0 ||
                    filterArrData.sort.length > 0 ||
                    filterArrData.time.length > 0 ? (
                      <>for</>
                    ) : null}{" "}
                    {props.time && <>"{props.time}", </>}
                    {queryText && <>"{queryText}", </>}
                    {filterArrData.levels &&
                      filterArrData.levels.map((ele) => (
                        <> "{ele.label}", </>
                      ))}{" "}
                    {filterArrData.price &&
                      filterArrData.price.map((ele) => <> "{ele.label}", </>)}
                    {filterArrData.healthConditions &&
                      filterArrData.healthConditions.map((ele) => (
                        <> "{ele.label}", </>
                      ))}
                    {filterArrData.time &&
                      filterArrData.time.map((ele) => <> "{ele.label}", </>)}
                    {filterArrData.style &&
                      filterArrData.style.map((ele) => <> "{ele.label}", </>)}
                    {filterArrData.sort &&
                      filterArrData.sort.map((ele) => <> "{ele.label}", </>)}
                  </>
                )}
              </div>
            )}

            {loading || createUrlLoading || getUrlLoading ? (
              <>
                {isMobile ? (
                  <Loader />
                ) : (
                  <div className="flex flex-row justify-between items-center">
                    {[1, 2, 3].map((ele) => (
                      <Loader />
                    ))}
                  </div>
                )}
              </>
            ) : filterType === "live" ? (
              <>
                <CardTabs
                  sessionData={courses}
                  filter={true}
                  setLiveDate={(val) => {
                    setLiveDate(val);
                  }}
                />
              </>
            ) : (
              <>
                {timeList.length > 0 && (
                  <div className="pb-3 pt-3 px-5">
                    <Slider {...settings}>
                      {timeList &&
                        timeList.map((d) => (
                          <div
                            key={d.name}
                            onClick={() => {
                              setCurrentVal(d.value);
                            }}
                            className={cx(
                              "mr-1 whitespace-nowrap rounded-full px-2 py-1 cursor-pointer",
                              d.value ===
                                (currentVal ? currentVal : timeList[0].value)
                                ? "bg-y-yellow text-white"
                                : "text-y-yellow"
                            )}
                          >
                            {d.name}
                          </div>
                        ))}
                    </Slider>
                  </div>
                )}
                <CardGroup
                  cards={
                    timeList.length > 0
                      ? courses
                          .filter(
                            (ele) =>
                              ele?.start_time_hour >=
                              (currentVal ? currentVal : timeList[0].value)
                          )
                          .sort((a, b) => {
                            if (a.start_time > b.start_time) {
                              return 1;
                            }
                            if (a.start_time === b.start_time) {
                              if (a.top_rated || b.top_rated) {
                                return (a.top_rated || "")?.length >
                                  (b.top_rated || "").length
                                  ? -1
                                  : 1;
                              }
                              return a.teacherCount > b.teacherCount ? -1 : 1;
                            }
                            return -1;
                          })
                          .map((c) => ({
                            key: c.id,
                            Component: (
                              <CourseCard
                                link={`/monthly-courses/${c.slug}-${c.id}`}
                                course={c}
                                onDetails={() => onCourseDetails(c)}
                              />
                            ),
                          }))
                      : courses
                          .sort((a, b) => {
                            if (a.start_time > b.start_time) {
                              return 1;
                            }
                            if (a.start_time === b.start_time) {
                              if (a.top_rated || b.top_rated) {
                                return (a.top_rated || "")?.length >
                                  (b.top_rated || "").length
                                  ? -1
                                  : 1;
                              }
                              return a.teacherCount > b.teacherCount ? -1 : 1;
                            }
                            return -1;
                          })
                          .map((c) => ({
                            key: c.id,
                            Component: (
                              <CourseCard
                                link={`/monthly-courses/${c.slug}-${c.id}`}
                                course={c}
                                onDetails={() => onCourseDetails(c)}
                              />
                            ),
                          }))
                  }
                />
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
}
