import GlowingPicture from "../../Atoms/GlowingPicture";
import React, { useState } from "react";
import Ratings from "react-ratings-declarative";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { ReviewsApi } from "../../Api/ReviewApi";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import useIsMobile from "../../Hooks/useMobile";
import Slider from "react-slick";
import Loader from "../../Atoms/Loader";
import CourseAction from "../../Actions/course";
import CourseSelectors from "../../Selectors/course";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../Atoms/ErrorMessage";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";

const Reviews = ({ id }) => {
  const { callApi, data, loading, errorMessage } = useApi(
    ReviewsApi.listAllReviews
  );
  const dispatch = useDispatch();

  const reviewsData = useSelector(CourseSelectors.reviewData);
  const indexData = useSelector(CourseSelectors.indexData);

  const [showMore, setShowMore] = useState({});

  const isMobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: indexData.review,
    beforeChange: (current, next) => {
      dispatch(CourseAction.setIndex("review", next));
    },
  };

  useState(() => {
    if (reviewsData.length === 0) {
      callApi()
        .then((res) => {
          const newVal = res.data.data.reverse();
          dispatch(CourseAction.SetReviewData(newVal));
        })
        .catch((err) => console.log(err));
    }
  }, [callApi]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (reviewsData.length === 0) {
    return <ErrorMessage>No Data Available</ErrorMessage>;
  }
  return (
    <>
      {/* <ReactTooltip className="testPurple" html={true} multiline={true} /> */}
      {isMobile ? (
        <>
          <div className="flex flex-nowrap w-full overflow-auto">
            {reviewsData &&
              reviewsData.map((ele) => (
                <div className="p-5 mr-5 px-5 bg-white lg:rounded-md lg:shadow-card-cream-sm h-60">
                  <div>
                    <div>
                      <div className="flex-col md:flex-row items-center">
                        <div className="pt-0 pl-3 md:pl-0 pb-3 text-sm">
                          <div
                            style={{
                              fontStyle: "italic",
                              maxHeight: 75,
                              overflow: "auto",
                            }}
                          >
                            {showMore[ele.id] ? (
                              <>“{ele.comment.replaceAll("?", ``)}”</>
                            ) : (
                              <>
                                “
                                {ele.comment
                                  .replaceAll("?", ``)
                                  .substring(0, 140)}
                                ”
                              </>
                            )}
                          </div>
                          {ele.comment && ele.comment.length > 170 && (
                            <div
                              className="text-gray-600 pt-2 text-right"
                              role="button"
                              onClick={() => {
                                let values = { showMore };
                                const idValue = showMore[ele.id];
                                if (idValue) {
                                  delete values[ele.id];
                                } else {
                                  values[ele.id] = true;
                                }
                                setShowMore({ ...values });
                              }}
                            >
                              {showMore[ele.id] ? "show less" : "show more"}
                            </div>
                          )}
                        </div>
                        <div className="flex pl-3 md:pl-0 items-center whitespace-nowrap">
                          <div>
                            <Ratings
                              rating={Number(ele.rating)}
                              widgetDimensions="20px"
                              widgetSpacings="3px"
                              widgetRatedColor="green"
                            >
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                            </Ratings>
                          </div>
                          {/* <div className="pt-1 pl-4 text-gray-700">
                            ( {ele.rating} )
                          </div> */}
                        </div>
                        <div className="flex items-center pt-2 pb-4">
                          <div>
                            {ele?.user?.picture ? (
                              <GlowingPicture imgUrl={ele?.user?.picture} />
                            ) : (
                              <GlowingPicture imgUrl={""} />
                            )}
                          </div>
                          <div className="font-xs pl-3 pr-5 whitespace-nowrap">
                            <div>
                              {ele?.user?.full_name
                                ? ele?.user?.full_name
                                : ele?.booking?.student?.full_name}
                            </div>
                            <div style={{ fontSize: 12, color: "grey  " }}>
                              {dayjs(ele.created_at).format("DD-MMM-YYYY")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <div>
            <Slider {...settings}>
              {reviewsData &&
                reviewsData.map((ele) => (
                  <div className="p-5 bg-white lg:rounded-md lg:shadow-card-cream-sm sm:max-w-xs w-full h-60">
                    <div>
                      <div>
                        <div className="flex-col md:flex-row items-center">
                          <div className="pt-0 pl-3 md:pl-0 pb-2 text-sm">
                            <div
                              style={{
                                fontStyle: "italic",
                                maxHeight: 85,
                                overflow: "auto",
                              }}
                            >
                              {showMore[ele.id] ? (
                                <>“{ele.comment.replaceAll("?", ``)}”</>
                              ) : (
                                <>
                                  “
                                  {ele.comment
                                    .replaceAll("?", ``)
                                    .substring(0, 140)}
                                  ”
                                </>
                              )}
                            </div>
                            {ele.comment && ele.comment.length > 170 && (
                              <div
                                className="text-gray-600 pt-2 text-right"
                                role="button"
                                onClick={() => {
                                  let values = { showMore };
                                  const idValue = showMore[ele.id];
                                  if (idValue) {
                                    delete values[ele.id];
                                  } else {
                                    values[ele.id] = true;
                                  }
                                  setShowMore({ ...values });
                                }}
                              >
                                {showMore[ele.id] ? "show less" : "show more"}
                              </div>
                            )}
                          </div>
                          <div className="flex pl-3 md:pl-0 items-center whitespace-nowrap">
                            <div>
                              <Ratings
                                rating={Number(ele.rating)}
                                widgetDimensions="20px"
                                widgetSpacings="3px"
                                widgetRatedColor="green"
                              >
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                              </Ratings>
                            </div>
                            {/* <div className="pt-1 pl-4 text-gray-700">
                            ( {ele.rating} )
                          </div> */}
                          </div>
                          <div className="flex items-center pt-2 pb-4">
                            <div>
                              {ele?.user?.picture ? (
                                <GlowingPicture imgUrl={ele?.user?.picture} />
                              ) : (
                                <GlowingPicture imgUrl={""} />
                              )}
                            </div>
                            <div className="font-xs pl-3 pr-5 whitespace-nowrap">
                              <div>
                                {ele?.user?.full_name
                                  ? ele?.user?.full_name
                                  : ele?.booking?.student?.full_name}
                              </div>
                              <div style={{ fontSize: 12, color: "grey  " }}>
                                {dayjs(ele.created_at).format("DD-MMM-YYYY")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );
};

export default Reviews;
