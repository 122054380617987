const Styles = {
  OrangeLinearGradient: {
    background: 'linear-gradient(261.13deg, #F8B045 10.01%, #F88849 84.67%)',
  },
  ButtonDisabled: {
    background: 'linear-gradient(261.13deg, hsl(0deg 0% 62%) 10.01%, hsl(0deg 0% 63%) 84.67%)',
  },
};

export default Styles;
