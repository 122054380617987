import dayjs from "dayjs";
import React from "react";
import SessionCard from "../../Atoms/SessionCard";
import DateHelpers from "../../Utilities/DateHelpers";
import CardGroup from "./CardGroup";

export default function DayClassGroup({ day, sessions }) {
  // const todaySessions = (sessions || []).filter(
  //   (s) => dayjs.unix(s.utc_start_at).format("YYYY-MM-DD") === day
  // );
  // if (todaySessions.length === 0) {
  //   return null;
  // }

  //const dayLabel = DateHelpers.humanizedDateText(day);

  return (
    <div className="mt-0">
      {/* <div className="px-5 opacity-60 font-semibold">{dayLabel}</div> */}

      <CardGroup
        cards={sessions.map((s) => ({
          key: s.id,
          Component: <SessionCard session={s} />,
        }))}
      />
    </div>
  );
}
