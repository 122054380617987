import cx from 'classnames';
import React from 'react';


export default function NumberButton({ children, number, selected, finished, className, onClick }) {

  let circleColor = 'bg-gray-400';
  if (selected) {
    circleColor = 'bg-y-yellow';
  }
  if (finished) {
    circleColor = 'bg-green-500';
  }

  return (
    <div
      className={cx(
        "inline-block cursor-pointer flex",
        selected && 'font-medium',
        className
      )}
      onClick={onClick}
    >
      <div className={cx(
        circleColor, 'text-white',
        "mr-2 rounded-full w-7 h-7 flex justify-center items-center text-sm"
      )}>
        {finished ? (
          <svg width="14" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 5l3.5 4L13 1" stroke="#fff" strokeWidth="2"/>
          </svg>
        ) : number}
      </div>

      {children}

      <div className={cx()}
           style={{ width: 14, height: 6, borderRadius: 20 }} />
    </div>
  );
}
