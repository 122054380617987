import { Menu, Transition } from "@headlessui/react";
import React from 'react';

export default function Dropdown({ button, items }) {
  return (
    <div className="relative inline-block text-left">
      <Menu>
        {({ open }) => (
          <>
            <Menu.Button style={{ outline: 'none' }}>
              {button}
            </Menu.Button>

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                style={{ zIndex: 150 }}
                className="absolute right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none">

                {(items || []).map(i => (
                  <div className="my-1" key={i.key}>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={i.onClick}
                          className={`${
                            active
                              ? "bg-gray-100"
                              : "text-gray-700"
                          } flex items-center w-full px-4 py-2 leading-5 text-left cursor-pointer`}
                        >
                          {i.icon}
                          {i.text}
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                ))}

              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}
