import TextService from "./TextService";

export default class PaymentService {
  /**
   * Get session fee object from fee
   *
   * @param fee
   * @param payWhatYouWant
   * @param numBooked
   */
  static getSessionFeeObjectFromFee(fee, payWhatYouWant, numBooked) {
    if (!fee) {
      // all exceptions applicable only to indian currency
      return fee;
    }

    if (payWhatYouWant) {
      return {
        ...fee,
        strike: true,
        strikeText: "free",
      };
    }

    if (numBooked === 0 && fee.currency === "inr") {
      return {
        ...fee,
        strike: true,
        strikeText: TextService.formatCurrencyFee({ currency: "inr", fee: 50 }),
      };
    }

    if (numBooked === 0 && fee.currency === "usd") {
      return {
        ...fee,
        strike: true,
        strikeText: TextService.formatCurrencyFee({ currency: "usd", fee: 5 }),
      };
    }

    return fee;
  }
}
