import cx from "classnames";
import React from "react";
import InputWrapper from "../../../Atoms/InputWrapper/InputWrapper";

function CustomInput({
  label,
  name,
  autoFocus,
  inputRef,
  required,
  errors,
  style,
  className,
  prefix,
  suffix,
  prefixWidth,
  inputWidth,
  type = "text",
  wrapperStyle = "hover-label",
  disabled,
  value,
  onChange,
}) {
  const _className = className;
  const _style = style || {};

  return (
    <InputWrapper
      label={label}
      name={name}
      required={required}
      errors={errors}
      wrapperStyle={wrapperStyle}
      prefix={prefix}
      prefixWidth={prefixWidth}
      suffix={suffix}
      inputWidth={inputWidth}
    >
      {({ className, style = {}, ...props }) => (
        <input
          {...props}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
          ref={inputRef}
          style={{ ..._style, ...style }}
          className={cx(className, _className, "appearance-none bg-white")}
          disabled={disabled}
        />
      )}
    </InputWrapper>
  );
}

export default CustomInput;
