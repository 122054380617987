import React, { useEffect, useState } from "react";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { BlogApi } from "../../Api/BlogApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import { Link, useLocation } from "react-router-dom";

export default function BlogPage(props) {
  const { search } = useLocation();
  const { callApi, loading, errorMessage, data } = useApi(BlogApi.blogById);

  const blogData = data?.data;

  useEffect(() => {
    let searchText = new URLSearchParams(search);

    let arr = props?.match?.params?.id
      ? props?.match?.params?.id.split("-")
      : props?.match?.params?.name
      ? props?.match?.params?.name.split("-")
      : [];

    const id = searchText.get("name")
      ? searchText.get("name")
      : arr[arr.length - 1];
    callApi(id);
  }, [callApi]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (!blogData) {
    return null;
  }

  return (
    <div className="bg-white p-10 w-full">
      <div className="mx-0 mt-3 items-center  space-y-2 sm:space-y-0">
        <div className="">
          <div>
            {blogData.image && (
              <img className="h-44" src={blogData.image} alt="cover" />
            )}
          </div>
          <div className="text-base sm:text-2xl pt-3 font-bold">
            {blogData.title}
          </div>
        </div>

        {blogData.description && (
          <div
            className="pt-10"
            dangerouslySetInnerHTML={{
              __html: blogData.description.replaceAll(
                "<p></p>",
                `<p style="padding:15px 0;"></p>`
              ),
            }}
          ></div>
        )}
      </div>
    </div>
  );
}
