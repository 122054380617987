import React, { useState, useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import storefrontRoutes from "../../Routes/storefrontRoutes";
import StorefrontHeader from "./StorefrontHeader";
import StoreFrontFooter from "./StorefrontFooter";
import PayWhatYouWantModal from "../../Components/PayWhatYouWantModal";
import GlowingPicture from "../../Atoms/GlowingPicture";
import MyLandbot from "../../Components/LandBot";
import ReactWhatsapp from "react-whatsapp";
import "../../Atoms/GlowingPicture/styles.css";
import UrlService from "../../Services/UrlService";
import Modal from "../../Atoms/Modal/Modal";
import TimeZoneData from "../../Utilities/TimeZoneData";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import UserAction from "../../Actions/user";
import CourseAction from "../../Actions/course";
import UserSelectors from "../../Selectors/user";
import Button from "../../Atoms/Button/Button";
import dayjs from "dayjs";
import BottomModal from "./BottomModel";

import { useApi } from "../../Utilities/Hooks/ApiHook";
import { UserApi } from "../../Api/UserApi";

const TimeZoneModal = ({ show, onClose }) => {
  const dispatch = useDispatch();
  const [time, setTime] = useState("");
  const [timeVal, setTimeVal] = useState("");
  const userTimeZone = useSelector(UserSelectors.userTimeZone);
  const userTime = useSelector(UserSelectors.userTime);

  return (
    <Modal
      show={show}
      onClose={onClose}
      className="sm:w-screen sm:max-w-xl h-96"
    >
      <div className="flex-col items-center justify-center text-center p-8">
        <h2 className="pt-3">Choose your Time</h2>

        <h4 className="pt-5">Current Timezone: {time ? time : userTimeZone}</h4>

        <h4 className="pt-5">Current Time: {timeVal ? timeVal : userTime}</h4>

        <div className="mt-8">
          <Select
            options={TimeZoneData}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(val) => {
              setTimeVal(dayjs().tz(val.value).format("h:mm a"));
              setTime(val.value);
            }}
            clearValue={() => {
              console.log("clear");
            }}
          />
        </div>

        <div className="flex justify-end mt-10">
          <Button
            className="mt-8"
            onClick={() => {
              dispatch(UserAction.setTimeZone(time));
              onClose();
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default function StorefrontTemplate() {
  const [showMessages, setShowMessages] = useState(false);
  const [showTimeZone, setShowTimeZone] = useState(false);
  const teacherDomain = UrlService.getTeacherDomain();
  const [y, setY] = useState(document.scrollingElement.scrollHeight);

  const { callApi } = useApi(UserApi.teacherRating);

  const [showBottomTrial, setShowBottomTrial] = useState(false);

  const showBottom = useSelector(UserSelectors.showBottom);

  const dispatch = useDispatch();

  const userTimeZone = useSelector(UserSelectors.userTimeZone);

  const userTime = useSelector(UserSelectors.userTime);

  const handleNavigation = useCallback(
    (e) => {
      if (y > window.scrollY) {
        if (showBottom === 0 && !showBottomTrial) {
          setShowBottomTrial(!showBottomTrial);
        }
      }
      setY(window.scrollY);
    },
    [y]
  );

  // useEffect(() => {
  //   window.addEventListener("scroll", handleNavigation);

  //   return () => {
  //     window.removeEventListener("scroll", handleNavigation);
  //   };
  // }, [handleNavigation]);

  const getStreamData = () => {
    callApi()
      .then((res) => {
        const teacherData = {};

        const { streams, reviews } = res.data.data;
        streams.map((ele) => {
          teacherData[ele.teacher] = { ...ele };
        });
        reviews.map((ele) => {
          if (teacherData[ele.teacher]) {
            teacherData[ele.teacher] = {
              ...teacherData[ele.teacher],
              ...ele,
              avgReviewsCount: ele.avgReviewsCount.toFixed(1),
            };
          } else {
            teacherData[ele.teacher] = {
              ...ele,
              avgReviewsCount: ele.avgReviewsCount.toFixed(1),
            };
          }
        });

        dispatch(CourseAction.setStreamDatas(teacherData));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // setTimeVal(dayjs().tz(userTimeZone).format("h:mm a"));
    // setTime(userTimeZone);
    getStreamData();
  }, []);

  return (
    <div className="bg-y-cream-600 min-h-screen pb-8">
      <div className="h-full max-w-screen-xl mx-auto home-main-cont">
        {/* {window.COUNTRY_CODE &&
        window.COUNTRY_CODE === "IN" &&
        !teacherDomain ? (
          <PayWhatYouWantModal />
        ) : null} */}
        <TimeZoneModal
          show={showTimeZone}
          onClose={() => {
            setShowTimeZone(!showTimeZone);
          }}
        />
        <StorefrontHeader />
        <Switch>
          {storefrontRoutes.map((route) => (
            <Route exact key={route.path} {...route} />
          ))}
          <Route path="*">
            <Redirect to="/not-found" />
          </Route>
        </Switch>
        {!showMessages ? (
          <div className="fixed bottom-10 transform cursor-pointer chat-cont z-20">
            <GlowingPicture
              alarm={userTime}
              onClick={() => {
                setShowTimeZone(!showTimeZone);
              }}
            />
            <ReactWhatsapp
              number="+917975322831"
              message={`Hi, I have some questions on Do Yoga.`}
              className="w-full"
            >
              <GlowingPicture whatsapp />
            </ReactWhatsapp>
            {/* <div
              class="flex items-center"
              onClick={() => {
                setShowMessages(!showMessages);
              }}
            >
              <GlowingPicture chatBot={true} />
            </div> */}
          </div>
        ) : (
          <div>
            <div className="fixed right-10 bottom-10 transform cursor-pointer">
              <GlowingPicture
                alarm={userTime}
                onClick={() => {
                  setShowTimeZone(!showTimeZone);
                }}
              />
              <ReactWhatsapp
                number="+917975322831"
                message={`Hi, I have some questions on Do Yoga.`}
                className="w-full"
              >
                <GlowingPicture whatsapp />
              </ReactWhatsapp>
              {/* <GlowingPicture
                close={true}
                onClick={() => {
                  setShowMessages(!showMessages);
                }}
              /> */}
            </div>
          </div>
        )}
        {/* {showMessages ? (
          <div className="chatSection">
            <MyLandbot />
          </div>
        ) : null} */}
        <StoreFrontFooter teacherDomain={teacherDomain} />
      </div>
      {/* {showBottomTrial && (
        <BottomModal
          handleClick={() => {
            dispatch(UserAction.setShowBootom(1));
            setShowBottomTrial(false);
          }}
        />
      )} */}
    </div>
  );
}
