import React, { useState } from 'react'
import { StorefrontApi } from '../../Api/StorefrontApi'
import { SubscriptionApi } from '../../Api/SubscriptionApi'
import ErrorMessage from '../../Atoms/ErrorMessage'
import UrlService from '../../Services/UrlService'
import { useApi } from '../../Utilities/Hooks/ApiHook'
import Vent from '../../vent'
import { Link } from 'react-router-dom'
import Button from '../../Atoms/Button/Button'

const errors = {
  0: 'You already have a booking for Live Class',
  1: 'Live class has already started',
  2: 'Could not join session. E_INV_FEE',
  3: 'You already have a booking to the course',
  4: 'Class batch has already started. You cannot join now',
  5: 'Class has already finished. You cannot join now.',
}

export default function SuccessFailback({}) {
  return (
    <div className="p-5 text-center">
      <div>
        <ErrorMessage>You already having booking for this class</ErrorMessage>
      </div>
      <div>
        <Link to="/">
          <Button className="mt-12">Go To Home</Button>
        </Link>
      </div>
    </div>
  )
}
