import React from "react";

export default function School({ teacher }) {
  if (!teacher || !teacher.school_name) {
    return null;
  }

  const schoolImages = teacher.school_images || [];

  return (
    <div>
      <h2 className="font-semibold text-xl">About the school</h2>
      <div className="bg-white shadow-card-cream-sm rounded-lg overflow-hidden mt-3">
        <div className="flex-row">
          {schoolImages.length > 0 && (
            <div>
              <img className="mr-5 w-full h-36 object-cover" src={schoolImages[0].file} alt="Yoga School" />
            </div>
          )}
          <div className="p-5">
            <h2 className="font-semibold">{teacher.school_name}</h2>
            {teacher.school_description && (
              <p className="text-sm leading-relaxed">{teacher.school_description}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
