const data = [
  {
    label: "Europe/Andorra",
    value: "Europe/Andorra",
  },
  {
    label: "Asia/Dubai",
    value: "Asia/Dubai",
  },
  {
    label: "Asia/Kabul",
    value: "Asia/Kabul",
  },
  {
    label: "Europe/Tirane",
    value: "Europe/Tirane",
  },
  {
    label: "Asia/Yerevan",
    value: "Asia/Yerevan",
  },
  {
    label: "Antarctica/Casey",
    value: "Antarctica/Casey",
  },
  {
    label: "Antarctica/Davis",
    value: "Antarctica/Davis",
  },
  {
    label: "Antarctica/DumontDUrville",
    value: "Antarctica/DumontDUrville",
  },
  {
    label: "Antarctica/Mawson",
    value: "Antarctica/Mawson",
  },
  {
    label: "Antarctica/Palmer",
    value: "Antarctica/Palmer",
  },
  {
    label: "Antarctica/Rothera",
    value: "Antarctica/Rothera",
  },
  {
    label: "Antarctica/Syowa",
    value: "Antarctica/Syowa",
  },
  {
    label: "Antarctica/Troll",
    value: "Antarctica/Troll",
  },
  {
    label: "Antarctica/Vostok",
    value: "Antarctica/Vostok",
  },
  {
    label: "America/Argentina/Buenos_Aires",
    value: "America/Argentina/Buenos_Aires",
  },
  {
    label: "America/Argentina/Cordoba",
    value: "America/Argentina/Cordoba",
  },
  {
    label: "America/Argentina/Salta",
    value: "America/Argentina/Salta",
  },
  {
    label: "America/Argentina/Jujuy",
    value: "America/Argentina/Jujuy",
  },
  {
    label: "America/Argentina/Tucuman",
    value: "America/Argentina/Tucuman",
  },
  {
    label: "America/Argentina/Catamarca",
    value: "America/Argentina/Catamarca",
  },
  {
    label: "America/Argentina/La_Rioja",
    value: "America/Argentina/La_Rioja",
  },
  {
    label: "America/Argentina/San_Juan",
    value: "America/Argentina/San_Juan",
  },
  {
    label: "America/Argentina/Mendoza",
    value: "America/Argentina/Mendoza",
  },
  {
    label: "America/Argentina/San_Luis",
    value: "America/Argentina/San_Luis",
  },
  {
    label: "America/Argentina/Rio_Gallegos",
    value: "America/Argentina/Rio_Gallegos",
  },
  {
    label: "America/Argentina/Ushuaia",
    value: "America/Argentina/Ushuaia",
  },
  {
    label: "Pacific/Pago_Pago",
    value: "Pacific/Pago_Pago",
  },
  {
    label: "Europe/Vienna",
    value: "Europe/Vienna",
  },
  {
    label: "Australia/Lord_Howe",
    value: "Australia/Lord_Howe",
  },
  {
    label: "Antarctica/Macquarie",
    value: "Antarctica/Macquarie",
  },
  {
    label: "Australia/Hobart",
    value: "Australia/Hobart",
  },
  {
    label: "Australia/Currie",
    value: "Australia/Currie",
  },
  {
    label: "Australia/Melbourne",
    value: "Australia/Melbourne",
  },
  {
    label: "Australia/Sydney",
    value: "Australia/Sydney",
  },
  {
    label: "Australia/Broken_Hill",
    value: "Australia/Broken_Hill",
  },
  {
    label: "Australia/Brisbane",
    value: "Australia/Brisbane",
  },
  {
    label: "Australia/Lindeman",
    value: "Australia/Lindeman",
  },
  {
    label: "Australia/Adelaide",
    value: "Australia/Adelaide",
  },
  {
    label: "Australia/Darwin",
    value: "Australia/Darwin",
  },
  {
    label: "Australia/Perth",
    value: "Australia/Perth",
  },
  {
    label: "Australia/Eucla",
    value: "Australia/Eucla",
  },
  {
    label: "Asia/Baku",
    value: "Asia/Baku",
  },
  {
    label: "America/Barbados",
    value: "America/Barbados",
  },
  {
    label: "Asia/Dhaka",
    value: "Asia/Dhaka",
  },
  {
    label: "Europe/Brussels",
    value: "Europe/Brussels",
  },
  {
    label: "Europe/Sofia",
    value: "Europe/Sofia",
  },
  {
    label: "Atlantic/Bermuda",
    value: "Atlantic/Bermuda",
  },
  {
    label: "Asia/Brunei",
    value: "Asia/Brunei",
  },
  {
    label: "America/La_Paz",
    value: "America/La_Paz",
  },
  {
    label: "America/Noronha",
    value: "America/Noronha",
  },
  {
    label: "America/Belem",
    value: "America/Belem",
  },
  {
    label: "America/Fortaleza",
    value: "America/Fortaleza",
  },
  {
    label: "America/Recife",
    value: "America/Recife",
  },
  {
    label: "America/Araguaina",
    value: "America/Araguaina",
  },
  {
    label: "America/Maceio",
    value: "America/Maceio",
  },
  {
    label: "America/Bahia",
    value: "America/Bahia",
  },
  {
    label: "America/Sao_Paulo",
    value: "America/Sao_Paulo",
  },
  {
    label: "America/Campo_Grande",
    value: "America/Campo_Grande",
  },
  {
    label: "America/Cuiaba",
    value: "America/Cuiaba",
  },
  {
    label: "America/Santarem",
    value: "America/Santarem",
  },
  {
    label: "America/Porto_Velho",
    value: "America/Porto_Velho",
  },
  {
    label: "America/Boa_Vista",
    value: "America/Boa_Vista",
  },
  {
    label: "America/Manaus",
    value: "America/Manaus",
  },
  {
    label: "America/Eirunepe",
    value: "America/Eirunepe",
  },
  {
    label: "America/Rio_Branco",
    value: "America/Rio_Branco",
  },
  {
    label: "America/Nassau",
    value: "America/Nassau",
  },
  {
    label: "Asia/Thimphu",
    value: "Asia/Thimphu",
  },
  {
    label: "Europe/Minsk",
    value: "Europe/Minsk",
  },
  {
    label: "America/Belize",
    value: "America/Belize",
  },
  {
    label: "America/St_Johns",
    value: "America/St_Johns",
  },
  {
    label: "America/Halifax",
    value: "America/Halifax",
  },
  {
    label: "America/Glace_Bay",
    value: "America/Glace_Bay",
  },
  {
    label: "America/Moncton",
    value: "America/Moncton",
  },
  {
    label: "America/Goose_Bay",
    value: "America/Goose_Bay",
  },
  {
    label: "America/Blanc-Sablon",
    value: "America/Blanc-Sablon",
  },
  {
    label: "America/Toronto",
    value: "America/Toronto",
  },
  {
    label: "America/Nipigon",
    value: "America/Nipigon",
  },
  {
    label: "America/Thunder_Bay",
    value: "America/Thunder_Bay",
  },
  {
    label: "America/Iqaluit",
    value: "America/Iqaluit",
  },
  {
    label: "America/Pangnirtung",
    value: "America/Pangnirtung",
  },
  {
    label: "America/Atikokan",
    value: "America/Atikokan",
  },
  {
    label: "America/Winnipeg",
    value: "America/Winnipeg",
  },
  {
    label: "America/Rainy_River",
    value: "America/Rainy_River",
  },
  {
    label: "America/Resolute",
    value: "America/Resolute",
  },
  {
    label: "America/Rankin_Inlet",
    value: "America/Rankin_Inlet",
  },
  {
    label: "America/Regina",
    value: "America/Regina",
  },
  {
    label: "America/Swift_Current",
    value: "America/Swift_Current",
  },
  {
    label: "America/Edmonton",
    value: "America/Edmonton",
  },
  {
    label: "America/Cambridge_Bay",
    value: "America/Cambridge_Bay",
  },
  {
    label: "America/Yellowknife",
    value: "America/Yellowknife",
  },
  {
    label: "America/Inuvik",
    value: "America/Inuvik",
  },
  {
    label: "America/Creston",
    value: "America/Creston",
  },
  {
    label: "America/Dawson_Creek",
    value: "America/Dawson_Creek",
  },
  {
    label: "America/Fort_Nelson",
    value: "America/Fort_Nelson",
  },
  {
    label: "America/Vancouver",
    value: "America/Vancouver",
  },
  {
    label: "America/Whitehorse",
    value: "America/Whitehorse",
  },
  {
    label: "America/Dawson",
    value: "America/Dawson",
  },
  {
    label: "Indian/Cocos",
    value: "Indian/Cocos",
  },
  {
    label: "Europe/Zurich",
    value: "Europe/Zurich",
  },
  {
    label: "Africa/Abidjan",
    value: "Africa/Abidjan",
  },
  {
    label: "Pacific/Rarotonga",
    value: "Pacific/Rarotonga",
  },
  {
    label: "America/Santiago",
    value: "America/Santiago",
  },
  {
    label: "America/Punta_Arenas",
    value: "America/Punta_Arenas",
  },
  {
    label: "Pacific/Easter",
    value: "Pacific/Easter",
  },
  {
    label: "Asia/Shanghai",
    value: "Asia/Shanghai",
  },
  {
    label: "Asia/Urumqi",
    value: "Asia/Urumqi",
  },
  {
    label: "America/Bogota",
    value: "America/Bogota",
  },
  {
    label: "America/Costa_Rica",
    value: "America/Costa_Rica",
  },
  {
    label: "America/Havana",
    value: "America/Havana",
  },
  {
    label: "Atlantic/Cape_Verde",
    value: "Atlantic/Cape_Verde",
  },
  {
    label: "America/Curacao",
    value: "America/Curacao",
  },
  {
    label: "Indian/Christmas",
    value: "Indian/Christmas",
  },
  {
    label: "Asia/Nicosia",
    value: "Asia/Nicosia",
  },
  {
    label: "Asia/Famagusta",
    value: "Asia/Famagusta",
  },
  {
    label: "Europe/Prague",
    value: "Europe/Prague",
  },
  {
    label: "Europe/Berlin",
    value: "Europe/Berlin",
  },
  {
    label: "Europe/Copenhagen",
    value: "Europe/Copenhagen",
  },
  {
    label: "America/Santo_Domingo",
    value: "America/Santo_Domingo",
  },
  {
    label: "Africa/Algiers",
    value: "Africa/Algiers",
  },
  {
    label: "America/Guayaquil",
    value: "America/Guayaquil",
  },
  {
    label: "Pacific/Galapagos",
    value: "Pacific/Galapagos",
  },
  {
    label: "Europe/Tallinn",
    value: "Europe/Tallinn",
  },
  {
    label: "Africa/Cairo",
    value: "Africa/Cairo",
  },
  {
    label: "Africa/El_Aaiun",
    value: "Africa/El_Aaiun",
  },
  {
    label: "Europe/Madrid",
    value: "Europe/Madrid",
  },
  {
    label: "Africa/Ceuta",
    value: "Africa/Ceuta",
  },
  {
    label: "Atlantic/Canary",
    value: "Atlantic/Canary",
  },
  {
    label: "Europe/Helsinki",
    value: "Europe/Helsinki",
  },
  {
    label: "Pacific/Fiji",
    value: "Pacific/Fiji",
  },
  {
    label: "Atlantic/Stanley",
    value: "Atlantic/Stanley",
  },
  {
    label: "Pacific/Chuuk",
    value: "Pacific/Chuuk",
  },
  {
    label: "Pacific/Pohnpei",
    value: "Pacific/Pohnpei",
  },
  {
    label: "Pacific/Kosrae",
    value: "Pacific/Kosrae",
  },
  {
    label: "Atlantic/Faroe",
    value: "Atlantic/Faroe",
  },
  {
    label: "Europe/Paris",
    value: "Europe/Paris",
  },
  {
    label: "Europe/London",
    value: "Europe/London",
  },
  {
    label: "Asia/Tbilisi",
    value: "Asia/Tbilisi",
  },
  {
    label: "America/Cayenne",
    value: "America/Cayenne",
  },
  {
    label: "Africa/Accra",
    value: "Africa/Accra",
  },
  {
    label: "Europe/Gibraltar",
    value: "Europe/Gibraltar",
  },
  {
    label: "America/Godthab",
    value: "America/Godthab",
  },
  {
    label: "America/Danmarkshavn",
    value: "America/Danmarkshavn",
  },
  {
    label: "America/Scoresbysund",
    value: "America/Scoresbysund",
  },
  {
    label: "America/Thule",
    value: "America/Thule",
  },
  {
    label: "Europe/Athens",
    value: "Europe/Athens",
  },
  {
    label: "Atlantic/South_Georgia",
    value: "Atlantic/South_Georgia",
  },
  {
    label: "America/Guatemala",
    value: "America/Guatemala",
  },
  {
    label: "Pacific/Guam",
    value: "Pacific/Guam",
  },
  {
    label: "Africa/Bissau",
    value: "Africa/Bissau",
  },
  {
    label: "America/Guyana",
    value: "America/Guyana",
  },
  {
    label: "Asia/Hong_Kong",
    value: "Asia/Hong_Kong",
  },
  {
    label: "America/Tegucigalpa",
    value: "America/Tegucigalpa",
  },
  {
    label: "America/Port-au-Prince",
    value: "America/Port-au-Prince",
  },
  {
    label: "Europe/Budapest",
    value: "Europe/Budapest",
  },
  {
    label: "Asia/Jakarta",
    value: "Asia/Jakarta",
  },
  {
    label: "Asia/Pontianak",
    value: "Asia/Pontianak",
  },
  {
    label: "Asia/Makassar",
    value: "Asia/Makassar",
  },
  {
    label: "Asia/Jayapura",
    value: "Asia/Jayapura",
  },
  {
    label: "Europe/Dublin",
    value: "Europe/Dublin",
  },
  {
    label: "Asia/Jerusalem",
    value: "Asia/Jerusalem",
  },
  {
    label: "Asia/Kolkata",
    value: "Asia/Kolkata",
  },
  {
    label: "Indian/Chagos",
    value: "Indian/Chagos",
  },
  {
    label: "Asia/Baghdad",
    value: "Asia/Baghdad",
  },
  {
    label: "Asia/Tehran",
    value: "Asia/Tehran",
  },
  {
    label: "Atlantic/Reykjavik",
    value: "Atlantic/Reykjavik",
  },
  {
    label: "Europe/Rome",
    value: "Europe/Rome",
  },
  {
    label: "America/Jamaica",
    value: "America/Jamaica",
  },
  {
    label: "Asia/Amman",
    value: "Asia/Amman",
  },
  {
    label: "Asia/Tokyo",
    value: "Asia/Tokyo",
  },
  {
    label: "Africa/Nairobi",
    value: "Africa/Nairobi",
  },
  {
    label: "Asia/Bishkek",
    value: "Asia/Bishkek",
  },
  {
    label: "Pacific/Tarawa",
    value: "Pacific/Tarawa",
  },
  {
    label: "Pacific/Enderbury",
    value: "Pacific/Enderbury",
  },
  {
    label: "Pacific/Kiritimati",
    value: "Pacific/Kiritimati",
  },
  {
    label: "Asia/Pyongyang",
    value: "Asia/Pyongyang",
  },
  {
    label: "Asia/Seoul",
    value: "Asia/Seoul",
  },
  {
    label: "Asia/Almaty",
    value: "Asia/Almaty",
  },
  {
    label: "Asia/Qyzylorda",
    value: "Asia/Qyzylorda",
  },
  {
    label: "Asia/Qostanay",
    value: "Asia/Qostanay",
  },
  {
    label: "Asia/Aqtobe",
    value: "Asia/Aqtobe",
  },
  {
    label: "Asia/Aqtau",
    value: "Asia/Aqtau",
  },
  {
    label: "Asia/Atyrau",
    value: "Asia/Atyrau",
  },
  {
    label: "Asia/Oral",
    value: "Asia/Oral",
  },
  {
    label: "Asia/Beirut",
    value: "Asia/Beirut",
  },
  {
    label: "Asia/Colombo",
    value: "Asia/Colombo",
  },
  {
    label: "Africa/Monrovia",
    value: "Africa/Monrovia",
  },
  {
    label: "Europe/Vilnius",
    value: "Europe/Vilnius",
  },
  {
    label: "Europe/Luxembourg",
    value: "Europe/Luxembourg",
  },
  {
    label: "Europe/Riga",
    value: "Europe/Riga",
  },
  {
    label: "Africa/Tripoli",
    value: "Africa/Tripoli",
  },
  {
    label: "Africa/Casablanca",
    value: "Africa/Casablanca",
  },
  {
    label: "Europe/Monaco",
    value: "Europe/Monaco",
  },
  {
    label: "Europe/Chisinau",
    value: "Europe/Chisinau",
  },
  {
    label: "Pacific/Majuro",
    value: "Pacific/Majuro",
  },
  {
    label: "Pacific/Kwajalein",
    value: "Pacific/Kwajalein",
  },
  {
    label: "Asia/Yangon",
    value: "Asia/Yangon",
  },
  {
    label: "Asia/Ulaanbaatar",
    value: "Asia/Ulaanbaatar",
  },
  {
    label: "Asia/Hovd",
    value: "Asia/Hovd",
  },
  {
    label: "Asia/Choibalsan",
    value: "Asia/Choibalsan",
  },
  {
    label: "Asia/Macau",
    value: "Asia/Macau",
  },
  {
    label: "America/Martinique",
    value: "America/Martinique",
  },
  {
    label: "Europe/Malta",
    value: "Europe/Malta",
  },
  {
    label: "Indian/Mauritius",
    value: "Indian/Mauritius",
  },
  {
    label: "Indian/Maldives",
    value: "Indian/Maldives",
  },
  {
    label: "America/Mexico_City",
    value: "America/Mexico_City",
  },
  {
    label: "America/Cancun",
    value: "America/Cancun",
  },
  {
    label: "America/Merida",
    value: "America/Merida",
  },
  {
    label: "America/Monterrey",
    value: "America/Monterrey",
  },
  {
    label: "America/Matamoros",
    value: "America/Matamoros",
  },
  {
    label: "America/Mazatlan",
    value: "America/Mazatlan",
  },
  {
    label: "America/Chihuahua",
    value: "America/Chihuahua",
  },
  {
    label: "America/Ojinaga",
    value: "America/Ojinaga",
  },
  {
    label: "America/Hermosillo",
    value: "America/Hermosillo",
  },
  {
    label: "America/Tijuana",
    value: "America/Tijuana",
  },
  {
    label: "America/Bahia_Banderas",
    value: "America/Bahia_Banderas",
  },
  {
    label: "Asia/Kuala_Lumpur",
    value: "Asia/Kuala_Lumpur",
  },
  {
    label: "Asia/Kuching",
    value: "Asia/Kuching",
  },
  {
    label: "Africa/Maputo",
    value: "Africa/Maputo",
  },
  {
    label: "Africa/Windhoek",
    value: "Africa/Windhoek",
  },
  {
    label: "Pacific/Noumea",
    value: "Pacific/Noumea",
  },
  {
    label: "Pacific/Norfolk",
    value: "Pacific/Norfolk",
  },
  {
    label: "Africa/Lagos",
    value: "Africa/Lagos",
  },
  {
    label: "America/Managua",
    value: "America/Managua",
  },
  {
    label: "Europe/Amsterdam",
    value: "Europe/Amsterdam",
  },
  {
    label: "Europe/Oslo",
    value: "Europe/Oslo",
  },
  {
    label: "Asia/Kathmandu",
    value: "Asia/Kathmandu",
  },
  {
    label: "Pacific/Nauru",
    value: "Pacific/Nauru",
  },
  {
    label: "Pacific/Niue",
    value: "Pacific/Niue",
  },
  {
    label: "Pacific/Auckland",
    value: "Pacific/Auckland",
  },
  {
    label: "Pacific/Chatham",
    value: "Pacific/Chatham",
  },
  {
    label: "America/Panama",
    value: "America/Panama",
  },
  {
    label: "America/Lima",
    value: "America/Lima",
  },
  {
    label: "Pacific/Tahiti",
    value: "Pacific/Tahiti",
  },
  {
    label: "Pacific/Marquesas",
    value: "Pacific/Marquesas",
  },
  {
    label: "Pacific/Gambier",
    value: "Pacific/Gambier",
  },
  {
    label: "Pacific/Port_Moresby",
    value: "Pacific/Port_Moresby",
  },
  {
    label: "Pacific/Bougainville",
    value: "Pacific/Bougainville",
  },
  {
    label: "Asia/Manila",
    value: "Asia/Manila",
  },
  {
    label: "Asia/Karachi",
    value: "Asia/Karachi",
  },
  {
    label: "Europe/Warsaw",
    value: "Europe/Warsaw",
  },
  {
    label: "America/Miquelon",
    value: "America/Miquelon",
  },
  {
    label: "Pacific/Pitcairn",
    value: "Pacific/Pitcairn",
  },
  {
    label: "America/Puerto_Rico",
    value: "America/Puerto_Rico",
  },
  {
    label: "Asia/Gaza",
    value: "Asia/Gaza",
  },
  {
    label: "Asia/Hebron",
    value: "Asia/Hebron",
  },
  {
    label: "Europe/Lisbon",
    value: "Europe/Lisbon",
  },
  {
    label: "Atlantic/Madeira",
    value: "Atlantic/Madeira",
  },
  {
    label: "Atlantic/Azores",
    value: "Atlantic/Azores",
  },
  {
    label: "Pacific/Palau",
    value: "Pacific/Palau",
  },
  {
    label: "America/Asuncion",
    value: "America/Asuncion",
  },
  {
    label: "Asia/Qatar",
    value: "Asia/Qatar",
  },
  {
    label: "Indian/Reunion",
    value: "Indian/Reunion",
  },
  {
    label: "Europe/Bucharest",
    value: "Europe/Bucharest",
  },
  {
    label: "Europe/Belgrade",
    value: "Europe/Belgrade",
  },
  {
    label: "Europe/Kaliningrad",
    value: "Europe/Kaliningrad",
  },
  {
    label: "Europe/Moscow",
    value: "Europe/Moscow",
  },
  {
    label: "Europe/Simferopol",
    value: "Europe/Simferopol",
  },
  {
    label: "Europe/Kirov",
    value: "Europe/Kirov",
  },
  {
    label: "Europe/Astrakhan",
    value: "Europe/Astrakhan",
  },
  {
    label: "Europe/Volgograd",
    value: "Europe/Volgograd",
  },
  {
    label: "Europe/Saratov",
    value: "Europe/Saratov",
  },
  {
    label: "Europe/Ulyanovsk",
    value: "Europe/Ulyanovsk",
  },
  {
    label: "Europe/Samara",
    value: "Europe/Samara",
  },
  {
    label: "Asia/Yekaterinburg",
    value: "Asia/Yekaterinburg",
  },
  {
    label: "Asia/Omsk",
    value: "Asia/Omsk",
  },
  {
    label: "Asia/Novosibirsk",
    value: "Asia/Novosibirsk",
  },
  {
    label: "Asia/Barnaul",
    value: "Asia/Barnaul",
  },
  {
    label: "Asia/Tomsk",
    value: "Asia/Tomsk",
  },
  {
    label: "Asia/Novokuznetsk",
    value: "Asia/Novokuznetsk",
  },
  {
    label: "Asia/Krasnoyarsk",
    value: "Asia/Krasnoyarsk",
  },
  {
    label: "Asia/Irkutsk",
    value: "Asia/Irkutsk",
  },
  {
    label: "Asia/Chita",
    value: "Asia/Chita",
  },
  {
    label: "Asia/Yakutsk",
    value: "Asia/Yakutsk",
  },
  {
    label: "Asia/Khandyga",
    value: "Asia/Khandyga",
  },
  {
    label: "Asia/Vladivostok",
    value: "Asia/Vladivostok",
  },
  {
    label: "Asia/Ust-Nera",
    value: "Asia/Ust-Nera",
  },
  {
    label: "Asia/Magadan",
    value: "Asia/Magadan",
  },
  {
    label: "Asia/Sakhalin",
    value: "Asia/Sakhalin",
  },
  {
    label: "Asia/Srednekolymsk",
    value: "Asia/Srednekolymsk",
  },
  {
    label: "Asia/Kamchatka",
    value: "Asia/Kamchatka",
  },
  {
    label: "Asia/Anadyr",
    value: "Asia/Anadyr",
  },
  {
    label: "Asia/Riyadh",
    value: "Asia/Riyadh",
  },
  {
    label: "Pacific/Guadalcanal",
    value: "Pacific/Guadalcanal",
  },
  {
    label: "Indian/Mahe",
    value: "Indian/Mahe",
  },
  {
    label: "Africa/Khartoum",
    value: "Africa/Khartoum",
  },
  {
    label: "Europe/Stockholm",
    value: "Europe/Stockholm",
  },
  {
    label: "Asia/Singapore",
    value: "Asia/Singapore",
  },
  {
    label: "America/Paramaribo",
    value: "America/Paramaribo",
  },
  {
    label: "Africa/Juba",
    value: "Africa/Juba",
  },
  {
    label: "Africa/Sao_Tome",
    value: "Africa/Sao_Tome",
  },
  {
    label: "America/El_Salvador",
    value: "America/El_Salvador",
  },
  {
    label: "Asia/Damascus",
    value: "Asia/Damascus",
  },
  {
    label: "America/Grand_Turk",
    value: "America/Grand_Turk",
  },
  {
    label: "Africa/Ndjamena",
    value: "Africa/Ndjamena",
  },
  {
    label: "Indian/Kerguelen",
    value: "Indian/Kerguelen",
  },
  {
    label: "Asia/Bangkok",
    value: "Asia/Bangkok",
  },
  {
    label: "Asia/Dushanbe",
    value: "Asia/Dushanbe",
  },
  {
    label: "Pacific/Fakaofo",
    value: "Pacific/Fakaofo",
  },
  {
    label: "Asia/Dili",
    value: "Asia/Dili",
  },
  {
    label: "Asia/Ashgabat",
    value: "Asia/Ashgabat",
  },
  {
    label: "Africa/Tunis",
    value: "Africa/Tunis",
  },
  {
    label: "Pacific/Tongatapu",
    value: "Pacific/Tongatapu",
  },
  {
    label: "Europe/Istanbul",
    value: "Europe/Istanbul",
  },
  {
    label: "America/Port_of_Spain",
    value: "America/Port_of_Spain",
  },
  {
    label: "Pacific/Funafuti",
    value: "Pacific/Funafuti",
  },
  {
    label: "Asia/Taipei",
    value: "Asia/Taipei",
  },
  {
    label: "Europe/Kiev",
    value: "Europe/Kiev",
  },
  {
    label: "Europe/Uzhgorod",
    value: "Europe/Uzhgorod",
  },
  {
    label: "Europe/Zaporozhye",
    value: "Europe/Zaporozhye",
  },
  {
    label: "Pacific/Wake",
    value: "Pacific/Wake",
  },
  {
    label: "America/New_York",
    value: "America/New_York",
  },
  {
    label: "America/Detroit",
    value: "America/Detroit",
  },
  {
    label: "America/Kentucky/Louisville",
    value: "America/Kentucky/Louisville",
  },
  {
    label: "America/Kentucky/Monticello",
    value: "America/Kentucky/Monticello",
  },
  {
    label: "America/Indiana/Indianapolis",
    value: "America/Indiana/Indianapolis",
  },
  {
    label: "America/Indiana/Vincennes",
    value: "America/Indiana/Vincennes",
  },
  {
    label: "America/Indiana/Winamac",
    value: "America/Indiana/Winamac",
  },
  {
    label: "America/Indiana/Marengo",
    value: "America/Indiana/Marengo",
  },
  {
    label: "America/Indiana/Petersburg",
    value: "America/Indiana/Petersburg",
  },
  {
    label: "America/Indiana/Vevay",
    value: "America/Indiana/Vevay",
  },
  {
    label: "America/Chicago",
    value: "America/Chicago",
  },
  {
    label: "America/Indiana/Tell_City",
    value: "America/Indiana/Tell_City",
  },
  {
    label: "America/Indiana/Knox",
    value: "America/Indiana/Knox",
  },
  {
    label: "America/Menominee",
    value: "America/Menominee",
  },
  {
    label: "America/North_Dakota/Center",
    value: "America/North_Dakota/Center",
  },
  {
    label: "America/North_Dakota/New_Salem",
    value: "America/North_Dakota/New_Salem",
  },
  {
    label: "America/North_Dakota/Beulah",
    value: "America/North_Dakota/Beulah",
  },
  {
    label: "America/Denver",
    value: "America/Denver",
  },
  {
    label: "America/Boise",
    value: "America/Boise",
  },
  {
    label: "America/Phoenix",
    value: "America/Phoenix",
  },
  {
    label: "America/Los_Angeles",
    value: "America/Los_Angeles",
  },
  {
    label: "America/Anchorage",
    value: "America/Anchorage",
  },
  {
    label: "America/Juneau",
    value: "America/Juneau",
  },
  {
    label: "America/Sitka",
    value: "America/Sitka",
  },
  {
    label: "America/Metlakatla",
    value: "America/Metlakatla",
  },
  {
    label: "America/Yakutat",
    value: "America/Yakutat",
  },
  {
    label: "America/Nome",
    value: "America/Nome",
  },
  {
    label: "America/Adak",
    value: "America/Adak",
  },
  {
    label: "Pacific/Honolulu",
    value: "Pacific/Honolulu",
  },
  {
    label: "America/Montevideo",
    value: "America/Montevideo",
  },
  {
    label: "Asia/Samarkand",
    value: "Asia/Samarkand",
  },
  {
    label: "Asia/Tashkent",
    value: "Asia/Tashkent",
  },
  {
    label: "America/Caracas",
    value: "America/Caracas",
  },
  {
    label: "Asia/Ho_Chi_Minh",
    value: "Asia/Ho_Chi_Minh",
  },
  {
    label: "Pacific/Efate",
    value: "Pacific/Efate",
  },
  {
    label: "Pacific/Wallis",
    value: "Pacific/Wallis",
  },
  {
    label: "Pacific/Apia",
    value: "Pacific/Apia",
  },
  {
    label: "Africa/Johannesburg",
    value: "Africa/Johannesburg",
  },
];
export default data;
