import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Logo } from "./Svg";

import Google from "./images/google.svg";
import Apple from "./images/apple.svg";
import Banner from "./images/banner.svg";

import App1 from "./images/app1.svg";
import App2 from "./images/app2.svg";
import App3 from "./images/app3.svg";
import App4 from "./images/app4.svg";
import Faq from "./FAQ";

import useIsMobile from "../../Hooks/useMobile";

import Ratings from "react-ratings-declarative";

import { Link } from "react-router-dom";

import "./style.css";

const data = [
  {
    name: "Teaches you a great level of relaxation and powerful tool.",
    desc: "Rishi",
  },
  {
    name: "Best meditation app to lead into a very sound sleep. I highly recommend it. Much easier to stay focused with this meditation than many others.",
    desc: "Lauren",
  },
  {
    name: "Practice grows more you use this app. Yoga Nidra will change your life and improve your health from inside out.",
    desc: "Ann",
  },
  {
    name: "I like listening to Yoga Nidra before sleep. It helps me relax. I have been enjoying the app for a while",
    desc: "Shweta",
  },
];

const appData = [App1, App2, App3, App4];

const YogaNidra = () => {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (window.location.href.includes("?"))
      window.location.href = "https://do.yoga/yoganidra";
  }, []);

  const articleStructuredData = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: "Yoga Nidra",
    image: "https://do.yoga/static/media/app1.aee27910.svg",
    description:
      "Yoga Nidra is a simple and powerful meditation practice. All you have to do is lie down and listen to the instructions. During Yoga Nidra, you relax completely. Relaxation is at a physical, mental, and emotional level. The practice leaves you feeling recharged.",
    brand: {
      "@type": "Brand",
      name: "Do Yoga",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "5",
      bestRating: "5",
      worstRating: "2",
      ratingCount: "1",
      reviewCount: "1",
    },
    review: {
      "@type": "Review",
      name: "Rishi",
      reviewBody: "Teaches you a great level of relaxation and powerful tool.",
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "2",
      },
      datePublished: "2023-03-16",
      author: { "@type": "Person", name: "Rishi" },
      publisher: { "@type": "Organization", name: "Google Play" },
    },
  };

  return (
    <div className="h-full max-w-screen-xl mx-auto home-main-cont yoga">
      <Helmet>
        <title>Yoga Nidra - Relax and Meditate</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Yoga Nidra - Relax and Meditate"></meta>
        <meta
          name="description"
          content="Yoga Nidra is a simple and powerful meditation practice that helps you relax completely, get recharged and explore your inner Self."
        />
        <meta
          property="og:title"
          content="Yoga Nidra - Relax and Meditate"
        ></meta>
        <meta
          property="twitter:title"
          content="Yoga Nidra - Relax and Meditate"
        ></meta>
        <meta
          property="og:description"
          content="Yoga Nidra is a simple and powerful meditation practice that helps you relax completely, get recharged and explore your inner Self."
        ></meta>
        <meta
          property="twitter:description"
          content="Yoga Nidra is a simple and powerful meditation practice that helps you relax completely, get recharged and explore your inner Self."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>

        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      </Helmet>
      <div className="flex justify-center items-center text-center mb-5">
        <div style={{}}>
          <div className="flex flex-col items-center justify-center text-center">
            <Logo />
          </div>
          <h1 className="text-4xl">Yoga Nidra</h1>
          <div className="text-xl pt-3">Relax & Meditate</div>
        </div>
      </div>
      <h2 className="text-xl font-semibold mt-5 pt-5">What is Yoga Nidra?</h2>
      <div className="text-lg pt-5">
        Yoga Nidra is a simple and powerful meditation practice. All you have to
        do is lie down and listen to the instructions. During Yoga Nidra, you
        relax completely. Relaxation is at a physical, mental, and emotional
        level. The practice leaves you feeling recharged.{" "}
      </div>
      <div className="text-lg pt-5">
        And, as you practice Yoga Nidra regularly, you will start exploring your
        inner Self - your habits and tendencies, with tools to work on yourself
        to unfold your full potential.
      </div>
      <h2 className="text-xl font-semibold pt-4">Download Yoga Nidra App</h2>
      <div className="text-lg pt-5">
        We have launched a Yoga Nidra app, you are taken through a guided
        journey with the script of the practice evolving as you do it regularly.
      </div>
      <div
        className="flex justify-center items-center mt-5 mb-5"
        style={{ marginTop: 40 }}
      >
        <div className="mr-5">
          <a href="https://play.google.com/store/apps/details?id=com.yoganidra">
            <img src={Google} alt="google" />
          </a>
        </div>
        <div className="ml-5">
          <a href="https://apps.apple.com/us/app/yoga-nidra-relax-meditate/id6450855935">
            <img src={Apple} alt="google" />
          </a>
        </div>
      </div>
      <div className="text-xl font-semibold " style={{ marginTop: 50 }}>
        User Reviews
      </div>
      <div className="flex flex-col md:flex-row justify-between items-stretch mt-5 pt-5">
        {data &&
          data.map((ele, i) => (
            <div
              className={`review-wid flex flex-col justify-between`}
              style={{
                marginLeft: i == 0 || isMobile ? 0 : 40,
                marginBottom: isMobile ? 40 : 0,
              }}
            >
              <div className="review-desc text-lg">{ele.name}</div>
              <div className="pt-4">
                <div>
                  <Ratings
                    rating={5}
                    widgetDimensions="20px"
                    widgetSpacings="3px"
                    widgetRatedColor="green"
                  >
                    <Ratings.Widget widgetRatedColor="#FFE600" />
                    <Ratings.Widget widgetRatedColor="#FFE600" />
                    <Ratings.Widget widgetRatedColor="#FFE600" />
                    <Ratings.Widget widgetRatedColor="#FFE600" />
                    <Ratings.Widget widgetRatedColor="#FFE600" />
                  </Ratings>
                </div>
                <div className="review-name text-lg">{ele.desc}</div>
              </div>
            </div>
          ))}
      </div>
      <h2 className="text-xl font-semibold mt-5 pt-5">How to do Yoga Nidra?</h2>
      <div className="text-lg pt-5">
        Yoga Nidra is best done lying down in Shavasana. Lying down on the floor
        with a yoga mat or even on your bed with the legs stretched out and feet
        flopped to the sides, with the hands few inches away from the body with
        your palms facing up. Keep your eyes gently closed and try to be still
        throughout the practice.
      </div>
      <div className="text-lg pt-5">
        You can also do Yoga Nidra seated or even standing straight. It is
        important to be still, so a comfortable posture is needed.
      </div>
      <div className="flex justify-center mt-5 mb-5 pt-5 pb-6">
        <img src={Banner} alt="google" />
      </div>
      <h2 className="text-xl font-semibold pt-4">When can I do Yoga Nidra?</h2>
      <div className="text-lg pt-5">
        You can do Yoga Nidra whenever you feel like relaxing throughout the
        day. As a meditation practice, it is best to do Yoga Nidra every day at
        a fixed time. It can be done in the morning after yoga or any physical
        activity. Afternoon or evening after work is also suitable where Yoga
        Nidra becomes a recharge tool. Yoga Nidra also helps one get to sleep.
      </div>
      <h2 className="text-xl font-semibold pt-5">
        What are the benefits of Yoga Nidra?
      </h2>
      <div className="text-lg pt-5">
        Yoga Nidra has been used for deep relaxation, in stress management and
        therapy, to enhance the learning process in education, to harmonize the
        deeper unconscious and awaken inner potential, and as a meditative
        technique.
      </div>
      <h2 className="text-xl font-semibold pt-5">
        What are the different components of Yoga Nidra?
      </h2>
      <div className="text-lg pt-5">
        A typical Yoga Nidra practice begins with orienting yourself to your
        environment and current state of body and mind. We then proceed to a
        body scan where we take our awareness through the different body parts
        and relax each one of them. We then focus our awareness on the breathing
        for sometime. This is followed by visualization and Sankalpa, your
        resolve. You are then gently brought back through the breath, body and
        space to the present.
      </div>
      <div className="youtubeFrame my-5">
        <iframe src="https://www.youtube.com/embed/EgDKalc2Ihk?autoplay=1&mute=1" />
      </div>
      <h2 className="text-xl font-semibold pt-5">Origins of Yoga Nidra</h2>
      <div className="text-lg pt-5">
        Yoga Nidra as a practice has its origin in the Tantra, and was codified
        by Swami Satyananda Saraswati, disciple of Swami Sivananda and Founder
        of Bihar School of Yoga. This app is made from his teachings.
      </div>
      <h3 className="text-xl font-semibold pt-5">Reference Book</h3>
      <div className="text-lg pt-5">
        You can learn more about Yoga Nidra and its theory by reading the book
        on Yoga Nidra written by Swami Satyananda Saraswati.
      </div>
      <div className="text-xl font-semibold pt-5">
        Buy Yoga Nidra book on Amazon:
      </div>
      <div>
        <a href="https://www.amazon.in/Yoga-Nidra-Swami-Satyananda-Saraswati/dp/8185787123">
          https://www.amazon.in/Yoga-Nidra-Swami-Satyananda-Saraswati/dp/8185787123
        </a>
      </div>
      <div className="text-xl font-semibold pt-5">
        Download Yoga Nidra App on Google Play:
      </div>
      <div>
        <a href="https://play.google.com/store/apps/details?id=com.yoganidra">
          https://play.google.com/store/apps/details?id=com.yoganidra
        </a>
      </div>
      <div className="text-xl font-semibold pt-5">
        Download App on iOS App Store:
      </div>
      <div>
        <a href="https://apps.apple.com/us/app/yoga-nidra-relax-meditate/id6450855935">
          https://apps.apple.com/us/app/yoga-nidra-relax-meditate/id6450855935
        </a>
      </div>
      <div className="text-xl font-semibold pt-5">App Screenshots</div>
      <div className="flex flex-col md:flex-row justify-between items-stretch mt-5 pt-5 mb-5">
        {appData &&
          appData.map((ele, i) => (
            <div
              style={{
                marginLeft: i == 0 || isMobile ? 0 : 40,
                marginBottom: isMobile ? 40 : 0,
              }}
            >
              <img src={ele} alt="app" style={{ objectFit: "cover" }} />
            </div>
          ))}
      </div>
      <div className="pt-5">
        <Link to="/yoganidra/terms-conditions">
          Terms & Conditions of Yoga Nidra App
        </Link>
      </div>
      <div className="pt-5">
        <Link to="/yoganidra/privacy-policy">
          Privacy Policy of Yoga Nidra App
        </Link>
      </div>

      <h2 className="text-xl font-semibold mt-5 pt-5 pb-5">
        FAQs - Frequently Asked Questions
      </h2>
      <Faq />

      <div className="flex justify-center items-center text-center mb-5">
        <div style={{}}>
          <div className="pt-5 mt-5 ">Hari Om Tat Sat 🙏</div>
          <div className="flex flex-col items-center justify-center text-center">
            <Logo width={69} height={89} />
          </div>
        </div>
      </div>

      <div className="pb-5 mb-5"></div>
    </div>
  );
};

export default YogaNidra;
