import React from "react";

export default function useImageUpload(onImage, video = false) {
  const ref = React.useRef();

  function onChange(e) {
    const target = e.target;

    if (!(target.files && target.files[0])) {
      return;
    }

    const fileObject = target.files[0];

    const reader = new FileReader();
    reader.onload = function (e) {
      onImage(e.target.result, fileObject);
    };

    reader.readAsDataURL(target.files[0]);
  }

  function showImageUpload() {
    if (ref.current) {
      ref.current.click();
    }
  }

  const component = (
    <input
      className="hidden"
      type="file"
      accept={video ? "video/mp4" : "image/png,image/jpeg,image/jpg"}
      onChange={onChange}
      ref={ref}
    />
  );

  return { component, showImageUpload, imageRef: ref };
}
