import React, { useState } from "react";
import { StorefrontApi } from "../../Api/StorefrontApi";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import UrlService from "../../Services/UrlService";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import Vent from "../../vent";
import { Link } from "react-router-dom";
import Button from "../../Atoms/Button/Button";

export default function SuccessCallback({ setBooking }) {
  const { callApi, errorMessage } = useApi(StorefrontApi.bookingDetails);
  const { callApi: SubscriptionBookDetails, errorMessage: subError } = useApi(
    SubscriptionApi.bookingDetails
  );
  const [localBooking, setLocalBooking] = React.useState(null);
  const [error, setError] = useState("");

  const fetchData = React.useCallback(() => {
    if (UrlService.getParam("type") === "subscription") {
      SubscriptionBookDetails(UrlService.getParam("booking")).then(
        (response) => {
          const booking = response.data.data;

          setLocalBooking(booking);

          if (booking.payment_status === "success") {
            Vent.onBooking(
              Vent.getTrackTypeFromBooking(booking),
              booking.currency_fee
            );

            setBooking(booking);
          } else {
            if (booking.payment_status === "failed") {
              setError(booking.payment_status);
            }
          }
        }
      );
    } else {
      callApi(UrlService.getParam("booking")).then((response) => {
        const booking = response.data.data;

        setLocalBooking(booking);

        if (
          booking.payment_status === "success" ||
          booking.payment_status === "pay-what-you-want"
        ) {
          Vent.onBooking(
            Vent.getTrackTypeFromBooking(booking),
            booking.currency_fee.fee_inr
          );

          setBooking(booking);
        } else {
          if (booking.payment_status === "failed") {
            setError(booking.payment_status_display);
          }
        }
      });
    }
  }, [setLocalBooking, setBooking, callApi]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  React.useEffect(() => {
    let intervalId = null;

    if (localBooking && localBooking.payment_status !== "success") {
      intervalId = setInterval(() => {
        fetchData();
      }, 2000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [localBooking, fetchData]);

  if (errorMessage || subError) {
    return (
      <div className="p-5">
        <ErrorMessage>{errorMessage || subError}</ErrorMessage>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-5 text-center">
        <div>
          <ErrorMessage>Payment {error}</ErrorMessage>
        </div>
        <div>
          <Link to="/">
            <Button className="mt-12">Go To Home</Button>
          </Link>
        </div>
      </div>
    );
  }

  if (!localBooking) {
    return <div className="p-5">Fetching your booking details...</div>;
  }

  if (localBooking.payment_status !== "success") {
    return (
      <div className="p-5">Please wait while we confirm your payment...</div>
    );
  }

  return (
    <div className="p-5">
      {localBooking.payment_status === "success" ? (
        <span>Thank you</span>
      ) : (
        <span>
          We could not confirm your booking. It is in state{" "}
          {localBooking.payment_status_display}
        </span>
      )}
    </div>
  );
}
