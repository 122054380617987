import _ from "lodash";
import dayjs from "dayjs";

const CourseService = {
  /**
   * Get start time for a course
   * @param course
   * @return {string|null}
   */
  startTimeText(course) {
    if (!course.utc_start_time) {
      return null;
    }

    return dayjs
      .utc("1970-01-01 " + course.utc_start_time)
      .local()
      .format("h:mm a");
  },

  /**
   * Get start time for a course
   * @param course
   * @return {string|null}
   */
  startTimeTextWithTimeZone(course, timeZone) {
    //console.log("course", course);
    const startTime = course.start_time;

    if (!startTime) {
      return null;
    }

    // let date = new Date(
    //   Date.UTC(
    //     2012,
    //     11,
    //     20,
    //     startTime.slice(0, 2),
    //     startTime.slice(3, 5),
    //     startTime.slice(6)
    //   )
    // );

    // console.log("Given IST datetime: " + date);

    // let intlDateObj = new Intl.DateTimeFormat("en-US", {
    //   timeZone: "America/New_York",
    // });

    // let usaTime = intlDateObj.format(date);
    // console.log("USA date: " + usaTime);

    //console.log(new Date());
    let date = `Wed Dec 07 2022 ${startTime} GMT+0530 (India Standard Time)`;
    let newDate = new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone: timeZone,
      })
    );

    // console.log("d", date);
    // console.log("con", newDate);
    // console.log("format", dayjs(newDate).format("h:mm a"));

    const currentDate = dayjs().tz(timeZone);
    const currentDateFormat = currentDate.format("YYYY-MM-DD");

    // return dayjs(currentDateFormat + " " + startTime)
    //   .tz(timeZone)
    //   .format("h:mm a");
    return dayjs(newDate).format("h:mm a");
  },

  /**
   * Get start end date text
   * Eg: From Jan 6 to Jan 10
   *
   * @param course
   * @return {string|string}
   */
  startEndDateText(course) {
    let out = "";
    if (course.utc_start_at) {
      out += "From " + dayjs.unix(course.utc_start_at).format("MMM D");
    }

    if (course.utc_end_at) {
      out += " to " + dayjs.unix(course.utc_end_at).format("MMM D");
    }

    return out ? out : null;
  },

  /**
   * Get start end date text
   * Eg: From Jan 6 to Jan 10
   *
   * @param course
   * @return {string|string}
   */
  startEndDateTextWithTimeZone(course, timeZone) {
    const startDate = course.start_date;
    const startTime = course.start_time;
    const endDate = course.end_date;

    let out = "";
    if (startDate) {
      out +=
        "From " +
        dayjs(startDate + " " + startTime)
          .tz(timeZone)
          .format("MMM D");
    }

    if (endDate) {
      out +=
        " to " +
        dayjs(endDate + " " + startTime)
          .tz(timeZone)
          .format("MMM D");
    }

    return out ? out : null;
  },

  /**
   * Get repeat schedule text
   * Eg: 3 days a week - M, T, W
   *
   * @param course
   * @param includeDays {boolean} should the day string M, T, W be included
   * @return {string|string}
   */
  repeatScheduleText(course, includeDays = true) {
    if (course.repeat_type === "everyday") {
      return "Everyday";
    }

    if (course.repeat_type === "custom" && course.repeat_value) {
      const parts = course.repeat_value.split(",");
      const count = parts.length;

      if (count === 7) {
        return "Everyday";
      }

      let out = `${count} day${count > 1 ? "s" : ""} a week`;

      if (!includeDays) {
        return out;
      }

      const joined = parts.map((p) => p.charAt(0).toUpperCase()).join(", ");
      return out + ` - ${joined}`;
    }

    return null;
  },

  /**
   * Repeat days full text (Eg: Sunday, Monday)
   *
   * @param course
   * @return {null|string}
   */
  repeatDaysFullText(course) {
    if (course.repeat_type === "everyday") {
      return "Everyday";
    }

    if (course.repeat_type !== "custom" || !course.repeat_value) {
      return null;
    }

    const days = course.repeat_value.split(",").map((p) => _.startCase(p));
    if (days.length === 7) {
      return "Everyday";
    }

    return days.join(", ");
  },
};

export default CourseService;
