import React from "react";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import SubscriptionCard from "../../Atoms/SubscriptionCard";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import CardGroup from "./CardGroup";
import cx from "classnames";
import OneOnOneGraphic from "../../Graphics/OneOnOneGraphic";
import useIsMobile from "../../Hooks/useMobile";
import { Helmet } from "react-helmet";

export default function OneOnOneUpdated(props) {
  const { callApi, data } = useApi(SubscriptionApi.list);
  const isMobile = useIsMobile();

  React.useEffect(() => {
    callApi();
  }, [callApi]);

  const ones = data?.data || [];

  const headerTexts = [
    "Connect deeply & get personal coaching with 1:1 yoga classes.",
    "Find a teacher who you can connect to.",
  ];

  return (
    <>
      <Helmet>
        <title>Subscription Courses</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Subscription Courses"></meta>
        {headerTexts &&
          headerTexts.map((t) => (
            <>
              <meta name="description" content={t} />
              <meta property="og:description" content={t}></meta>
              <meta property="twitter:description" content={t}></meta>
            </>
          ))}
        <meta property="og:title" content="Subscription Courses"></meta>
        <meta property="twitter:title" content="Subscription Courses"></meta>

        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="px-0 sm:px-2 flex flex-col w-full">
        <div
          className={cx(
            "flex flex-row justify-between items-center overflow-x-auto",
            "h-40 sm:h-full sm:w-4/5"
          )}
        >
          <div className="text-sm sm:text-lg text-gray-600 font-semibold pr-2 pt-0 sm:pt-5 sm:w-2/4">
            {headerTexts.map((t) => (
              <p className="mt-2" key={t}>
                {t}
              </p>
            ))}
          </div>
          <div>
            <OneOnOneGraphic width={isMobile ? 170 : 300} height={130} />
          </div>
        </div>
      </div>
      <div className="flex-1">
        <div className="px-0 mt-0 flex flex-col w-full">
          <div
            className={cx(
              "inline-block cursor-pointer text-lg",
              "font-semibold",
              "text-y-yellow-dark pl-0 md:pl-1 mt-5"
            )}
          >
            Subscription Courses
          </div>
        </div>
      </div>
      <div className="mt-4">
        <CardGroup
          cards={ones.map((c) => ({
            key: c.id,
            Component: (
              <SubscriptionCard
                link={`/subscriptions/${c.id}`}
                one={c}
                //onDetails={() => onDetails(c)}
              />
            ),
          }))}
        />
      </div>
    </>
  );
}
