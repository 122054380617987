import React, { useEffect, useState } from "react";
import { UserApi } from "../../Api/UserApi";
import ErrorMessage from "../../Atoms/ErrorMessage";

import { useApi } from "../../Utilities/Hooks/ApiHook";

import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

import { useHistory } from "react-router-dom";
import Button from "../../Atoms/Button/Button";
import ReactGA from "react-ga";

const CompleteStage = () => {
  const history = useHistory();
  const { callApi, loading, data, errorMessage, setSuccessResponse } = useApi(
    UserApi.teacherProfile
  );

  const dataLinks = [
    { text: "Profile", link: "/studio/profile" },
    { text: "Courses", link: "/studio/setup-class" },
    { text: "Students", link: "/studio/bookings" },
  ];

  React.useEffect(() => {
    callApi();
    pageTracking();
    ReactPixel.pageView();
  }, [callApi]);

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (loading) {
    return <div>Loading Teacher Profile..</div>;
  }

  return (
    <div>
      <div className="pt-10 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
        Your first courses has been set up. Your bookings will show up here.
      </div>
      <div className="pt-8 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
        Check out your yoga website at
      </div>
      <div
        className="pt-4 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0"
        onClick={() => {
          window.open(`https://${data?.data?.domain}.do.yoga/`);
          ReactGA.event({
            category: "1:1 Details",
            action: "Routing",
            label: JSON.stringify(data?.data?.domain),
          });
          ReactPixel.track("track", {
            category: "1:1 Details",
            action: "Routing",
            label: JSON.stringify(data?.data?.domain),
          });
        }}
        role="button"
      >
        https://{data?.data?.domain}.do.yoga
      </div>
      <div className="flex pt-6 pb-10 mb-0">
        <Button
          py="py-3"
          className="whitespace-nowrap"
          onClick={() => {
            window.open(`https://${data?.data?.domain}.do.yoga/`);
            ReactGA.event({
              category: "1:1 Details",
              action: "Routing",
              label: JSON.stringify(data?.data?.domain),
            });
            ReactPixel.track("track", {
              category: "1:1 Details",
              action: "Routing",
              label: JSON.stringify(data?.data?.domain),
            });
          }}
        >
          Go to website
        </Button>
      </div>
      <div className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
        Tell the world to do yoga with you
      </div>
      <div className="flex pt-6 pb-10 mb-0">
        <Button py="py-3" className="whitespace-nowrap" onClick={() => {}}>
          Share
        </Button>
      </div>
      <div className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
        You can set up more courses, make changes to your profile and manage
        everything through
      </div>
      <div className="pt-4">
        <svg
          //onClick={onMobileMenu}
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#473F21"
            strokeWidth="1.5"
            strokeLinecap="round"
            d="M6.75 7.25h10.5M6.75 11.75h7.5M6.75 16.25h4.5"
          />
        </svg>
      </div>
      <div className="pt-5 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
        This icon which is on the top right or the floater below.
      </div>
      <div className="pt-5 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0">
        Manage your online yoga classes
      </div>
      <div className="flex pt-6 pb-10 mb-10">
        {dataLinks &&
          dataLinks.map((ele, i) => (
            <Button
              py="py-3"
              className={`${i > 0 ? "ml-5" : ""}`}
              onClick={() => {
                history.push(ele.link);
              }}
            >
              {ele.text}
            </Button>
          ))}
      </div>
    </div>
  );
};

export default CompleteStage;
