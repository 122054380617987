import React from "react";

export default function CardGroup({ cards }) {
  return (
    <div className="mx-0 mt-3 flex flex-col items-center sm:flex-row sm:flex-wrap space-y-2 sm:space-y-0">
      {cards.map((card) => (
        <div className="sm:pr-5 sm:pb-5 w-full max-w-sm" key={card.key}>
          {card.Component}
        </div>
      ))}
    </div>
  );
}
