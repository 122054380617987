import React from 'react';
import NumberButton from "../../Atoms/NumberButton";
import useIsMobile from "../../Hooks/useMobile";
import { useApi } from "../../Utilities/Hooks/ApiHook";

export default function StepsCard({ steps, onFinish, initialData = {} }) {
  const isMobile = useIsMobile();
  const [index, setIndex] = React.useState(0);
  const [formData, setFormData] = React.useState(initialData);
  const { callApi, loading, formErrors } = useApi(onFinish);

  const { component: SelectedComponent, props: componentProps = {} } = steps[index];

  function onFinishHandler(data) {
    if (!onFinish) {
      return;
    }

    callApi(data)
      .catch(e => {
        if (e.data.code !== 'VALIDATION_ERROR') {
          return;
        }

        // if a validation error, we'll focus on first step with an error
        const invalidFields = Object.keys(e.data.data);
        steps.some((s, i) => {
          const hasInvalid = (s.formFields || []).some(field => invalidFields.includes(field));
          if (hasInvalid) {
            setIndex(i);
          }

          return hasInvalid;
        })
      });
  }

  function onNext(data) {
    const newFormData = { ...formData, [index]: data };
    setFormData(newFormData);

    if (index === steps.length - 1) {
      onFinishHandler(newFormData);
    } else {
      setIndex(index + 1);
    }
  }

  function onPrevious(data) {
    setFormData({ ...formData, [index]: data });
    setIndex(i => i > 0 ? i - 1 : i);
  }

  function onIndex(i) {
    // user can only click a step to go back not in front
    setIndex(current => i < current ? i : current);
  }

  return (
    <div className="px-7 sm:px-10 py-8 bg-white shadow-card-cream sm:rounded-md">
      <div className="flex flex-row flex-nowrap overflow-x-auto">
        {steps.map((step, i) => (
          <React.Fragment key={step.title}>
            <div className="flex-1 whitespace-nowrap pb-3">
              <NumberButton
                number={i + 1}
                finished={i < index}
                selected={index === i}
                onClick={() => onIndex(i)}
              >
                {isMobile ? (index === i ? step.title : '') : step.title}
              </NumberButton>
            </div>
            {i < (steps.length - 1) && (
              <div
                className="flex-1 border-t border-gray-300 mt-3 mr-3 ml-1"
                key={`${step.title}-sep`}
              />
            )}
          </React.Fragment>
        ))}
      </div>

      <div className="pt-3">
        {SelectedComponent && (
          <SelectedComponent
            onNext={onNext}
            onPrevious={onPrevious}
            data={formData[index] || {}}
            loading={loading}
            formErrors={formErrors}
            {...componentProps}
          />
        )}
      </div>
    </div>
  );
}
