import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../../Atoms/Button/Button";
import Form from "../../../Atoms/Form";
import FormError from "../../../Atoms/FormError";
import FormText from "../../../Atoms/FormText";
import Input from "../../../Atoms/Input/Input";

export default function SubscriptionForm({
  data,
  onNext,
  loading,
  formErrors,
}) {
  const { register, handleSubmit } = useForm({
    defaultValues: data,
  });

  function _onNext(values) {
    const numericFields = ["fee_india", "fee_intl", "classes_count"];

    // cast invalid numbers to null
    Object.keys(values).forEach((k) => {
      if (numericFields.includes(k) && !values[k]) {
        values[k] = null;
      }
    });

    if (values.expiry_date === "") {
      values = { ...values, expiry_date: "2030-12-30" };
    }

    onNext(values);
  }

  return (
    <div className="mt-8">
      <Form onSubmit={handleSubmit(_onNext)} errors={formErrors}>
        <FormText className="my-5">Fees</FormText>
        <div>
          <Input
            name="title"
            label="Title"
            type="text"
            inputWidth="w-40 sm:w-48"
            inputRef={register({ required: true })}
          />
        </div>
        <div>
          <Input
            name="classes_count"
            label="Class Count"
            type="number"
            inputWidth="w-40 sm:w-48"
            inputRef={register({ required: true })}
          />
        </div>
        <div className="pb-3">Price</div>
        <div className="flex flex-row justify-between">
          <Input
            name="fee_india"
            label="For India"
            inputWidth="w-40 sm:w-48"
            prefix="Rs."
            required
            inputRef={register({ required: true })}
          />
          <Input
            name="fee_intl"
            label="For International"
            inputWidth="w-40 sm:w-48"
            prefix="$"
            required
            inputRef={register({ required: true })}
          />
        </div>

        <div className="flex flex-row justify-between">
          <Input
            name="expiry_date"
            label="Expiry Date"
            type="date"
            inputWidth="w-40 sm:w-48"
            inputRef={register({ required: false })}
          />
          <div></div>
        </div>

        <FormError className="my-5" error={formErrors} alignRight />

        <div className="mt-2 flex flex-row justify-end">
          <Button loading={loading} type="submit">
            Done
          </Button>
        </div>
      </Form>
    </div>
  );
}
