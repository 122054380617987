const initialState = {
  showOtpLoginModal: false,
  otpLoginNext: null,
  showProfileModal: false,
  profileNext: null
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case 'ui/showOtpLogin':
      return {
        ...state,
        otpLoginNext: action.payload.otpLoginNext,
        showOtpLoginModal: true,
        showProfileModal: false,
        profileNext: null
      }

    case 'ui/hideOtpLogin':
      return {
        ...state,
        showOtpLoginModal: false,
        otpLoginNext: null,
        showProfileModal: false,
        profileNext: null
      }

    case 'ui/profile':
      return {
        ...state,
        showProfileModal: true,
        showOtpLoginModal: false,
        profileNext: action.payload.profileNext,
        otpLoginNext: null
      }
    
    case 'ui/hideProfile':
      return {
        ...state,
        showProfileModal: false,
        showOtpLoginModal: false,
        profileNext: null,
        otpLoginNext: null
      }

    default:
      return state;
  }
}
