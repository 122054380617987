import React, { useEffect, useState } from "react";
//import { Editor } from "react-draft-wysiwyg";
//import { EditorState, convertToRaw } from "draft-js";
//import draftToHtml from "draftjs-to-html";
//import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useApi } from "../../Utilities/Hooks/ApiHook";
import { MediaApi } from "../../Api/MediaApi";
import { BlogApi } from "../../Api/BlogApi";

import Button from "../../Atoms/Button/Button";
import useImageUpload from "../../Hooks/useImageUpload";
import CustomInput from "../TeacherProfile/Stages/CustomInput";
import "./blog.css";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

import ImageUploader from "react-images-upload";
import { Editor } from "@tinymce/tinymce-react";

export default function BlogPage() {
  const [data, setData] = useState({ image: "", title: "" });
  const {
    callApi: callUpload,
    loading: loader,
    errorMessage: error,
  } = useApi(MediaApi.uploadMedia);

  const history = useHistory();

  const { callApi, loading, errorMessage } = useApi(BlogApi.createBlog);

  const { component, showImageUpload, imageRef } = useImageUpload(onImage);

  function onImage(image) {
    const imageElement = imageRef.current;
    callUpload("course-image", imageElement.files[0]).then((res) => {
      //console.log(res);
      setData({ ...data, image: res.data.data.file });
    });
  }

  const onEditorChange = (newValue, editor) => {
    setEditorState(newValue);
  };

  function onUploadClick() {
    showImageUpload();
  }

  const [editorState, setEditorState] = useState(() => "");

  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      callUpload("course-image", file).then(({ data }) => {
        resolve({ data: { link: data.data.file } });
      });
    });
  };

  const handleSubmit = () => {
    const formatData = {
      ...data,
      description: editorState,
    };

    callApi(formatData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data Saved Successfully",
        });
        history.push("/blog/articles");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  };

  return (
    <div className="bg-white p-10 rounded">
      <div>
        <div className="pb-10">
          {data.image ? (
            <Picture image={data.image} onUploadClick={onUploadClick} />
          ) : (
            <EmptyPicture onUploadClick={onUploadClick} />
          )}
          {data.image && (
            <div className="flex flex-row justify-end">
              <Button onClick={onUploadClick}>Change</Button>
            </div>
          )}
        </div>
        <div>
          <CustomInput
            name="title"
            label="Title"
            value={data.title}
            onChange={(e) => {
              setData({ ...data, title: e.target.value });
            }}
          />
        </div>
        <div
          style={{
            border: "1px solid grey",
            padding: "2px",
          }}
          className="rounded"
        >
          {/* <Editor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            toolbarClassName="rdw-storybook-toolbar"
            wrapperClassName="rdw-storybook-wrapper"
            editorClassName="editorClass"
            toolbar={{
              image: {
                uploadCallback: uploadImageCallBack,
                previewImage: true,
                alt: { present: true, mandatory: false },
              },
            }}
          /> */}
          <Editor
            apiKey="vuj6sbpou1d8f0gulcfrrq2t4yk8yfx1hhc7tyuu7ntrlbo6"
            value={editorState}
            init={{
              branding: false,
              height: 400,
              menubar: true,
              plugins:
                "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern contextmenu",
              toolbar:
                "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
              contextmenu:
                "paste | link image inserttable | cell row column deletetable",
              image_advtab: false,
              paste_as_text: true,
              paste_text_sticky: true,
              paste_text_sticky_default: true,
              convert_urls: false,
              paste_data_images: true,

              file_picker_callback: function (callback, value, meta) {
                var input = document.createElement("input");
                input.setAttribute("type", "file");

                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.readAsDataURL(file);

                  callUpload("course-image", file)
                    .then((result) => {
                      if (meta.filetype == "file") {
                        callback(result.data.data.file, { text: "My text" });
                      }

                      if (meta.filetype == "image") {
                        callback(result.data.data.file, { alt: "My alt text" });
                      }

                      if (meta.filetype == "media") {
                        callback(result.data.data.file, {
                          source2: "alt.ogg",
                          poster: "image.jpg",
                        });
                      }
                    })
                    .catch((err) => {
                      //reject('Some error occured')
                    });

                  //const res = setUploadFun(file);
                };
                input.click();
              },
            }}
            onEditorChange={onEditorChange}
          />
        </div>
      </div>
      <div className="flex flex-row justify-end mt-5">
        <Button onClick={handleSubmit}>Submit</Button>
      </div>
      {component}
    </div>
  );
}

function Picture({ image }) {
  return (
    <div className="flex flex-col p-0 ">
      <img src={image} alt="photo" width={500} height={500} />
    </div>
  );
}

function EmptyPicture({ onUploadClick }) {
  return (
    <div
      className="flex flex-row items-center p-0 px-0 "
      onClick={onUploadClick}
      role={"button"}
    >
      <div className="p-5 w-20 h-20 bg-white rounded-full shadow-card-cream-sm flex justify-center items-center cursor-pointer">
        <svg
          width="60"
          height="60"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1702_11433)">
            <path
              d="M26.4128 7.17294H23.2824L21.5541 4.56424C20.9019 3.58598 19.7606 2.99902 18.5867 2.99902H11.4128C10.2389 2.99902 9.09758 3.58598 8.44541 4.56424L6.71715 7.17294H3.58671C1.59758 7.17294 -0.000244141 8.77076 -0.000244141 10.7599V23.4121C-0.000244141 25.4012 1.59758 26.999 3.58671 26.999H26.4128C28.4019 26.999 29.9998 25.4012 29.9998 23.4121V10.7599C29.9998 8.77076 28.4019 7.17294 26.4128 7.17294ZM14.9998 24.0642C10.5324 24.0642 6.9128 20.4447 6.9128 15.9773C6.9128 11.5099 10.5324 7.92294 14.9998 7.92294C19.4671 7.92294 23.0867 11.5425 23.0867 16.0099C23.0867 20.4447 19.4671 24.0642 14.9998 24.0642ZM25.9563 12.2273C25.9237 12.2273 25.8911 12.2273 25.8258 12.2273H24.5215C23.9345 12.1947 23.478 11.7055 23.5106 11.1186C23.5432 10.5642 23.9671 10.1403 24.5215 10.1077H25.8258C26.4128 10.0751 26.9019 10.5316 26.9345 11.1186C26.9671 11.7055 26.5432 12.1947 25.9563 12.2273Z"
              fill="#F8B045"
            />
            <path
              d="M14.9998 11.5098C12.5215 11.5098 10.4998 13.5315 10.4998 16.0098C10.4998 18.488 12.5215 20.4772 14.9998 20.4772C17.478 20.4772 19.4998 18.4554 19.4998 15.9772C19.4998 13.4989 17.478 11.5098 14.9998 11.5098Z"
              fill="#F8B045"
            />
          </g>
          <defs>
            <clipPath id="clip0_1702_11433">
              <rect
                width="30"
                height="30"
                fill="white"
                transform="translate(-0.000244141 -0.000976562)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div className="mt-5 ml-5">
        <h3 className="text-sm">Upload Cover picture *</h3>
      </div>
    </div>
  );
}
