import cx from 'classnames';
import React from 'react';


export default function ErrorMessage({children, className}) {
  return (
    <span className={cx("text-red-500", className)}>
      {children}
    </span>
  );
}
