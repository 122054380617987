const UiAction = {  
  /**
   * Show OtpLogin Modal
   */
  showOtpLogin: (next) => ({
    type: 'ui/showOtpLogin',
    payload: {
      otpLoginNext: next
    }
  }),

  /**
   * Hide OtpLogin Modal
   */
  hideOtpLogin: () => ({
    type: 'ui/hideOtpLogin',
  }),

  /**
   * Show Profile
   */
  showProfile: (next) => ({
    type: 'ui/profile',
    payload: {
      profileNext: next
    }
  }),

  /**
   * Hide Profile
   */
  hideProfile: () => ({
    type: 'ui/hideProfile',
  }),
  

};


export default UiAction;
