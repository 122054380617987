import React from "react";

export default function MyLandbot() {
  return (
    <iframe
      className="w-full h-full"
      title="landbot"
      src="https://chats.landbot.io/v3/H-995873-K6B42M7I2TR4XAM8/index.html"
    />
  );
}
