import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { UserApi } from "../../../Api/UserApi";
import Button from "../../../Atoms/Button/Button";
import ErrorMessage from "../../../Atoms/ErrorMessage";
import Form from "../../../Atoms/Form";
import Input from "../../../Atoms/Input/Input";
import TextArea from "../../../Atoms/TextArea";
import UploadPhotosGallery from "../../../Atoms/UploadPhotosGallery";
import useTemporaryMessage from "../../../Hooks/useTemporaryMessage";
import { useApi } from "../../../Utilities/Hooks/ApiHook";
import Modal from "../../../Atoms/Modal/Modal";
import CustomInput from "./CustomInput";

export default function YogaSchoolStage({ data, onSuccess }) {
  const { message, showMessage } = useTemporaryMessage({
    className: "text-green-500 mr-5",
  });
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [showModal, setShowModal] = useState(false);
  const { callApi, formErrors, loading, errorMessage } = useApi(
    UserApi.updateTeacherProfile
  );
  const [images, setImages] = React.useState(data.school_images || []);

  const [invitesArr, setInvitesArr] = useState(
    JSON.parse(data.invited_users) || []
  );

  const { register, handleSubmit } = useForm({
    defaultValues: data || {},
  });

  function onImage(image) {
    setImages((i) => [...i, image]);
  }

  function onSave(d) {
    
    const format = [...invitesArr, { name: name, email: email }];
    callApi({ ...d, invited_users: JSON.stringify(format) }).then(
      (response) => {
        showMessage("Saved!");
        onSuccess(response.data);
        setShowModal(false);
      }
    );
  }

  //invited_users

  const View = () => {
    return (
      <div className="p-10">
        <div>Invite teachers from your school</div>
        <div className="mt-4">
          <CustomInput
            name="name"
            label="Teacher name *"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <CustomInput
            name="email"
            label="Teacher Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mt-4 flex flex-row justify-start items-center">
          {message}
          <Button
            type="submit"
            loading={loading}
            onClick={handleSubmit(onSave)}
          >
            Invite
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSave)} errors={formErrors}>
      <div>
        {/* <div className="mt-4 text-sm bg-y-cream p-2 text-y-yellow rounded-md">
          You can optionally add your yoga school details. Not needed if you are
          an independent teacher.
        </div> */}

        <div className="mt-8">
          <Input name="school_name" label="School name" inputRef={register()} />
        </div>

        <div className="mt-4">
          <TextArea
            name="school_description"
            label="Description"
            inputRef={register()}
          />
        </div>

        <div className="mt-4">
          <UploadPhotosGallery
            label="Add photos of your school"
            type="school-image"
            images={images}
            onImage={onImage}
          />
        </div>

        <div className="text-base text-gray-600 mt-4">
          Other teachers from this school
        </div>

        {invitesArr && invitesArr.length > 0 && (
          <table class="table-auto border my-4">
            <thead
              className="border-b-2 text-left"
              style={{ backgroundColor: "rgba(242, 242, 242, 1)" }}
            >
              <tr className="text-sm">
                <td className="pr-10 p-5">Name</td>
                <td className="pr-10">Email</td>
                <td className="pr-10 ">Status</td>
              </tr>
            </thead>
            <tbody className="p-5">
              {invitesArr.map((ele) => (
                <tr className="text-sm">
                  <td className="pr-10 p-5">{ele.name}</td>
                  <td className="pr-10 p-5 pl-0">{ele.email}</td>
                  <td className="pr-10 p-5 pl-0">Invited</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div
          className="mt-0 text-lg bg-y-cream p-2 pl-0 text-y-yellow rounded-md cursor-pointer"
          onClick={() => {
            setShowModal(true);
          }}
        >
          + Invite teachers
        </div>

        <Modal />

        <ErrorMessage>{errorMessage}</ErrorMessage>

        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          className="sm:w-full sm:max-w-md"
        >
          {View}
        </Modal>

        <div className="mt-4 flex flex-row justify-start items-center">
          {message}
          <Button type="submit" loading={loading}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
}
