import cx from "classnames";
import React from "react";
import Styles from "../../Constants/styles";
import { useDispatch, useSelector } from "react-redux";
import UiAction from "../../Actions/ui";
import UserSelectors from "../../Selectors/user";
import { useHistory } from "react-router-dom";

function Button({
  children,
  onClick,
  className,
  loading,
  link,
  disabled,
  loginNext = null,
  type = "button",
  requireLogin = false,
  py = "py-4",
}) {
  const isLoggedIn = useSelector(UserSelectors.isLoggedIn);
  const history = useHistory();
  const dispatch = useDispatch();

  function onClickHelper(e) {
    if (loading) {
      // we don't allow any action if loading
      e.preventDefault();
      return;
    }

    if (requireLogin && !isLoggedIn) {
      dispatch(
        UiAction.showOtpLogin(loginNext || link || history.location.pathname)
      );
      onClick();
      return;
    }

    if (link) {
      history.push(link);
      return;
    }

    if (onClick) {
      onClick();
      return;
    }
  }

  return (
    <button
      type={type}
      className={cx(
        "focus:outline-none",
        `tracking-wide inline-block ${py} px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
        "cursor-pointer",
        className
      )}
      style={{
        ...(disabled ? Styles.ButtonDisabled : Styles.OrangeLinearGradient),
      }}
      onClick={onClickHelper}
    >
      {loading && (
        <svg
          className="animate-spin -ml-2 mr-3 h-5 w-5 text-white inline-block"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {children}
    </button>
  );
}

export default Button;
