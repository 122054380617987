import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../Atoms/Button/Button";
import ErrorMessage from "../../../Atoms/ErrorMessage";
import Form from "../../../Atoms/Form";
import Input from "../../../Atoms/Input/Input";
import Select from "../../../Atoms/Select";
import TextArea from "../../../Atoms/TextArea";
import UploadPhotosGallery from "../../../Atoms/UploadPhotosGallery";
import CustomInput from "../../TeacherProfile/Stages/CustomInput";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./courseForm.css";

import { Editor } from "@tinymce/tinymce-react";

export default function Description({ onNext, data, formErrors, isCourse }) {
  console.log("data", data);

  const [experiencesArr, setExperiencesArr] = useState(
    data.description && data.description[0] === "["
      ? JSON.parse(data.description)
      : data.description && data.description.length > 0
      ? [{ title: "Overview", description: data.description }]
      : [{ title: "", description: "" }]
  );

  const { register, handleSubmit } = useForm({
    defaultValues: data,
  });

  function onSubmit(data) {
    onNext({ ...data, description: JSON.stringify(experiencesArr) });
  }

  const experianceData = { title: "", institution: "" };

  const handleChange = (e, i, data = "") => {
    const formatData = experiencesArr.map((ele, idx) => {
      if (idx === i) {
        if (data) {
          return { ...ele, description: data };
        } else {
          return { ...ele, [e.target.name]: e.target.value };
        }
      } else {
        return ele;
      }
    });
    setExperiencesArr([...formatData]);
  };

  const handleDelete = (i) => {
    const formatData = experiencesArr
      .map((ele, idx) => {
        if (idx !== i) {
          return ele;
        }
      })
      .filter(Boolean);
    console.log(formatData);
    setExperiencesArr(
      formatData.length > 0 && formatData[0] !== undefined
        ? [...formatData]
        : []
    );
  };

  const handleCreate = () => {
    setExperiencesArr([...experiencesArr, experianceData]);
  };

  return (
    <div className="mt-5">
      <div className="w-9/12">
        <div className="mt-5 text-lg font-semibold cursor-pointer">
          Describe your yoga course in detail.
        </div>
        <div className="mt-5 text-y-gray">
          Adding more information about the course helps the student make the
          decision to join the course easier.
        </div>
        <div className="mt-5 text-y-gray">
          For monthly yoga courses, suggested sections are:{" "}
        </div>
        <div className="mt-5 text-y-gray">Overview of the course</div>
        <div className="mt-0 text-y-gray">Who is this course for?</div>
        <div className="mt-0 text-y-gray">Typical class plan</div>
        <div className="mt-0 mb-8 text-y-gray">
          What are the course outcomes?
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} errors={formErrors}>
        {experiencesArr &&
          experiencesArr.map((ele, idx) => (
            <div className="border p-5 py-4 rounded-lg border-gray-300 mb-8">
              <div className="mt-4">
                <CustomInput
                  name="title"
                  label="Title"
                  value={ele.title}
                  onChange={(e) => {
                    handleChange(e, idx);
                  }}
                />
              </div>
              <div className="mt-4 editor">
                {/* <CKEditor
                  name="description"
                  editor={ClassicEditor}
                  config={{
                    toolbar: {
                      items: [
                        "heading",
                        "|",
                        "fontfamily",
                        "fontsize",
                        "|",
                        "alignment",
                        "|",
                        "fontColor",
                        "fontBackgroundColor",
                        "|",
                        "bold",
                        "italic",
                        "strikethrough",
                        "underline",
                        "subscript",
                        "superscript",
                        "|",
                        "link",
                        "|",
                        "outdent",
                        "indent",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "todoList",
                        "|",
                        "code",
                        "codeBlock",
                        "|",
                        "insertTable",
                        "|",
                        "undo",
                        "redo",
                      ],
                      shouldNotGroupWhenFull: true,
                    },
                  }}
                  data={ele.description}
                  onReady={(editor) => {
                    console.log("Editor is ready to use!", editor);
                    console.log(
                      "item====",
                      Array.from(editor.ui.componentFactory.names())
                    );
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    console.log({ event, editor, data });
                    handleChange(event, idx, data);
                  }}
                /> */}

                <Editor
                  apiKey="vuj6sbpou1d8f0gulcfrrq2t4yk8yfx1hhc7tyuu7ntrlbo6"
                  value={ele.description}
                  init={{
                    branding: false,
                    height: 400,
                    menubar: true,
                    plugins:
                      "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern contextmenu",
                    toolbar:
                      "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                    contextmenu:
                      "paste | link image inserttable | cell row column deletetable",
                    image_advtab: false,
                    paste_as_text: true,
                    paste_text_sticky: true,
                    paste_text_sticky_default: true,
                    convert_urls: false,
                    paste_data_images: true,

                    // file_picker_callback: function (callback, value, meta) {
                    //   var input = document.createElement("input");
                    //   input.setAttribute("type", "file");

                    //   input.onchange = function () {
                    //     var file = this.files[0];

                    //     var reader = new FileReader();
                    //     reader.readAsDataURL(file);

                    //     callUpload("course-image", file)
                    //       .then((result) => {
                    //         console.log("result", result);
                    //         if (meta.filetype == "file") {
                    //           callback(result.data.data.file, {
                    //             text: "My text",
                    //           });
                    //         }

                    //         if (meta.filetype == "image") {
                    //           callback(result.data.data.file, {
                    //             alt: "My alt text",
                    //           });
                    //         }

                    //         if (meta.filetype == "media") {
                    //           callback(result.data.data.file, {
                    //             source2: "alt.ogg",
                    //             poster: "image.jpg",
                    //           });
                    //         }
                    //       })
                    //       .catch((err) => {
                    //         console.log("err", err);
                    //         //reject('Some error occured')
                    //       });

                    //     //const res = setUploadFun(file);
                    //   };
                    //   input.click();
                    // },
                  }}
                  onEditorChange={(newValue) => {
                    //const data = editor.getData();
                    //console.log({ event, editor, data });
                    handleChange({}, idx, newValue);
                  }}
                />
              </div>
              <div
                className="text-right flex justify-end cursor-pointer mt-5"
                onClick={() => {
                  handleDelete(idx);
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.8096 10.667C3.8096 11.4037 4.68304 12.0003 5.76147 12.0003H13.5689C14.6473 12.0003 15.5207 11.4037 15.5207 10.667V2.66699H3.8096V10.667Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M13.0809 0.666656L12.105 0H7.22538L6.24942 0.666656H2.83371V2H16.4966V0.666656H13.0809Z"
                    fill="#BDBDBD"
                  />
                </svg>
              </div>
            </div>
          ))}

        <div
          className="mt-4 text-lg bg-y-cream p-2 pl-0 text-y-yellow rounded-md cursor-pointer"
          onClick={handleCreate}
        >
          + Add new section
        </div>
        {/* <div className="">
          <Input
            required
            name="title"
            label={isCourse ? "Course Title" : "Class title"}
            inputRef={register({ required: true })}
          />
        </div>

        <div className="mt-4">
          <TextArea
            required
            name="description"
            label={isCourse ? "Course Description" : "Class description"}
            inputRef={register({ required: true })}
          />
        </div>

        <div className="mt-4">
          <Select
            required
            name="level"
            label="Level"
            options={[
              { value: "beginner", label: "Beginner" },
              { value: "intermediate", label: "Intermediate" },
              { value: "advanced", label: "Advanced" },
            ]}
            inputRef={register({ required: true })}
          />
        </div>

        <div className="mt-4">
          <Input
            required
            name="style"
            label="Style of Yoga"
            inputRef={register({ required: true })}
          />
        </div>

        <div className="mt-4">
          <UploadPhotosGallery
            label={`Add photos of your ${isCourse ? "course" : "class"}`}
            type="course-image"
            images={images}
            onImage={onImage}
          />
        </div>

        {imageError && <ErrorMessage>{imageError}</ErrorMessage>} */}

        <div className="mt-2 flex flex-row justify-end">
          <Button type="submit">Next</Button>
        </div>
      </Form>
    </div>
  );
}
