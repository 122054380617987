import React from "react";
import TextService from "../../Services/TextService";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

export default function OneOnOneCard({ one, onDetails, link }) {
  const teacher = one.teacher;

  const metaLines = [];
  if (teacher.teaching_years) {
    metaLines.push(`Teaching for ${teacher.teaching_years} years`);
  }

  if (teacher.style) {
    metaLines.push(teacher.style);
  }

  return (
    <Link to={link} onClick={onDetails}>
      <div className="bg-white w-full p-5 rounded-md shadow-card-cream-sm max-w-sm cursor-pointer">
        <div className="flex flex-row justify-between">
          {teacher.profile_picture && (
            <img
              className="object-cover rounded-full w-16 h-16 mr-3"
              src={teacher.profile_picture}
              alt="Placeholder"
            />
          )}

          <div className="flex-1">
            <div>{teacher.full_name}</div>
            <div className="text-xs text-gray-500 leading-5">
              {teacher.location && (
                <div className="flex flex-row items-center">
                  <svg
                    className="mr-2"
                    width="7"
                    height="10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.399 1.712A3.394 3.394 0 003.36.001 3.394 3.394 0 00.478 1.712a3.481 3.481 0 00-.046 3.44l2.48 4.54a.597.597 0 00.526.309.597.597 0 00.523-.304l.003-.006 2.48-4.539a3.481 3.481 0 00-.045-3.44zm-2.96 2.82a1.408 1.408 0 010-2.812c.775-.001 1.405.63 1.405 1.405s-.63 1.406-1.406 1.406z"
                      fill="currentColor"
                    />
                  </svg>
                  {teacher.location}
                </div>
              )}
              {metaLines.map((l) => (
                <p key={l}>{l}</p>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between mt-5">
          {one.duration && (
            <div className="flex flex-row items-center">
              <svg
                width="12"
                height="12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 0C2.692 0 0 2.692 0 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6zm0 11.25A5.256 5.256 0 01.75 6 5.256 5.256 0 016 .75 5.256 5.256 0 0111.25 6 5.256 5.256 0 016 11.25z"
                  fill="currentColor"
                />
                <path
                  d="M6.375 2.25h-.75v3.905l2.36 2.36.53-.53-2.14-2.14V2.25z"
                  fill="currentColor"
                />
              </svg>
              <p className="text-sm ml-2">
                <span className="text-green-500">{one.duration} min</span>
              </p>
            </div>
          )}

          <div className="flex-1 flex flex-row justify-end items-center">
            {one.currency_fee && (
              <p className="mr-2">
                {TextService.formatCurrencyFee(one.currency_fee)}
              </p>
            )}
            <div style={{ width: 100 }}>
              <Button py="py-3" className="w-full">
                Book
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
