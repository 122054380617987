import { useEffect } from "react";
import UrlService from "../../Services/UrlService";
import { StorefrontApi } from "../../Api/StorefrontApi";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { useSelector } from "react-redux";
import UserSelectors from "../../Selectors/user";
import { useHistory } from "react-router-dom";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

const loadScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const RazorPay = (props) => {
  const { callApi } = useApi(StorefrontApi.bookingDetails);
  const { callApi: SuccsesApi } = useApi(StorefrontApi.paymentSuccessApi);
  const { callApi: paymentFailureApi } = useApi(
    StorefrontApi.paymentFailureApi
  );

  const { callApi: SubscriptionBookDetails } = useApi(
    SubscriptionApi.bookingDetails
  );
  const { callApi: SuccsesSubscriptionApi } = useApi(
    SubscriptionApi.bookSubscriptionPayCheck
  );
  const { callApi: paymentSubscriptionFailureApi } = useApi(
    SubscriptionApi.bookSubscriptionPayFailure
  );
  const user = useSelector(UserSelectors.user);
  const history = useHistory();

  console.log("email", SuccsesApi);

  const handlePaymentSuccess = async (response, data) => {
    console.log("success handler res", response);
    if (UrlService.getParam("courseType") === "subscription") {
      try {
        SuccsesSubscriptionApi(UrlService.getParam("bookingId"), response).then(
          (res) => {
            // history.push(
            //   `/booking-status/subscriptions/${UrlService.getParam(
            //     "id"
            //   )}?booking=${UrlService.getParam(
            //     "bookingId"
            //   )}&booking_status=success&type=subscription`
            // );
            history.push(
              `/booking-status?booking=${UrlService.getParam(
                "bookingId"
              )}&booking_status=success&type=subscription`
            );
          }
        );
      } catch (error) {
        console.log(console.error());
      }
    } else {
      try {
        SuccsesApi(UrlService.getParam("bookingId"), response).then((res) => {
          // history.push(
          //   `/${UrlService.getParam("courseType")}/${UrlService.getParam(
          //     "id"
          //   )}/${UrlService.getParam("slug")}?booking=${UrlService.getParam(
          //     "bookingId"
          //   )}&booking_status=success`
          // );

          history.push(
            `/booking-status?booking=${UrlService.getParam(
              "bookingId"
            )}&booking_status=success`
          );
        });
      } catch (error) {
        console.log(console.error());
      }
    }
  };

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
    const showRazorpay = async (data) => {
      const res = await loadScript();
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      const amount = data.currency_fee
        ? data.currency_fee * 100
        : data.fee * 100;
      console.log("amount", amount);
      var options = {
        key: `rzp_live_QqzWC0j38jO618`,
        //key_id: `rzp_test_XAekibWcRk5BXF`,
        amount: amount,
        currency: "INR",
        name: data.title ? data.title : data.subscription.title,
        description: data.type_display,
        image: "",
        order_id: data.razorypay_checkout_id,
        handler: function (response) {
          console.log("success handler");
          handlePaymentSuccess(response, data);
        },
        modal: {
          ondismiss: function () {
            if (UrlService.getParam("courseType") === "subscription") {
              paymentSubscriptionFailureApi(
                UrlService.getParam("bookingId")
              ).then((res) => {
                history.push(
                  `/booking-status?booking=${UrlService.getParam(
                    "bookingId"
                  )}&booking_status=success&type=subscription`
                );
              });
            } else {
              paymentFailureApi(UrlService.getParam("bookingId")).then(
                (res) => {
                  history.push(
                    `/booking-status?booking=${UrlService.getParam(
                      "bookingId"
                    )}&booking_status=success`
                  );
                }
              );
            }
          },
        },
        prefill: {
          name: user.full_name,
          email: user.email,
          contact: user.profile.mobile_number,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);
      rzp1.open();
    };
    if (UrlService.getParam("courseType") === "subscription") {
      SubscriptionBookDetails(UrlService.getParam("bookingId")).then(
        (response) => {
          const booking = response.data.data;
          console.log("book", booking);
          if (booking.razorypay_checkout_id) {
            showRazorpay(booking);
          }
        }
      );
    } else {
      callApi(UrlService.getParam("bookingId")).then((response) => {
        const booking = response.data.data;
        console.log("book", booking);
        if (booking.razorypay_checkout_id) {
          showRazorpay(booking);
        }
      });
    }
  }, []);

  console.log("call razor", props);
  console.log(UrlService.getParam("courseType"));
  return (
    <div>
      <Helmet>
        <title>Payment</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Payment"></meta>
        <meta name="description" content="Payment" />
        <meta property="og:title" content="Payment"></meta>
        <meta property="twitter:title" content="Payment"></meta>
        <meta property="og:description" content="Payment"></meta>
        <meta property="twitter:description" content="Payment"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div></div>
    </div>
  );
};

export default RazorPay;
