import React, { useState } from "react";
import { StorefrontApi } from "../../Api/StorefrontApi";
import CourseCard from "../../Atoms/CourseCard";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import useIsMobile from "../../Hooks/useMobile";
import Vent from "../../vent";
import Slider from "react-slick";
import Loader from "../../Atoms/Loader";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import CourseAction from "../../Actions/course";
import CourseSelectors from "../../Selectors/course";
import { useDispatch, useSelector } from "react-redux";

export default function Banners() {
  const { callApi, data, loading } = useApi(StorefrontApi.banners);

  const [index, setIndex] = useState(0);

  const bannersData = useSelector(CourseSelectors.bannersData);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (bannersData.length === 0) {
      callApi().then((res) => {
        dispatch(CourseAction.setFeaturedCourse(res.data.data));
      });
    }
  }, [callApi]);

  let courses = bannersData || [];

  //   function onCourseDetails(course) {
  //     Vent.onClassDetails(Vent.TYPE_MONTHLY_CLASS, course.title);
  //   }

  const isMobile = useIsMobile();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: true,
    onSwipe: (val) => {},
    beforeChange: (current, next) => setIndex(next),
    arrows: isMobile ? false : true,
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-row justify-between items-center">
          {[1, 2, 3].map((ele) => (
            <Loader />
          ))}
        </div>
      ) : (
        <>
          <div className="px-5 md:px-0 mb-3">
            {courses && courses.length > 0 && (
              <Slider {...settings}>
                {courses
                  .filter(
                    (ele) =>
                      ele.files &&
                      ele.files.length > 0 &&
                      ele.files[0].file.substring(
                        ele.files[0].file.length - 3
                      ) !== "mp4"
                  )
                  .map((c) => (
                    <>
                      <div className="px-0">
                        <div
                          className="flex justify-center items-center text-center "
                          onClick={() => {
                            window.open(
                              `https://${courses[index].teacher.domain}.do.yoga/`
                            );
                            ReactGA.event({
                              category: "1:1 Details",
                              action: "Routing",
                              label: JSON.stringify(courses[index]),
                            });
                            ReactPixel.track("track", {
                              category: "1:1 Details",
                              action: "Routing",
                              label: JSON.stringify(courses[index]),
                            });
                          }}
                        >
                          <img
                            src={c.files[0].file}
                            alt={
                              c.teacher.full_name
                                ? c.teacher.full_name
                                : c.teacher.first_name
                                ? c.teacher.first_name
                                : c.title
                            }
                            style={{
                              width: isMobile ? "90vw" : "70vw",
                              height: isMobile ? "30vh" : "75vh",
                            }}
                          />
                        </div>
                        <div
                          className="text-gray-600 text-base font-bold pt-3 px-0 md:px-5 mx-0 md:mx-3"
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          onClick={() => {
                            window.open(`https://${c.teacher.domain}.do.yoga/`);
                            ReactGA.event({
                              category: "1:1 Details",
                              action: "Routing",
                              label: JSON.stringify(c),
                            });
                            ReactPixel.track("track", {
                              category: "1:1 Details",
                              action: "Routing",
                              label: JSON.stringify(c),
                            });
                          }}
                        >
                          {c.title}
                        </div>
                        {/* <div className="text-gray-600" style={{ fontSize: "14px" }}>
                      {courses[index].description}
                    </div> */}
                        {/* <div
                      role={"button"}
                      className="pb-3 text-sm text-y-yellow-dark flex flex-row items-center"
                      onClick={() => {
                        window.open(
                          `https://${courses[index].teacher.domain}.do.yoga/`
                        );
                        ReactGA.event({
                          category: "1:1 Details",
                          action: "Routing",
                          label: JSON.stringify(courses[index]),
                        });
                        ReactPixel.track("track", {
                          category: "1:1 Details",
                          action: "Routing",
                          label: JSON.stringify(courses[index]),
                        });
                      }}
                    >
                      <div>Go to teacher page</div>
                      <div className="pl-2">
                        <svg
                          width="15"
                          height="25"
                          viewBox="0 0 16 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.624989 4.50001L13.8612 4.50001L11.6686 6.68201C11.4239 6.9255 11.423 7.32122 11.6665 7.56588C11.91 7.81056 12.3057 7.81147 12.5504 7.568L15.8161 4.31801C15.8163 4.31782 15.8165 4.31761 15.8167 4.31742C16.0607 4.07392 16.0615 3.67692 15.8167 3.43261C15.8165 3.43242 15.8164 3.4322 15.8162 3.43201L12.5504 0.182024C12.3058 -0.0614138 11.9101 -0.0605698 11.6665 0.184148C11.423 0.428803 11.424 0.824521 11.6686 1.06802L13.8612 3.25001L0.624989 3.25001C0.279801 3.25001 -1.14441e-05 3.52983 -1.14441e-05 3.87501C-1.14441e-05 4.2202 0.279801 4.50001 0.624989 4.50001Z"
                            fill="#E56F2C"
                          />
                        </svg>
                      </div>
                    </div> */}
                      </div>
                    </>
                  ))}
              </Slider>
            )}
          </div>
          {/* {courses && courses.length > 0 && (
            <>
              <div
                className="text-gray-600 text-base font-bold"
                style={{ fontSize: "14px" }}
              >
                {courses[index].title}
              </div>
              <div className="text-gray-600" style={{ fontSize: "14px" }}>
                {courses[index].description}
              </div>
              <div
                role={"button"}
                className="pb-3 text-sm text-y-yellow-dark flex flex-row items-center"
                onClick={() => {
                  window.open(
                    `https://${courses[index].teacher.domain}.do.yoga/`
                  );
                  ReactGA.event({
                    category: "1:1 Details",
                    action: "Routing",
                    label: JSON.stringify(courses[index]),
                  });
                  ReactPixel.track("track", {
                    category: "1:1 Details",
                    action: "Routing",
                    label: JSON.stringify(courses[index]),
                  });
                }}
              >
                <div>Go to teacher page</div>
                <div className="pl-2">
                  <svg
                    width="15"
                    height="25"
                    viewBox="0 0 16 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.624989 4.50001L13.8612 4.50001L11.6686 6.68201C11.4239 6.9255 11.423 7.32122 11.6665 7.56588C11.91 7.81056 12.3057 7.81147 12.5504 7.568L15.8161 4.31801C15.8163 4.31782 15.8165 4.31761 15.8167 4.31742C16.0607 4.07392 16.0615 3.67692 15.8167 3.43261C15.8165 3.43242 15.8164 3.4322 15.8162 3.43201L12.5504 0.182024C12.3058 -0.0614138 11.9101 -0.0605698 11.6665 0.184148C11.423 0.428803 11.424 0.824521 11.6686 1.06802L13.8612 3.25001L0.624989 3.25001C0.279801 3.25001 -1.14441e-05 3.52983 -1.14441e-05 3.87501C-1.14441e-05 4.2202 0.279801 4.50001 0.624989 4.50001Z"
                      fill="#E56F2C"
                    />
                  </svg>
                </div>
              </div>
            </>
          )} */}
        </>
      )}
    </>
  );
}
