import React, { useEffect } from "react";
import LandingTemplate from "../../Templates/LandingTemplate";
import Button from "../../Atoms/Button/Button";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

function Landing(props) {
  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);
  return (
    <LandingTemplate>
      <p className="font-semibold">
        DoYoga lets you join live yoga classes taught by yoga teachers from
        across the world.
      </p>
      <p className="mt-5">
        We are targeting to go live to users in a couple of weeks.
      </p>
      <p className="mt-5">
        We are onboarding yoga teachers on the platform now.
      </p>
      <Button
        className="mt-5 w-full md:w-auto"
        link="/studio/profile"
        requireLogin
      >
        Sign up as a yoga teacher
      </Button>
    </LandingTemplate>
  );
}

export default Landing;
