import React from "react";

export default function HoveringGirl({
  width = 498,
  height = 456,
  className,
  id = 1,
}) {
  const shadowWidth = (width / 498) * 419;
  const shadowHeight = (height / 456) * 73;

  return (
    <div className={className}>
      <div className="flex flex-col items-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 498 456"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="landing-girl-animate"
        >
          <path
            d="M260.332.083s24.92-2.613 33.894 21.451c8.986 24.063 17.44 31.94 18.493 44.581 4.28 51.13 28.16 39.564 21.44 69.038-6.72 29.473-11.36 33.93-11.36 33.93l-57.08-11.192-57.827.188s22.614-11.298 17.587-35.361c-5.04-24.064-17.587-63.504-10.507-90.365 7.094-26.86 30.467-30.22 45.36-32.27z"
            fill="#16202E"
          />
          <path
            d="M334.452 342.434s-.026-.053-.04-.107v-.022-.018c-.013-.012-.013-.025-.013-.025l-4.293.267c-23.4 1.426-135.174 7.9-150.72 2.189 0 0 .426-1.515 1.173-3.517a190.05 190.05 0 012.76-6.919c7.347-17.287 15.187-27.727 18.32-35.136 1.093-2.552 2.253-4.147 3.04-10.372 2.173-17.391 1.84-45.029-1.52-56.764-4.547-15.928-11.627-26.548-4.293-47.535 7.333-20.988 8.626-40.125 8.626-40.125l23.48-9.28s-1.506 56.229 30.347 57.493c31.867 1.268 30.787-61.385 30.787-61.385l24.08 7.527s4.306 33.126 9.106 41.473c4.8 8.343 2.28 30.595-3.28 43.489-5.573 12.895-9.106 37.172-9.106 51.587 0 3.087.853 7.113 2.2 11.636 4.626 15.501 15.146 36.797 17.626 45.831.174.629.307 1.196.4 1.7.48 2.672.787 4.468 1 5.67.32 1.955.347 2.343.32 2.343z"
            fill="#EB7171"
          />
          <path
            d="M444.639 449.982c-26.293.504-86.72 6.069-112.773-3.539-18.974-7.006-63.2-19.784-95.334-26.4-11.96-2.457-22.24-4.061-28.933-4.198-17.56-.36-35.56-16.083-48.391-26.718-5.201-4.305-9.549-7.776-12.688-9.053-3.061-1.248-5.358-2.747-6.785-4.252-1.051-1.112-1.627-2.232-1.684-3.26-.133-2.529 2.885-4.509 9.732-4.509 1.908 0 3.867.088 5.845.236 11.944.894 24.678 4.028 31.838 3.812 8.346-.255 31.093 19.724 35.653 20.732 1.92.429 7.64 2.134 15.027 2.677 9.986.736 23-.664 33.773-10.264 5.627-5.017 13.693-10.901 22.227-16.681 19.64-13.29 41.746-26.015 42.24-26.3 0-.003.013-.003.013-.003v.025c0 .008.013.014.013.019v.024c.014.027.027.06.04.101 1.52 4.151 4.52 8.987 8.08 12.039 5.2 4.48 8.4 4.415 28.68 5.397 3.96.194 8.2.407 12.56.672 18.934 1.15 40.147 3.304 49.747 9.286a216.534 216.534 0 0114.52 9.88v.004c12.827 9.52 25.293 21.161 27.773 30.772.267 1.054.427 2.086.44 3.088.254 13.654-5.306 35.908-31.613 36.413z"
            fill="#DBDFF0"
          />
          <path
            d="M334.506 342.462c.253.684 1.226 3.3 3.133 6.263 6.427 10.016 13.08 9.946 20.52 10.445l-70.547 37.979-92.293-2.258-36.111-5.764-6.874-1.097s-17.935-12.481-21.991-15.095a261.94 261.94 0 007.708-.373c-.133-2.529 2.885-4.509 9.732-4.509 1.908 0 3.867.088 5.845.236 6.524-6.68 25.758-23.57 25.758-23.57l155.066-2.469.054.212z"
            fill="#DBDFF0"
          />
          <path
            d="M383.772 360.539c-26.346 7.275-48.36 27.238-48.36 27.238l-33 5.942 53.974-34.678c.56.046 1.16.088 1.773.129 3.68.251 8.133.459 13.053.697 3.96.194 8.2.407 12.56.672z"
            fill="#B6BAC7"
          />
          <path
            d="M246.079 392.897c17.453-2.988 17.12-9.006 23.387-17.35 5.386-7.192 10.893-6.421 22.68-6.982a115.3 115.3 0 015.52-.132c1.653 0 3.36-.144 5.12-.418 12.013-1.846 25.88-9.561 31.613-16.841.773-.98 1.88-1.789 3.24-2.449 1.227-.6 2.64-1.074 4.213-1.446.347-.082.694-.164 1.054-.234 10.466-2.143 15.986-1.127 21.373-.755 7.333.504 19.68 10.384 10.32 12.66-1.067.261-2.2.565-3.387.917-9.066 2.69-20.893 8.027-30.746 16.531-11.12 9.608-42.227 18.964-52.854 25.033-4.84 2.766-26.386 10.207-51.08 18.612l9.547-27.146z"
            fill="#EEA987"
          />
          <path
            d="M268.252 409.079c-8.88 3.167-19.946 6.952-31.72 10.963-29.493 10.043-63.48 21.451-78.886 27.917-8.106 3.399-19.519 5.372-31.938 6.354-.048.004-.097.008-.142.014a252.68 252.68 0 01-12.48.65c-16.448.481-33.284-.43-45.635-1.804-.035-.006-.064-.008-.101-.014-8.43-.941-14.76-2.1-17.426-3.178-7.757-3.139-13.757-17.07-15.245-29.176-.555-4.474-.495-8.7.33-12.042 1.84-7.529 11.095-16.514 22.366-23.334l.005-.004c7.268-4.399 15.382-7.898 22.89-9.532 10.296-2.236 28.752-2.36 44.444-2.782 1.918-.054 3.802-.112 5.625-.177h.004c2.731-.097 5.324-.215 7.705-.369 6.847-.442 11.984-1.167 13.806-2.546.086-.061.164-.126.229-.193l.423.155h.005l6.252 2.31 40.609 14.982c14.387 11.381 31.04 8.776 41.294 6.705 2.093-.427 3.92-.827 5.413-1.083 3.213-.549 5.84-1.201 7.987-1.954l14.186 18.138z"
            fill="#DBDFF0"
          />
          <path
            d="M435.372 366.463s-62.786-47.464-69.36-59.098c-6.586-11.632-35.266-89.071-38.546-94.382-.267-.428-.827-.846-1.627-1.249-8.947-4.481-48.08-6.777-48.08-6.777l-79.907 8.09s-.16.283-.44.799c-2.56 4.752-15.813 29.329-18.773 36.627-3.293 8.09-20.237 52.34-24.277 58.916-4.05 6.576-89.343 69.69-91.363 70.702-2.02 1.015-18.133-6.257-14.845-10.808 3.29-4.554 65.492-67.481 73.078-75.068 7.588-7.584 35.908-71.557 37.679-79.144 1.768-7.584 7.075-46.272 20.728-56.642 5.213-3.952 15.76-9.791 26.84-14.526.307-.13.613-.264.92-.392 8.253-3.465 16.133-6.202 22.827-8.709.226-.084.453-.169.68-.252 7.373-2.727 12.973-4.188 12.973-4.188l34.707-2.255s8.24 1.564 13.52 3.068c.12.038.253.074.386.111 7.96 2.28 18.747 5.471 23.694 7.417.12.048.24.092.36.143.52.204 1.066.415 1.653.639 9.16 3.546 26.493 10.144 28.84 17.177 2.533 7.583 10.12 27.559 12.147 42.225 2.013 14.668 21.733 64.736 25.026 72.574 3.28 7.836 12.64 18.458 18.2 27.561 5.574 9.103 42.547 53.165 46.454 55.443 3.92 2.273-13.494 11.998-13.494 11.998z"
            fill="#EEA987"
          />
          <path
            d="M306.266 393.301s67.066-12.1 82.746-5.271c15.68 6.827 27.307 22.504 42.734 20.988 15.426-1.519.2-8.561-10.8-11.488-11-2.925-19.574-16.201-48.667-15.253-29.093.948-48.627 5.88-48.627 5.88s-13.026 3.398-17.386 5.144z"
            fill="#C6CAD9"
          />
          <path
            d="M167.159 389.761l-23.825-3.975s-10.092-11.413-18.622-12.676a868.19 868.19 0 005.627-.175h.004a261.94 261.94 0 007.708-.373c6.841-.439 11.981-1.164 13.801-2.544.224-.012.44-.023.654-.037h.002c3.02-.175 5.012-.278 5.012-.278l1.244 2.587 8.395 17.471z"
            fill="#DBDFF0"
          />
          <path
            d="M156.659 387.034s-5.715-5.433-10.645-6.96c-4.931-1.527-12.808-9.137-3.858-11.404 11.238-2.845 31.67 4.313 37.23 4.188 5.573-.128 15.706 2.381 23.533 7.895 7.827 5.514 17.48 13.525 23.653 13.373 0 0-.4 1.489-4.44 1.363-4.053-.127-63.256-6.634-63.256-6.634l-2.217-1.821z"
            fill="#EEA987"
          />
          <path
            d="M243.879 394.485c-8.48 1.765-21.92 2.888-42.493.877-43.992-4.299-65.738-11.491-88.748-2.332-23.012 9.16-19.978 11.688-31.608 11.688-11.634 0 7.332-14.413 32.112-18.712 24.781-4.299 86.23 7.587 93.31 7.587 5.774 0 25.094 1.793 40.574-1.158l-3.147 2.05z"
            fill="#C6CAD9"
          />
          <path
            d="M475.812 410.481c-2.24-.118-5.28-.8-8.973-2.916-8.16-4.667-18.64-27.5-18.8-27.856 12.827 9.52 25.293 21.161 27.773 30.772z"
            fill="#B6BAC7"
          />
          <path
            d="M476.159 376.367c-2.467-.474-7.867-7.396-7.96-9.293-.107-1.896 9-3.888 10.427 0 1.413 3.888-2.467 9.293-2.467 9.293zm20.867 23.136c-.2-4.645-17.64-15.361-14.507-18.014 3.12-2.656 3.507-12.328 3.507-14.699 0-2.371-2.947-5.025-5.88-8.061-2.947-3.034-18.027.19-20.96 0-2.934-.19-10.32-4.267-10.32-4.267s-16.734 9.472-14.84 10.811c1.893 1.338 18.52 18.585 23.333 25.7 4.813 7.114 10.733 9.384 17.76 13.272 7.013 3.888 6.547-1.328 6.547-1.328.746.854 4.826 2.277 4.826 2.277 5.414 1.043 4.84-4.079 4.84-4.079s5.88 3.035 5.694-1.612z"
            fill="#EEA987"
          />
          <path
            d="M57.374 385.429c-.14.405-9.318 26.277-22.242 35.076a9.97 9.97 0 01-.454.297c-.556-4.471-.49-8.697.329-12.039 1.843-7.529 11.096-16.514 22.367-23.334z"
            fill="#B6BAC7"
          />
          <path
            d="M18.4 381.894c1.423-3.888 10.526-1.897 10.433 0-.098 1.896-5.502 8.819-7.967 9.292 0 0-3.888-5.404-2.467-9.292zM5.693 415.937s-.57 5.12 4.836 4.077c0 0 4.077-1.424 4.836-2.276 0 0-.475 5.215 6.542 1.327 7.018-3.888 12.94-6.158 17.756-13.272 4.814-7.115 21.44-24.36 23.336-25.7 1.894-1.34-14.846-10.811-14.846-10.811s-7.376 4.079-10.315 4.268c-2.94.189-18.016-3.035-20.955 0-2.94 3.033-5.88 5.689-5.88 8.06 0 2.369.38 12.043 3.508 14.697 3.13 2.656-14.318 13.371-14.508 18.018-.189 4.646 5.69 1.612 5.69 1.612z"
            fill="#EEA987"
          />
          <path
            d="M276.012 419.017s-71.693 29.792-150.304 35.296c-.048.004-.097.008-.142.014a372.96 372.96 0 01-11.732.628c-.246.01-.498.018-.748.022-15.238.538-30.603.062-45.635-1.804-.035-.006-.064-.008-.101-.014 0 0 128.489-46.174 173.316-59.181 6.186-1.796 10.773-2.957 13.213-3.28l.187.243 14.186 18.138 7.76 9.938z"
            fill="#DBDFF0"
          />
          <path
            d="M263.626 14.157s33 4.762 28.16 53.477c-4.827 48.716-33.947 44.849-35.894 44.808-1.946-.041-29.226 1.013-31.586-58.43 0 0-1.094-43.96 39.32-39.855z"
            fill="#EEA987"
          />
          <path
            d="M278.279 103.801s-2.04 25.34 2.84 32.932c4.88 7.593-4.173 17.896-22.827 14.372-18.653-3.526-17.573-14.102-17.573-14.102s5.227-15.637 3.693-28.154c-1.52-12.518 33.867-5.048 33.867-5.048z"
            fill="#EEA987"
          />
          <path
            d="M259.852 31.811s15.414 16.211 22.574 24.418c7.16 8.209 7.986 24.064 7.986 24.064s12.067-14.738 7.014-31.899c-5.04-17.161-16.974-50.55-47.2-40.292-30.214 10.26-31.334 55.403-27.787 77.787 0 0 13.56-49.415 37.413-54.078z"
            fill="#16202E"
          />
          <path
            d="M246.839 110.235s14.067 6.646 27.747-3.172c0 0-3.8 7.851-13.867 8.967-10.08 1.116-13.88-5.795-13.88-5.795z"
            fill="#A77860"
          />
          <path
            d="M325.226 214.347l37.226-9.445c-.813-9.716-10.573-39.624-14.533-48.868-3.84-8.957-27.946-16.872-55.426-24.748-.2 10.967-2.734 62.412-31.174 61.28-28.187-1.119-30.293-47.54-30.413-58.016-.227.083-.454.168-.68.252-33.694 12.621-51.507 16.816-59.294 30.771-8.506 15.261-13.202 43.438-13.202 43.438l39.829 5.356c1.533 5.7 3.774 11.247 5.6 17.646 3.36 11.732 3.307 42.14 1.133 59.529 0 0 55.294-22.297 110.814-4.653-1.36-4.523-2.2-8.55-2.2-11.636 0-14.416 3.146-38.866 9.106-51.587 1.294-2.775 2.374-5.951 3.214-9.319zM334.399 342.262l-4.293.267-150.72 2.189s.426-1.515 1.173-3.517a190.05 190.05 0 012.76-6.919l149.413-1.561c.174.629.307 1.196.4 1.7.48 2.672.787 4.468 1 5.67.32 1.955.32 2.17.32 2.17l-.053.001z"
            fill="#EB7171"
          />
          <path
            d="M242.052 119.07s-45.413 40.399-33.626 65.601c10.066 21.511 45.72 8.319 39.84-14.221-5.88-22.536-6.214-51.38-6.214-51.38z"
            fill="#16202E"
          />
        </svg>

        <svg
          width={shadowWidth}
          height={shadowHeight}
          viewBox="0 0 419 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="landing-shadow-animate"
        >
          <ellipse
            cx="209.5"
            cy="36.5"
            rx="209.5"
            ry="36.5"
            fill={`url(#paint${id}0_radial)`}
          />
          <defs>
            <radialGradient
              id={`paint${id}0_radial`}
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(0 36.5 -209.5 0 209.5 36.5)"
            >
              <stop stopColor="#D6D6D6" />
              <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
}
