import React from "react";
import { useForm } from "react-hook-form";
import { UserApi } from "../../../Api/UserApi";
import Button from "../../../Atoms/Button/Button";
import ErrorMessage from "../../../Atoms/ErrorMessage";
import Form from "../../../Atoms/Form";
import Input from "../../../Atoms/Input/Input";
import useTemporaryMessage from "../../../Hooks/useTemporaryMessage";
import { useApi } from "../../../Utilities/Hooks/ApiHook";

import useImageUpload from "../../../Hooks/useImageUpload";
import ProfilePicStage from "./ProfilePicStage";
import { MediaApi } from "../../../Api/MediaApi";

export default function ProfileStage({ data, onSuccess }) {
  const { message, showMessage } = useTemporaryMessage({
    className: "text-green-500 mr-5",
  });
  const { callApi, formErrors, loading, errorMessage } = useApi(
    UserApi.updateTeacherProfile
  );

  const { callApi: callUpload } = useApi(MediaApi.uploadMedia);

  const { component, showImageUpload } = useImageUpload(onImage, true);

  const { register, handleSubmit } = useForm({
    defaultValues: data || {},
  });

  const [images, setImages] = React.useState(data.profile_videos || []);

  function onImage(data, image) {
    callUpload("profile-video", image).then(({ data }) => {
      setImages([data.data]);
    });
  }

  function onSave(d) {
    callApi({ ...d, profile_videos: images }).then((response) => {
      showMessage("Saved!");
      onSuccess(response.data);
    });
  }

  return (
    <Form onSubmit={handleSubmit(onSave)} errors={formErrors}>
      <div className="mt-8">
        <Input
          required
          name="name"
          label="Your Name"
          inputRef={register({ required: true })}
        />
      </div>

      <div className="mt-4">
        <Input
          required
          name="location"
          label="Location"
          inputRef={register({ required: true })}
        />
      </div>

      <div>
        <ProfilePicStage />
      </div>

      <div className="mt-8">
        {images && images.length > 0 ? (
          <>
            {/* <Picture image={images[0].file} onUploadClick={showImageUpload} /> */}
            <video className="object-cover w-full h-full" controls>
              <source src={images[0].file} type="video/mp4" />
            </video>
            <Button className="mt-5" onClick={showImageUpload}>
              Change Video
            </Button>
          </>
        ) : (
          <EmptyPicture onUploadClick={showImageUpload} />
        )}
      </div>

      {component}

      <ErrorMessage>{errorMessage}</ErrorMessage>

      <div className="mt-2 flex flex-row justify-end items-center">
        {message}
        <Button type="submit" loading={loading}>
          Save
        </Button>
      </div>
    </Form>
  );
}

function EmptyPicture({ onUploadClick }) {
  return (
    <div className="flex flex-row items-center p-0 px-10">
      <div
        onClick={onUploadClick}
        className="p-5 w-20 h-20 bg-white rounded-full shadow-card-cream-sm flex justify-center items-center cursor-pointer"
      >
        <svg
          width="60"
          height="60"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.9694 6.18652H3.28228C1.47702 6.18652 0 7.66355 0 9.4688V20.5301C0 22.3353 1.47702 23.8123 3.28228 23.8123H16.9694C18.7746 23.8123 20.2516 22.3353 20.2516 20.5301V9.4688C20.2516 7.63073 18.7746 6.18652 16.9694 6.18652Z"
            fill="#F8B045"
          />
          <path
            d="M27.5711 7.99232C27.3742 8.02514 27.1773 8.12361 27.0132 8.22208L21.8928 11.1761V18.791L27.046 21.7451C27.9978 22.3031 29.1794 21.9748 29.7374 21.023C29.9015 20.7276 30 20.3993 30 20.0383V9.89604C30 8.6816 28.8512 7.69692 27.5711 7.99232Z"
            fill="#F8B045"
          />
        </svg>
      </div>

      <div className="mt-5 ml-5">
        <h3 className="text-sm">Upload your intro video</h3>
        <p className="text-sm text-gray-400 mt-3">
          Students have 90% higher chance of booking classes with intro videos
        </p>
      </div>
    </div>
  );
}
