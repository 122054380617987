import LiveClassesGraphic from "../../Graphics/LiveClassesGraphic";
import useIsMobile from "../../Hooks/useMobile";
import cx from "classnames";
import Button from "../../Atoms/Button/Button";
import "./style.css";
import { useEffect } from "react";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function TeacherStatic() {
  const isMobile = useIsMobile();

  const points = [
    {
      title: "Get more students to join your yoga classes",
      values: [
        "Let people discover your classes on Do Yoga.",
        "Get your own website where students can join your yoga classes.",
        "Manage student lead pipeline.",
      ],
    },
    {
      title: "Automate all class operations",
      values: [
        "Set up all kinds of classes - monthly courses, workshops, retreats, teacher training courses.",
        "Automate post sign up flows - collecting student info, sending class links, post class feedback.",
        "Track attendance",
      ],
    },
    {
      title: "Payments & Reminders",
      values: [
        "Set up online payments across all payment instruments.",
        "Accept international payments with geo fenced pricing.",
        "Automate subscription reminders and keep renewals high.",
      ],
    },
  ];

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Teacher Sign up</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Teacher Sign up"></meta>
        <meta name="description" content="Teacher Sign up" />
        <meta property="og:title" content="Teacher Sign up"></meta>
        <meta property="twitter:title" content="Teacher Sign up"></meta>
        <meta property="og:description" content="Teacher Sign up"></meta>
        <meta property="twitter:description" content="Teacher Sign up"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div
        className={cx(
          "flex flex-row justify-between items-center overflow-x-auto",
          " sm:h-full sm:w-4/5"
        )}
      >
        <div className="mt-5 text-sm sm:text-lg text-y-yellow-dark font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
          <p className="mt-0">
            You teach the yoga.
            <br /> We will do everything else.
          </p>
          <div class="mt-5 md:pl-0 block flex flex-row pb-3 relative text-gray-600 w-full"></div>
          <p>
            <div>
              All the technology needed to manage & grow your yoga teaching
              practice.
            </div>
          </p>

          <Button
            className="h-12 whitespace-nowrap	 md:whitespace-normal mt-5 w-full md:w-auto "
            link="/teacher-profile"
            requireLogin
          >
            Sign up
          </Button>
        </div>
        <div>
          <LiveClassesGraphic
            width={!isMobile ? 280 : 140}
            height={!isMobile ? 160 : 118}
          />
        </div>
      </div>

      <div className="mt-10">
        {points &&
          points.map((ele) => (
            <div className="w-full flex flex-col md:flex-row items-start	md:items-center justify-between pb-6">
              <div className="w-full md:w-2/5 text-y-yellow-dark font-bold text-sm">
                {ele.title}
              </div>
              <div className="w-full md:w-3/5 mt-5 md:mt-0 text-gray-600 text-sm">
                {ele.values.map((item) => (
                  <div className="text flex flex-row items-center">{item}</div>
                ))}
              </div>
            </div>
          ))}
      </div>

      <div
        className={cx(
          "mt-8 flex flex-row justify-between items-center overflow-x-auto",
          "h-40 sm:h-full sm:w-4/5"
        )}
      >
        <div className="text-sm sm:text-lg text-y-yellow-dark font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
          <p className="mt-0">Improve student experience significantly.</p>
          <div class="md:pl-0 mt-5 block flex flex-row pb-3 relative text-gray-600 w-full"></div>
          <p>
            <div>
              You talk to the students only about yoga. Automate all class
              operations & payments.
            </div>
          </p>

          <Button
            className="mt-5 w-full md:w-auto"
            link="/teacher-profile"
            requireLogin
          >
            Get Started!
          </Button>
        </div>
        <div></div>
      </div>

      <div className="pt-10">
        <div className="text-y-yellow-dark font-bold pb-5">Pricing</div>
        <div className="flex flex-col md:flex-row justify-between">
          <div>
            <div className="text-base text-gray-600 font-bold">
              List your existing online classes with us and get more students.
            </div>
            <div className="text-sm text-gray-600 pt-3">
              25% commission on student fees
            </div>
          </div>
          <div className="mt-5 md:mt-0">
            <div className="text-base text-gray-600 font-bold">
              Automate all class operations with Do Yoga for new & existing
              students
            </div>
            <div className="text-sm text-gray-600 pt-3">Rs.50 per student.</div>
          </div>
        </div>
        <div className="text-sm text-gray-600 pt-8 pb-7">
          Payment gateway charges as per actual.
        </div>

        <Button
          className="mt-5 w-full md:w-auto"
          link="/teacher-profile"
          requireLogin
        >
          Sign up now
        </Button>
      </div>
    </div>
  );
}
