import React from "react";
import { useHistory } from "react-router-dom";
import { CourseApi } from "../../../Api/CourseApi";
import StepsCard from "../../../Components/StepsCard";
import CreatedModal from "../CreatedModal";
import BasicInfoStep from "./BasicInfoStep";
import FeeStep from "./FeeStep";
import TimingsStep from "./TimingsStep";
import VideoStep from "./VideoStep";
import Description from "./Description";

export default function CourseForm({ isCourse, course }) {
  const [showCreated, setShowCreated] = React.useState(false);
  const history = useHistory();
  const isEdit = !!course;

  function onFinish(data) {
    const apiData = Object.values(data).reduce(
      (acc, d) => ({ ...acc, ...d }),
      {}
    );

    if (!isCourse) {
      apiData.recurring_class = apiData.recurring_class === "1";
    }

    if (isEdit) {
      return onEdit(apiData);
    } else {
      return onCreate(apiData);
    }
  }

  function onEdit(data) {
    data.type = course.type;

    return CourseApi.update(course.id, data).then((response) => {
      setShowCreated(true);

      return response;
    });
  }

  function onCreate(data) {
    data.type = isCourse ? "course" : "regular-class";

    return CourseApi.create(data).then((response) => {
      setShowCreated(true);

      return response;
    });
  }

  function onModalClose() {
    setShowCreated(false);
    setTimeout(() => {
      history.push("/studio/setup-class");
    }, 300);
  }

  return (
    <div>
      <div className="max-w-lg px-7 sm:px-0">
        {isEdit ? (
          <div>
            <span
              className="mr-2"
              onClick={() => history.push("/studio/classes")}
            >
              My Classes
            </span>
            <span className="mr-2 text-gray-400">{">"}</span>
            <span className="text-y-gray">{course.title}</span>
          </div>
        ) : (
          <div>
            <span
              className="mr-2"
              onClick={() => history.push("/studio/setup-class")}
            >
              Setup Class
            </span>
            <span className="mr-2 text-gray-400">{">"}</span>
            <span className="text-y-gray">
              {isCourse ? "Course" : "Regular Yoga Class"}
            </span>
          </div>
        )}
      </div>
      <div className="w-full  mt-5">
        <StepsCard
          initialData={prepareInitialData(course)}
          onFinish={onFinish}
          steps={[
            {
              title: "Basic info",
              formFields: ["title", "level", "style"],
              props: {
                isCourse,
              },
              component: BasicInfoStep,
            },
            {
              title: "Description",
              formFields: ["description"],
              props: {
                isCourse,
              },
              component: Description,
            },
            {
              title: "Timings",
              formFields: [
                "start_time",
                "duration",
                "start_date",
                "end_date",
                "recurring_class",
                "join_after_start",
                "repeat_type",
                "repeat_day",
                "repeat_days",
              ].filter((o) => o),
              props: {
                isCourse,
              },
              component: TimingsStep,
            },
            {
              title: "Fees",
              formFields: [
                "period_fee_india",
                "period_fee_intl",
                "single_fee_india",
                "single_fee_intl",
                "no_drop_in",
              ].filter((o) => o),
              props: {
                isCourse,
              },
              component: FeeStep,
            },
            {
              title: "Video",
              props: {
                isCourse,
                isEdit,
              },
              formFields: ["video_platform", "video_link", "video_password"],
              component: VideoStep,
            },
          ]}
        />
      </div>

      <CreatedModal show={showCreated} onClose={onModalClose} />
    </div>
  );
}

function prepareInitialData(course) {
  if (!course) {
    return {};
  }

  return {
    0: {
      title: course.title,
      level: course.level,
      style: course.style,
      images: course.images,
      videos: course.videos,
    },
    1: {
      description: course.description,
    },
    2: {
      start_time: course.start_time,
      duration: course.duration,
      start_date: course.start_date,
      end_date: course.end_date,
      recurring_class: course.recurring_class ? "1" : "0",
      join_after_start: course.join_after_start,
      repeat_type: course.repeat_type,
      repeat_day: course.repeat_type === "none" ? course.repeat_value : null,
      repeat_days:
        course.repeat_type === "custom"
          ? (course.repeat_value || "").split(",").filter((o) => o)
          : null,
    },
    3: {
      period_fee_india: course.period_fee_india,
      period_fee_intl: course.period_fee_intl,
      single_fee_india: course.single_fee_india,
      single_fee_intl: course.single_fee_intl,
      no_drop_in: course.no_drop_in,
    },
    4: {
      video_platform: course.video_platform,
      video_link: course.video_link,
      video_password: course.video_password,
      meeting_id: course.meeting_id,
    },
  };
}
