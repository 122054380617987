import React, { useRef, useState } from "react";
import { StorefrontApi } from "../../Api/StorefrontApi";
import CourseCard from "../../Atoms/CourseCard";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import useIsMobile from "../../Hooks/useMobile";
import Vent from "../../vent";
import Slider from "react-slick";
import Loader from "../../Atoms/Loader";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import CourseAction from "../../Actions/course";
import CourseSelectors from "../../Selectors/course";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import cx from "classnames";

export default function MonthlyClasses({
  filterArr,
  name,
  typeData,
  showTime = false,
}) {
  const { callApi, data, loading } = useApi(StorefrontApi.filterCourses);
  const { callApi: callFixedApi } = useApi(StorefrontApi.fixedCourses);
  const dispatch = useDispatch();

  const currentVal = useSelector(CourseSelectors.selectedTime);

  const monthlyData = useSelector(CourseSelectors.monthlyData);
  const beginnerData = useSelector(CourseSelectors.beginnerData);
  const kidsData = useSelector(CourseSelectors.kidsData);
  const advancedData = useSelector(CourseSelectors.advancedData);
  const weightLossData = useSelector(CourseSelectors.weightLossData);
  const pregnancyData = useSelector(CourseSelectors.pregnancyData);
  const termData = useSelector(CourseSelectors.termData);
  const pcosData = useSelector(CourseSelectors.pcosData);
  const raData = useSelector(CourseSelectors.raData);

  const indexData = useSelector(CourseSelectors.indexData);

  const teacherRatingData = useSelector(CourseSelectors.streamDatas);

  let courseData = [];
  let index = 0;

  if (typeData === "month") {
    courseData = monthlyData;
    index = indexData.monthly;
  } else if (typeData === "beginner") {
    courseData = beginnerData;
    index = indexData.beginner;
  } else if (typeData === "kids") {
    courseData = kidsData;
    index = indexData.kids;
  } else if (typeData === "advanced") {
    courseData = advancedData;
    index = indexData.advanced;
  } else if (typeData === "weight") {
    courseData = weightLossData;
    index = indexData.weightLoss;
  } else if (typeData === "pregnancy") {
    courseData = pregnancyData;
    index = indexData.pregnancy;
  } else if (typeData === "pcos") {
    courseData = pcosData;
  } else if (typeData === "ra") {
    courseData = raData;
  } else {
    courseData = termData;
    index = indexData.term;
  }

  React.useEffect(() => {
    if (typeData === "month" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setMonthlyCourse(res.data.data));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "beginner" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setBeginnersCourse(res.data.data));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "kids" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setKidsCourse(res.data.data));
          callApi({ ...filterArr, levels: [], queryText: "kid" })
            .then((response) => {
              let newArr = [],
                arr = res.data.data || [];
              response.data.data.map((ele) => {
                const findData = arr.find((item) => item.id === ele.id);
                if (!findData) {
                  newArr.push(ele);
                }
              });
              dispatch(
                CourseAction.setKidsCourse([...res.data.data, ...newArr])
              );
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "advanced" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setAdvancedCourse(res.data.data));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "weight" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setWeightLossCourse(res.data.data));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "pregnancy" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setPregnancyCourse(res.data.data));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "pcos" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setPCOSCourse(res.data.data));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "ra" && courseData.length === 0) {
      callApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setRACourse(res.data.data));
        })
        .catch((err) => console.log(err));
    } else if (typeData === "term" && courseData.length === 0) {
      callFixedApi(filterArr)
        .then((res) => {
          dispatch(CourseAction.setTermCourse(res.data.data));
        })
        .catch((err) => console.log(err));
    }
  }, [callApi, callFixedApi]);

  let courses =
    courseData.map((ele) => ({
      ...ele,
      teacherRating:
        teacherRatingData[ele.teacher?.id] &&
        teacherRatingData[ele.teacher?.id].avgReviewsCount
          ? parseInt(teacherRatingData[ele.teacher?.id].avgReviewsCount)
          : 0,
      teacherCount:
        teacherRatingData[ele.teacher?.id] &&
        teacherRatingData[ele.teacher?.id].total
          ? parseInt(teacherRatingData[ele.teacher?.id].total)
          : 0,
    })) || [];
  //let courses = dataApp || []

  let timeList = [];

  if (showTime) {
    let newData = courses.map((ele) => {
      let date = `Wed Dec 07 2022 ${ele.start_time} GMT+0530 (India Standard Time)`;
      let newDate = new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone: "Asia/Kolkata",
        })
      );

      return {
        ...ele,
        start_time_list: dayjs(newDate).format("h:mm a"),
        start_time_hour: parseInt(dayjs(newDate).format("H")),
        start_time_minute: dayjs(newDate).format("mm"),
      };
    });

    let obj = {};

    let newData1 = newData.map((ele) => {
      if (!obj[ele?.start_time_list]) {
        obj[ele?.start_time_list] = ele?.start_time_hour;
      }
    });

    const sortable = Object.fromEntries(
      Object.entries(obj).sort(([, a], [, b]) => a - b)
    );

    let newListArr = Object.keys(obj)
      .filter((ele) => {
        const newVal = ele.split(":");
        if (newVal[1][0] == 0 && newVal[1][1] == 0) {
          return ele;
        }
      })
      .map((item) => ({ name: item, value: obj[item] }))
      .sort((a, b) => a.value - b.value);

    courses = [...newData];
    timeList = [...newListArr];
  }

  function onCourseDetails(course) {
    Vent.onClassDetails(Vent.TYPE_MONTHLY_CLASS, course.title);
  }

  const isMobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: index,
    beforeChange: (current, next) => {
      let key = "";
      if (typeData === "month") {
        key = "monthly";
      } else if (typeData === "beginner") {
        key = "beginner";
      } else if (typeData === "kids") {
        key = "kids";
      } else if (typeData === "advanced") {
        key = "advanced";
      } else if (typeData === "weight") {
        key = "weightLoss";
      } else if (typeData === "pregnancy") {
        key = "pregnancy";
      } else if (typeData === "pcos") {
        key = "pcos";
      } else if (typeData === "ra") {
        key = "ra";
      } else {
        key = "term";
      }

      dispatch(CourseAction.setIndex(key, next));
    },
  };

  const slider = useRef(null);

  const settingsTime = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
  };

  const settingsTime1 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 13,
    slidesToScroll: 13,
    initialSlide: 0,
  };

  const dataSettings =
    document.body.clientWidth > 768 ? settingsTime1 : settingsTime;

  if (showTime) {
    return (
      <>
        <div className="pb-3 px-5">
          <Slider {...dataSettings}>
            {timeList &&
              timeList.map((d) => (
                <div
                  key={d.name}
                  onClick={() => {
                    dispatch(CourseAction.setSelectedTime(d.value));
                    if (slider.current) {
                      slider.current.slickGoTo(0);
                    }
                    dispatch(CourseAction.setIndex("monthly", 0));
                  }}
                  className={cx(
                    "mr-1 whitespace-nowrap rounded-full px-2 py-1 cursor-pointer",
                    d?.value === (currentVal ? currentVal : timeList[0]?.value)
                      ? "bg-y-yellow text-white"
                      : "text-y-yellow"
                  )}
                >
                  {d.name}
                </div>
              ))}
          </Slider>
        </div>

        {isMobile ? (
          <>
            {loading ? (
              <Loader />
            ) : (
              <div className="flex flex-nowrap w-full overflow-auto px-0">
                {name === "fixed" ? (
                  <>
                    {courses &&
                      courses
                        .filter(
                          (ele) =>
                            ele?.start_time_hour >=
                            (currentVal ? currentVal : timeList[0]?.value)
                        )
                        .filter((ele) => ele.type === "course")
                        .sort((a, b) => (a.start_time > b.start_time ? 1 : -1))
                        .map((c) => (
                          <div style={{ paddingRight: 90 }}>
                            <CourseCard
                              link={`/fixed-courses/${c.slug}-${c.id}`}
                              course={c}
                              onDetails={() => {
                                onCourseDetails(c);
                                ReactGA.event({
                                  category: "Courses Details",
                                  action: "Routing",
                                  label: JSON.stringify(c),
                                });
                                ReactPixel.track("track", {
                                  category: "Courses Details",
                                  action: "Routing",
                                  label: JSON.stringify(c),
                                });
                              }}
                              homeComponent={true}
                              currentVal={currentVal}
                            />
                          </div>
                        ))}
                  </>
                ) : (
                  <>
                    {courses &&
                      courses
                        .filter(
                          (ele) =>
                            ele?.start_time_hour >=
                            (currentVal ? currentVal : timeList[0]?.value)
                        )
                        .filter((ele) => ele.type === "regular-class")
                        .sort((a, b) => {
                          if (a.start_time > b.start_time) {
                            return 1;
                          }
                          if (a.start_time === b.start_time) {
                            if (a.top_rated || b.top_rated) {
                              return (a.top_rated || "")?.length >
                                (b.top_rated || "").length
                                ? -1
                                : 1;
                            }
                            return a.teacherCount > b.teacherCount ? -1 : 1;
                          }
                          return -1;
                        })
                        .map((c) => (
                          <div style={{ paddingRight: 90 }}>
                            <CourseCard
                              link={`/monthly-courses/${c.slug}-${c.id}`}
                              course={c}
                              onDetails={() => {
                                onCourseDetails(c);
                                ReactGA.event({
                                  category: "Courses Details",
                                  action: "Routing",
                                  label: JSON.stringify(c),
                                });
                                ReactPixel.track("track", {
                                  category: "Courses Details",
                                  action: "Routing",
                                  label: JSON.stringify(c),
                                });
                              }}
                              homeComponent={true}
                              currentVal={currentVal}
                            />
                          </div>
                        ))}
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {loading ? (
              <div className="flex flex-row justify-between items-center">
                {[1, 2, 3].map((ele) => (
                  <Loader />
                ))}
              </div>
            ) : (
              <div className={`${courses.length > 3 ? "px-0" : "px-0"}`}>
                {name === "fixed" ? (
                  <Slider ref={slider} {...settings}>
                    {courses &&
                      courses
                        .filter(
                          (ele) =>
                            ele?.start_time_hour >=
                            (currentVal ? currentVal : timeList[0]?.value)
                        )
                        .filter((ele) => ele.type === "course")
                        .sort((a, b) => (a.start_time > b.start_time ? 1 : -1))
                        .map((c) => (
                          <CourseCard
                            link={`/fixed-courses/${c.slug}-${c.id}`}
                            course={c}
                            onDetails={() => onCourseDetails(c)}
                            homeDesktop={true}
                            currentVal={currentVal}
                          />
                        ))}
                  </Slider>
                ) : (
                  <Slider ref={slider} {...settings}>
                    {courses &&
                      courses
                        .filter(
                          (ele) =>
                            ele?.start_time_hour >=
                            (currentVal ? currentVal : timeList[0]?.value)
                        )
                        .filter((ele) => ele.type === "regular-class")
                        .sort((a, b) => {
                          if (a.start_time > b.start_time) {
                            return 1;
                          }

                          if (a.start_time === b.start_time) {
                            if (a.top_rated || b.top_rated) {
                              return (a.top_rated || "")?.length >
                                (b.top_rated || "").length
                                ? -1
                                : 1;
                            }
                            return a.teacherCount > b.teacherCount ? -1 : 1;
                          }
                          return -1;
                        })
                        .map((c) => (
                          <CourseCard
                            link={`/monthly-courses/${c.slug}-${c.id}`}
                            course={c}
                            onDetails={() => onCourseDetails(c)}
                            homeDesktop={true}
                            currentVal={currentVal}
                          />
                        ))}
                  </Slider>
                )}
              </div>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className="flex flex-nowrap w-full overflow-auto px-0">
              {name === "fixed" ? (
                <>
                  {courses.map((c) => (
                    <div style={{ paddingRight: 90 }}>
                      <CourseCard
                        link={`/fixed-courses/${c.slug}-${c.id}`}
                        course={c}
                        onDetails={() => {
                          onCourseDetails(c);
                          ReactGA.event({
                            category: "Courses Details",
                            action: "Routing",
                            label: JSON.stringify(c),
                          });
                          ReactPixel.track("track", {
                            category: "Courses Details",
                            action: "Routing",
                            label: JSON.stringify(c),
                          });
                        }}
                        homeComponent={true}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {courses
                    .sort((a, b) => {
                      if (a.start_time > b.start_time) {
                        return 1;
                      }
                      if (a.start_time === b.start_time) {
                        if (a.top_rated || b.top_rated) {
                          return (a.top_rated || "")?.length >
                            (b.top_rated || "").length
                            ? -1
                            : 1;
                        }
                        return a.teacherCount > b.teacherCount ? -1 : 1;
                      }
                      return -1;
                    })
                    .map((c) => (
                      <div style={{ paddingRight: 90 }}>
                        <CourseCard
                          link={`/monthly-courses/${c.slug}-${c.id}`}
                          course={c}
                          onDetails={() => {
                            onCourseDetails(c);
                            ReactGA.event({
                              category: "Courses Details",
                              action: "Routing",
                              label: JSON.stringify(c),
                            });
                            ReactPixel.track("track", {
                              category: "Courses Details",
                              action: "Routing",
                              label: JSON.stringify(c),
                            });
                          }}
                          homeComponent={true}
                        />
                      </div>
                    ))}
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <div className="flex flex-row justify-between items-center">
              {[1, 2, 3].map((ele) => (
                <Loader />
              ))}
            </div>
          ) : (
            <div className={`${courses.length > 3 ? "px-0" : "px-0"}`}>
              {name === "fixed" ? (
                <Slider {...settings}>
                  {courses.map((c) => (
                    <CourseCard
                      link={`/fixed-courses/${c.slug}-${c.id}`}
                      course={c}
                      onDetails={() => onCourseDetails(c)}
                      homeDesktop={true}
                    />
                  ))}
                </Slider>
              ) : (
                <Slider {...settings}>
                  {courses
                    .sort((a, b) => {
                      if (a.start_time > b.start_time) {
                        return 1;
                      }
                      if (a.start_time === b.start_time) {
                        if (a.top_rated || b.top_rated) {
                          return (a.top_rated || "")?.length >
                            (b.top_rated || "").length
                            ? -1
                            : 1;
                        }
                        return a.teacherCount > b.teacherCount ? -1 : 1;
                      }
                      return -1;
                    })
                    .map((c) => (
                      <CourseCard
                        link={`/monthly-courses/${c.slug}-${c.id}`}
                        course={c}
                        onDetails={() => onCourseDetails(c)}
                        homeDesktop={true}
                      />
                    ))}
                </Slider>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
