import { put, get, post } from "./api";

function uploadProfilePicture(data) {
  return post("/users/me/profile/picture/", data, null, {
    "Content-Type": "multipart/form-data",
  });
}

export const UserApi = {
  // teacher
  uploadProfilePicture,
  teacherProfile: () => get("/users/teacher-profile/"),
  teacherByDomain: (domain) => get(`/users/by-domain/${domain}`),
  teacherByDomainUpcoming: (domain) =>
    get(`/users/bookings/upcoming/by-domain/${domain}`),
  updateTeacherProfile: (data) => put("/users/teacher-profile/", data),

  // user
  getUserMe: () => get("/users/me/"),
  getUpcomingClasses: () => get("/users/bookings/upcoming/"),
  getCompletedClasses: () => get("/users/bookings/completed/"),
  updateProfile: (data) => post("/users/profile/", data),

  teacherSettlement: (data) => post("/bookings/settlement/", data),
  teacherRating: () => post("/bookings/stream-count/"),

  createTherapyUser: (data) => post(`/therapy/`, data),
  createDietUser: (data) => post(`/therapy/aahara/`, data),
};
