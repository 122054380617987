import React from "react";

const NewLogo = ({ width = 155, height = 108, className = "", id = 1 }) => {
  const shadowWidth = (width / 498) * 419;
  const shadowHeight = (height / 456) * 73;

  return (
    <div className={className}>
      <div className="flex flex-col items-center">
        <svg
          width={width}
          height={height}
          viewBox="0 0 87 80"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M45.5193 0.0145264C45.5193 0.0145264 49.8766 -0.442408 51.4455 3.7652C53.0169 7.97257 54.4949 9.34993 54.6791 11.5603C55.4275 20.5003 59.6029 18.4781 58.4279 23.6315C57.2529 28.7849 56.4416 29.5643 56.4416 29.5643L46.4611 27.6074L36.3501 27.6402C36.3501 27.6402 40.304 25.6647 39.4251 21.4573C38.5439 17.2497 36.3501 10.3536 37.588 5.65684C38.8283 0.960354 42.9152 0.372854 45.5193 0.0145264Z"
            fill="#16202E"
          />
          <path
            d="M77.7456 78.6796C73.1482 78.7677 62.5825 79.7408 58.0271 78.0609C54.7096 76.8358 46.9765 74.6016 41.358 73.4448C39.2668 73.0152 37.4693 72.7347 36.299 72.7107C33.2286 72.6477 30.0813 69.8986 27.8378 68.0391C26.9283 67.2863 26.1681 66.6795 25.6193 66.4562C25.084 66.2379 24.6823 65.9759 24.4329 65.7127C24.2492 65.5182 24.1485 65.3224 24.1384 65.1427C24.1151 64.7004 24.6429 64.3542 25.8401 64.3542C26.1737 64.3542 26.5162 64.3696 26.8621 64.3955C28.9506 64.5519 31.177 65.0998 32.4289 65.062C33.8884 65.0175 37.8656 68.5108 38.6629 68.687C38.9986 68.7621 39.9988 69.0603 41.2904 69.1551C43.0365 69.2838 45.3119 69.039 47.1956 67.3605C48.1795 66.4832 49.5899 65.4544 51.082 64.4437C54.5161 62.1201 58.3814 59.8951 58.4677 59.8452C58.4677 59.8447 58.47 59.8447 58.47 59.8447C58.47 59.8447 58.47 59.8463 58.47 59.8491C58.47 59.8505 58.4724 59.8515 58.4724 59.8524C58.4724 59.854 58.4724 59.8554 58.4724 59.8566C58.4747 59.8612 58.477 59.8671 58.4793 59.8743C58.7451 60.6001 59.2697 61.4456 59.8922 61.9793C60.8014 62.7626 61.3609 62.7512 64.9069 62.923C65.5993 62.9568 66.3406 62.9941 67.103 63.0405C70.4135 63.2415 74.1227 63.6182 75.8012 64.664C76.5915 65.156 77.4705 65.7456 78.3401 66.3916C78.3401 66.3916 78.3401 66.3916 78.3401 66.3923C80.5828 68.0568 82.7626 70.0923 83.1962 71.7728C83.2429 71.9572 83.2708 72.1376 83.2732 72.3127C83.3175 74.7002 82.3453 78.5913 77.7456 78.6796Z"
            fill="#DBDFF0"
          />
          <path
            d="M58.4885 59.8796C58.5328 59.9992 58.703 60.4566 59.0364 60.9746C60.1601 62.7259 61.3234 62.7138 62.6243 62.801L50.2892 69.4416L34.1516 69.0469L27.8376 68.039L26.6356 67.8472C26.6356 67.8472 23.4997 65.6648 22.7905 65.2079C23.2682 65.1906 23.7212 65.1701 24.1383 65.1426C24.115 64.7003 24.6428 64.3541 25.8399 64.3541C26.1735 64.3541 26.516 64.3695 26.862 64.3954C28.0027 63.2274 31.3657 60.2743 31.3657 60.2743L58.4792 59.8425L58.4885 59.8796Z"
            fill="#DBDFF0"
          />
          <path
            d="M67.1028 63.0406C62.4961 64.3125 58.647 67.803 58.647 67.803L52.877 68.8421L62.3142 62.7785C62.4121 62.7867 62.5171 62.7939 62.6243 62.8011C63.2678 62.845 64.0464 62.8813 64.9067 62.9231C65.5991 62.9569 66.3405 62.9942 67.1028 63.0406Z"
            fill="#B6BAC7"
          />
          <path
            d="M46.9043 71.5277C45.3517 72.0814 43.4167 72.7432 41.3581 73.4445C36.2012 75.2005 30.2586 77.1952 27.5647 78.3259C26.1475 78.9201 24.1518 79.2652 21.9804 79.4367C21.972 79.4374 21.9634 79.4381 21.9555 79.4393C21.2843 79.4913 20.5963 79.5277 19.9041 79.5491C19.8612 79.5507 19.8172 79.5521 19.7733 79.5528C16.8974 79.637 13.9536 79.4778 11.7941 79.2374C11.788 79.2365 11.7829 79.236 11.7764 79.2351C10.3025 79.0705 9.19557 78.8679 8.72954 78.6793C7.37316 78.1305 6.32406 75.6947 6.06388 73.5778C5.9669 72.7957 5.9774 72.0566 6.12171 71.4724C6.44344 70.1559 8.0616 68.5848 10.0323 67.3923C10.0332 67.3916 10.0332 67.3916 10.0332 67.3916C11.304 66.6225 12.7227 66.0108 14.0355 65.725C15.8357 65.334 19.0627 65.3123 21.8065 65.2386C22.142 65.2291 22.4714 65.2191 22.7901 65.2076H22.7908C23.2683 65.1906 23.7217 65.1701 24.1381 65.1431C25.3352 65.0659 26.2335 64.939 26.552 64.698C26.5671 64.6873 26.5806 64.6758 26.5921 64.6642L26.666 64.6912C26.666 64.6912 26.666 64.6912 26.6669 64.6912L27.7601 65.0953L34.8606 67.7147C37.3762 69.7048 40.288 69.2492 42.0808 68.8872C42.4468 68.8126 42.7662 68.7426 43.0273 68.6979C43.5892 68.6018 44.0485 68.4878 44.4238 68.3561L46.9043 71.5277Z"
            fill="#DBDFF0"
          />
          <path
            d="M53.5508 68.7688C53.5508 68.7688 65.2774 66.6531 68.0191 67.8473C70.7607 69.0409 72.7937 71.7821 75.491 71.517C78.1884 71.2515 75.526 70.0201 73.6027 69.5083C71.6793 68.9968 70.1802 66.6755 65.0933 66.8413C60.0063 67.007 56.5908 67.8694 56.5908 67.8694C56.5908 67.8694 54.3131 68.4637 53.5508 68.7688Z"
            fill="#C6CAD9"
          />
          <path
            d="M29.228 68.1499L25.0621 67.4549C25.0621 67.4549 23.2975 65.4593 21.8062 65.2385C22.1421 65.229 22.4718 65.2192 22.79 65.208H22.7907C23.2684 65.1907 23.7214 65.1702 24.1384 65.1427C25.3346 65.066 26.2334 64.9392 26.5516 64.6979C26.5908 64.6958 26.6285 64.6939 26.6658 64.6914C26.6658 64.6914 26.6658 64.6914 26.6663 64.6914C27.1943 64.6608 27.5427 64.6429 27.5427 64.6429L27.7602 65.0951L29.228 68.1499Z"
            fill="#DBDFF0"
          />
          <path
            d="M42.6424 68.9758C41.1597 69.2844 38.8097 69.4807 35.2124 69.1292C27.5204 68.3775 23.7182 67.12 19.6948 68.7214C15.6711 70.323 16.2017 70.7651 14.1681 70.7651C12.134 70.7651 15.4501 68.2449 19.7829 67.4933C24.1159 66.7416 34.8604 68.8198 36.0983 68.8198C37.1078 68.8198 40.4859 69.1334 43.1926 68.6174L42.6424 68.9758Z"
            fill="#C6CAD9"
          />
          <path
            d="M83.196 71.7728C82.8044 71.7523 82.2728 71.6329 81.627 71.2629C80.2003 70.4469 78.3678 66.4545 78.3398 66.3923C80.5826 68.0568 82.7624 70.0923 83.196 71.7728Z"
            fill="#B6BAC7"
          />
          <path
            d="M10.0321 67.3924C10.0076 67.4632 8.40299 71.987 6.14322 73.5254C6.11594 73.5443 6.08959 73.5616 6.06371 73.5774C5.9665 72.7957 5.97815 72.0567 6.12129 71.4725C6.44348 70.1559 8.06143 68.5849 10.0321 67.3924Z"
            fill="#B6BAC7"
          />
          <path
            d="M48.2607 73.2653C48.2607 73.2653 35.7251 78.4745 21.9799 79.4369C21.9715 79.4376 21.9629 79.4383 21.955 79.4394C21.274 79.4861 20.5898 79.5238 19.9036 79.5492C19.8607 79.5509 19.8167 79.5523 19.7728 79.553C17.1086 79.6472 14.4219 79.5639 11.7936 79.2375C11.7875 79.2366 11.7824 79.2361 11.7759 79.2352C11.7759 79.2352 34.2423 71.1615 42.0803 68.8873C43.1621 68.5733 43.964 68.3702 44.3907 68.3138L44.4233 68.3562L46.9038 71.5278L48.2607 73.2653Z"
            fill="#DBDFF0"
          />
          <path
            d="M43.0271 68.6983C46.0789 68.1759 46.0206 67.1237 47.1163 65.6648C48.0582 64.4073 49.021 64.542 51.0819 64.4439C51.413 64.428 51.7371 64.4208 52.0471 64.4208C52.3362 64.4208 52.6346 64.3956 52.9423 64.3478C55.0429 64.0249 57.4675 62.676 58.47 61.4031C58.6052 61.2317 58.7987 61.0902 59.0365 60.9748C59.251 60.8699 59.4981 60.7872 59.7732 60.7221C59.8338 60.7077 59.8944 60.6934 59.9574 60.6811C61.7875 60.3064 62.7526 60.4841 63.6945 60.5491C64.9767 60.6373 67.1356 62.3648 65.499 62.7627C65.3125 62.8084 65.1143 62.8616 64.9068 62.9231C63.3215 63.3934 61.2536 64.3266 59.5307 65.8135C57.5864 67.4935 52.1474 69.1294 50.2893 70.1906C49.443 70.6741 45.6756 71.9753 41.3579 73.4449L43.0271 68.6983Z"
            fill="#A56651"
          />
          <path
            d="M76.1253 64.0764C76.1253 64.0764 65.147 55.7772 63.9977 53.7429C62.846 51.7091 57.8313 38.1688 57.2578 37.2403C57.2111 37.1654 57.1132 37.0922 56.9733 37.0218C55.409 36.2383 48.5665 35.8368 48.5665 35.8368L34.5948 37.2515C34.5948 37.2515 34.5668 37.3009 34.5178 37.3911C34.0702 38.222 31.7529 42.5194 31.2353 43.7953C30.6595 45.21 27.6968 52.947 26.9904 54.0968C26.2824 55.2466 11.3688 66.2823 11.0156 66.4592C10.6624 66.6366 7.84496 65.3651 8.41987 64.5694C8.99525 63.773 19.8712 52.7703 21.1977 51.4437C22.5245 50.1177 27.4763 38.9319 27.7859 37.6054C28.095 36.2793 29.0229 29.5147 31.4102 27.7013C32.3217 27.0103 34.1658 25.9894 36.1032 25.1616C36.1568 25.1387 36.2104 25.1154 36.264 25.093C37.7071 24.4871 39.085 24.0085 40.2553 23.5702C40.2949 23.5555 40.3345 23.5406 40.3742 23.5261C41.6634 23.0494 42.6426 22.7939 42.6426 22.7939L48.7111 22.3996C48.7111 22.3996 50.1518 22.6731 51.075 22.9361C51.096 22.9426 51.1194 22.9489 51.1427 22.9554C52.5345 23.3541 54.4205 23.912 55.2854 24.2524C55.3064 24.2608 55.3274 24.2684 55.3484 24.2773C55.4393 24.313 55.5349 24.3498 55.6375 24.389C57.2391 25.0091 60.2699 26.1627 60.6802 27.3924C61.1231 28.7183 62.4497 32.2111 62.804 34.7756C63.1561 37.3403 66.6041 46.0947 67.18 47.4651C67.7535 48.8352 69.3901 50.6926 70.3622 52.2842C71.3367 53.8758 77.8016 61.5802 78.4846 61.9784C79.1701 62.3759 76.1253 64.0764 76.1253 64.0764Z"
            fill="#A56651"
          />
          <path
            d="M27.3917 67.6731C27.3917 67.6731 26.3925 66.723 25.5304 66.4561C24.6682 66.1892 23.2909 64.8584 24.8559 64.4621C26.8208 63.9646 30.3933 65.2163 31.3655 65.1944C32.34 65.172 34.1118 65.6108 35.4803 66.5748C36.8488 67.539 38.5367 68.9397 39.6161 68.9131C39.6161 68.9131 39.5462 69.1735 38.8398 69.1514C38.1311 69.1292 27.7794 67.9915 27.7794 67.9915L27.3917 67.6731Z"
            fill="#A56651"
          />
          <path
            d="M83.2569 65.8081C82.8256 65.7251 81.8814 64.5149 81.8651 64.1831C81.8465 63.8516 83.4388 63.5033 83.6882 64.1831C83.9353 64.8629 83.2569 65.8081 83.2569 65.8081ZM86.9055 69.8534C86.8705 69.0412 83.8211 67.1675 84.369 66.7035C84.9145 66.2391 84.9821 64.548 84.9821 64.1335C84.9821 63.7189 84.4669 63.2548 83.954 62.7239C83.4388 62.1935 80.802 62.7573 80.2891 62.7239C79.7762 62.6908 78.4847 61.9779 78.4847 61.9779C78.4847 61.9779 75.5588 63.6341 75.8899 63.8681C76.2209 64.1022 79.1281 67.1178 79.9697 68.3618C80.8113 69.6058 81.8464 70.0026 83.0751 70.6824C84.3014 71.3622 84.2198 70.4502 84.2198 70.4502C84.3503 70.5997 85.0637 70.8484 85.0637 70.8484C86.0102 71.0307 85.91 70.1353 85.91 70.1353C85.91 70.1353 86.9381 70.6659 86.9055 69.8534Z"
            fill="#A56651"
          />
          <path
            d="M3.2171 66.7745C3.46609 66.0947 5.0577 66.4427 5.04138 66.7745C5.02436 67.106 4.07947 68.3164 3.6484 68.3992C3.6484 68.3992 2.96858 67.4543 3.2171 66.7745ZM0.995565 72.7269C0.995565 72.7269 0.896006 73.6221 1.84113 73.4398C1.84113 73.4398 2.55407 73.1908 2.68672 73.0418C2.68672 73.0418 2.60371 73.9536 3.8307 73.2738C5.05768 72.594 6.09329 72.1972 6.93537 70.9532C7.77698 69.7092 10.6842 66.6938 11.0157 66.4595C11.3467 66.2252 8.41973 64.5693 8.41973 64.5693C8.41973 64.5693 7.13002 65.2824 6.6162 65.3155C6.10214 65.3486 3.46608 64.7849 2.95225 65.3155C2.43796 65.8459 1.92414 66.3103 1.92414 66.7248C1.92414 67.1391 1.99034 68.8305 2.53751 69.2947C3.08467 69.7591 0.0338879 71.6325 0.000782828 72.445C-0.0323222 73.2575 0.995565 72.7269 0.995565 72.7269Z"
            fill="#A56651"
          />
          <path
            d="M46.0949 2.47529C46.0949 2.47529 51.8649 3.30804 51.0187 11.8258C50.1747 20.3439 45.0831 19.6678 44.7427 19.6605C44.4023 19.6533 39.6324 19.8377 39.2197 9.4439C39.2197 9.4439 39.0286 1.7577 46.0949 2.47529Z"
            fill="#A56651"
          />
          <path
            d="M48.6573 18.1496C48.6573 18.1496 48.3006 22.5803 49.1539 23.9078C50.0072 25.2355 48.4242 27.0369 45.1627 26.4207C41.9011 25.8043 42.0899 23.9551 42.0899 23.9551C42.0899 23.9551 43.0038 21.2209 42.7357 19.0322C42.4699 16.8436 48.6573 18.1496 48.6573 18.1496Z"
            fill="#A56651"
          />
          <path
            d="M45.4353 5.56224C45.4353 5.56224 48.1303 8.39669 49.3823 9.83163C50.6342 11.267 50.7787 14.0392 50.7787 14.0392C50.7787 14.0392 52.8886 11.4624 52.005 8.46173C51.1238 5.46106 49.0372 -0.376853 43.7521 1.41665C38.4693 3.21062 38.2734 11.1038 38.8936 15.0177C38.8936 15.0177 41.2645 6.37751 45.4353 5.56224Z"
            fill="#16202E"
          />
          <path
            d="M43.1597 19.2746C43.1597 19.2746 45.6192 20.4366 48.0112 18.72C48.0112 18.72 47.3468 20.0927 45.5866 20.2878C43.8241 20.483 43.1597 19.2746 43.1597 19.2746Z"
            fill="#633029"
          />
          <path
            d="M58.4792 59.8748C58.4792 59.8748 58.4746 59.8655 58.4723 59.8562C58.4723 59.8555 58.4723 59.8541 58.4723 59.8522C58.4723 59.8515 58.4723 59.8508 58.4723 59.8492C58.4699 59.8471 58.4699 59.8447 58.4699 59.8447C58.4699 59.8447 58.2018 59.8615 57.7192 59.8914C53.6277 60.1408 34.0841 61.2727 31.3657 60.2742C31.3657 60.2742 31.4403 60.0093 31.5709 59.6592C31.7294 59.2356 31.8926 58.8329 32.0535 58.4494C33.338 55.4268 34.7089 53.6014 35.2567 52.3059C35.4479 51.8596 35.6507 51.5808 35.7883 50.4923C36.1683 47.4515 36.11 42.6189 35.5225 40.5671C34.7275 37.7821 33.4896 35.9251 34.7718 32.2556C36.0541 28.5858 36.2802 25.2397 36.2802 25.2397L40.3857 23.6171C40.3857 23.6171 40.1222 33.4488 45.6918 33.6698C51.2637 33.8915 51.0749 22.9365 51.0749 22.9365L55.2853 24.2526C55.2853 24.2526 56.0383 30.0448 56.8776 31.5042C57.7169 32.9629 57.2763 36.8537 56.3041 39.1084C55.3296 41.363 54.7118 45.6079 54.7118 48.1283C54.7118 48.668 54.861 49.3721 55.0965 50.1629C55.9054 52.8733 57.7449 56.5969 58.1785 58.1764C58.2088 58.2865 58.2321 58.3855 58.2485 58.4737C58.3324 58.9409 58.386 59.2549 58.4233 59.4652C58.4793 59.807 58.4839 59.8748 58.4792 59.8748Z"
            fill="#FFA500"
          />
          <path
            d="M56.8659 37.4788L63.375 35.8273C63.2328 34.1284 61.5263 28.899 60.8338 27.2827C60.1624 25.7165 55.9474 24.3326 51.1425 22.9554C51.1075 24.873 50.6645 33.8682 45.6918 33.6703C40.7633 33.4747 40.395 25.3579 40.374 23.5262C40.3344 23.5406 40.2947 23.5555 40.2551 23.5702C34.3638 25.7771 31.2491 26.5105 29.8876 28.9505C28.4002 31.6189 27.5791 36.5458 27.5791 36.5458L34.5433 37.4823C34.8114 38.4789 35.2031 39.4488 35.5225 40.5676C36.11 42.6189 36.1006 47.9358 35.7206 50.9763C35.7206 50.9763 45.3887 47.0776 55.0964 50.1627C54.8586 49.3719 54.7117 48.6678 54.7117 48.1281C54.7117 45.6075 55.2619 41.3325 56.3041 39.1082C56.5302 38.623 56.719 38.0677 56.8659 37.4788Z"
            fill="#FFA500"
          />
          <path
            d="M58.4699 59.8447C58.4699 59.8447 58.2018 59.8615 57.7192 59.8913L31.3657 60.2741C31.3657 60.2741 31.4403 60.0093 31.5709 59.6591C31.7294 59.2355 31.8926 58.8329 32.0535 58.4494L58.1785 58.1764C58.2088 58.2864 58.2321 58.3855 58.2485 58.4736C58.3324 58.9408 58.386 59.2548 58.4233 59.4651C58.4793 59.8069 58.4792 59.8444 58.4792 59.8444L58.4699 59.8447Z"
            fill="#FFA500"
          />
          <path
            d="M42.3232 20.8195C42.3232 20.8195 34.3826 27.8833 36.4435 32.29C38.2037 36.0511 44.4377 33.7445 43.4096 29.8034C42.3815 25.8629 42.3232 20.8195 42.3232 20.8195Z"
            fill="#16202E"
          />
        </svg>

        <svg
          width={shadowWidth}
          height={shadowHeight}
          viewBox="0 0 419 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="landing-shadow-animate"
        >
          <ellipse
            cx="209.5"
            cy="36.5"
            rx="209.5"
            ry="36.5"
            fill={`url(#paint${id}0_radial)`}
          />
          <defs>
            <radialGradient
              id={`paint${id}0_radial`}
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="matrix(0 36.5 -209.5 0 209.5 36.5)"
            >
              <stop stopColor="#D6D6D6" />
              <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default NewLogo;
