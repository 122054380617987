import _ from "lodash";
import store from "../store";

const CourseSelectors = {
  liveCourse: (store) => store.course.liveData,
  monthlyData: (store) => store.course.monthlyData,
  personalData: (store) => store.course.personalData,
  beginnerData: (store) => store.course.beginnerData,
  kidsData: (store) => store.course.kidsData,
  weightLossData: (store) => store.course.weightLossData,
  pregnancyData: (store) => store.course.pregnancyData,
  advancedData: (store) => store.course.advancedData,
  termData: (store) => store.course.termData,
  subscriptionData: (store) => store.course.subscriptionData,
  bannersData: (store) => store.course.bannersData,
  blogData: (store) => store.course.blogData,
  reviewData: (store) => store.course.reviewData,
  indexData: (store) => store.course.indexData,
  pcosData: (store) => store.course.pcosData,
  raData: (store) => store.course.raData,
  viewAllData: (store) => store.course.viewAll,
  selectedTime: (store) => store.course.selectedTime,
  streamDatas: (store) => store.course.streamDatas,
  showLoading: (store) => store.course.showLoading,
};

export default CourseSelectors;
