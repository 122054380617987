import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import RefreshUserMe from "./Atoms/RefreshUserMe";
import ReduxOtpLoginModal from "./Components/ReduxLoginModal";
import ReduxProfileModal from "./Components/ReduxProfileModal";
import JoinClass from "./Pages/JoinClass";
import LandingBeta from "./Pages/LandingBeta/LandingBeta";
import StorefrontTemplate from "./Templates/StorefrontTemplate";
import StudioTemplate from "./Templates/StudioTemplate";
import LandBotPage from "./Pages/LandBotPage";
import FindYourYoga from "./Pages/LandBotPage/FindYourYoga";
import FindYourYoga3 from "./Pages/LandBotPage/FindYourYoga3";
import FindYourYogaWorld from "./Pages/LandBotPage/FindYourYogaWorld";
import PaymentBot from "./Pages/LandBotPage/PaymentBot";
import HealthForm from "./Pages/LandBotPage/HealthForm";
import Join50Bot from "./Pages/LandBotPage/Join50Bot";
import Plans from "./Pages/canvas-home";
import Settlement from "./Pages/settlement";
import Hindi from "./Pages/canvas-home/Hindi";
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "./Templates/StorefrontTemplate/NotFound";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import YogaNidra from "./Pages/YogaNidra";
import Terms from "./Pages/YogaNidra/Terms";
import Privacy from "./Pages/YogaNidra/Privacy";

ReactGA.initialize("UA-197703300-1");
ReactGA.pageview(window.location.pathname + window.location.search);

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};
ReactPixel.init("228481502032949", advancedMatching, options);

ReactPixel.pageView(); // For tracking page view

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route exact path="/findyouryoga">
          <LandBotPage />
        </Route>
        <Route exact path="/findyouryoga2">
          <FindYourYoga />
        </Route>
        <Route exact path="/findyouryoga3">
          <FindYourYoga3 />
        </Route>
        <Route exact path="/findyouryogaworld">
          <FindYourYogaWorld />
        </Route>
        <Route exact path="/joinyogaclass">
          <PaymentBot />
        </Route>
        <Route exact path="/health-form">
          <HealthForm />
        </Route>
        <Route exact path="/plans">
          <Plans />
        </Route>
        <Route exact path="/hindi">
          <Hindi />
        </Route>
        <Route exact path="/join50Bot">
          <Join50Bot />
        </Route>
        <Route exact path="/teacher">
          <LandingBeta />
        </Route>
        <Route path="/studio">
          <StudioTemplate />
        </Route>
        <Route path="/join/:id">
          <JoinClass />
        </Route>

        <Route exact path="/not-found">
          <NotFound />
        </Route>

        <Route exact path="/settlements/teacher">
          <Settlement />
        </Route>

        <Route exact path="/yoganidra">
          <YogaNidra />
        </Route>

        <Route exact path="/yoganidra/terms-conditions">
          <Terms />
        </Route>

        <Route exact path="/yoganidra/privacy-policy">
          <Privacy />
        </Route>

        <Route path="/">
          <StorefrontTemplate />
        </Route>

        <Route path="*">
          <Redirect to="/not-found" />
        </Route>
      </Switch>

      <ReduxOtpLoginModal />
      <ReduxProfileModal />
      <RefreshUserMe />
    </Router>
  );
}

export default App;
