import _ from 'lodash';
import React from 'react';

/**
 * Use API Hook
 *
 * @param fn
 */
export function useApi(fn) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});

  const setSuccessResponse = React.useCallback((response) => {
    setLoading(false);
    setError(null);
    setErrorMessage(null);
    setFormErrors({});
    setData(response);
  }, [setLoading, setError, setErrorMessage, setFormErrors, setData]);

  const callApi = React.useCallback((...args) => {
    if (!fn) {
      return Promise.resolve();
    }

    setLoading(true);
    return fn(...args)
      .then(response => {
        setFormErrors({});
        setError(null);
        setErrorMessage(null);

        setData(response.data);

        return response;
      })
      .catch(e => {
        if (!e || !e.response) {
          return Promise.reject(e);
        }

        const response = e.response;
        setError(response);

        setErrorMessage(_.get(response, 'data.message', 'Sorry, that did not work. Can you try again?'))

        // handle form errors
        setFormErrors({});
        const responseData = response.data || {};

        if (responseData.code === 'VALIDATION_ERROR') {
          if ((typeof responseData.data) === 'string') {
            setFormErrors({ form: responseData.data });

          } else if ((typeof responseData.data) === 'object') {
            setFormErrors(responseData.data);
          }

        } else {
          setFormErrors({ form: responseData.message || 'There was an error' })
        }

        return Promise.reject(response);
      })
      .finally(() => {
        setLoading(false);
      });

  }, [setLoading, setData, setFormErrors, fn, setErrorMessage]);

  return {
    callApi,
    loading,
    data,
    error,
    formErrors,
    errorMessage,
    setSuccessResponse,
  }
}
