import { useWindowWidth } from "@react-hook/window-size";

/**
 * Is current screen a mobile
 *
 * @return {boolean}
 */
export default function useIsMobile() {
  const width = useWindowWidth();

  return width < 640;
}
