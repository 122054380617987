import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Styles from "../../Constants/styles";
import UiAction from "../../Actions/ui";
import UserAction from "../../Actions/user";
import Dropdown from "../../Atoms/Dropdown";
import GlowingPicture from "../../Atoms/GlowingPicture";
import TextLink from "../../Atoms/TextLink/TextLink";
import Logo from "../../Graphics/Logo";
import { STOREFRONT_HOME_PAGE } from "../../Routes/storefrontRoutes";
import UserSelectors from "../../Selectors/user";
import UrlService from "../../Services/UrlService";
import Modal from "../../Atoms/Modal/Modal";
import cx from "classnames";
import SideMenu from "./SideBar";
import useIsMobile from "../../Hooks/useMobile";

const LinkModal = ({ show, onClose, history }) => {
  const data = [
    {
      name: "Levels",
      items: [
        {
          value: "beginner",
          label: "Beginner",
          checked: false,
          link: "/courses/beginner-courses",
        },
        {
          value: "intermediate",
          label: "Intermediate",
          checked: false,
          link: "/courses/intermediate",
        },
        {
          value: "advanced",
          label: "Advanced",
          checked: false,
          link: "/courses/advanced",
        },
      ],
    },
    {
      name: "Price",
      items: [
        {
          type: "to",
          minValue: "1000",
          maxValue: "2000",
          label: "1000 to 2000",
          value: "1000 to 2000",
          checked: false,
          link: "/courses/price-1000-to-2000",
        },
        {
          type: "to",
          minValue: "2000",
          maxValue: "3000",
          label: "2000 to 3000",
          value: "2000 to 3000",
          checked: false,
          link: "/courses/price-2000-to-3000",
        },
        {
          type: "less",
          minValue: "1000",
          label: "<1000",
          value: "<1000",
          checked: false,
          link: "/courses/price-less-than-1000",
        },
        {
          type: "plus",
          minValue: "3000",
          label: ">3000",
          value: ">3000",
          checked: false,
          link: "/courses/price-greater-than-3000",
        },
      ],
    },
    {
      name: "Health Conditions",
      items: [
        {
          value: "pregnancy",
          label: "Pregnancy",
          checked: false,
          link: "/courses/health/pregnancy",
        },
        {
          value: "diabetes",
          label: "Diabetes",
          checked: false,
          link: "/courses/health/diabetes",
        },
        {
          value: "PCOS",
          label: "PCOS",
          checked: false,
          link: "/courses/health/PCOS",
        },
        {
          value: "Blood Pressure",
          label: "Blood Pressure",
          checked: false,
          link: "/courses/health/Blood Pressure",
        },
        {
          value: "Back Pain",
          label: "Back Pain",
          checked: false,
          link: "/courses/health/Back Pain",
        },
        {
          value: "Hypertension",
          label: "Hypertension",
          checked: false,
          link: "/courses/health/:Hypertension",
        },
        {
          value: "Arthritis",
          label: "Arthritis",
          checked: false,
          link: "/courses/health/Arthritis",
        },
      ],
    },
    {
      name: "Style",
      items: [
        {
          value: "Hatha Yoga",
          label: "Hatha Yoga",
          checked: false,
          link: "/courses/style/Hatha Yoga",
        },
        {
          value: "Power Yoga",
          label: "Power Yoga",
          checked: false,
          link: "/courses/style/Power Yoga",
        },
        {
          value: "Ashtanga Yoga",
          label: "Ashtanga Yoga",
          checked: false,
          link: "/courses/style/Ashtanga Yoga",
        },
        {
          value: "Sivananda Yoga",
          label: "Sivananda Yoga",
          checked: false,
          link: "/courses/style/Sivananda Yoga",
        },
        {
          value: "Iyengar Yoga",
          label: "Iyengar Yoga",
          checked: false,
          link: "/courses/style/Iyengar Yoga",
        },
        {
          value: "Yin Yoga",
          label: "Yin Yoga",
          checked: false,
          link: "/courses/style/Yin Yoga",
        },
        {
          value: "Satyananda Yoga",
          label: "Satyananda Yoga",
          checked: false,
          link: "/courses/style/Satyananda Yoga",
        },
      ],
    },
    {
      name: "Time",
      items: [
        {
          start: "05:00",
          end: "07:55",
          label: "Early Morning(05:00AM - 07:00AM)",
          value: "Early Morning(05:00AM - 07:00AM)",
          checked: false,
          link: "/courses/early-morning",
        },
        {
          start: "08:00",
          end: "12:55",
          label: "Morning(08:00AM - 12:00PM)",
          value: "Morning(08:00AM - 12:00PM)",
          checked: false,
          link: "/courses/morning",
        },
        {
          start: "13:00",
          end: "16:55",
          label: "Afternoon(01:00PM - 04:00PM)",
          value: "Afternoon(01:00PM - 04:00PM)",
          checked: false,
          link: "/courses/afternoon",
        },
        {
          start: "17:00",
          end: "21:00",
          label: "Evening(05:00PM - 09:00PM)",
          value: "Evening(05:00PM - 09:00PM)",
          checked: false,
          link: "/courses/evening",
        },
      ],
    },
  ];

  const [index, setIndex] = useState({});

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      className="sm:w-full md:w-2/4"
    >
      <div className="p-5 px-8 flex">
        <div className="w-50">
          {data &&
            data.map((ele) => (
              <div
                className="flex items-center justify-between mb-4 pb-4 shadow-md rounded px-5 hover-cont"
                role="button"
                onMouseEnter={() => {
                  setIndex({ ...ele });
                }}
                style={{
                  background:
                    ele.name === index.name ? "rgb(212, 212, 212)" : "",
                }}
              >
                <div className="pt-3 pr-5">{ele.name}</div>
                <div style={{ transform: "rotate(-90deg)" }} className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="25px"
                    viewBox="0 0 50 50"
                    style={{ fill: "grey" }}
                  >
                    <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                  </svg>
                </div>
              </div>
            ))}
        </div>
        <div className="ml-10 w-full">
          {index &&
            index.items &&
            index.items.map((item) => (
              <div
                className="flex items-center justify-between mb-4 pb-4 shadow-md rounded px-5 hover-cont"
                role="button"
                onClick={() => {
                  onClose();
                  history.push(item.link);
                }}
              >
                <div className="pt-3 pr-5">{item.label}</div>
              </div>
            ))}
        </div>
      </div>
    </Modal>
  );
};

export default function StorefrontHeader() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(UserSelectors.user);
  const profilePicture = useSelector(UserSelectors.profilePicture);
  const isSubDomain = UrlService.getTeacherDomain();
  const [show, setShow] = useState(false);
  const isMobile = useIsMobile();

  function onLogout() {
    dispatch(UserAction.logout());
  }

  function onOtpLogin() {
    dispatch(UiAction.showOtpLogin());
  }

  function onProfileClick() {
    dispatch(UiAction.showProfile());
  }

  function onLogoClick() {
    if (isSubDomain) {
      // we goto main home page
      window.location.replace(UrlService.getRootUrl());
    } else {
      history.push(STOREFRONT_HOME_PAGE);
    }
  }

  const [menuExpanded, setMenuExpanded] = React.useState(false);

  function toggleMenu() {
    setMenuExpanded((m) => !m);
  }

  return (
    <>
      {isMobile && (
        <SideMenu
          expanded={menuExpanded}
          toggleExpanded={toggleMenu}
          setExpanded={setMenuExpanded}
        />
      )}
      <div className="flex flex-row justify-between w-full items-center p-0 pt-0 pb-0">
        <div className="block md:hidden">
          <svg
            onClick={toggleMenu}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="25px"
            height="25px"
            viewBox="0 0 50 50"
            style={{ fill: "rgb(229, 111, 44)" }}
          >
            <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
          </svg>
          {/* <svg
            onClick={toggleMenu}
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="#473F21"
              strokeWidth="1.5"
              strokeLinecap="round"
              d="M6.75 7.25h10.5M6.75 11.75h7.5M6.75 16.25h4.5"
            />
          </svg> */}
        </div>
        <div className="grow flex items-center transform cursor-pointer text-center">
          <div onClick={onLogoClick}>
            <Logo width={150} />
          </div>
          <div className="hidden md:block">
            <div
              className={cx(
                "ml-4 flex items-center justify-between focus:outline-none",
                `tracking-wide inline-block px-4 rounded shadow-md text-center font-semibold text-white text-sm`,
                "cursor-pointer"
              )}
              style={Styles.OrangeLinearGradient}
              onClick={() => {
                setShow(true);
              }}
            >
              <div>Explore</div>
              <div className="pt-3 pl-4 block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "white" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        {!isMobile && (
          <LinkModal
            onClose={() => {
              setShow(false);
            }}
            show={show}
            history={history}
          />
        )}
        <div>
          {user ? (
            <Dropdown
              button={<GlowingPicture imgUrl={profilePicture} />}
              items={[
                {
                  key: "profile",
                  text: "My Profile",
                  onClick: onProfileClick,
                },
                {
                  key: "learning",
                  text: "My Bookings",
                  onClick: () => history.push("/my/learnings"),
                },
                {
                  key: "teacher",
                  text: "Switch to teacher",
                  onClick: () => history.push("/teacher-profile"),
                  icon: (
                    <svg
                      className="mr-3 h-4 w-4"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.035 6.096h8.52a.889.889 0 100-1.778h-8.52L5.073 2.28a.889.889 0 00-1.257-1.257L.26 4.579a.89.89 0 00-.059.065l-.023.031c-.01.013-.02.025-.028.039-.009.012-.016.025-.024.038l-.021.036-.02.04-.017.039c-.006.013-.01.027-.015.04l-.015.042-.01.04c-.004.015-.008.03-.01.044-.004.016-.006.032-.008.048l-.006.038a.895.895 0 000 .176l.006.039.007.047.01.044.011.04.015.042.015.04.017.039.02.04.021.036.024.039c.008.013.018.025.028.038L.2 5.77a.886.886 0 00.059.064l3.556 3.556a.889.889 0 001.257-1.257L3.035 6.096zM16.687 12.882c.009-.01.016-.022.024-.032.01-.013.02-.025.028-.038.009-.013.016-.026.024-.04l.02-.035c.008-.013.014-.027.02-.04l.018-.039.015-.04.014-.042.01-.04.012-.044.007-.047.005-.04a.903.903 0 000-.175c0-.013-.003-.026-.005-.039-.003-.015-.004-.031-.008-.046-.002-.015-.006-.03-.01-.044l-.01-.04-.015-.043-.015-.04c-.005-.013-.012-.026-.018-.038-.006-.014-.012-.027-.02-.04l-.02-.037-.024-.038-.028-.038c-.008-.011-.015-.022-.024-.032a.882.882 0 00-.058-.064v-.001l-3.556-3.556a.889.889 0 00-1.257 1.257l2.038 2.038h-8.52a.889.889 0 000 1.778h8.52l-2.038 2.038a.889.889 0 001.257 1.257l3.555-3.555.001-.001c.02-.02.04-.042.058-.064z"
                        fill="currentColor"
                      />
                    </svg>
                  ),
                },
                {
                  key: "logout",
                  text: "Logout",
                  onClick: onLogout,
                  icon: (
                    <svg
                      className="mr-3 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                  ),
                },
              ]}
            />
          ) : (
            <TextLink
              className="font-semibold"
              color="text-y-yellow-dark"
              onClick={onOtpLogin}
            >
              Login
            </TextLink>
          )}
        </div>
      </div>
      {/* <div class="md:hidden block flex flex-row justify-center pt-2 relative mx-auto text-gray-600">
        <input
          class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          type="search"
          name="search"
          placeholder="Search"
          onKeyDown={handleClick}
          onChange={(e) => {
            setQueryText(e.target.value);
          }}
        />
        <button
          type="submit"
          class="absolute right-0 top-0 mt-4 mr-10"
          onClick={handleClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="25px"
            height="25px"
            viewBox="0 0 50 50"
            style={{ fill: "black" }}
          >
            <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
          </svg>
        </button>
      </div> */}
    </>
  );
}
