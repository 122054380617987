import userReducer from "./user";
import uiReducer from "./ui";
import courseReducer from "./course";

const rootReducer = {
  user: userReducer,
  ui: uiReducer,
  course: courseReducer,
};

export default rootReducer;
