import React from 'react';


function YogaPersonInfrontOfLaptopArt({ width = 292, height = 203 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 292 203" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M213.819 6.991c11.167 8.504 16.485 25.333 21.98 40.214 5.672 14.88 11.699 27.636 24.816 48.185 13.294 20.55 33.679 48.54 29.957 66.786-3.546 18.247-31.198 26.75-55.66 21.613-24.284-5.315-45.378-23.916-67.889-21.436-22.512 2.303-46.265 25.865-64.877 27.636-18.612 1.772-31.73-18.069-42.896-38.796-11.168-20.55-20.208-41.985-10.99-57.929 9.394-16.12 37.224-26.75 54.241-37.202 17.016-10.629 23.22-21.08 32.438-30.647 9.217-9.566 21.271-18.424 35.983-22.675 14.713-4.252 31.729-4.075 42.897 4.251z"
        fill="#FCF3E2" />
      <path
        d="M267.907 79.46H108.611a4.172 4.172 0 00-4.072 3.262L79.407 195.023h167.849l24.723-110.478a4.173 4.173 0 00-4.072-5.084z"
        fill="#F7B38B" />
      <path
        d="M266.044 79.46H106.747a4.173 4.173 0 00-4.072 3.262L77.543 195.023h167.848l24.725-110.478c.583-2.608-1.401-5.084-4.072-5.084z"
        fill="#FDE1CA" />
      <path d="M265.073 84.512H107.325L83.724 189.974h157.748l23.601-105.462z" fill="#fff" />
      <path d="M85.987 179.861h157.839l-2.354 10.113H83.724l2.263-10.113z" fill="#EEA886" />
      <path
        d="M183.604 193.852H17.066c-2.003 0-3.627 1.729-3.627 3.862 0 2.132 1.624 3.861 3.627 3.861h166.538c2.003 0 3.627-1.729 3.627-3.861 0-2.133-1.624-3.862-3.627-3.862z"
        fill="#F7CDAF" />
      <path d="M153.664 193.852a3.862 3.862 0 000 7.723h90.161c3.306 0 3.692-1.646 3.692-3.861v-3.862h-93.853z"
            fill="#F7B38B" />
      <path
        d="M244.093 199.089h-6.586a1.35 1.35 0 010-2.699h6.586a1.35 1.35 0 110 2.699zM231.222 198.845a1.106 1.106 0 110-2.213 1.106 1.106 0 010 2.213zM225.181 198.845a1.106 1.106 0 110-2.213 1.106 1.106 0 010 2.213z"
        fill="#F03B46" />
      <path d="M253.379 103.193H114.704l-14.985 73.366h138.674l14.986-73.366z" fill="#FFF2DB" />
      <path d="M255.211 94.226H116.536l-1.832 8.966h138.675l1.832-8.966z" fill="#FDE1CA" />
      <path d="M250.746 100.56H112.071l-14.986 73.366H235.76l14.986-73.366z" fill="#FAF7DF" />
      <path d="M252.577 91.593H113.903l-1.832 8.967h138.675l1.831-8.967z" fill="#EEA886" />
      <path d="M99.172 163.709h138.775l-2.087 10.217H97.085l2.087-10.217z" fill="#E3DFB9" />
      <path
        d="M122.176 142.621c-.366-1.159-1.527-1.792-3.026-1.837l-.174 2.489-.77-2.444a8.482 8.482 0 00-2.554.757c-.07.033-.136.069-.204.104l.196 3.147-1.139-2.612c-.388.25-.746.523-1.075.812l.61 1.936-1.156-1.419c-1.271 1.329-1.9 2.886-1.505 4.137.372 1.182 1.573 1.817 3.115 1.838l.189-1.702.576 1.667a8.395 8.395 0 002.644-.768c.188-.088.372-.183.551-.281l-.739-2.972 1.441 2.542a9.129 9.129 0 001.162-.91l-.964-2.278 1.585 1.631c1.088-1.26 1.602-2.679 1.237-3.837z"
        fill="#58BD9F" />
      <path
        d="M120.202 131.492c-1.275.599-2.167 1.883-2.518 3.383l1.69.069-1.835.861a6.304 6.304 0 00.265 2.387c.02.062.045.12.066.182l3.302-.836-2.939 1.665c.181.334.391.635.623.904l2.13-.999-1.704 1.436c1.118.995 2.604 1.304 3.98.658 1.3-.611 2.202-1.934 2.538-3.472l-2.328.213 2.447-.967a6.312 6.312 0 00-.259-2.473 6.33 6.33 0 00-.179-.492l-3.23 1.339 2.927-1.95a5.027 5.027 0 00-.707-.971l-2.556 1.422 2.011-1.909c-1.083-.825-2.45-1.048-3.724-.45zM138.52 148.311c.403-.774.286-1.621-.218-2.304l-1.202 1.242.851-1.634a3.45 3.45 0 00-1.241-.722c-.04-.013-.08-.023-.119-.035l-1.374 1.754.799-1.885a4.114 4.114 0 00-.749-.043l-.674 1.293.246-1.259c-1.054.145-1.988.693-2.423 1.53-.411.789-.282 1.654.248 2.344l.926-.897-.643 1.215c.334.319.765.582 1.278.756.107.036.215.067.322.094l1.103-1.899-.66 1.98c.276.037.553.047.824.029l.706-1.631-.192 1.561c.958-.188 1.789-.715 2.192-1.489z"
        fill="#80D3B7" />
      <path
        d="M139.053 140.359c.14 1.245-.604 2.605-1.863 3.703l-1.116-1.996.294 2.626a9.116 9.116 0 01-2.468 1.161c-.072.021-.143.036-.214.056l-1.057-2.82.046 3.031c-.428.06-.839.081-1.232.069l-.233-2.08-.432 2.024c-1.6-.232-2.74-1.113-2.89-2.457-.143-1.27.633-2.659 1.938-3.768l1.683 2.692-1.018-3.203a9.086 9.086 0 013.129-1.367l.525 3.054.24-3.183c.468-.06.919-.077 1.343-.048l.064 2.622.716-2.51c1.416.304 2.406 1.149 2.545 2.394z"
        fill="#58BD9F" />
      <path
        d="M136.24 129.338c1.259.731 1.707 2.3 1.387 4.134l-2.941-.407 2.654 1.543a10.717 10.717 0 01-1.515 2.915c-.055.077-.114.148-.171.223l-3.617-1.021 2.76 2.034c-.388.409-.796.777-1.213 1.105l-2.103-1.221 1.365 1.756c-1.864 1.215-3.833 1.592-5.192.802-1.284-.746-1.725-2.362-1.368-4.242l2.471.235-2.24-1.156c.313-.991.829-2.024 1.551-3.022.15-.207.305-.408.465-.601l3.277 1.636-2.602-2.383c.431-.442.883-.842 1.347-1.186l2.414 1.737-1.505-2.332c1.736-1.008 3.518-1.28 4.776-.549z"
        fill="#80D3B7" />
      <path
        d="M124.552 154.424l-.563-.101c.013-.075 1.329-7.496.471-9.614-.789-1.947-2.634-1.338-2.713-1.311l-.186-.542c.025-.007 2.441-.805 3.429 1.638.919 2.271-.382 9.618-.438 9.93z"
        fill="#58BD9F" />
      <path
        d="M125.491 153.288l-.57-.051c.002-.025.235-2.555.896-6.767.671-4.274 2.985-7.368 3.084-7.498l.456.345c-.023.031-2.327 3.115-2.974 7.242-.659 4.191-.89 6.704-.892 6.729zM123.24 153.292l-.568-.06c.936-8.808.007-12.61-.003-12.647l.555-.142c.039.156.967 3.918.016 12.849z"
        fill="#80D3B7" />
      <path
        d="M123.975 153.319l-.561-.114c1.628-7.972 5.178-10.287 5.329-10.381l.305.483c-.035.022-3.5 2.308-5.073 10.012z"
        fill="#58BD9F" />
      <path
        d="M126.071 153.319l-.561-.114c.679-3.322 1.992-5.549 3.902-6.619 2.154-1.205 4.249-.467 4.337-.435l-.195.538c-.018-.007-1.947-.679-3.87.401-1.755.987-2.97 3.083-3.613 6.229z"
        fill="#80D3B7" />
      <path d="M132.434 152.378h-15.065l-.078.382s-1.362 7.87 1.832 10.948h6.885c4.508-3.148 6.426-11.33 6.426-11.33z"
            fill="#fff" />
      <path d="M122.953 171.496h66.179l-2.297-4.498h-59.48l-4.402 4.498z" fill="#7C4340" />
      <path
        d="M159.346 114.505s-2.225 0-3.728 2.121c-1.503 2.122-2.001 4.311-2.001 4.311h8.762s1.796-6.432-3.033-6.432z"
        fill="#418074" />
      <path
        d="M158.738 123.48l7.6-1.679 8.035-4.122.839 1.363s-4.962 3.779-7.348 5.129c-3.739 2.116-9.517 3.072-9.517 3.072l.391-3.763zM155.087 123.948l-5.856-2.39-1.281-6.255-.833.257s-1.261 6.03.182 7.914c2.158 2.817 6.65 4.602 7.812 3.929.752-.436.833-1.052.925-1.708.111-.788-.268-1.468-.949-1.747z"
        fill="#F5757A" />
      <path
        d="M178.316 118.654l-1.453 1.293 1.09 10.159s-4.763.915-9.239 3.686c0 0-3.876 2.455-2.56 6.937 2.635-.754 6.474-2.033 9.403-3.764 4.931-2.914 5.805-4.407 5.805-4.407 3.441-3.625-3.046-13.904-3.046-13.904zM159.212 139.211c-.013 1.356-.092 10.109.14 14.103.252 4.336-.648 15.354-.648 15.354h1.706s4.026-10.662 3.15-15.425c1.094-4.612 2.104-9.873 2.594-12.513 0 0-4.077.869-6.942-1.519z"
        fill="#F8A486" />
      <path
        d="M157.773 123.46c-3.625 0-4.489 1.415-5.149 3.98-.483 1.878-.421 3.919 1.651 6.748a30.41 30.41 0 006.982-4.285c-1.323-3.725.083-6.443-3.484-6.443z"
        fill="#F5757A" />
      <path
        d="M168.714 133.792c-1.55-.886-2.902-1.04-4.064-.769-.046-.017-.091-.032-.139-.051-1.817-.714-3.052-2.256-3.481-4.176l-.45-2.015s-1.219-.875-1.577-3.359h-.178s-.506 3.575-3.498 4.686c-1.431-1.966-.061-4.301-.061-4.301l-.267.106s-1.568 1.894-2.46 3.33c-.892 1.436-2.081 3.789 2.503 7.918l.003-.001c1.006 1.193 2.347 2.519 4.128 4.022.015-.01.026-.02.04-.031l-.001.06c2.865 2.387 6.942 1.519 6.942 1.519-1.316-4.483 2.56-6.938 2.56-6.938z"
        fill="#F03B46" />
      <path d="M158.77 123.689c-.93.515-2.025.615-3.285.302l.624-3.054h3.223l-.562 2.752z" fill="#F5757A" />
      <path
        d="M154.131 169.406c.399-.168 4.655-1.792 4.655-1.792l2.003.002s.08 2.334-.767 2.334h-5.618c-.591 0-.672-.377-.273-.544z"
        fill="#F8A486" />
      <path
        d="M177.829 117.246c-.056-.132-.341-.034-.341-.034s.26-.276.189-.454c-.071-.177-.454.019-.454.019s.259-.336.173-.52c-.086-.184-.452.045-.452.045s.298-.279.093-.486c-.052-.053-.141-.037-.246.014-.043-.103-.209-.237-.858.039-.853.362-1.248 1.335-1.248 1.335-.279.42-.498.762-.498.762l.573 1.309s1.268-.64 2.112-1.174c.846-.535 1.014-.723.957-.855z"
        fill="#F5757A" />
      <path
        d="M175.894 115.214c.221.288 2.423 3.441 2.423 3.441l-1.305 2.674s-.691.521-.812-.277l-.802-5.294c-.084-.556.275-.831.496-.544z"
        fill="#F8A486" />
      <path
        d="M160.431 120.735c2.032-1.876 1.831-4.864-.346-5.709l-.052-.02c-1.738-.654-3.997.661-4.574 3.483-.576 2.823-.47 3.812 1.433 3.812.954 0 2.551-.654 3.539-1.566zM147.117 115.56s.11-1.392.593-2.219c.482-.827 1.692-1.729 1.81-1.748.118-.02.052.322-1.089 1.434-.347.57-.172.967-.172.967s.661-1.052.815-1.052c.223 0-.604 1.98-1.362 2.618"
        fill="#F5757A" />
      <path d="M158.07 114.841s.263 2.768 2.613 3.251c.304 1.38-1.254 2.845-1.254 2.845h2.036s2.869-7.209-3.395-6.096z"
            fill="#418074" />
      <path d="M176.716 115.869s-1.031.723-.909 1.028c.12.306 1.098-.51 1.098-.51l-.189-.518z" fill="#F5757A" />
      <path d="M176.954 116.312s-1.03.723-.91 1.028c.121.306 1.078-.565 1.078-.565l-.168-.463z" fill="#F5757A" />
      <path d="M177.147 116.763s-1.03.723-.91 1.028c.121.306 1.078-.565 1.078-.565l-.168-.463z" fill="#F5757A" />
      <path d="M177.433 117.132s-.968.679-.854.965c.113.287 1.007-.486 1.007-.486l-.153-.479z" fill="#F5757A" />
      <path d="M209.692 100.56h41.054l-14.986 73.366h-41.054l14.986-73.366z" fill="#fff" />
      <path
        d="M242.78 106.125h-27.396c-.829 0-1.639.673-1.809 1.503l-1.211 5.925-.496 2.431 2.928-2.431h26.466c.83 0 1.64-.673 1.81-1.502l.903-4.423c.17-.83-.366-1.503-1.195-1.503zM233.856 149.812H206.46c-.83 0-1.639.673-1.809 1.502l-1.21 5.926-.496 2.431 2.927-2.431h26.467c.829 0 1.639-.673 1.809-1.503l.903-4.423c.17-.829-.366-1.502-1.195-1.502z"
        fill="#C2DCEA" />
      <path
        d="M209.074 137.018h27.395c.83 0 1.365.673 1.196 1.502l-1.21 5.926-.497 2.431-1.935-2.431h-26.466c-.83 0-1.365-.673-1.196-1.503l.904-4.423c.169-.829.979-1.502 1.809-1.502z"
        fill="#80B0D3" />
      <path
        d="M240.282 118.349h-27.395c-.83 0-1.64.673-1.809 1.502l-2.527 12.37-.496 2.431 2.927-2.431h26.467c.829 0 1.64-.672 1.809-1.502l2.22-10.868c.169-.829-.366-1.502-1.196-1.502z"
        fill="#C2DCEA" />
      <path
        d="M236.312 96.076c-.173.846.373 1.532 1.219 1.532.847 0 1.673-.686 1.846-1.532.173-.847-.373-1.533-1.22-1.533-.846 0-1.672.686-1.845 1.533zM245.448 96.076c-.173.846.373 1.532 1.219 1.532.847 0 1.673-.686 1.847-1.532.172-.847-.374-1.533-1.221-1.533-.846 0-1.672.686-1.845 1.533zM240.88 96.076c-.174.846.373 1.532 1.219 1.532.847 0 1.673-.686 1.846-1.532.173-.847-.373-1.533-1.22-1.533-.846 0-1.673.686-1.845 1.533z"
        fill="#C57C59" />
      <path opacity=".1"
            d="M216.432 108.68h23.458M231.07 110.724h-14.972M213.221 120.698h23.457M227.858 122.742h-14.972M233.908 139.707h-23.457M218.249 141.751h14.972M230.552 152.256h-23.458M212.134 154.41h-5.391M212.469 124.958h18.16M227.106 127.001h-14.972"
            stroke="#1C3A34" strokeWidth=".613" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M50.335 156.247c-11.954-20.486 2.685-42.134 2.685-42.134l5.163 2.343c-.436-5.746-3.512-18.099-22.491-11.278-10.447-7.407-22.677-9.851-22.677-9.851l8.457-27.146-3.177-3.455S-4.65 92.192 2.568 101.88c0 0 1.768 5.987 13.355 13.775 5.876 3.95 13.705 7.018 19.996 9.085.52 7.33 1.395 20.658 1.783 32.412-4.938 12.725 1.144 41.216 1.144 41.216h4.559s3.609-29.44 6.648-41.026c.092-.352.186-.717.282-1.095z"
        fill="#F03B46" />
      <path
        d="M50.335 156.247c3.063-12.08 7.885-37.083 7.885-37.083s.086-1.094-.037-2.708l-5.163-2.343s-14.639 21.648-2.685 42.134z"
        fill="#F24E62" />
      <path
        d="M67.98 77.621l-19.394-4.487-19.221-11.017-2.984 3.644s11.195 10.097 16.834 13.706c8.835 5.654 23.753 8.209 23.753 8.209L67.98 77.62z"
        fill="#F5757A" />
      <path
        d="M82.153 88.204c-.364-6.854-1.9-10.636-11.587-10.636s-7.162 7.495-13.085 17.701c-2.555 4.401-7.774 6.978-10.788 7.949 1.69 4.123 5.083 10.772 11.55 16.362 21.22-15.27 24.31-23.813 23.91-31.376z"
        fill="#F8A486" />
      <path
        d="M67.779 78.177c2.205 1.377 5.076 1.646 8.613.809v-8.162H67.78v7.353zM77.478 78.874l16.951-6.386 6.838-16.715 3.895.884s-1.731 15.915-6.615 20.948c-7.305 7.528-20.28 12.299-23.02 10.5-1.77-1.164-1.65-2.81-1.539-4.565.134-2.104 1.517-3.923 3.49-4.666zM55.222 200.34c-.975-.448-11.461-4.787-11.461-4.787l-5.352.006s-1.49 6.237.773 6.237h15.014c1.58 0 2-1.008 1.026-1.456zM26.646 55.532c-.748.769-8.351 9.194-8.351 9.194l2.028 7.145s1.561 1.394 2.32-.739l5.031-14.146c.53-1.487-.28-2.222-1.028-1.453z"
        fill="#F5757A" />
      <path
        d="M101.432 55.96s-2.353-5.804-1.461-6.099c.891-.295.917 2.949 1.576 2.57.364-.21.042-6.853.853-6.949.811-.096.165 3.508.768 3.706.511.169.716-4.075 1.483-4.015.767.06-.63 4.149.074 4.318.703.169 1.079-4.1 1.742-3.832.663.27-.614 4.096-.239 4.267.374.171 1.034-3.373 1.717-3.058.683.315-2.783 9.788-2.783 9.788l-3.73-.696zM22.456 63.246c-1.965-1.427-2.311-1.93-2.09-2.283.222-.353.931-.091.931-.091s-.544-.737-.257-1.212c.286-.475 1.203.049 1.203.049s-.51-.897-.18-1.39c.331-.491 1.184.12 1.184.12s-.643-.744.015-1.296 2.23 1.625 2.584 1.64c-.275-1.643.336-1.517.923-1.092.538.39.949.93 1.243 1.526a99.698 99.698 0 011.694 3.672l-2.248 3.497s-3.036-1.712-5.002-3.14zM64.95 70.284c-4.403-5.011-2.235-12.995 4.043-15.254l.149-.053c5.002-1.747 10.321 1.764 10.321 9.307s-1.667 9.748-6.749 9.748c-2.55 0-5.62-1.308-7.764-3.748z"
        fill="#F5757A" />
      <path
        d="M64.039 71.822s2.965 2.03 6.649 1.402c3.683-.63 4.67-2.067 5.704-5.211 1.034-3.145 4.448-3.504 4.808-7.278.359-3.773-4.942-8.445-10.692-8.445s-9.074 4.916-9.973 10.32c-.898 5.403 3.504 9.212 3.504 9.212z"
        fill="#418074" />
      <path
        d="M67.097 71.289s3.7.928 1.553 5.662c-2.147 4.735 2.986 6.784 1.05 12.534-1.222 3.626-6.897 7.549-14.84 2.701-7.941-4.848-7.204-11.824-3.938-14.619 3.266-2.794 7.55-1.204 9.47-3.127 1.923-1.923 3.392-4.734 6.705-3.151z"
        fill="#418074" />
      <path opacity=".1" d="M35.996 114.113s-.68 5.314-.077 10.627" stroke="#1C3A34" strokeWidth=".613"
            strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M63.546 71.338s2.831-2.384 5.445 1.497" stroke="#1C3A34" strokeWidth=".613" strokeMiterlimit="10"
            strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.163 201.575h270.919" stroke="#7E4730" strokeWidth="1.226" strokeMiterlimit="10" strokeLinecap="round"
            strokeLinejoin="round" />
      <path
        d="M116.23 104.968a1.714 1.714 0 011.694-1.448h28.146c1.017 0 1.811.88 1.705 1.892l-1.217 11.629a1.714 1.714 0 01-1.705 1.536h-28.755a1.713 1.713 0 01-1.693-1.98l1.825-11.629z"
        fill="#FB2424" />
      <path
        d="M120.164 114.402l1.413-6.749h1.386l-1.174 5.621h3.434l-.235 1.128h-4.824zm5.667 0l1.413-6.749h1.386l-1.409 6.749h-1.39zm6.058 0h-1.482l-1.211-6.749h1.386l.856 5.106 2.795-5.106h1.376l-3.72 6.749zm2.915 0l1.408-6.749h5.005l-.235 1.128h-3.614l-.322 1.533h3.494l-.235 1.128h-3.494l-.424 1.832h3.927l-.239 1.128h-5.271z"
        fill="#fff" />
    </svg>
  );
}

export default YogaPersonInfrontOfLaptopArt;
