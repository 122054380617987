import React from 'react';


export default function useTemporaryMessage({className = null, timeout = 5000}) {
  const [text, showMessage] = React.useState(null);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      showMessage(null);
    }, timeout);

    return () => clearTimeout(timeoutId);
  }, [text, timeout, showMessage]);

  let message = null;
  if (text) {
    message = <span className={className}>{text}</span>
  }

  return { showMessage, message }
}
