import React from 'react';


export default function OneOnOneGraphic({ width = 162, height = 98 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 162 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M154.111 37.605c-1.259-4.576-4.988-8.494-9.598-9.53-8.29-1.864-16.051 5.29-24.54 5.6-5.593.205-10.962-2.665-14.921-6.64-3.96-3.974-6.71-8.982-9.249-13.994-1.345-2.654-2.683-5.384-4.785-7.484-2.931-2.928-7.054-4.33-11.127-5.034-5.605-.969-11.606-.73-16.654 1.902-8.306 4.332-12.782 14.359-21.462 17.87-4.073 1.648-8.598 1.64-12.987 1.532-3.373-.084-6.816-.204-10.046.776-3.23.979-6.27 3.288-7.176 6.554-.756 2.728.06 5.619.873 8.33C15.54 47.846 19.64 61.303 22.744 71.66h119.635c-1.915-3.425-.56-8.29 1.721-11.482 2.28-3.19 5.405-5.714 7.576-8.981 2.623-3.95 3.695-9.016 2.435-13.592z"
        fill="#fff" />
      <path
        d="M110.636 25.027a14.146 14.146 0 008.957 2.67c1.974-.1 4.268-.957 4.691-2.897.169-.778-.009-1.594-.295-2.338-1.061-2.765-3.572-4.79-6.34-5.812-2.499-.921-9.157-2.465-11.121.118-2.098 2.761 2.089 6.79 4.108 8.26zM135.201 26.149c-1.209.157-3.018.339-3.622-.987-.35-.768-.146-1.537.474-2.08 1.24-1.087 4.244-1.13 5.318.256 1.175 1.517-.925 2.649-2.17 2.81zM44.74 12.322c-1.565 1.023-3.462 1.632-5.311 1.366-.224-.032-.455-.081-.635-.218-.178-.135-.288-.341-.386-.543-.365-.75-.663-1.569-.587-2.4.114-1.239 1.037-2.253 2.042-2.981a10.408 10.408 0 016.13-1.968c1.517.01 3.271.828 2.709 2.607-.516 1.63-2.6 3.247-3.962 4.137zM54.438 1.705c.277.138.526.35.662.628.206.421.124.946-.129 1.34-.661 1.037-3.016 1.865-3.412.195-.371-1.566 1.537-2.83 2.879-2.163z"
        fill="#FDDFE8" fillOpacity=".4" />
      <path
        d="M159.213 71.83H.139A.136.136 0 010 71.697c0-.073.062-.133.14-.133h159.073c.077 0 .139.06.139.133a.136.136 0 01-.139.132z"
        fill="#C2C8EA" />
      <path
        d="M162 90.416c0 .972-8.009 1.76-17.888 1.76-9.879 0-17.888-.788-17.888-1.76 0-.971 8.009-1.759 17.888-1.759 9.879 0 17.888.788 17.888 1.759z"
        fill="#E7E8F7" />
      <path d="M154.487 58.48h-20.765a1.777 1.777 0 01-1.773-1.781v-.07h24.31v.07c0 .983-.793 1.78-1.772 1.78z"
            fill="#F68A4C" />
      <path
        d="M154.609 57.768h-21.01c-.729 0-1.341-.48-1.559-1.139h-.091v.192c0 .916.739 1.659 1.65 1.659h21.01c.912 0 1.65-.743 1.65-1.659v-.192h-.09a1.646 1.646 0 01-1.56 1.139z"
        fill="#F5744D" />
      <path
        d="M150.676 49.52a1.774 1.774 0 011.444-.825.665.665 0 01-.057.447 1.043 1.043 0 01-.289.34 1.105 1.105 0 01-.533.243c-.196.026-.416-.076-.565-.205zM150.712 48.502a5.005 5.005 0 01-.849-.553 2.285 2.285 0 01-.623-.79c.336-.134.744 0 .996.26s.442.721.476 1.083zM150.936 46.346a.95.95 0 01.565-.505 1.6 1.6 0 01.769-.053 1.034 1.034 0 01-.562.593c-.259.107-.518.083-.772-.035z"
        fill="#31B9A7" />
      <path
        d="M151.162 44.63c-.079.01-.157-.028-.225-.071a1.402 1.402 0 01-.609-.86.787.787 0 01.643.216c.169.164.213.48.191.715zM150.657 50.738a.967.967 0 00-.348-.462c-.268-.191-.612-.231-.94-.265.059.135.154.25.25.362.086.099.174.2.292.257a.835.835 0 00.219.062c.139.023.387.042.527.046zM151.206 47.696a1.193 1.193 0 011.15-.276c-.115.211-.282.39-.504.48-.223.09-.458-.056-.646-.204zM150.424 45.978a.975.975 0 00-.951-.61c.064.41.539.637.951.61z"
        fill="#44BFA0" />
      <path
        d="M150.116 49.1a.417.417 0 00-.191-.287.926.926 0 00-.329-.125 1.374 1.374 0 00-.437-.033c-.003.155.108.295.24.374.133.078.29.104.443.117.135.012.139-.04.274-.046z"
        fill="#31B9A7" />
      <path
        d="M150.979 50.992a.606.606 0 01.209-.421c.119-.108.268-.177.421-.225a.53.53 0 01-.123.472.636.636 0 01-.507.174z"
        fill="#44BFA0" />
      <path d="M151.596 51.726a1.24 1.24 0 011.393.226c-.242.09-.485.176-.744.177-.259.002-.519-.178-.649-.403z"
            fill="#31B9A7" />
      <path
        d="M152.208 50.664c.361-.23.861-.217 1.209.034a.923.923 0 01-.588.322c-.231.03-.431-.223-.621-.356zM152.95 49.552c-.184-.013-.334-.17-.397-.344-.063-.175-.057-.366-.051-.552l.012-.302c.189.09.336.289.39.494.053.205.053.492.046.704z"
        fill="#44BFA0" />
      <path
        d="M153.379 48.91c.19-.035.381-.07.574-.065.193.005.39.053.543.17a.856.856 0 01-.565.321c-.22.024-.38-.287-.552-.426zM153.931 48.304a.914.914 0 01-.407-.98c.17.048.325.155.412.31.087.155.043.499-.005.67z"
        fill="#31B9A7" />
      <path
        d="M154.287 48.339c.269-.206.7-.15.907.118-.255.237-.655.123-.907-.118zM153.055 49.97a.89.89 0 011.014.07c-.167.103-.351.207-.544.242-.193.035-.33-.173-.47-.311z"
        fill="#44BFA0" />
      <path
        d="M152.255 50.207a.4.4 0 01-.274-.308.621.621 0 01.077-.418c.062-.024.133.023.168.08a.44.44 0 01.045.194c.009.118.027.341-.016.452z"
        fill="#31B9A7" />
      <path d="M150.06 52.935c-.028-.035-2.736-3.57-3.47-5.96l.136-.042c.726 2.365 3.419 5.88 3.446 5.915l-.112.087z"
            fill="#A4474F" />
      <path
        d="M149.282 51.768c-.341-.053-.701-.09-1.022.037-.103.04-.199.097-.29.16.105.053.213.097.324.136.173.06.358.102.538.07.181-.03.396-.227.45-.403z"
        fill="#44BFA0" />
      <path d="M148.946 51.215a.652.652 0 01.398-.62c.052.138.055.299-.017.428-.072.13-.236.164-.381.192z"
            fill="#31B9A7" />
      <path
        d="M148.372 50.42a2.563 2.563 0 00-.554-.068c-.181-.009-.374-.014-.53.077.038.092.064.204.146.259a.496.496 0 00.252.06c.245.014.5-.166.686-.328z"
        fill="#44BFA0" />
      <path
        d="M147.946 49.683a.968.968 0 01.254-.657.58.58 0 01.318-.19c.018.137.032.276-.003.41-.063.25-.356.293-.569.437z"
        fill="#31B9A7" />
      <path d="M147.388 48.708a1.207 1.207 0 00-.991.144c.142.13.329.204.52.193.192-.01.345-.193.471-.337z"
            fill="#44BFA0" />
      <path d="M146.953 47.737a.89.89 0 01.634-.645c.078.13 0 .308-.095.427-.104.132-.371.224-.539.218z"
            fill="#31B9A7" />
      <path d="M147.659 48.584a.594.594 0 01.358-.574.492.492 0 01-.01.384c-.055.117-.225.153-.348.19z"
            fill="#44BFA0" />
      <path d="M146.76 48.053a.71.71 0 00-.711.065c.096.152.307.172.486.172.148 0 .143-.112.225-.237z" fill="#31B9A7" />
      <path
        d="M146.496 47.067a.947.947 0 00-.845-.013.772.772 0 00.428.203c.162.025.256-.167.417-.19zM148.735 50.349a.809.809 0 01.28-.715.596.596 0 01.017.455c-.056.146-.154.197-.297.26z"
        fill="#44BFA0" />
      <path d="M147.644 49.729a.759.759 0 00-.716.077c.241.184.491.126.716-.077z" fill="#31B9A7" />
      <path
        d="M150.646 53.139h-.141c0-.33.012-8.082 1.143-10.129l.124.07c-1.113 2.014-1.126 9.978-1.126 10.059zM151.001 53.166l-.131-.055c.014-.034 1.454-3.45 3.894-5.791l.098.103c-2.418 2.32-3.847 5.709-3.861 5.743z"
        fill="#A4474F" />
      <path d="M152.951 52.703H148.2s-.085 2.79.718 3.942h3.315c.803-1.151.718-3.942.718-3.942z" fill="#69CFF6" />
      <path d="M152.688 55.524c.145-.592.212-1.245.242-1.774h-4.709c.03.529.097 1.182.242 1.774h4.225z"
            fill="#5F95D5" />
      <path d="M149.867 56.645h2.366c.803-1.151.718-3.942.718-3.942s-1.109 2.873-3.084 3.942z" fill="#68B4E7" />
      <path
        d="M152.451 53.75a9.551 9.551 0 01-1.222 1.774h1.459a6.874 6.874 0 00.086-.408l.018-.104c.02-.119.038-.237.053-.355l.009-.06a12.84 12.84 0 00.072-.803l.003-.044h-.478z"
        fill="#4D7CC7" />
      <path d="M140.422 54.49h-3.226l.631-10.656h1.963l.632 10.656z" fill="#CFDCF3" />
      <path
        d="M138.051 42.712c-.258-.368-.227-.859-.191-1.307.036-.449.053-.944-.223-1.298.19.07.314.253.406.434.237.463.357.975.475 1.482l.137.59c.024.101.152.402.089.496-.125.186-.608-.275-.693-.397z"
        fill="#F68A4C" />
      <path d="M140.422 54.49l-.584-9.859h-.001s.306 7.138-1.051 9.859h1.636z" fill="#BBC3E8" />
      <path d="M143.853 54.52h-2.018l.395-6.587h1.229l.394 6.587z" fill="#CFDCF3" />
      <path
        d="M142.267 47.095c-.032-.266.023-.533.017-.8a1.784 1.784 0 00-.239-.852c.35.303.619.7.772 1.139.097.279.129.673-.102.905-.26.26-.42-.166-.448-.392z"
        fill="#F68A4C" />
      <path d="M143.854 54.52l-.395-6.587h-.203c-.004 1.26-.094 5.45-1.055 6.587h1.653z" fill="#BBC3E8" />
      <path d="M145.453 54.49h-10.865l.07.12a4.102 4.102 0 003.53 2.02h3.665a4.1 4.1 0 003.53-2.02l.07-.12z"
            fill="#F14182" />
      <path d="M143.363 54.49l-.071.12a4.1 4.1 0 01-3.53 2.02h2.091a4.1 4.1 0 003.53-2.02l.07-.12h-2.09z"
            fill="#D83885" />
      <path
        d="M139.098 58.48h-1.075c-3.241 7.896-6.564 32.35-6.564 32.35h1.526a1.987 1.987 0 001.984-1.973c.083-9.139 4.129-30.377 4.129-30.377zM149.11 58.48h1.076c3.24 7.896 6.564 32.35 6.564 32.35h-1.526a1.985 1.985 0 01-1.984-1.973c-.084-9.139-4.13-30.377-4.13-30.377z"
        fill="#D95050" />
      <path
        d="M138.023 58.48c-.698 1.7-1.399 4.166-2.07 6.991.294-.2.592-.42.887-.6.405-.246.817-.48 1.234-.706.589-3.397 1.024-5.685 1.024-5.685h-1.075zM150.186 58.48h-1.076s.166.868.43 2.329c.481-.034.963-.056 1.445-.065a28.623 28.623 0 00-.799-2.264z"
        fill="#A4474F" />
      <path
        d="M72.68 43.03H25.783a1.73 1.73 0 01-1.725-1.733V12.48a1.73 1.73 0 011.725-1.733H72.68a1.73 1.73 0 011.725 1.733v28.819a1.73 1.73 0 01-1.725 1.733z"
        fill="#685377" />
      <path
        d="M71.935 41.63H26.528c-.59 0-1.069-.481-1.069-1.074V13.22c0-.594.479-1.075 1.07-1.075h45.406c.59 0 1.069.481 1.069 1.075v27.335c0 .593-.479 1.074-1.07 1.074z"
        fill="#C8D9E1" />
      <path
        d="M70.894 40.66H27.57c-.563 0-1.02-.45-1.02-1.003V14.12c0-.555.457-1.004 1.02-1.004h43.325c.564 0 1.02.45 1.02 1.004v25.537c0 .554-.456 1.003-1.02 1.003z"
        fill="#E3F6FD" />
      <path d="M51.508 48.14h-4.553l1.283-5.11h1.667l1.603 5.11z" fill="#473752" />
      <path d="M55.07 47.066H43.393v1.075H55.07v-1.075z" fill="#685377" />
      <path
        d="M60.879 38.97c0 .71-5.544 1.285-12.383 1.285-6.84 0-12.383-.575-12.383-1.285 0-.709 5.544-1.283 12.383-1.283 6.838 0 12.383.574 12.383 1.284z"
        fill="#CFDCF3" />
      <path
        d="M47.948 17.412c-.267-.192-.646-.185-.933-.025-.286.16-.484.453-.578.769-.094.316-.091.654-.048.98-.202.066-.339.281-.394.487-.057.206-.043.424-.027.637.02.252.042.507.122.747.08.24.223.466.438.599-.08.133-.088.287-.09.444 0 .411.05.823.153 1.222a.52.52 0 00.068.172c.066.095.183.138.292.175.262.087.566.166.798.017a1.76 1.76 0 001.108.498c.114.006.23 0 .339-.032.266-.08.457-.308.633-.523.17.175.398.316.62.415.223.1.506.104.69-.057.163-.142.213-.37.249-.584.084-.493.144-1.01-.005-1.487.137-.176.206-.39.276-.603.102-.314.206-.634.201-.963-.004-.33-.137-.675-.414-.851.109-.152.122-.365.131-.552.015-.297.014-.6-.07-.886-.082-.287-.257-.556-.519-.696-.186-.1-.401-.128-.611-.155-.118-.014-.248-.026-.345.042.009-.21-.163-.435-.352-.525a.915.915 0 00-.363-.087c-.26-.008-.513.096-.727.245a4.488 4.488 0 00-.642.577z"
        fill="#914968" />
      <path
        d="M46.998 19.585c-.207-.019-.287.25-.287.423 0 .526.125.748.29.809.06.021.125.03.187.02a.227.227 0 00.076-.026 5.394 5.394 0 01-.266-1.226zM50.665 19.585c.207-.019.287.25.287.423 0 .526-.125.748-.29.809a.385.385 0 01-.187.02.226.226 0 01-.076-.026 5.4 5.4 0 00.266-1.226z"
        fill="#F68A6F" />
      <path
        d="M52.114 26.86c-.114-.293-.182-2.936-.182-3.216 0-.052-.897-.217-.897-.217h-4.42s-.863.125-1.01.178c-.147.054.213 2.024 0 3.23-.067.383.135 1.26.487 2.038.521 1.151.563 3.032.376 3.173-.186.14-.21.656-.046.715.268.096.48.186.764.204.165.01.335-.022.495.02.086.021.164.063.246.099.41.176.874.166 1.32.133.934-.069 1.883-.232 2.703-.687a.323.323 0 00.113-.089c.07-.1.011-.238-.041-.349a2.185 2.185 0 01-.182-.544 3.21 3.21 0 01-.038-.552c-.01-1.24.095-2.482.312-3.703 0 0 .114-.141 0-.433z"
        fill="#79D4EE" />
      <path
        d="M51.035 23.427l-.72-.083c-.717-.1-.71-1.462-.71-1.462h-1.56s.008 1.362-.71 1.462l-.72.083s-.51 3.003 2.17 3.637h.08c2.68-.634 2.17-3.637 2.17-3.637z"
        fill="#F68A6F" />
      <path
        d="M49.632 22.257c-.028-.215-.027-.375-.027-.375h-1.56s.005.984-.434 1.34c.145-.053.285-.119.419-.197.173-.102.335-.222.51-.32.162-.09.334-.159.506-.228.133-.054.373-.12.586-.22z"
        fill="#F57C6C" />
      <path
        d="M50.669 18.734a.752.752 0 01-.473.445.842.842 0 01-.648-.066.945.945 0 01-.466-.671c-.096.119-.211.225-.349.29a.608.608 0 01-.444.04.428.428 0 01-.293-.315c-.041.223-.089.45-.209.642s-.33.343-.554.33c-.098-.005-.201-.047-.246-.135l-.01-.016s.037 2 1.15 2.79c.421.3.97.3 1.392 0 1.113-.79 1.15-2.79 1.15-2.79s.015-.381 0-.544zM48.99 27.972c0-.042-.014-.097-.055-.098.003-.312-.013-.62-.01-.93 0-.066 0-.136-.038-.189-.038-.053-.127-.072-.165-.02-.019.026-.02.058-.021.09l-.026.757a2.624 2.624 0 01-.068.604c-.079.29-.255.543-.428.79a.36.36 0 01-.11.114.308.308 0 01-.148.03c-.573-.037-1.142-.06-1.713-.115l-.661-.063c-.057-.005-.12-.013-.161-.052-.044-.041-.055-.107-.062-.167a4.415 4.415 0 01.032-1.226c.019-.108.033-.182.072-.283.031-.083.158-.248.171-.336.161-1.008-.03-2.485.006-3.273-.076-.014-.95.076-1.238 1.116-.288 1.04-.238 1.928-.407 2.543-.17.614-.493 2.098-.153 2.32.34.222 1.511.734 3.277.512.385-.049.794-.096 1.178-.033.131.021.262.058.395.049.133-.01.273-.079.318-.204a.497.497 0 00.02-.171l-.006-1.765z"
        fill="#F9A67B" />
      <path
        d="M48.975 29.908a.497.497 0 00.02-.171l-.006-1.765c0-.042-.012-.097-.054-.098.003-.312-.012-.62-.01-.93v-.013a.35.35 0 00-.058.153 8.854 8.854 0 00-.096 1.255c-.002.184.001.369-.004.553a5.945 5.945 0 01-.098.92.823.823 0 00-.016.3h.004c.133-.01.273-.079.318-.204z"
        fill="#F68A6F" />
      <path
        d="M48.871 27.972c0-.042.013-.097.055-.098-.003-.312.012-.62.01-.93 0-.066 0-.136.038-.189.038-.053.127-.072.165-.02.018.026.02.058.021.09l.026.757c.007.203.014.408.068.604.079.29.255.543.428.79.03.043.063.088.11.114.044.025.097.03.148.03.573-.037 1.142-.06 1.713-.115l.661-.063c.057-.005.119-.013.161-.052.044-.041.055-.107.062-.167.046-.408-.032-.758-.1-1.162a1.328 1.328 0 00-.07-.283c-.032-.083-.226-.294-.24-.381-.195-1.23-.159-2.465-.195-3.253.138.038 1.032.037 1.321 1.077.289 1.04.238 1.928.408 2.543.17.614.492 2.098.152 2.32-.34.222-1.27.734-3.036.512-.386-.049-.794-.096-1.178-.033-.131.021-.262.058-.395.049-.133-.01-.273-.079-.318-.204-.02-.054-.02-.114-.02-.171l.005-1.765z"
        fill="#F9A67B" />
      <path
        d="M48.871 27.972c0-.042.013-.097.055-.098-.003-.312.012-.62.01-.93 0-.066 0-.136.038-.189.038-.053.127-.072.165-.02.018.026.02.058.021.09l.026.757c.007.203.014.408.068.604.079.29.255.543.428.79.03.043.063.088.11.114.044.025.097.03.148.03.573-.037 1.142-.06 1.713-.115l.661-.063c.057-.005.119-.013.161-.052.044-.041.055-.107.062-.167.046-.408-.032-.758-.1-1.162a1.328 1.328 0 00-.07-.283c-.032-.083-.226-.294-.24-.381-.195-1.23-.159-2.465-.195-3.253.138.038 1.032.037 1.321 1.077.289 1.04.238 1.928.408 2.543.17.614.492 2.098.152 2.32-.34.222-1.27.734-3.036.512-.386-.049-.794-.096-1.178-.033-.131.021-.262.058-.395.049-.133-.01-.273-.079-.318-.204-.02-.054-.02-.114-.02-.171l.005-1.765z"
        fill="#F9A67B" />
      <path
        d="M45.73 28.96l-.183-.018c-.057-.005-.12-.013-.161-.052-.038-.035-.051-.088-.058-.141-.357.051-.809.158-.809.158.405-.013.951.03 1.21.052zM52.528 28.779a.198.198 0 01-.053.111c-.042.04-.104.047-.161.052l-.307.03s.715-.05 1.218-.05c0 0-.306-.086-.697-.143zM48.914 27.884a.319.319 0 01.126.179c.024.075.03.156.035.234l.027.401a.029.029 0 00.029.027h.002a.029.029 0 00.027-.03l-.028-.402c-.006-.081-.012-.166-.037-.247a.376.376 0 00-.151-.21c-.006-.004-.012-.004-.018-.003v.04c-.006 0-.01.004-.016.007l.004.004z"
        fill="#F68A6F" />
      <path
        d="M51.93 32.54c-.816.447-1.756.609-2.684.677-.445.033-.91.043-1.32-.133-.08-.036-.16-.078-.245-.1-.16-.041-.33-.01-.495-.02a1.89 1.89 0 01-.528-.118l-.032 1.138c.53.389 1.25.364 1.906.335 1.211-.053 2.447-.11 3.58-.546.037-.119-.023-.238-.053-.36a4.82 4.82 0 01-.129-.873z"
        fill="#F9A67B" />
      <path
        d="M58.147 35.4c-.061-.642-.522-1.255-1.148-1.394a2.312 2.312 0 00-.471-.043 9.508 9.508 0 00-3.586.65c-.21.082-.422.14-.574-.028a1.646 1.646 0 00-.17-.67.273.273 0 00-.066-.093.128.128 0 00-.074-.03c-1.118.418-2.334.475-3.526.527-.655.029-1.376.054-1.905-.335l.003-.12c-.215.093-.396.258-.598.38-.529.323-1.18.346-1.8.337a24.372 24.372 0 01-1.264-.053c-.28-.02-.563-.043-.836.022a2.155 2.155 0 00-.624.281c-.41.253-.798.57-1.022.998-.225.427-.255.981.02 1.377.177.252.457.416.75.507.294.09.602.115.908.139 1.521.115 3.05.208 4.57.09l5.896.673c.312-.08 4.842-1.518 5.468-2.78.066-.132.062-.288.049-.435z"
        fill="#69CFF6" />
      <path d="M51.415 37.078l-.548.313-3.606-1.225s-.023 1.225-.527 1.775l5.984.674s-1.05-.281-1.303-1.537z"
            fill="#F68A6F" />
      <path
        d="M46.973 37.968l2.818.317.025-.006c.269-.075.542-.16.764-.33.188-.144.332-.363.351-.595l-.064.036-.13-.044a4.156 4.156 0 01-1.923.573c-.35.015-.701-.014-1.051-.039-.235-.017-.482-.074-.701.036a.92.92 0 00-.09.052z"
        fill="#F57C6C" />
      <path
        d="M51.415 37.078s-1.996 1.12-2.369 1.124c-.372.002-2.47-.43-3.258-.358-.789.072-1.63.157-1.774.358-.144.2-.126.355-.072.413-.083.062-.13.201-.081.293-.061.037-.13.124-.13.195 0 .072.04.143.1.18a.32.32 0 00-.076.117.165.165 0 00.009.138c.027.045.078.069.125.09.059.026.105.038.159.072-.015.042.009.1.039.132a.24.24 0 00.114.062c.164.045.338.007.504-.032l.79-.185c.179-.041.344-.013.527-.02l2.09-.094c.406-.019.813-.038 1.22-.034 1.204.013 3.105-.75 3.298-.914-.869-.523-1.215-1.537-1.215-1.537z"
        fill="#F9A67B" />
      <path
        d="M47.26 36.166s-2.497-1.079-4.054-1.152c0 0 1.423-.025 2.723.416 1.3.44 2.102.998 2.102.998l-.77-.262zM51.415 37.078s2.456-1.873 4.167-2.495c0 0-2.586.656-4.89 2.748l.175.06.548-.313z"
        fill="#5CADE4" />
      <path
        d="M47.467 33.47c.42-.036.844-.006 1.266-.01a7.493 7.493 0 002.254-.38c.282-.093.703-.194.956-.38a5.801 5.801 0 01-.013-.16c-.815.447-1.756.609-2.683.677-.446.033-.91.043-1.32-.133-.082-.036-.16-.078-.245-.1-.161-.041-.33-.01-.496-.02a1.893 1.893 0 01-.528-.118l-.023.824c.26-.115.547-.175.832-.2z"
        fill="#F68A6F" />
      <path
        d="M43.905 38.652c.066-.004.16-.005.205-.002.016 0 .03-.011.03-.027a.029.029 0 00-.026-.03 2.339 2.339 0 00-.187 0c.004.008.01.016.015.022a.233.233 0 00-.037.037zM44.324 39.673l-.335-.003c.019.008.038.017.059.03a.08.08 0 00-.004.028l.279.002a.029.029 0 000-.057zM44.02 39.309a.029.029 0 000-.057h-.227a.21.21 0 00.039.03l-.022.027h.21zM43.824 38.937c.068.004.166.014.214.023h.006c.013 0 .025-.01.027-.024a.028.028 0 00-.023-.033 2.244 2.244 0 00-.197-.022c.003.01.005.018.01.027a.282.282 0 00-.037.03z"
        fill="#F57C6C" />
      <path
        d="M60.39 15.63c-2.564 0-4.642 1.083-4.642 2.42 0 .676.534 1.287 1.392 1.726l-.72 1.795 1.486-1.48a7.977 7.977 0 002.483.378c2.564 0 4.642-1.083 4.642-2.42 0-1.336-2.078-2.42-4.642-2.42zM40.605 24.17l-2.214 1.464c-.658-.393-1.525-.635-2.478-.635-2.053 0-3.717 1.11-3.717 2.48 0 1.369 1.664 2.48 3.717 2.48 2.052 0 3.716-1.111 3.716-2.48 0-.39-.139-.758-.38-1.086l1.356-2.223zM63.557 33.15v1.114a.426.426 0 00-.216-.056c-.192 0-.349.117-.349.263 0 .145.157.262.35.262.18 0 .327-.102.346-.234h.002v-1.242l1.23-.23v1.017a.41.41 0 00-.238-.07c-.184 0-.333.105-.333.234 0 .13.15.234.333.234.184 0 .333-.105.333-.234h.015v-1.349l-1.473.292zM31.658 33.968v1.32a.503.503 0 00-.256-.067c-.228 0-.413.139-.413.31 0 .172.185.312.413.312.214 0 .39-.121.411-.277h.003v-1.472l1.457-.273v1.206a.485.485 0 00-.282-.084c-.218 0-.394.125-.394.278 0 .153.176.277.394.277.218 0 .394-.124.394-.277h.018v-1.598l-1.745.345zM36.002 18.54v1.626a.621.621 0 00-.316-.082c-.28 0-.51.172-.51.383 0 .212.23.384.51.384.263 0 .48-.15.507-.342h.003v-1.814l.266-.051c0 .22-.024.434-.062.651.163-.132.328-.266.458-.433a.81.81 0 00.19-.544l-1.046.222zM67.744 25.962v1.634a.622.622 0 00-.317-.083c-.283 0-.512.172-.512.385 0 .213.23.386.512.386.264 0 .481-.15.509-.344h.003v-1.823l.267-.05c0 .22-.024.436-.062.653.164-.132.33-.267.46-.434a.812.812 0 00.191-.547l-1.051.223z"
        fill="#CBCBEB" />
      <path
        d="M63.355 48.14H34.859a4.713 4.713 0 00-4.633 3.898L26.98 70.279c-.12.67.393 1.285 1.07 1.285h42.363c.683 0 1.197-.625 1.068-1.3l-3.505-18.288a4.712 4.712 0 00-4.621-3.835z"
        fill="#F68A4C" />
      <path
        d="M63.017 49.946H35.21a2.988 2.988 0 00-2.932 2.438l-3.184 16.804a.48.48 0 00.47.571h39.344a.48.48 0 00.469-.577l-3.436-16.84a2.988 2.988 0 00-2.924-2.396z"
        fill="#D95050" />
      <path d="M30.733 60.537h36.88l-.326-1.598h-36.25l-.303 1.598z" fill="#F4684F" />
      <path d="M58.423 58.94h-1.339v-8.994h1.34v8.993zM41.437 69.759h-1.655v-9.222h1.655v9.222z" fill="#F35C4D" />
      <path d="M60.394 67.796H45.316v1.64h15.078v-1.64z" fill="#69CFF6" />
      <path d="M55.273 67.796H48.99v1.64h6.284v-1.64z" fill="#BDEAFB" />
      <path d="M42.528 66.233h12.46v1.563h-12.46v-1.563z" fill="#CCDEF4" />
      <path d="M49.912 66.233h5.077v1.563h-5.077v-1.563z" fill="#E3F6FD" />
      <path
        d="M62.896 55.374s1.034-.103 1.506 1.348c.472 1.45-.998.452-1.029.469-.031.016-.477-1.817-.477-1.817zM61.195 55.58s-1.488.785-1.335 1.733c.154.949.893.385.893.385l.442-2.119z"
        fill="#F6908E" />
      <path
        d="M63.881 57.363c0 1.21-.76 1.552-1.7 1.552-.938 0-1.698-.342-1.698-1.552s.76-2.192 1.699-2.192c.938 0 1.7.981 1.7 2.192z"
        fill="#FAB9B1" />
      <path
        d="M63.29 57.678c0 .683-.496 1.237-1.108 1.237-.612 0-1.109-.554-1.109-1.237s.497-1.236 1.109-1.236 1.108.553 1.108 1.236z"
        fill="#FBCEDC" />
      <path
        d="M61.456 57.768c.19.47.065.965-.278 1.104-.343.14-.774-.129-.963-.599-.19-.47-.065-.964.277-1.103.343-.14.775.128.964.598zM62.855 57.768c-.189.47-.065.965.278 1.104.343.14.774-.129.964-.599.19-.47.065-.964-.278-1.103-.343-.14-.774.128-.964.598z"
        fill="#F7999F" />
      <path
        d="M61.093 54.258a.488.488 0 11-.957.191.488.488 0 11.957-.191zM63.654 53.593a.488.488 0 11-.958.191.488.488 0 11.958-.191z"
        fill="#FAB9B1" />
      <path
        d="M63.3 54.32c.138.7-.352 1.19-1.048 1.329-.696.14-1.335-.125-1.474-.825-.138-.7.314-1.38 1.01-1.52A1.286 1.286 0 0163.3 54.32z"
        fill="#F8A3A9" />
      <path d="M123.132 20.366h-7.366s-1.145 15.12-6.413 19.248h20.192c-5.268-4.128-6.413-19.248-6.413-19.248z"
            fill="#31B9A7" />
      <path d="M119.976 68.676h-1.054V39.614h1.054v29.062z" fill="#27AAAE" />
      <path d="M119.976 40.922h-1.054v-1.308h1.054v1.308z" fill="#2F83A1" />
      <path d="M123.378 69.727h-7.857a.684.684 0 00-.645.46l-.481 1.376h10.108l-.481-1.377a.684.684 0 00-.644-.459z"
            fill="#D95050" />
      <path d="M120.986 68.676h-2.955a.524.524 0 00-.523.526v.525h4.001v-.525a.524.524 0 00-.523-.526z"
            fill="#F68A4C" />
      <path d="M122.864 52.559h-.391V39.614h.391v12.945z" fill="#53435F" />
      <path d="M123.554 53.142a.887.887 0 01-.885.89.887.887 0 01-.885-.89c0-.49.396-.889.885-.889s.885.398.885.89z"
            fill="#F68A4C" />
      <path
        d="M109.925 39.106a6.693 6.693 0 01-.572.508h5.744l11.142-4.944c-.32-.775-.61-1.587-.871-2.417l-15.443 6.853zM115.117 25.424a61.017 61.017 0 01-.593 3.066l9.102-4.039a68.972 68.972 0 01-.352-2.647l-8.157 3.62zM113.685 31.748a29.414 29.414 0 01-1.203 3.338l12.17-5.401a55.8 55.8 0 01-.561-2.554l-10.406 4.617z"
        fill="#36BCB6" />
      <path d="M124.308 28.19c-.228 3.714-1.85 10.722-10.753 11.424h15.99c-2.686-2.105-4.3-7.066-5.237-11.424z"
            fill="#2EB9AE" />
      <path
        d="M124.652 29.685l-.04-.173a55.234 55.234 0 01-.223-.96l-.055-.249a4.448 4.448 0 00-.026-.114c-.032.522-.093 1.11-.196 1.735l.54-.24zM126.239 34.67l-.017-.046a25.68 25.68 0 01-.396-1.028c-.026-.07-.052-.14-.077-.212-.13-.365-.255-.734-.373-1.108l-.008-.023-2.207.98c-.688 1.59-1.768 3.145-3.442 4.33l6.52-2.894zM113.555 39.614h1.542l.843-.374c-.731.178-1.523.306-2.385.374z"
        fill="#2BB9C1" />
      <path d="M126.4 35.04c-.159 1.517-1.236 3.359-2.774 4.574h5.919c-1.278-1.001-2.311-2.653-3.145-4.575z"
            fill="#2AB8B6" />
      <path
        d="M60.403 82.595c2.682-.523 5.48-.658 8.258-.762 17.096-.636 34.231-.172 51.338.292 2.646.072 5.802.366 6.964 2.057 1.018 1.48-.094 3.228-1.277 4.644-2.851 3.414-6.585 6.789-11.909 7.991-2.026.457-4.168.57-6.29.663-15.061.66-30.198.462-45.216-.591-1.293-.09-10.208-.742-15.456-1.762-4.783-.93-3.771-3.531-1.935-4.904 4.35-3.25 9.295-6.411 15.523-7.628z"
        fill="#E7E8F7" />
      <path
        d="M59.908 81.352c2.682-.524 5.48-.659 8.258-.762 17.096-.636 34.231-.172 51.338.292 2.646.072 5.802.366 6.964 2.056 1.018 1.48-.094 3.228-1.277 4.644-2.851 3.414-6.585 6.79-11.909 7.991-2.026.458-4.168.57-6.29.663-15.062.66-30.198.462-45.216-.591-1.293-.09-10.208-.741-15.456-1.761-4.783-.93-3.772-3.532-1.935-4.905 4.35-3.25 9.295-6.41 15.523-7.627z"
        fill="#F14182" />
      <path
        d="M107.551 87.813c.872.031 1.774.072 2.556.507.782.434 1.262 1.365 1.273 2.345.02 1.712-14.507 6.847-45.736.904-1.12-.213-4.58-.666-3.423-2.552.817-1.33 3.994-1.614 5.25-1.775 5.994-.768 12.367-.088 18.393.007 7.23.114 14.46.302 21.687.564zM58.096 91.156c0 .659-2.542 1.193-5.677 1.193s-5.677-.534-5.677-1.193 2.542-1.193 5.677-1.193 5.677.534 5.677 1.193z"
        fill="#EF2C87" />
      <path
        d="M55.011 83.7a7.81 7.81 0 01.652-3.085c.089-.197.133-.41.133-.626v-2.18c0-.826-.666-1.495-1.488-1.495H50.84c-.822 0-1.488.67-1.488 1.495v2.18c0 .216.044.428.132.626a7.81 7.81 0 01.652 3.085c.014 1.998-1.225 3.382-1.225 4.706v1.361c0 .777.626 1.406 1.398 1.406h4.529c.772 0 1.398-.63 1.398-1.406v-1.361c0-1.324-1.24-2.708-1.226-4.706z"
        fill="#69CFF6" />
      <path d="M54.227 75.64h-3.13v.674h3.13v-.675z" fill="#53435F" />
      <path d="M53.163 74.424h-1.149a.281.281 0 00-.28.282v.933h1.71v-.933a.281.281 0 00-.281-.282z" fill="#3C2D48" />
      <path
        d="M55.796 79.989v-.715h-6.443v.715c0 .216.044.428.132.626a7.81 7.81 0 01.652 3.085c.01 1.268-.486 2.288-.85 3.202h6.574c-.364-.914-.859-1.934-.85-3.202a7.81 7.81 0 01.652-3.085c.089-.197.133-.41.133-.626z"
        fill="#CCDEF4" />
      <path
        d="M49.005 90.26c.198.532.706.913 1.305.913h4.528c.773 0 1.399-.63 1.399-1.406v-1.361c0-1.324-1.24-2.708-1.226-4.706.01-1.47.43-2.595.652-3.085.089-.197.133-.41.133-.626v-2.18a1.49 1.49 0 00-.598-1.191c-.406.653-.322 1.624-.39 2.35a64.764 64.764 0 01-.41 3.404c-.256 1.74-.604 3.524-1.571 4.99-.898 1.362-2.285 2.346-3.822 2.898z"
        fill="#6AB7E8" />
      <path
        d="M55.86 86.902c-.069-.174-.143-.353-.217-.536l-.03-.074a19.747 19.747 0 01-.2-.517 9.586 9.586 0 01-.088-.259l-.033-.095a7.194 7.194 0 01-.103-.36c-.01-.037-.017-.075-.026-.112a5.791 5.791 0 01-.12-.694c-.005-.043-.011-.086-.015-.13a4.883 4.883 0 01-.017-.425 7.81 7.81 0 01.652-3.085c.089-.197.133-.41.133-.626v-.715h-1.02a64.49 64.49 0 01-.377 3.098c-.23 1.563-.538 3.158-1.299 4.53h2.76z"
        fill="#B8C6EA" />
      <path
        d="M100.878 50.039c-.663-3.33-3.607-5.179-6.92-6.436-2.852-1.083-2.92-4.248-2.884-5.107.919-1.257 1.485-2.928 1.485-4.769 0-3.902-2.522-7.064-5.632-7.064s-5.63 3.162-5.63 7.064c0 1.893.596 3.609 1.563 4.877.023.99-.145 3.957-2.889 4.999-3.312 1.257-6.257 3.107-6.92 6.436 0 0-2.134 12.354-.588 13.907 1.546 1.553 6.772.888 6.772.888h15.46s5.226.665 6.772-.888c1.546-1.553-.589-13.907-.589-13.907z"
        fill="#F7947E" />
      <path
        d="M75.853 51.441s1.796 9.08 2.092 10.739v-2.128s-.59-6.008-2.092-8.61zM97.96 50.574c-.371 2.365-1.033 6.64-1.033 6.64l-.528 4.534c1.61-4.295 1.561-11.174 1.561-11.174z"
        fill="#F57778" />
      <path
        d="M96.927 57.214s-1.755-3.073-1.529-7.34c.227-4.27 1.535-5.123 1.535-5.123l-2.098-.797s-1.028 6.094-6.29 6.431c-5.322.342-6.881 1.176-9.94-6.507l-1.603.842c1.082 3.518 1.89 6.929 2.413 10.573.131.914.242 1.865-.033 2.747-.249.799-.8 1.473-1.437 2.012-.19 2.118.026 4.187.196 6.306.17 2.12.13 4.338-.792 6.253-.072.15-.152.32-.098.48.047.137.182.223.311.29.913.476 1.961.6 2.983.718 1.062.12 2.123.242 3.185.364 3.324.38 10.875-.46 12.026-1.03 1.146-.566 1.076-1.123 1.161-1.875.093-.808-.257-1.59-.488-2.369-.562-1.887.05-9.922.498-11.975z"
        fill="#F03B46" />
      <path
        d="M96.912 70.95c-.072-.595-.31-1.179-.483-1.76-.166-.557-.229-1.65-.225-2.963-.256.405-.226 1.156-.33 1.54a7.24 7.24 0 01-1.031 2.212c-.097.139-.205.304-.153.466.056.173.272.242.451.217.18-.024.344-.114.521-.153.448-.097.897.145 1.25.44zM80.663 72.458c.358.102.87.098.956-.266.062-.262-.169-.492-.37-.67-1.97-1.745-3.17-4.34-3.222-6.979l-.062-.404c.051.736.116 1.475.176 2.22.142 1.78.135 3.628-.416 5.309.982.085 1.968.515 2.938.79z"
        fill="#D33985" />
      <path
        d="M92.09 55.41a12.984 12.984 0 001.344-7.88c-.171-1.164-.502-2.3-.686-3.461-.883-5.564 2.052-10.635.71-16.759-.541-2.47-2.288-4.548-4.69-5.304-3.944-1.242-6.844 1.74-6.844 1.74-1.988 1.92-2.365 5-2.014 7.749.35 2.748 1.3 5.403 1.506 8.166.266 3.573-.715 7.245.199 10.708.504 1.911 1.575 3.703 1.447 5.714-.027.407.136.817.46 1.063.4.304.71.086 1.034-.037.876-.332 1.776.033 2.69.363.205.074.58.098.79.045.532-.134.992-.667 1.52-.523.367.1.596.503.966.585.284.063.58-.087.772-.306.192-.219.3-.497.405-.769.1-.258.263-.85.391-1.095z"
        fill="#53435F" />
      <path
        d="M94.904 30.472c0-.652-.677-1.001-1.109-.544.058 1.265-.028 2.49-.178 3.693.907-.75 1.426-1.972 1.287-3.149zM79.91 31.495c-.071-.557-.11-1.127-.114-1.698-.42-.236-.942.103-.942.675-.14 1.177.551 2.547 1.458 3.297l-.002-.017c-.16-.748-.303-1.499-.4-2.257zM110.704 79.918c-2.138-4.064-8.725-1.364-10.026-1.41-1.269-.046-3.607-1.554-4.585-5.263-.1.063-.21.126-.336.189-1.152.569-8.703 1.41-12.027 1.029l-3.185-.364c-.78-.09-1.575-.185-2.313-.436h-.002s-1.112 3.974-1.86 3.974c-.75 0-5.936-3.31-9.035-3.079-3.098.233-4.787 1.82-4.278 5.863.509 4.044 4.255 4.695 5.55 5.903 1.295 1.209 3.608 4.88 10.962 5.16 0 0 3.947.22 5.41 0 3.443-.518 11.948 2.556 20.572-2.874 0 0 7.544-4.149 5.153-8.692z"
        fill="#F7947E" />
      <path
        d="M93.825 74.797c.358-.063 1.523-.296 2.54-.683a10.94 10.94 0 01-.272-.869c-.1.063-.21.126-.336.189-1.152.569-8.703 1.41-12.027 1.029l-3.185-.364c-.78-.09-1.575-.185-2.313-.436h-.002s-.27.962-.632 1.94c5.392-.512 10.888.132 16.227-.806z"
        fill="#F57778" />
      <path
        d="M85.416 91.44c.086-.006.171-.011.261-.014l.118-.001c.09-.002.18-.003.275-.002l.1.002c.105.002.211.005.322.01l.07.002c3.965.178 11.116 1.811 18.432-2.493-.169-2.383-.714-8.257-2.087-10.857-.451.095-.858.189-1.21.264l-.078.017-.146.03-.131.027-.108.02-.131.022-.087.014a3.144 3.144 0 01-.125.015l-.061.007a1.342 1.342 0 01-.153.004 2.041 2.041 0 01-.299-.037c-.029-.005-.058-.013-.088-.02a2.75 2.75 0 01-.259-.072c-.027-.009-.054-.017-.082-.028a4.372 4.372 0 01-1.185-.678l-.009-.006a6.5 6.5 0 01-1.248-1.323c-2.082.64-11.045 3.009-20.033-.416v.001c-.044.11-.088.22-.133.326l-.003.007a7.196 7.196 0 01-.412.84l-.014.023c-.042.07-.084.134-.126.192l-.02.026a1.37 1.37 0 01-.122.143l-.019.019a.628.628 0 01-.12.093l-.014.006a.274.274 0 01-.122.034c-.337 0-1.574-.67-3.115-1.388-1.579.973-4.55 3.586-5.14 9.701v.003c.09.059.176.117.255.176.086.064.167.129.238.195 1.295 1.209 3.608 4.88 10.961 5.16 0 0 3.948.22 5.412 0a5.01 5.01 0 01.334-.038c.033-.003.07-.004.103-.006z"
        fill="#69CFF6" />
      <path
        d="M98.321 77.283a6.84 6.84 0 01-.814-.94c-2.082.64-11.045 3.009-20.033-.416v.001c-.044.11-.088.22-.133.326l-.003.007c-.114.268-.232.511-.35.724.066.03.133.056.2.077 1.074.34 2.17.628 3.262.898 2.184.542 4.405.956 6.65 1.128 3.608.275 7.312-.094 10.65-1.495.201-.084.401-.177.571-.31zM99.322 91.14c-2.63.1-5.281.198-7.88-.217-1.188-.19-2.36-.487-3.559-.593-2.177-.193-4.353.25-6.529.45a31.21 31.21 0 01-3.44.11c-.863-.024-2.217-.421-3.065-.19 1.29.418 2.838.712 4.72.784 0 0 3.947.22 5.41 0a5.01 5.01 0 01.335-.038c.033-.003.07-.004.103-.006.085-.006.17-.011.26-.014l.118-.001c.09-.002.18-.003.275-.002l.1.002c.105.002.211.005.322.01l.07.002c2.916.13 7.554 1.046 12.714-.189.014-.037.033-.071.046-.109z"
        fill="#65BAEB" />
    </svg>
  );
}
