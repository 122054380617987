import React from "react";
import DateAndTimeCard from "../../Components/OneDateAndTimeCard";
import TextService from "../../Services/TextService";
import UrlService from "../../Services/UrlService";
import { useHistory } from "react-router-dom";

export default function OneOnOne({ one }) {
  const [showBooking, setShowBooking] = React.useState(false);
  const [bookingProps, setBookingProps] = React.useState({});

  const history = useHistory();

  if (!one) {
    return null;
  }

  const fields = [];

  if (one.duration) {
    fields.push({ key: "TIME", value: `${one.duration} min` });
  }

  if (one.currency_fee) {
    fields.push({
      key: "FEE",
      value: TextService.formatCurrencyFee(one.currency_fee),
    });
  }

  function onJoin(props) {
    // setBookingProps({
    //   date: props.date,
    //   time: `${props.slot}:00`,
    // });
    // setShowBooking(true);
    history.push(
      `/1-1/${one.id}/payment?date=${props.date}&slot=${props.slot}`
    );
  }

  return (
    <div className="mt-3">
      <h2 className="font-semibold text-xl mb-2">1:1 Yoga Session</h2>
      <div className="mt-5">
        {fields.map((f) => (
          <div key={f.key} className="flex flex-row justify-start text-sm mb-2">
            <p
              className="text-gray-400 tracking-wide"
              style={{ minWidth: 100 }}
            >
              {f.key}:
            </p>
            <p>{f.value}</p>
          </div>
        ))}
      </div>

      <div className="mt-8">
        <DateAndTimeCard one={one} onJoin={onJoin} />
      </div>
    </div>
  );
}
