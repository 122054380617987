import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from '../../Atoms/Modal/Modal';
import UnderlineButton from '../../Atoms/UnderlineButton/UnderlineButton';
import ProfileForm from './ProfileForm';
import HoveringGirl from '../../Graphics/HoveringGirl';
import './styles.css';
import UserAction from '../../Actions/user';

function ProfileModal({show, onClose, updateUser}) {

  function onSuccess(){
    onClose();
  }

  return (
    <Modal show={show} onClose={onClose} className="sm:w-full sm:max-w-3xl">
      <div className="flex flex-row">
        <div className="hidden sm:block bg-y-cream flex-1 flex flex-row justify-center p-8">
          <HoveringGirl width="100%" />
        </div>
        <div className="x-profile-modal p-8 flex-1">
          <div className="pb-12">
            <UnderlineButton selected={true}>Profile</UnderlineButton>
          </div>
          <ProfileForm onSuccess={onSuccess} updateUser={updateUser}></ProfileForm>
        </div>
      </div>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    updateUser: UserAction.updateUser,
  }, dispatch);
}

function mapStateToProps({ ui }) {
  return {
    profileNext: ui.profileNext,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileModal);
