import React from "react";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

export default function SubscriptionCard({
  one,
  onDetails,
  link,
  homeComponent = false,
  homeDesktop = false,
}) {
  const teacher = one.teacher;

  const metaLines = [];
  if (teacher.teaching_years) {
    metaLines.push(`Teaching for ${teacher.teaching_years} years`);
  }

  if (teacher.style) {
    metaLines.push(teacher.style);
  }

  return (
    <Link to={link} onClick={onDetails}>
      <div
        className="bg-white w-full p-5 rounded-md shadow-card-cream-sm max-w-sm cursor-pointer"
        style={{ width: homeComponent ? "170%" : homeDesktop ? "96%" : "" }}
      >
        {one.title && <div>{one.title}</div>}
        <div className="flex flex-row justify-between items-center">
          <div className="text-gray-500">
            {one.classes_count && <div>{one.classes_count} Sessions</div>}
            {one.fee_india && (
              <p className="mr-2 text-green-500">₹ {one.fee_india}</p>
            )}
          </div>
          <div className="flex flex-col text-center justify-center items-center">
            <div
              className="text-center justify-center"
              style={{ textAlign: "center" }}
            >
              {teacher.profile_picture && (
                <img
                  className="object-cover rounded-full w-20 h-20 text-center"
                  src={teacher.profile_picture}
                  alt="Placeholder"
                />
              )}
            </div>
            <div className="pt-2">{teacher.full_name}</div>
          </div>
        </div>

        <div className="flex flex-row items-center justify-between mt-3">
          <div className="flex-1 flex flex-row justify-end items-center">
            <div style={{ width: 100 }}>
              <Button py="py-3" className="w-full">
                Book
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}
