import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserAction from "../../Actions/user";
import Dropdown from "../../Atoms/Dropdown";
import GlowingPicture from "../../Atoms/GlowingPicture";
import Logo from "../../Graphics/Logo";
import { STOREFRONT_HOME_PAGE } from "../../Routes/storefrontRoutes";
import UserSelectors from "../../Selectors/user";

export default function StudioHeader({ onMobileMenu }) {
  const dispatch = useDispatch();
  const profilePicture = useSelector(UserSelectors.profilePicture);
  const history = useHistory();

  function onLogout() {
    dispatch(UserAction.logout());
    history.push('/');
  }

  return (
    <div className="bg-y-cream-600 flex flex-row justify-between items-center p-5">
      <div className="sm:hidden flex flex-row justify-start items-center">
        <svg onClick={onMobileMenu} width="30" height="30" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path stroke="#473F21" strokeWidth="1.5" strokeLinecap="round"
                d="M6.75 7.25h10.5M6.75 11.75h7.5M6.75 16.25h4.5" />
        </svg>
        <Logo height={40} width={120} />
      </div>
      <div />
      <Dropdown
        button={<GlowingPicture imgUrl={profilePicture} />}
        items={[
          {
            key: 'teacher',
            text: 'Switch to student',
            onClick: () => history.push(STOREFRONT_HOME_PAGE),
            icon: (
              <svg className="mr-3 h-4 w-4" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.035 6.096h8.52a.889.889 0 100-1.778h-8.52L5.073 2.28a.889.889 0 00-1.257-1.257L.26 4.579a.89.89 0 00-.059.065l-.023.031c-.01.013-.02.025-.028.039-.009.012-.016.025-.024.038l-.021.036-.02.04-.017.039c-.006.013-.01.027-.015.04l-.015.042-.01.04c-.004.015-.008.03-.01.044-.004.016-.006.032-.008.048l-.006.038a.895.895 0 000 .176l.006.039.007.047.01.044.011.04.015.042.015.04.017.039.02.04.021.036.024.039c.008.013.018.025.028.038L.2 5.77a.886.886 0 00.059.064l3.556 3.556a.889.889 0 001.257-1.257L3.035 6.096zM16.687 12.882c.009-.01.016-.022.024-.032.01-.013.02-.025.028-.038.009-.013.016-.026.024-.04l.02-.035c.008-.013.014-.027.02-.04l.018-.039.015-.04.014-.042.01-.04.012-.044.007-.047.005-.04a.903.903 0 000-.175c0-.013-.003-.026-.005-.039-.003-.015-.004-.031-.008-.046-.002-.015-.006-.03-.01-.044l-.01-.04-.015-.043-.015-.04c-.005-.013-.012-.026-.018-.038-.006-.014-.012-.027-.02-.04l-.02-.037-.024-.038-.028-.038c-.008-.011-.015-.022-.024-.032a.882.882 0 00-.058-.064v-.001l-3.556-3.556a.889.889 0 00-1.257 1.257l2.038 2.038h-8.52a.889.889 0 000 1.778h8.52l-2.038 2.038a.889.889 0 001.257 1.257l3.555-3.555.001-.001c.02-.02.04-.042.058-.064z"
                  fill="currentColor" />
              </svg>
            )
          },
          {
            key: 'logout',
            text: 'Logout',
            onClick: onLogout,
            icon: (
              <svg className="mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
              </svg>
            ),
          }
        ]}
      />
    </div>
  )
}
