import cx from "classnames";
import React from "react";
import { useHistory, withRouter } from "react-router-dom";
import Logo from "../../Graphics/Logo";
import useIsMobile from "../../Hooks/useMobile";
import "./sideMenu.css";

function SideMenu({
  history,
  expanded,
  toggleExpanded,
  setExpanded,
  showMenu,
}) {
  const [overlay, setOverlay] = React.useState(expanded);

  React.useEffect(() => {
    let timeout;

    if (expanded) {
      timeout = setTimeout(() => {
        setOverlay(true);
      }, 50);
    } else {
      timeout = setTimeout(() => {
        setOverlay(false);
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [expanded, setOverlay]);

  React.useEffect(() => {
    setExpanded(false);
  }, [history.location.key, setExpanded]);

  const isMobile = useIsMobile();

  return (
    <div className="sm:w-2/6 x-side-menu">
      {(expanded || overlay) && (
        <div
          style={{ zIndex: 10000 }}
          className={cx(
            "absolute inset-0 bg-black sm:hidden",
            "transition duration-500 ease-in-out",
            expanded && overlay ? "opacity-70" : "opacity-0",
            !isMobile && "hidden"
          )}
          onClick={toggleExpanded}
        />
      )}

      <div
        style={{ zIndex: 10001 }}
        className={cx(
          "absolute sm:static top-0 left-0 w-9/12 sm:w-full h-full",
          "bg-white p-3",
          "transform transition duration-500 ease-in-out",
          !isMobile && "shadow-right-cream",
          isMobile &&
            (expanded ? "translate-x-0 shadow-lg" : "-translate-x-full")
        )}
      >
        <div className="mt-5">
          <Logo height={60} />

          {showMenu && (
            <div className="mt-16 mr-8">
              <MenuButton link="/studio/bookings">Students</MenuButton>
              <MenuButton link="/studio/setup-class">Courses</MenuButton>
              <MenuButton link="/studio/profile">Profile</MenuButton>
              <MenuButton link="/studio/website">Website</MenuButton>
              <MenuButton link="/studio/blogs">Blog</MenuButton>
              {/* <MenuButton link="/studio/students">Students</MenuButton> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function MenuButton({ children, link, className }) {
  const history = useHistory();
  const selected = history.location.pathname.indexOf(link) === 0;

  return (
    <div
      className={cx(
        "block my-7 text-y-yellow text-right cursor-pointer flex justify-end items-center",
        selected && "font-semibold",
        className
      )}
      onClick={() => history.push(link)}
    >
      {children}

      <div
        className={cx(
          "ml-5 bg-y-yellow h-full",
          selected ? "opacity-100" : "opacity-0"
        )}
        style={{ width: 6, height: 16, borderRadius: 20 }}
      >
        &nbsp;
      </div>
    </div>
  );
}

export default withRouter(SideMenu);
