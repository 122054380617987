import dayjs from "dayjs";

export default class SessionService {
  /**
   * Get date text for session
   * @param session
   */
  static getDateText(session) {
    return dayjs.unix(session.utc_start_at).format('MMM D, h:mm a');
  }
}
