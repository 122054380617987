import React from "react";
import { UserApi } from "../../Api/UserApi";
import Button from "../../Atoms/Button/Button";
import ErrorMessage from "../../Atoms/ErrorMessage";
import BookingService from "../../Services/BookingService";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import ConfirmBookingModal from "./ConfirmBookingModal";
import cx from "classnames";
import dayjs from "dayjs";
import SessionCard from "../../Atoms/SessionCard";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import Review from "../../Components/AddReview";

const apis = {
  Upcoming: UserApi.getUpcomingClasses,
  Completed: UserApi.getCompletedClasses,
};

export default function BookingsList({ section, showTabs, ...props }) {
  const { loading, callApi, errorMessage, data } = useApi(apis[section]);
  const [date, setDate] = React.useState(null);

  React.useEffect(() => {
    callApi();
    pageTracking(props);
    ReactPixel.pageView();
  }, [callApi]);

  function onUpdated() {
    callApi();
  }

  if (loading) {
    return <div>Loading classes...</div>;
  }

  if (errorMessage) {
    return (
      <div>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }

  if (data?.data.length === 0) {
    return null;
  }

  return (
    <div>
      {showTabs ? (
        <>
          {data?.data.map((d) => (
            <BookingCard
              key={d.number}
              booking={d}
              onUpdated={onUpdated}
              showTabs={showTabs}
              section={section}
            />
          ))}
        </>
      ) : (
        <>
          {data && data?.data && data?.data.length > 0 && (
            <>
              <DaySelector
                sessions={data?.data}
                date={date}
                setDate={setDate}
              />
              <DaySessions
                sessions={data?.data}
                date={date}
                onUpdated={onUpdated}
                showTabs={showTabs}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

function DaySessions({ date, sessions, onUpdated, showTabs }) {
  const sessionsFiltered = sessions.filter((s) => {
    const startDate = dayjs.unix(s.utc_start_at);
    const endDate = dayjs.unix(s.utc_end_at);

    if (s.course?.repeat_type === "custom") {
      if (endDate >= date && startDate <= date) {
        const daysArr = s.course.repeat_value.split(",");
        const dayName = dayjs(date).format("dddd").toLowerCase();
        const findArr = daysArr.find((ele) => ele === dayName);
        if (findArr) return s;
      }
    } else if (s.type === "live-class") {
      if (s.session.date === dayjs(date).format("YYYY-MM-DD")) return s;
    } else {
      if (endDate >= date && startDate <= date) {
        return s;
      }
    }
  });

  return (
    <div className="mt-4 flex flex-row flex-wrap justify-center sm:justify-start">
      {sessionsFiltered.map((d) => (
        <div key={d.id} className="mb-5 sm:mr-5 w-full max-w-sm">
          <BookingCard
            showTabs={showTabs}
            key={d.number}
            booking={d}
            onUpdated={onUpdated}
          />
        </div>
      ))}
    </div>
  );
}

function DaySelector({ date, setDate, sessions }) {
  const dates = React.useMemo(() => {
    const out = [];
    for (let i = 0; i < 5; i++) {
      const day = dayjs().add(i, "day");

      const filterArr = sessions.filter((s) => {
        const startDate = dayjs.unix(s.utc_start_at);
        const endDate = dayjs.unix(s.utc_end_at);

        if (s.type === "live-class") {
          const day = dayjs().add(i, "day");
          const dayFormatted = day.format("YYYY-MM-DD");
          if (s.session.date === dayFormatted) return s;
        } else {
          if (new Date(day) <= endDate && new Date(day) >= startDate) {
            return s;
          }
        }
      });

      if (filterArr.length === 0) {
        continue;
      }

      const now = dayjs();

      out.push({
        date: new Date(day),
        label: day.isSame(now, "day") ? "Today" : day.format("D MMM"),
      });
    }

    return out;
  }, [sessions]);

  React.useEffect(() => {
    if (!date && dates && dates.length > 0) {
      if (dates[0].date) {
        setDate(dates[0]?.date);
      }
    }
  }, [date, dates, setDate]);

  return (
    <div className="flex flex-row overflow-x-auto py-0">
      {dates.map((d) => (
        <div
          key={d.date}
          onClick={() => setDate(d.date)}
          className={cx(
            "mr-1 whitespace-nowrap rounded-full px-2 py-1 cursor-pointer",
            d.date === date ? "bg-y-yellow text-white" : "text-y-yellow"
          )}
        >
          {d.label}
        </div>
      ))}
    </div>
  );
}

function BookingCard({ booking, onUpdated, showTabs, section }) {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [showReview, setShowReview] = React.useState(false);

  const courseID = booking.id;

  function onJoin() {
    window.open(booking.video_url, "_blank");
  }

  function onConfirm() {
    setShowConfirm(true);
  }

  function onConfirmClose(result) {
    if (result === true) {
      onUpdated();
    }

    setShowConfirm(false);
  }

  const bookingDateText = BookingService.getDateText(booking);

  const handleClose = () => {
    setShowReview(false);
  };

  let actionRight = null;
  if (section === "Completed" && courseID) {
    actionRight = (
      <div className="mt-2">
        <Button
          className="w-full"
          py="py-3"
          onClick={() => {
            setShowReview(true);
          }}
        >
          Write Review
        </Button>
      </div>
    );
  }
  if (booking.video_url) {
    actionRight = (
      <div className="mt-2">
        <Button className="w-full" py="py-3" onClick={onJoin}>
          Join
        </Button>
        <Button
          className="w-full mt-2"
          py="py-3"
          onClick={() => {
            setShowReview(true);
          }}
        >
          Write Review
        </Button>
      </div>
    );
  }

  if (booking.status !== "success") {
    actionRight = (
      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm bg-gray-100 text-gray-800">
        {booking.status_display}
      </span>
    );
  }

  if (booking.status === "pending-student") {
    actionRight = (
      <div className="flex flex-col justify-end items-end ">
        <Button py="py-3" onClick={onConfirm}>
          Confirm 1:1
        </Button>
        <div className="text-xs text-y-gray mt-2">
          Teacher has suggested an alternate time
        </div>
      </div>
    );
  }

  return (
    <>
      {showTabs ? (
        <>
          <div className="bg-white rounded shadow-card-cream-lg p-5 flex flex-row justify-between items-center mt-5">
            <div>
              <div className="font-medium">{booking.title}</div>
              <div className="text-xs text-y-gray mt-1 leading-5">
                <p>
                  {booking.type_display} | Booking ID {booking.number}
                </p>
                {bookingDateText && <p>{bookingDateText}</p>}
              </div>
            </div>
            <div className="flex flex-row justify-end">{actionRight}</div>
          </div>
          {showReview && <Review id={courseID} handleClose={handleClose} />}
        </>
      ) : (
        <>
          <SessionCard
            session={booking.type === "live-class" ? booking.session : booking}
            dateBooking={
              booking.type === "live-class"
                ? dayjs.unix(booking.utc_start_at).format("YYYY-MM-DD")
                : dayjs.unix(booking.utc_start_at).format("YYYY-MM-DD")
            }
            bookingDateText={bookingDateText}
            booking={true}
            actionRight={actionRight}
          />
        </>
      )}

      <ConfirmBookingModal
        show={showConfirm}
        onClose={onConfirmClose}
        booking={booking}
      />
    </>
  );
}
