import React, { useState } from "react";
import Review from "../../Components/AddReview";
import dayjs from "dayjs";

import { useHistory } from "react-router-dom";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { StorefrontApi } from "../../Api/StorefrontApi";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import BookingService from "../../Services/BookingService";
import Swal from "sweetalert2";

export default function ReviewCont(props) {
  const id = props.match.params.id;
  //const [courseData, setCourseData] = useState({});
  const { callApi, data } = useApi(StorefrontApi.bookingDetailsReview);
  const history = useHistory();

  React.useEffect(() => {
    callApi(id);

    pageTracking(props);
    ReactPixel.pageView();
  }, [callApi]);

  const courseData = data?.data || {};

  //console.log(courseData);

  const bookingDateText = BookingService.getDateText(courseData);
  const teacher = courseData?.teacher?.full_name;

  //console.log(bookingDateText);

  return (
    <>
      {/* {isLoggedIn ? (
        <div>
          {courseData && courseData.title && (
            <div className="mb-5 bg-white rounded shadow-card-cream-lg p-5 flex flex-row justify-between items-center mt-5">
              <div>
                <div className="font-medium">{courseData.title}</div>
                <div className="text-xs text-y-gray mt-1 leading-5">
                  <p>{courseData.type_display}</p>
                  <p>Teacher : {teacher}</p>
                  {bookingDateText && <p>{bookingDateText}</p>}
                </div>
              </div>
            </div>
          )}
          <Review
            id={id}
            handleClose={() => {
              history.push("/");
            }}
            courseData={courseData}
          />
        </div>
      ) : (
        <div style={{ position: "relative", height: "80vh" }}>
          <OtpLoginModal payModal={true} />
        </div>
      )} */}

      <div>
        {courseData && courseData.title && (
          <div className="mb-5 bg-white rounded shadow-card-cream-lg p-5 flex flex-row justify-between items-center mt-5">
            <div>
              <div className="font-medium">{courseData.title}</div>
              <div className="text-xs text-y-gray mt-1 leading-5">
                <p>{courseData.type_display}</p>
                <p>Teacher : {teacher}</p>
                {bookingDateText && <p>{bookingDateText}</p>}
              </div>
            </div>
          </div>
        )}
        <Review
          id={id}
          handleClose={() => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Review Saved Successfully",
            });
            history.push("/");
          }}
          courseData={courseData}
        />
      </div>
    </>
  );
}
