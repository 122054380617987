import { Link } from "react-router-dom";
import "./store.css";
import Contact from "../../Pages/Contact";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
const StoreFrontFooter = ({ teacherDomain }) => {
  return (
    <>
      {/*    */}
      <div className="sm:flex flex-row">
        <div className="flex-1">
          <div className="mt-9 flex flex-row items-center flex-wrap">
            <Link
              to="/about"
              className="pb-4 px-0 md:px-0 text-y-yellow-dark nav"
              onClick={() => {
                ReactGA.event({
                  category: "Footer",
                  action: "Routing",
                  label: "About Page",
                });
                ReactPixel.track("track", {
                  category: "Footer",
                  action: "Routing",
                  label: "About Page",
                });
              }}
            >
              About Us
            </Link>
            <Link
              to="/contact"
              className="pb-4 px-0 md:px-5 text-y-yellow-dark nav"
              onClick={() => {
                ReactGA.event({
                  category: "Footer",
                  action: "Routing",
                  label: "Contact Us Page",
                });
                ReactPixel.track("track", {
                  category: "Footer",
                  action: "Routing",
                  label: "Contact Us Page",
                });
              }}
            >
              Contact Us
            </Link>
            <Link
              to="/privacy-policy"
              className="pb-4 px-0 md:px-5 text-y-yellow-dark nav"
              onClick={() => {
                ReactGA.event({
                  category: "Footer",
                  action: "Routing",
                  label: "Privacy Policy Page",
                });
                ReactPixel.track("track", {
                  category: "Footer",
                  action: "Routing",
                  label: "Privacy Policy Page",
                });
              }}
            >
              Privacy Policy
            </Link>
            <Link
              to="/refund-policy"
              className="pb-4 px-0 md:px-5 text-y-yellow-dark nav"
              onClick={() => {
                ReactGA.event({
                  category: "Footer",
                  action: "Routing",
                  label: "Refund Policy",
                });
                ReactPixel.track("track", {
                  category: "Footer",
                  action: "Routing",
                  label: "Refund Policy",
                });
              }}
            >
              Refund Policy
            </Link>
            <Link
              to="/terms-and-conditions"
              className="pb-4 px-0 md:px-5 text-y-yellow-dark nav"
              onClick={() => {
                ReactGA.event({
                  category: "Footer",
                  action: "Routing",
                  label: "Terms & Conditions",
                });
                ReactPixel.track("track", {
                  category: "Footer",
                  action: "Routing",
                  label: "Terms & Conditions",
                });
              }}
            >
              Terms & Conditions
            </Link>
            <Link
              to="/teach"
              className="pb-4 px-0 md:px-5 text-y-yellow-dark nav"
              onClick={() => {
                ReactGA.event({
                  category: "Footer",
                  action: "Routing",
                  label: " Sign up as teacher",
                });
                ReactPixel.track("track", {
                  category: "Footer",
                  action: "Routing",
                  label: " Sign up as teacher",
                });
              }}
            >
              Sign up as teacher
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default StoreFrontFooter;
