import cx from "classnames";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../Atoms/Button/Button";
import MyCourses from "../MyCourses";
import TeacherSubscriptionCourses from "../MyCourses/TeacherSubscriptionCourses";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function Setup({ showMyCourse = true }) {
  const history = useHistory();
  const [selected, setSelected] = React.useState("course");

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);

  const itemTypes = [
    {
      type: "course",
      title: "Monthly Yoga Class",
      description:
        "You teach yoga on a regular weekly schedule to a group of people and stream it live",
      url: "/studio/setup-class/course",
    },
    {
      type: "fixed-course",
      title: "Course",
      description:
        "You teach a course on a topic to a group of and stream it live",
      url: "/studio/setup-class/fixed-course",
    },
    {
      type: "1-1",
      title: "1:1 Sessions",
      description: "You teach privately 1:1 to a student",
      url: "/studio/setup-class/1-1",
    },
    {
      type: "subscription",
      title: "Subscription",
      description: "You create subscriptions for your courses",
      url: "/studio/setup-class/subscription",
    },
  ];

  function onNext() {
    history.push(itemTypes.find((o) => o.type === selected).url);
  }

  return (
    <div className="px-7 sm:px-0 flex-col">
      <Helmet>
        <title>{selected}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={selected}></meta>
        <meta name="description" content={selected} />
        <meta property="og:title" content={selected}></meta>
        <meta property="twitter:title" content={selected}></meta>
        <meta property="og:description" content={selected}></meta>
        <meta property="twitter:description" content={selected}></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div>
        <h2
          className={`text-xl md:text-2xl font-semibold cursor-pointer pb-2 ${
            !showMyCourse ? "mt-10 text-y-yellow-dark" : ""
          }`}
        >
          Set Up Yoga Courses
        </h2>
        <div className="px-7 py-8 bg-white w-full max-w-xl shadow-card-cream sm:rounded-md">
          {itemTypes.map((o) => (
            <Item
              key={o.type}
              title={o.title}
              description={o.description}
              selected={selected === o.type}
              onSelected={() => setSelected(o.type)}
            />
          ))}

          <div className="flex flex-row justify-end mt-8">
            <Button onClick={onNext}>Next</Button>
          </div>
        </div>
      </div>
      {showMyCourse && (
        <div className="mt-4">
          <h2 className="text-xl md:text-2xl font-semibold cursor-pointer pb-2">
            Your Courses
          </h2>
          <MyCourses />
        </div>
      )}
      {showMyCourse && (
        <div className="mt-4">
          <h2 className="text-xl md:text-2xl font-semibold cursor-pointer pb-2">
            Your Subscription Courses
          </h2>
          <TeacherSubscriptionCourses />
        </div>
      )}
      {/* <div className="mt-4">
        <h2 className="text-xl md:text-2xl font-semibold cursor-pointer pb-2">
          Deleted Courses
        </h2>
        <MyCourses deleted={true} />
      </div> */}
    </div>
  );
}

function Item({ title, description, selected, onSelected }) {
  return (
    <div className="flex flex-row py-4" onClick={onSelected}>
      <div
        className={cx(
          "mt-1 cursor-pointer",
          "appearance-none w-5 h-5 border-2 rounded-full",
          selected ? "border-y-yellow" : "border-grey-500"
        )}
      >
        <div
          className={cx(
            "w-full h-full rounded-full border-4 border-white",
            selected && "bg-y-yellow"
          )}
        />
      </div>
      <div className="ml-3 flex-1">
        <h2 className="text-lg font-semibold cursor-pointer">{title}</h2>
        <p className="mt-1 text-y-gray">{description}</p>
      </div>
    </div>
  );
}
