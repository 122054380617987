import cx from "classnames";
import dayjs from "dayjs";
import React, { useState } from "react";
import SessionCard from "../../Atoms/SessionCard";
import { StorefrontApi } from "../../Api/StorefrontApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import useIsMobile from "../../Hooks/useMobile";
import Slider from "react-slick";
import Loader from "../../Atoms/Loader";
import DayClassGroup from "./DayClassGroup";
import CourseAction from "../../Actions/course";
import CourseSelectors from "../../Selectors/course";
import UserSelectors from "../../Selectors/user";
import { useDispatch, useSelector } from "react-redux";

export default function CardTabs({ filter, sessionData, setLiveDate }) {
  const [date, setDate] = React.useState(null);

  const { callApi, data, loading } = useApi(StorefrontApi.sessions);

  const [load, setLoad] = useState(false);

  const liveData = useSelector(CourseSelectors.liveCourse);
  const dispatch = useDispatch();

  const handleApi = (date) => {
    if (filter) {
      setLiveDate(date);
    } else {
      callApi({ date: date })
        .then((res) => {
          setLoad(true);
          dispatch(CourseAction.setLiveCourse(res.data.data));
        })
        .catch((err) => {
          setLoad(true);
          console.log(err);
        });
    }
  };

  React.useEffect(() => {
    if (!filter && liveData.length === 0 && date) {
      handleApi(date);
    } else if (filter && date) {
      setLiveDate(date);
    }
  }, [callApi, date]);

  const sessions = filter ? sessionData : liveData || [];

  return (
    <>
      <DaySelector
        sessions={sessions}
        date={date}
        setDate={setDate}
        handleApi={(val) => handleApi(val)}
        load={load}
      />
      <DaySessions
        sessions={sessions}
        date={date}
        loading={loading}
        filter={filter}
      />
    </>
  );
}

function DaySessions({ date, sessions, loading, filter }) {
  const isMobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const userTimeZone = useSelector(UserSelectors.userTimeZone);
  // const sessionsFiltered = (sessions || []).filter(
  //   (s) =>
  //     dayjs(s.date + " " + s.course.start_time)
  //       .tz(userTimeZone)
  //       .format("YYYY-MM-DD") === date
  // );
  if (sessions.length === 0 && !loading) {
    return (
      <div
        className={cx(
          "mr-1 whitespace-nowrap rounded-full px-3 py-1 cursor-pointer text-y-yellow pt-5"
        )}
      >
        No Class Available
      </div>
    );
  }

  return (
    <>
      {filter ? (
        <DayClassGroup sessions={sessions} />
      ) : isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className="flex flex-nowrap w-full overflow-auto">
              {sessions.map((s) => (
                <div key={s.id} style={{ paddingRight: 90 }}>
                  <SessionCard session={s} homeComponent={true} />
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <div className="flex flex-row justify-between items-center">
              {[1, 2, 3].map((ele) => (
                <Loader />
              ))}
            </div>
          ) : (
            <div className={`${sessions.length > 3 ? "px-0" : "px-0"}`}>
              <Slider {...settings}>
                {sessions.map((s) => (
                  <SessionCard session={s} homeDesktop={true} />
                ))}
              </Slider>
            </div>
          )}
        </>
      )}
    </>
  );
}

function DaySelector({ date, setDate, sessions, handleApi, load }) {
  const userTimeZone = useSelector(UserSelectors.userTimeZone);

  const [dates, setDates] = React.useState([]);

  // const dates = React.useMemo(() => {
  //   setDate(null);
  //   const out = [];
  //   for (let i = 0; i < 5; i++) {
  //     const day = dayjs().tz(userTimeZone).add(i, "day");
  //     const dayFormatted = day.format("YYYY-MM-DD");

  //     // if (
  //     //   !sessions.filter(
  //     //     (s) =>
  //     //       dayjs(s.date + " " + s.course.start_time)
  //     //         .tz(userTimeZone)
  //     //         .format("YYYY-MM-DD") === dayFormatted
  //     //   ).length
  //     // ) {
  //     //   // no classes on this date
  //     //   continue;
  //     // }

  //     const now = dayjs().tz(userTimeZone);

  //     out.push({
  //       date: dayFormatted,
  //       label: day.isSame(now, "day") ? "Today" : day.format("D MMM"),
  //     });
  //   }

  //   return out;
  // }, [sessions, userTimeZone]);

  React.useEffect(() => {
    if (dates.length === 0) {
      const out = [];
      for (let i = 0; i < 5; i++) {
        const day = dayjs().tz(userTimeZone).add(i, "day");
        const dayFormatted = day.format("YYYY-MM-DD");

        const now = dayjs().tz(userTimeZone);

        out.push({
          date: dayFormatted,
          label: day.isSame(now, "day") ? "Today" : day.format("D MMM"),
        });
      }

      setDates([...out]);
    }
    if (!date) {
      if (sessions.length > 0) {
        setDate(sessions[0].date);
      } else {
        if (dates && dates.length > 0) {
          if (dates[0].date) {
            setDate(dates[0]?.date);
          }
        }
      }
    }

    const check =
      date === dates[0]?.date &&
      dayjs()
        .tz(userTimeZone)
        .add(0, "day")
        .isSame(dayjs(date).tz(userTimeZone), "day");

    if (
      load &&
      sessions &&
      sessions.length === 0 &&
      dates.length > 0 &&
      check
    ) {
      setDates(dates.slice(1));
      setDate(dates[1]?.date);
    }
  }, [date, dates, setDate, sessions]);

  return (
    <div className="flex flex-row overflow-x-auto py-3">
      {dates.map((d) => (
        <div
          key={d.date}
          onClick={() => {
            setDate(d.date);
            handleApi(d.date);
          }}
          className={cx(
            "mr-1 whitespace-nowrap rounded-full px-2 py-1 cursor-pointer",
            d.date === date ? "bg-y-yellow text-white" : "text-y-yellow"
          )}
        >
          {d.label}
        </div>
      ))}
    </div>
  );
}
