import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import UserAction from "../../Actions/user";
import { UserApi } from "../../Api/UserApi";
import UserSelectors from "../../Selectors/user";

export default function RefreshUserMe() {
  const dispatch = useDispatch();
  const user = useSelector(store => UserSelectors.user(store));
  const userRef = React.useRef();

  // keep user in sync with a ref
  React.useEffect(() => {
    userRef.current = user;
  }, [user])

  // fetch if user exists
  React.useEffect(() => {
    if (userRef.current) {
      UserApi.getUserMe()
        .then(response => {
          dispatch(UserAction.set(response.data.data))
        })
    }
  }, [dispatch])

  return null;
}
