import { post } from "./api";

function uploadMedia(type, file) {
  const data = new FormData();
  data.append("file", file);

  return post(`/media/upload/${type}/`, data, null, {
    "Content-Type": "multipart/form-data",
  });
}

export const MediaApi = {
  uploadMedia,
};
