import React, { useState } from "react";
import dayjs from "dayjs";
import { StorefrontApi } from "../../Api/StorefrontApi";
import CourseCard from "../../Atoms/CourseCard";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import Vent from "../../vent";
import CardGroup from "./CardGroup";
import Button from "../../Atoms/Button/Button";
import { useHistory, useLocation } from "react-router-dom";
import cx from "classnames";
import Checkbox from "../../Atoms/Checkbox";
import "./store.css";
import MonthlyClassesGraphic from "../../Graphics/MonthlyClassesGraphic";
import useIsMobile from "../../Hooks/useMobile";
import FilterDatasEnum from "../../Utilities/FilterData";
import Loader from "../../Atoms/Loader";
import CardTabs from "./CardTabs";
import axios from "axios";
import Modal from "../../Atoms/Modal/Modal";
import Copy from "../../Assets/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";

const LinkModal = ({ show, onClose, link }) => {
  const [showText, setShowText] = useState("");
  return (
    <Modal
      show={show}
      onClose={() => {
        setShowText("");
        onClose();
      }}
      className="sm:w-full sm:max-w-md"
    >
      <div className="p-10 flex items-center justify-center flex-col">
        <div className="flex flex-row items-center">
          <div>{link}</div>
          <CopyToClipboard
            text={link}
            onCopy={() => setShowText("Text Copied")}
          >
            <div role="button" className="pl-5">
              <img className="w-10 h-10" src={Copy} alt="logo" />
            </div>
          </CopyToClipboard>
        </div>
        <div className="pt-3 text-sm" style={{ color: "rgb(15 118 110)" }}>
          {showText}
        </div>
      </div>
    </Modal>
  );
};

export default function FilterClasses(props) {
  const isMobile = useIsMobile();
  const history = useHistory();
  const location = useLocation();
  const { callApi, data, loading } = useApi(StorefrontApi.filterCourses);
  const [queryText, setQueryText] = useState("");
  const [show, setShow] = useState(false);
  const courses = data?.data || [];
  const [filterType, setFilterType] = useState("month");
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [link, setLink] = useState("");

  const [filterArrData, setFilterArrData] = useState({
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
  });

  const [filterArrShow, setFilterArrShow] = useState({
    levels: true,
    price: false,
    healthConditions: false,
    time: false,
    style: false,
    sort: false,
  });

  const [filterData, setFilterData] = useState({ ...FilterDatasEnum });

  const handleApplyFilter = (reset = false) => {
    setShow(false);
    const bannerQueryText = params.get("banner");
    const titleQueryText = params.get("courseName");
    if (reset) {
      setQueryText("");
      setFilterArrData({
        levels: [],
        price: [],
        healthConditions: [],
        time: [],
        sort: [],
        style: [],
      });
      setFilterData({ ...FilterDatasEnum });
      history.replace({
        pathname: location.pathname,
        search: `?name=&type=${filterType}&courseName=${titleQueryText}&banner=${bannerQueryText}`,
        state: {
          ...props?.location?.state,
        },
      });
      const formatData = {
        levels: [],
        price: [],
        healthConditions: [],
        time: [],
        style: [],
        sort: [],
        queryText: "",
        type: filterType,
      };
      callApi(formatData);
    } else {
      const formatFilterArr = JSON.stringify(filterArrData);
      const formatFilter = JSON.stringify(filterData);

      history.replace({
        pathname: location.pathname,
        search: `?name=${queryText}&courseName=${titleQueryText}&banner=${bannerQueryText}&type=${filterType}&filterArr=${formatFilterArr}&filter=${formatFilter}`,
        state: {
          ...props?.location?.state,
        },
      });
      const formatLevels = filterArrData.levels.map((ele) => ele.value);
      const formatData = {
        ...filterArrData,
        levels: [...formatLevels],
        queryText: queryText,
        type: filterType,
      };
      callApi(formatData);
    }
  };

  React.useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
    const search = props?.location?.search;
    const params = new URLSearchParams(search);
    const queryString = params.get("name");
    const filterType = params.get("type");
    if (queryString) {
      setQueryText(queryString);
    } else {
      setQueryText("");
    }
    if (filterType) {
      setFilterType(filterType);
    } else {
      setFilterType("month");
    }
    const filterItems = JSON.parse(params.get("filterArr"));
    const filterCheckItems = JSON.parse(params.get("filter"));

    if (filterItems) {
      setFilterArrData({ ...filterItems });
      if (filterCheckItems) {
        setFilterData({ ...filterCheckItems });
      } else {
        setFilterData({ ...FilterDatasEnum });
      }

      const formatLevels = filterItems.levels.map((ele) => ele.value);
      const formatData = {
        ...filterItems,
        levels: [...formatLevels],
        queryText: queryString,
        type: filterType,
      };
      callApi(formatData);
    } else {
      const filterArr = {
        levels: [],
        price: [],
        healthConditions: [],
        time: [],
        style: [],
        queryText: queryString,
        type: filterType,
        sort: [],
      };
      callApi(filterArr);
    }
  }, [callApi, props?.location?.search]);

  const showFilter = props?.showFilter ? props.showFilter : false;
  const filterText = props?.filterText ? props.filterText : "";

  function onCourseDetails(course) {
    Vent.onClassDetails(Vent.TYPE_MONTHLY_CLASS, course.title);
  }

  const params = new URLSearchParams(props?.location?.search);

  const bannerQuery = JSON.parse(params.get("banner"));
  const titleQuery = params.get("courseName");

  const handleChangeCheck = (name, value) => {
    const key = name;
    const originalDatas = filterData[key].find((ele) => ele.value === value);
    const queryListDatas = filterArrData[key].find(
      (ele) => ele.value === value
    );

    if (queryListDatas) {
      const queryFilterDatas = filterArrData[key].filter(
        (ele) => ele.value !== value
      );
      const newArr = filterData[key].map((ele) => {
        if (ele.value === value) {
          return { ...ele, checked: false };
        } else {
          return ele;
        }
      });
      setFilterData({
        ...filterData,
        [key]: [...newArr],
      });
      setFilterArrData({
        ...filterArrData,
        [key]: [...queryFilterDatas],
      });
    } else {
      const newArr = filterData[key].map((ele) => {
        if (ele.value === value) {
          return { ...ele, checked: true };
        } else {
          return ele;
        }
      });
      setFilterData({
        ...filterData,
        [key]: [...newArr],
      });
      setFilterArrData({
        ...filterArrData,
        [key]: [...filterArrData[key], originalDatas],
      });
    }
  };

  const checkBoxComponent = () => {
    return (
      <div className="w-full mx-2 md:mx-0 my-2">
        <div
          className="block flex justify-end pr-0 cursor-pointer"
          onClick={() => {
            if (props.showFilter) {
              props.setShowFilter();
            }
            setShow(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35px"
            height="35px"
            viewBox="0 0 50 50"
            style={{ fill: "grey" }}
          >
            <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
          </svg>
        </div>
        <div className="block md:hidden flex mb-4 mt-1">
          <input
            class="pl-10 w-11/12 md:w-2/4 border-2 border-gray-300 bg-white h-10 px-5 rounded-lg text-sm focus:outline-none"
            type="text"
            name="search"
            value={queryText}
            placeholder="Find your yoga practice"
            onChange={(e) => {
              setQueryText(e.target.value);
            }}
          />
          <button type="submit" class="absolute mt-2 ml-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25px"
              height="25px"
              viewBox="0 0 50 50"
              style={{ fill: "grey" }}
            >
              <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
            </svg>
          </button>
        </div>
        <div className="sm:flex flex-row w-11/12 justify-between">
          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    levels: !filterArrShow.levels,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Level</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.levels &&
                  filterData.levels &&
                  filterData.levels.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="levels"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.levels &&
                  filterData.levels.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="levels"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    time: !filterArrShow.time,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Time</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.time &&
                  filterData.time &&
                  filterData.time.map((ele) => (
                    <Checkbox
                      label={
                        <>
                          {ele.label.split("(").map((item, i) => {
                            return (
                              <p className="pb-1">
                                {i === 1 ? "(" : ""}
                                {item}
                              </p>
                            );
                          })}
                        </>
                      }
                      value={ele.value}
                      name="time"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.time &&
                  filterData.time.map((ele) => (
                    <Checkbox
                      label={
                        <>
                          {ele.label.split("(").map((item, i) => {
                            return (
                              <p className="pb-1">
                                {i === 1 ? "(" : ""}
                                {item}
                              </p>
                            );
                          })}
                        </>
                      }
                      value={ele.value}
                      name="time"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    healthConditions: !filterArrShow.healthConditions,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Health Conditions</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.healthConditions &&
                  filterData.healthConditions &&
                  filterData.healthConditions.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="healthConditions"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.healthConditions &&
                  filterData.healthConditions.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="healthConditions"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    style: !filterArrShow.style,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Style</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.style &&
                  filterData.style &&
                  filterData.style.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="style"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.style &&
                  filterData.style.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="style"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    price: !filterArrShow.price,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Price</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.price &&
                  filterData.price &&
                  filterData.price.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      name="price"
                      value={ele.value}
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.price &&
                  filterData.price.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      name="price"
                      value={ele.value}
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>

          <div>
            <div
              onClick={() => {
                if (isMobile) {
                  setFilterArrShow({
                    ...filterArrShow,
                    sort: !filterArrShow.sort,
                  });
                }
              }}
              className="font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
            >
              <div>Sort</div>
              <div className="pt-2 block md:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="25px"
                  viewBox="0 0 50 50"
                  style={{ fill: "grey" }}
                >
                  <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                </svg>
              </div>
            </div>
            {isMobile ? (
              <>
                {filterArrShow.sort &&
                  filterData.sort &&
                  filterData.sort.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="sort"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            ) : (
              <>
                {filterData.sort &&
                  filterData.sort.map((ele) => (
                    <Checkbox
                      label={ele.label}
                      value={ele.value}
                      name="sort"
                      onChange={(e) => {
                        handleChangeCheck(e.target.name, e.target.value);
                      }}
                      checked={ele.checked}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
        <div className="flex">
          {!showFilter && (
            <button
              className={cx(
                "focus:outline-none",
                `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
                "cursor-pointer text-y-yellow-dark"
              )}
              style={{ backgroundColor: "#ffffff" }}
              onClick={() => {
                setFilterArrData({
                  levels: [],
                  price: [],
                  healthConditions: [],
                  time: [],
                  style: [],
                  sort: [],
                });
                handleApplyFilter(true);
              }}
            >
              Reset
            </button>
          )}

          <Button
            py={`py-3 ml-2 ${!showFilter ? "md:ml-10" : "md:ml-0"}`}
            onClick={() => {
              if (showFilter) {
                const formatFilterArr = JSON.stringify(filterArrData);
                const formatFilter = JSON.stringify(filterData);

                const banners = JSON.stringify([
                  "A regular practice helps you progress.",
                  "Find a monthly yoga class that works for you.",
                ]);

                const titleQuereyName = "Monthly Yoga Courses";

                history.push({
                  pathname: "/courses-all",
                  search: `?name=${
                    isMobile ? queryText : filterText
                  }&courseName=${titleQuereyName}&banner=${banners}&type=${filterType}&filterArr=${formatFilterArr}&filter=${formatFilter}`,
                  state: {
                    filterDatas: filterArrData,
                    filterCheckData: filterData,
                    name: "Monthly Yoga Courses",
                    bannerText: [
                      "A regular practice helps you progress.",
                      "Find a monthly yoga class that works for you.",
                    ],
                  },
                });
              } else {
                handleApplyFilter();
              }
            }}
          >
            Search
          </Button>
          {showFilter && (
            <button
              className={cx(
                "focus:outline-none",
                `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
                "cursor-pointer text-y-yellow-dark ml-4 md:ml-10"
              )}
              style={{ backgroundColor: "#ffffff" }}
              onClick={() => {
                props.setShowFilter();
              }}
            >
              Close
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="block md:hidden">
        {(show || showFilter) && (
          <>
            <div
              className="fixed z-10 w-screen h-screen top-0 left-0"
              style={{ backgroundColor: "rgba(0,0,0,0.4)" }}
            >
              <div className="fixed right-0 w-4/6 bg-y-cream-600 top-0 h-screen z-10 p-3 overflow-auto">
                {checkBoxComponent()}
              </div>
            </div>
          </>
        )}
      </div>
      <>
        {/* {!showFilter && (
          <div
            className={cx(
              "flex flex-row justify-between items-center overflow-x-auto",
              "h-40 sm:h-full sm:w-4/5"
            )}
          >
            <div className="text-sm sm:text-lg text-gray-600 font-semibold pr-2 pt-0 sm:pt-5 sm:w-2/4">
              {bannerQuery &&
                bannerQuery.map((t) => (
                  <p className="mt-2" key={t}>
                    {t}
                  </p>
                ))}
            </div>
            <div>
              <MonthlyClassesGraphic
                width={isMobile ? 170 : 300}
                height={130}
              />
            </div>
          </div>
        )} */}

        <div className="flex-1">
          <div className="px-0 mt-0 flex flex-col w-full">
            {!showFilter && (
              <>
                <div
                  className={cx(
                    "hidden sm:inline-block cursor-pointer text-lg",
                    "font-semibold",
                    "text-y-yellow-dark pl-0 md:pl-1 mt-10"
                  )}
                >
                  {titleQuery}
                </div>
                <div class="px-0 md:px-0 mx-0 flex flex-row justify-between items-center pb-0 relative md:w-4/5 mt-5">
                  {isMobile ? (
                    <div
                      className={cx(
                        "inline-block cursor-pointer text-lg",
                        "font-semibold",
                        "text-y-yellow-dark pl-0 md:pl-1 mt-0"
                      )}
                    >
                      {titleQuery}
                    </div>
                  ) : (
                    <div className="hidden md:block sm:w-4/5 pt-1">
                      <div className="flex flex-row mb-4 mt-1 ml-0">
                        <input
                          class="relative pl-10 w-full border-2 border-gray-300 bg-white h-12 px-5 pr-16 rounded-full text-sm focus:outline-none"
                          type="text"
                          name="search"
                          value={queryText}
                          placeholder="Find your yoga practice"
                          onKeyDown={(e) => {
                            if ((e.key === "Enter") | !e.key)
                              handleApplyFilter();
                          }}
                          onChange={(e) => {
                            setQueryText(e.target.value);
                          }}
                        />
                        <button
                          type="submit"
                          class="absolute mt-2 top-3 left-1 ml-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            width="25px"
                            height="25px"
                            viewBox="0 0 50 50"
                            style={{ fill: "grey" }}
                          >
                            <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => setShow(!show)}
                  >
                    <div>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 20 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line y1="1.052" x2="20" y2="1.052" stroke="#686868" />
                        <line
                          x1="5"
                          y1="6.052"
                          x2="15"
                          y2="6.052"
                          stroke="#686868"
                        />
                        <line
                          x1="8"
                          y1="11.052"
                          x2="13"
                          y2="11.052"
                          stroke="#686868"
                        />
                      </svg>
                    </div>
                    <div className="pl-2 font-semibold text-gray-600">
                      Filter
                    </div>
                  </div>
                </div>
              </>
            )}
            {(show || showFilter) && (
              <div className="hidden md:block w-full mx-0 my-3">
                {checkBoxComponent()}
              </div>
            )}
            <Button
              py={`w-44 md:w-44 py-3 px-0 whitespace-nowrap my-2`}
              onClick={() => {
                axios
                  .get(
                    `https://tinyurl.com/api-create.php?url=${window.location.href}`
                  )
                  .then((res) => {
                    setLink(res.data);
                    setShowLinkModal(true);
                  })
                  .catch((err) => alert("Some error occured"));
              }}
            >
              Get link to share
            </Button>
            {!showFilter && (
              <div className="px-1 md:px-3 pt-3">
                {loading ? null : (
                  <>
                    {courses.length} results{" "}
                    {queryText ||
                    filterArrData.levels.length > 0 ||
                    filterArrData.price.length > 0 ||
                    filterArrData.healthConditions.length > 0 ||
                    filterArrData.style.length > 0 ||
                    filterArrData.sort.length > 0 ||
                    filterArrData.time.length > 0 ? (
                      <>for</>
                    ) : null}{" "}
                    {queryText && <>"{queryText}", </>}
                    {filterArrData.levels &&
                      filterArrData.levels.map((ele) => (
                        <> "{ele.label}", </>
                      ))}{" "}
                    {filterArrData.price &&
                      filterArrData.price.map((ele) => <> "{ele.label}", </>)}
                    {filterArrData.healthConditions &&
                      filterArrData.healthConditions.map((ele) => (
                        <> "{ele.label}", </>
                      ))}
                    {filterArrData.time &&
                      filterArrData.time.map((ele) => <> "{ele.label}", </>)}
                    {filterArrData.style &&
                      filterArrData.style.map((ele) => <> "{ele.label}", </>)}
                    {filterArrData.sort &&
                      filterArrData.sort.map((ele) => <> "{ele.label}", </>)}
                  </>
                )}
              </div>
            )}

            {loading ? (
              <>
                {isMobile ? (
                  <Loader />
                ) : (
                  <div className="flex flex-row justify-between items-center">
                    {[1, 2, 3].map((ele) => (
                      <Loader />
                    ))}
                  </div>
                )}
              </>
            ) : filterType === "live" ? (
              <>
                <CardTabs sessionData={courses} filter={true} />
              </>
            ) : (
              <>
                <CardGroup
                  cards={courses.map((c) => ({
                    key: c.id,
                    Component: (
                      <CourseCard
                        link={`/monthly-courses/${c.slug}-${c.id}`}
                        course={c}
                        onDetails={() => onCourseDetails(c)}
                      />
                    ),
                  }))}
                />
              </>
            )}
          </div>
        </div>
      </>

      <LinkModal
        show={showLinkModal}
        onClose={() => {
          setShowLinkModal(false);
        }}
        link={link}
      />
    </>
  );
}
