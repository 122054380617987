import React from "react";
import CourseCard from "../../Atoms/CourseCard";
import Vent from "../../vent";

export default function Courses({ courses, fixedCourses }) {
  const allCourses = [...courses, ...fixedCourses];
  if (allCourses.length === 0) {
    return null;
  }

  function onCourseDetails(course) {
    if (course.type === "course") {
      Vent.onClassDetails(Vent.TYPE_COURSE, course.title);
    } else {
      Vent.onClassDetails(Vent.TYPE_MONTHLY_CLASS, course.title);
    }
  }

  return (
    <div className="mt-3">
      <h2 className="font-semibold text-xl mb-2">Courses</h2>
      <div className="mt-3 flex flex-col flex-wrap justify-center sm:justify-start">
        {allCourses.map((c) => (
          <div key={c.id} className="mb-5 sm:mr-5">
            <CourseCard
              link={
                c.type === "course"
                  ? `/fixed-courses/${c.slug}-${c.id}`
                  : `/monthly-courses/${c.slug}-${c.id}`
              }
              course={c}
              onDetails={() => onCourseDetails(c)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
