import { useEffect, useRef } from "react";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import cx from "classnames";
import Button from "../../Atoms/Button/Button";
import useIsMobile from "../../Hooks/useMobile";
import Slider from "react-slick";

import { useForm } from "react-hook-form";
import Form from "../../Atoms/Form";
import Input from "../../Atoms/Input/Input";
import { UserApi } from "../../Api/UserApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import Swal from "sweetalert2";

import Banner from "./images/banner.svg";
import Banner1 from "./images/banner1.svg";
import Teacher1 from "./images/teacher1.svg";
import Teacher2 from "./images/teacher2.svg";
import Teacher3 from "./images/teacher3.svg";
import "./style.css";

const arr = [
  {
    name: "Swathi",
    image: Teacher1,
    meta: ">15 years experience M.Sc Nutrition",
  },
  {
    name: "Indhu",
    image: Teacher2,
    meta: ">10 years experience M.Sc Nutrition",
  },
  {
    name: "Adarsha",
    image: Teacher3,
    meta: ">5 years experience Diploma in Nutrition",
  },
];

const Diet = (props) => {
  const myRef = useRef(null);
  const isMobile = useIsMobile();
  const { register, handleSubmit } = useForm({});
  const {
    loading,
    callApi: createUserApi,
    errorMessage,
  } = useApi(UserApi.createDietUser);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    //initialSlide: indexData.onoOnOne,
  };

  function onSubmit(data) {
    createUserApi({ ...data, course: "Diet" })
      .then((res) => {
        window.open("https://rzp.io/l/jzMoGrtA", "_blank");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  }

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);

  return (
    <div className="p-0">
      <Helmet>
        <title>Aahara - Personalized Nutrition Coaching by Do Yoga</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="title"
          content="Aahara - Personalized Nutrition Coaching by Do Yoga"
        ></meta>
        <meta
          name="description"
          content="Get daily diet plans from your personal nutrition coach helping you achieve your health goals."
        />
        <meta
          property="og:title"
          content="Aahara - Personalized Nutrition Coaching by Do Yoga"
        ></meta>
        <meta
          property="twitter:title"
          content="Aahara - Personalized Nutrition Coaching by Do Yoga"
        ></meta>
        <meta
          property="og:description"
          content="Get daily diet plans from your personal nutrition coach helping you achieve your health goals."
        ></meta>
        <meta
          property="twitter:description"
          content="Get daily diet plans from your personal nutrition coach helping you achieve your health goals."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>

      <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5">
        <h2 className="mt-5 text-y-yellow-dark">
          Aahara : Personalized Nutrition Coaching
        </h2>
      </div>

      <div
        className={cx(
          "px-0 flex flex-row  justify-between items-start overflow-x-auto mt-2 md:mt-2",
          "sm:h-full sm:w-4/5"
        )}
      >
        <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-0 sm:w-2/4">
          <p
            className="pt-1 pb-4 text-black text-base font-bold"
            style={{ fontSize: "14px" }}
          >
            Aahara, meaning "food" in Sanskrit, is our personalized nutrition
            coaching service. <br /> <br /> Elevate your well-being with
            tailored daily diet plans designed to help you achieve your health
            goals.
          </p>

          <div className="flex pt-0">
            <Button
              py="py-3"
              className="whitespace-nowrap"
              onClick={() => {
                executeScroll();
                //window.open("https://rzp.io/l/jzMoGrtA", "_blank");
              }}
            >
              Sign Up
            </Button>
          </div>
        </div>
        <div className="banner-img">
          <img src={Banner} alt="banner" />
        </div>
      </div>

      <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5">
        <h2 className="mt-5 text-y-yellow-dark">
          Nutrition Plans Tailored to Your Goals
        </h2>
      </div>

      <p
        className="pt-2 pb-4 text-black text-base"
        style={{ fontSize: "14px" }}
      >
        At Aahara, our certified nutritionists craft{" "}
        <span className="font-bold">customized meal plans</span> aimed at
        supporting{" "}
        <span className="font-bold">
          {" "}
          weight loss, weight gain, PCOD management, diabetes management,
          increased energy, improved performance, and overall wellness.
        </span>
        Our approach prioritizes meeting your health objectives through mindful
        and nourishing eating habits.
      </p>

      <div className="banner-big">
        <img src={Banner1} alt="banner" />
      </div>

      <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5">
        <h2 className="mt-5 text-y-yellow-dark">
          How does it work once you onboard to nutrition coaching?
        </h2>
      </div>

      <div>
        <p
          className="pt-2 pb-4 text-black text-base"
          style={{ fontSize: "14px" }}
        >
          <span className="font-bold">1. Understanding Your Needs:</span>
          Begin with a detailed call with our nutrition coach to outline your
          health goals and preferences. <br />
          <br />
          <span className="font-bold">2. Crafting Your Plan:</span> Our
          nutritionists create a detailed nutrition plan aligned with your goals
          and eating habits. <br />
          <br />
          <span className="font-bold">3. Follow-Up and Support:</span> Stay
          connected, share your progress, and seek guidance as you follow the
          plan.
          <br />
          <br /> <span className="font-bold">4. Ongoing Adjustments:</span>{" "}
          Connect every 10 days for reviews and adjustments to keep you on
          track.
          <br />
          <br />
          <span className="font-bold">5. Long-Term Success:</span> Monthly
          assessment to plan further coaching or self-management guidance.
        </p>
      </div>

      <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5">
        <h2 className="mt-5 text-y-yellow-dark">
          Pricing for Nutrition Coaching
        </h2>
      </div>

      <div className="text-lg md:text-xl font-bold pr-2 pt-2 sm:pt-2 sm:w-2/4">
        <p
          className="pt-1 pb-4 text-black text-base font-bold"
          style={{ fontSize: "14px" }}
        >
          Get a personal nutrition coach assigned at just ₹2500 per month.{" "}
          <br />
          <br /> Enjoy a 20% discount on 3-month plans. Transform your
          well-being with Aahara.
        </p>
      </div>

      <div className="flex pt-0">
        <Button
          py="py-3"
          className="whitespace-nowrap"
          onClick={() => {
            window.open("https://rzp.io/l/jzMoGrtA", "_blank");
          }}
        >
          Pay Rs.2500
        </Button>
      </div>

      <div className="text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5">
        <h2 className="mt-5 text-y-yellow-dark">Our Nutrition Coaches</h2>
      </div>

      <div className="w-full px-0 pt-5">
        {isMobile ? (
          <div className="flex flex-nowrap w-full overflow-auto mt-1 px-0">
            {arr &&
              arr.map((ele) => (
                <>
                  <div className="p-0 pt-0 rounded-md cursor-pointer">
                    <div className="flex flex-col text-center justify-center w-32 sm:w-44 mr-5 sm:mr-0">
                      <div className="text-center items-center self-center">
                        <img
                          className="object-cover text-center rounded-full w-32 h-32 sm:w-44 sm:h-44"
                          src={ele.image}
                          alt="Placeholder"
                        />
                      </div>

                      <div className="pt-3 text-center">
                        <div className="text-gray-700 font-bold text-sm text-center">
                          {ele.name}
                        </div>
                        <div className="text-xs text-gray-500 leading-5 text-center">
                          <div className="flex flex-row items-center justify-center">
                            {ele.meta}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
          </div>
        ) : (
          <div className={``}>
            <Slider {...settings}>
              {arr &&
                arr.map((ele) => (
                  <>
                    <div className="p-0 pt-0 rounded-md cursor-pointer">
                      <div className="flex flex-col text-center justify-center w-32 sm:w-44 mr-5 sm:mr-0">
                        <div className="text-center items-center self-center">
                          <img
                            className="object-cover text-center rounded-full w-32 h-32 sm:w-44 sm:h-44"
                            src={ele.image}
                            alt="Placeholder"
                          />
                        </div>

                        <div className="pt-3 text-center">
                          <div className="text-gray-700 font-bold text-sm text-center">
                            {ele.name}
                          </div>
                          <div className="text-xs text-gray-500 leading-5 text-center">
                            <div className="flex flex-row items-center justify-center">
                              {ele.meta}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </Slider>
          </div>
        )}
      </div>

      <h2 className="mt-2 text-y-yellow-dark text-lg md:text-xl font-bold mt-10">
        Tell us your details and we will reach out to you
      </h2>
      <div className="w-full md:w-2/5 pt-5 mt-5 mb-5" ref={myRef}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Input
              required
              name="name"
              label="Name"
              inputRef={register({ required: true })}
              className=""
            />
          </div>

          <div className="">
            <Input
              required
              name="phone"
              label="Phone"
              inputRef={register({ required: true })}
            />
          </div>

          <div className="flex pt-0">
            <Button py="py-3" className="whitespace-nowrap" type="submit">
              Submit & Book
            </Button>
          </div>
        </Form>
      </div>
      <p
        className="pt-1 pb-4 text-black text-base font-bold"
        style={{ fontSize: "14px" }}
      >
        Our counselor will reach you to understand your needs and assign a
        nutritionist to you.
      </p>
    </div>
  );
};

export default Diet;
