import _ from 'lodash';
import React from 'react';
import { Controller } from "react-hook-form";
import DayPeriodSelector from "../DayPeriodSelector";
import ErrorMessage from "../ErrorMessage";
import { FormContext } from "../Form";


export default function DayPeriodSelectorField({ slots, name, required, control, formErrors }) {
  const formCtx = React.useContext(FormContext);
  const fieldErrors = formCtx.errors[name] || [];

  const e = [_.get(formErrors, `${name}.message`), ...fieldErrors].filter(o => !!o);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: !!required }}
      render={({ onChange, value }) => {
        return (
          <div>
            <DayPeriodSelector
              slots={slots}
              onChange={onChange}
              value={value}
            />

            {e.length > 0 && (
              <div className="mt-2">
                {e.map(e => (
                  <ErrorMessage>{e}</ErrorMessage>
                ))}
              </div>
            )}
          </div>
        )
      }}
    />
  );
}
