import CoverImage from "./CoverImage";
import Teacher from './Teacher';
import School from './School';

const SessionDetailsComponents = {
  CoverImage,
  Teacher,
  School,
};

export default SessionDetailsComponents;
