import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CourseDetailsPage from "../../Components/CourseDetailsPage";
import Sessions from "../TeacherDomainPage/Sessions";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { StorefrontApi } from "../../Api/StorefrontApi";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import Reviews from "../../Components/Reviews";
import { UserApi } from "../../Api/UserApi";
import { useDispatch, useSelector } from "react-redux";
import UserSelectors from "../../Selectors/user";
import Faq from "../Storefront/FAQ";

import { useForm } from "react-hook-form";
import Form from "../../Atoms/Form";
import Input from "../../Atoms/Input/Input";
import Button from "../../Atoms/Button/Button";
import Swal from "sweetalert2";
import ReactWhatsapp from "react-whatsapp";
import cx from "classnames";
import Styles from "../../Constants/styles";
import YogaSun from "../../Graphics/YogaSun";
import useIsMobile from "../../Hooks/useMobile";
import "../../Components/CourseDetailsPage/style.css";

export default function MonthlyCourseDetails(props) {
  const { search } = useLocation();
  const isMobile = useIsMobile();

  let countryCode = "";

  const { register, handleSubmit } = useForm({});

  const [loadData, setLoadData] = useState(true);

  let searchText = new URLSearchParams(search);

  let arr = props?.match?.params?.slug
    ? props?.match?.params?.slug.split("-")
    : [];

  const id = searchText.get("name")
    ? searchText.get("name")
    : arr[arr.length - 1];

  const {
    callApi: courseDetails,
    loading,
    data: courseData,
  } = useApi(StorefrontApi.courseDetails);

  const { callApi, data } = useApi(StorefrontApi.UpcomingCourses);

  const [teacherId, setTeacherId] = useState("");

  const [currentSessionId, setCurrentSessionId] = useState(null);

  const isLoggedIn = useSelector(UserSelectors.isLoggedIn);

  const {
    callApi: upcomingApi,
    errorMessage,
    data: upcomingData,
  } = useApi(UserApi.getUpcomingClasses);

  const { callApi: createUserApi } = useApi(UserApi.createTherapyUser);

  if (window.COUNTRY_CODE) {
    countryCode = window.COUNTRY_CODE;
  }

  function onSubmit(data) {
    createUserApi({ ...data, course: "Home" })
      .then((res) => {
        countryCode === "IN"
          ? window.open("https://rzp.io/l/B2G3CH3", "_blank")
          : window.open("https://buy.stripe.com/5kAbLl8aw5vC3ao5kk", "_blank");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Sorry",
          text: err?.data?.message
            ? err?.data?.message
            : "Something went wrong!",
        });
      });
  }

  React.useEffect(() => {
    courseDetails(id)
      .then((res) => {
        //console.log(res);
        setTeacherId(res?.data?.data?.teacher?.id);
      })
      .catch((err) => {
        console.log(err);
      });
    callApi(id)
      .then((res) => {
        if (isLoggedIn) {
          upcomingApi();
        }
      })
      .catch((err) => console.log(err));

    pageTracking(props);
    ReactPixel.pageView();
  }, [callApi, id]);

  React.useEffect(() => {
    if (upcomingData?.data && data?.data?.sessions && isLoggedIn) {
      for (let i = 0; i < data.data.sessions.length; i++) {
        const item = upcomingData.data.find(
          (ele) =>
            ele.type === "live-class" &&
            ele.session.id === data.data.sessions[i].id
        );
        if (!item) {
          setCurrentSessionId(data.data.sessions[i]);
          break;
        }
      }
    } else if (data?.data?.sessions && data?.data?.sessions.length > 0) {
      setCurrentSessionId(data?.data?.sessions[0]);
    }
  }, [upcomingData?.data, data?.data?.sessions]);

  const sessions = data?.data?.sessions || [];

  return (
    <>
      <CourseDetailsPage
        setLoadData={setLoadData}
        id={id}
        backText="Back to all monthly courses"
        backUrl="/monthly"
        isMonthlyCourse
        currentSession={currentSessionId}
      />

      {/* <div class="max-w-screen-lg mx-auto py-8 relative">
        {teacherId && <Reviews id={teacherId} />}
      </div> */}

      <div class="max-w-screen-lg mx-auto py-8 pb-0 relative">
        {data && <Sessions sessions={sessions} />}
      </div>

      {loadData ? (
        <div class="loading">Loading&#8230;</div>
      ) : (
        <>
          <div class="max-w-screen-lg mx-auto py-8 pt-0 relative">
            <h2 className="pt-0 text-lg md:text-xl text-y-yellow-dark font-bold px-0 sm:mt-0 pb-4">
              FAQs - Frequently Asked Questions
            </h2>
            <Faq />
          </div>

          <div class="max-w-screen-lg mx-auto py-8 pt-0 relative">
            <h2 className="mt-2 text-y-yellow-dark text-lg md:text-xl font-bold mt-0">
              Tell us your details and book your first yoga session with us.
            </h2>
            <p
              className="pt-1 pb-4 text-black text-base font-bold"
              style={{ fontSize: "14px" }}
            >
              Our yoga counselor will reach you to understand your needs and
              assign the best yoga class for you.
            </p>
            <div className="w-full md:w-2/5 pt-3">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="">
                  <Input
                    required
                    name="name"
                    label="Name"
                    inputRef={register({ required: true })}
                    className=""
                  />
                </div>

                <div className="">
                  <Input
                    required
                    name="phone"
                    label="Phone"
                    inputRef={register({ required: true })}
                  />
                </div>

                <div className="flex pt-0">
                  <Button py="py-3" className="whitespace-nowrap" type="submit">
                    Submit & Book Trial
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          <div class="max-w-screen-lg mx-auto py-8 pt-0 relative mb-10">
            <div
              className={cx(
                "px-0 flex flex-row md:flex-row-reverse justify-between items-center overflow-x-auto sm:mt-10",
                "sm:h-full sm:w-4/5"
              )}
            >
              <div className="join-live-class text-lg md:text-xl font-bold pr-2 pt-0 sm:pt-5 sm:w-2/4">
                <p className="mt-2 text-y-yellow-dark">
                  Talk to our yoga counselors.
                  <br />
                  <br />
                  We are here to help you.
                  <br />
                  <br />
                </p>
                {/* <p
            className="sm:block mt-2 text-black text-base"
            style={{ fontSize: "14px" }}
          >
            Tell us about yourself and let us guide you to the right yoga class.
          </p> */}
                <div className="flex">
                  <ReactWhatsapp
                    number="+917975322831"
                    message={`Hi, I want to do yoga. About me - `}
                    className=""
                  >
                    <button
                      className={cx(
                        "w-full mt-5 focus:outline-none",
                        `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
                        "cursor-pointer text-white flex items-center justify-center"
                      )}
                      style={{
                        ...Styles.OrangeLinearGradient,
                        whiteSpace: "nowrap",
                      }}
                    >
                      <img
                        src="/img/whatsapp.png"
                        height={40}
                        width={40}
                        alt="whatspp"
                        style={{ marginRight: 5 }}
                      />
                      Whatsapp Us
                    </button>
                  </ReactWhatsapp>
                </div>
              </div>
              <div>
                <YogaSun
                  width={!isMobile ? 230 : 353}
                  height={!isMobile ? 100 : 301}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
