import cx from "classnames";
import React from "react";
import { Transition } from "@headlessui/react";

function Modal({ show, onClose, children, className }) {
  const [render, setRender] = React.useState(show);
  const [localShow, setLocalShow] = React.useState(show);

  React.useEffect(() => {
    if (show) {
      setRender(true);
      setTimeout(() => setLocalShow(true), 100);
    } else {
      setLocalShow(false);
      setTimeout(() => setRender(false), 500);
    }
  }, [show, setRender, setLocalShow]);

  if (!render) {
    return null;
  }

  return (
    <div className="fixed z-40 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 text-center sm:block sm:p-0">
        <Transition
          show={localShow}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          className="fixed inset-0 transition-opacity"
          onClick={onClose}
        >
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </Transition>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>

        <Transition
          show={localShow}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          className={cx(
            "flex-1 inline-block align-bottom bg-white rounded-t-lg sm:rounded-b-lg text-left",
            "shadow-xl transform transition-all sm:my-8 sm:align-middle overflow-auto",
            "fixed sm:static bottom-0 w-full",
            className
          )}
          role="dialog"
        >
          {children}
        </Transition>
      </div>
    </div>
  );
}

export default Modal;
