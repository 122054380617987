import cx from "classnames";
import React from "react";
import AlarmImg from "../../Assets/alarm.png";
import "./styles.css";

export default function GlowingPicture({
  imgUrl,
  onClick,
  width = "3.5rem",
  height = "3.5rem",
  chatBot,
  close,
  whatsapp,
  video = false,
  alarm,
  name = "name",
}) {
  return (
    <div
      className={cx(
        "w-14 h-14 rounded-full relative outline-none",
        onClick && "cursor-pointer"
      )}
      style={{ width, height }}
      onClick={onClick}
    >
      <div className="x-ring x-glowing-ring-1" />
      <div className="x-ring x-glowing-ring-2" />
      <div className="x-ring x-glowing-ring-3" />
      <div className="x-ring x-glowing-ring-4 overflow-hidden">
        {imgUrl && video ? (
          <>
            <video className="object-cover w-full h-full">
              <source src={imgUrl} type="video/mp4" />
            </video>
          </>
        ) : imgUrl ? (
          <img
            className="object-cover w-full h-full"
            src={imgUrl}
            alt={name ? name : "Profile"}
          />
        ) : (
          <div className="flex justify-center items-center w-full h-full">
            {whatsapp && <img src="/img/whatsapp.png" className="p-1" />}

            {chatBot ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="40"
                height="40"
                viewBox="0 0 172 172"
                style={{ fill: "#000000" }}
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <path d="M0,172v-172h172v172z" fill="none"></path>
                  <g fill="#e56f2c">
                    <path d="M33.92969,17.88867c-9.27228,0.01055 -16.78632,7.52459 -16.79687,16.79688v76.09247c0.01055,9.27228 7.52459,16.78632 16.79688,16.79688h48.47604l31.70804,25.78386c1.00561,0.81847 2.39247,0.98517 3.56339,0.42831c1.17092,-0.55686 1.91685,-1.73786 1.91659,-3.03445v-23.17772h18.47656c9.27228,-0.01055 16.78632,-7.52459 16.79688,-16.79687v-76.09247c-0.01037,-9.27236 -7.52451,-16.78651 -16.79687,-16.79687zM33.92969,24.60742h104.14063c5.56339,0.00629 10.07183,4.51474 10.07813,10.07813v76.09181c-0.00629,5.56339 -4.51474,10.07183 -10.07812,10.07813h-21.83594c-1.85533,0 -3.35937,1.50404 -3.35937,3.35938v19.47585l-27.15517,-22.08133c-0.59899,-0.48689 -1.34738,-0.75265 -2.1193,-0.75258h-49.67085c-5.56339,-0.00629 -10.07183,-4.51474 -10.07812,-10.07812v-76.09313c0.00629,-5.56339 4.51474,-10.07183 10.07813,-10.07812zM40.97847,45.0937c-1.85533,0 -3.35937,1.50404 -3.35937,3.35938c0,1.85533 1.50404,3.35938 3.35938,3.35938h38.72861c1.85533,0 3.35938,-1.50404 3.35938,-3.35937c0,-1.85533 -1.50404,-3.35937 -3.35937,-3.35937zM40.97847,69.37109c-1.85533,0 -3.35937,1.50404 -3.35937,3.35938c0,1.85533 1.50404,3.35938 3.35938,3.35938h90.04306c1.85533,0 3.35938,-1.50404 3.35938,-3.35937c0,-1.85533 -1.50404,-3.35937 -3.35937,-3.35937zM40.97847,93.65045c-1.85533,0 -3.35937,1.50404 -3.35937,3.35938c0,1.85533 1.50404,3.35938 3.35938,3.35938h90.04306c1.85533,0 3.35938,-1.50404 3.35938,-3.35937c0,-1.85533 -1.50404,-3.35937 -3.35937,-3.35937z"></path>
                  </g>
                </g>
              </svg>
            ) : close ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="36"
                height="36"
                viewBox="0 0 172 172"
                style={{ fill: "#000000" }}
              >
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="1"
                  stroke-linecap="butt"
                  stroke-linejoin="miter"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style={{ mixBlendMode: "normal" }}
                >
                  <path d="M0,172v-172h172v172z" fill="none"></path>
                  <g fill="#e56f2c">
                    <path d="M46.45736,17.83268c-2.6579,0.02573 -5.08331,1.52025 -6.30127,3.8828c-1.21796,2.36256 -1.02846,5.20513 0.49235,7.3851l38.49284,56.89941l-38.49284,56.89941c-1.4786,2.11985 -1.70137,4.87159 -0.58302,7.20168c1.11835,2.33009 3.40458,3.87761 5.98339,4.05008c2.57881,0.17247 5.05076,-1.05683 6.46942,-3.21725l35.27344,-52.1403l35.27344,52.1403c1.41867,2.16042 3.89061,3.38972 6.46942,3.21725c2.57881,-0.17247 4.86505,-1.71999 5.98339,-4.05008c1.11835,-2.33009 0.89558,-5.08183 -0.58302,-7.20168l-38.49284,-56.89941l38.49284,-56.89941c1.47861,-2.11985 1.70139,-4.8716 0.58304,-7.2017c-1.11835,-2.3301 -3.40458,-3.87762 -5.98341,-4.05009c-2.57882,-0.17246 -5.05077,1.05685 -6.46943,3.21728l-35.27344,52.1403l-35.27344,-52.1403c-1.33856,-2.03881 -3.62203,-3.25701 -6.06087,-3.2334z"></path>
                  </g>
                </g>
              </svg>
            ) : alarm ? (
              <div className="text-center font-bold">{alarm}</div>
            ) : (
              <svg
                className="transform translate-y-2"
                width="80%"
                height="80%"
                viewBox="0 0 29 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.104 24.028c.28-.874-.216-1.804-1.108-2.079-3.98-1.225-5.67-8.207-6.066-10.781-.058-.365-.25-.719-.25-.719-.731-1.362-3.498-1.998-3.81-2.034-.559-.066-.63-.485-.653-.628.977-.665 1.637-2.272 1.637-3.724 0-2.143-.232-3.881-3.213-3.881h-.233c-2.981 0-3.212 1.738-3.212 3.881 0 1.452.66 3.059 1.636 3.724-.023.143-.116.569-.654.628-.335.037-3.51.77-3.938 2.356 0 0-.052.227-.084.403-.004.024-.333 2.418-1.318 5.02-.84 2.218-2.345 5.017-4.743 5.755a1.652 1.652 0 00-1.108 2.079 1.689 1.689 0 001.596 1.16c-1.426.804-1.635 1.924-1.635 2.505 0 .23.122.981.678 1.672.5.622 1.496 1.362 3.403 1.362.286 0 .588-.016.899-.05 1.268-.133 2.547-.333 3.784-.527.449-.07.897-.141 1.346-.208.598-.09 1.677-.15 3.205-.177h.523c1.528.027 2.607.087 3.205.177.45.067.898.137 1.346.208 1.237.194 2.516.394 3.784.528.31.033.613.05.9.05 1.906 0 2.902-.742 3.402-1.363.556-.691.678-1.441.678-1.672 0-.58-.209-1.701-1.635-2.505l.023.001a1.69 1.69 0 001.614-1.161zm-19.774-.21c-.858.262-1.055.29-1.79.391-.239.033-.532.074-.922.133-.38.04-.73.093-1.052.157 2.207-1.194 3.987-3.472 5.307-6.796.073.648.119 1.361.119 2.145 0 2.56-1.323 3.867-1.662 3.97zm15.1.524c-.389-.059-.682-.104-.921-.133-2.313-.279-3.452-1.801-3.452-4.36 0-.819.05-1.56.129-2.23 1.327 3.382 3.126 5.693 5.363 6.894-.34-.07-.711-.128-1.119-.17z"
                  fill="#F57C6C"
                />
              </svg>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
