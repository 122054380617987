import React from 'react';
import Button from "../Button/Button";
import ErrorMessage from "../ErrorMessage";
import Modal from "../Modal/Modal";
import TextLink from "../TextLink/TextLink";


export default function ConfirmationModal({ show, onClose, onConfirm, text, confirmText, confirmLoading, error }) {
  if (!text) {
    text = 'Are you sure?';
  }

  if (!confirmText) {
    confirmText = 'Confirm';
  }

  return (
    <Modal show={show} onClose={onClose} className="sm:w-full sm:max-w-md">
      <div className="p-8 bg-gray-100">
        <div>{text}</div>
        {error && <div className="mt-3"><ErrorMessage>{error}</ErrorMessage></div>}
      </div>
      <div>
        <div className="p-8 flex flex-row justify-end items-center">
          <TextLink className="pr-5" onClick={onClose}>Cancel</TextLink>
          <Button loading={confirmLoading} onClick={onConfirm}>{confirmText}</Button>
        </div>
      </div>
    </Modal>
  );
}
