import React, { useEffect, useState } from "react";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { BlogApi } from "../../Api/BlogApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { Helmet } from "react-helmet";

export default function BlogPage(props) {
  const { callApi, loading, errorMessage, data } = useApi(BlogApi.blogList);

  const history = useHistory();

  const urlname = props?.match?.params?.name;

  const blogData = data?.data.slice().reverse() || [];

  //console.log(blogData);

  useEffect(() => {
    if (urlname === "yoga-practices") {
      callApi("asana");
    } else {
      callApi();
    }
  }, [callApi]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (blogData.length === 0) {
    return <ErrorMessage>No Data Available</ErrorMessage>;
  }

  const dataTitle =
    urlname === "yoga-practices" ? "Directory of Yoga Practices" : "Blogs";

  return (
    <>
      <Helmet>
        <title>{dataTitle}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={dataTitle}></meta>
        <meta name="description" content={dataTitle} />
        <meta property="og:title" content={dataTitle}></meta>
        <meta property="twitter:title" content={dataTitle}></meta>
        <meta property="og:description" content={dataTitle}></meta>
        <meta property="twitter:description" content={dataTitle}></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div>
        <div
          className={cx(
            "hidden sm:inline-block cursor-pointer text-xl",
            "font-semibold",
            "text-y-yellow-dark pl-0 md:pl-1 mt-10 pb-5"
          )}
        >
          {dataTitle}
        </div>
        <div className="mx-0 mt-3 w-full flex flex-col items-center sm:flex-row sm:flex-wrap space-y-2 sm:space-y-0">
          {blogData &&
            blogData.map((ele) => (
              <Link
                to={`/blog${
                  urlname === "yoga-practices" ? "/yoga-practices" : "/articles"
                }/${ele.title.replaceAll(" ", "-")}-${ele.id}`}
                onClick={() => {}}
              >
                <div className="sm:pr-5 sm:pb-5 w-full max-w-sm">
                  <div className="bg-white p-5 rounded-md shadow-card-cream-sm w-full max-w-sm cursor-pointer">
                    <div>
                      {ele.image && (
                        <img
                          className="h-48 w-64"
                          src={ele.image}
                          alt="cover"
                        />
                      )}
                    </div>
                    <div className="text-base pt-3 font-bold">{ele.title}</div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </div>
    </>
  );
}
