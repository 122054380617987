import React from "react";
import { useForm } from "react-hook-form";
import Input from "../../Atoms/Input/Input";
import Button from "../../Atoms/Button/Button";
import { UserApi } from "../../Api/UserApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import Tag from "../../Atoms/Tag/Tag";
import "react-tagsinput/react-tagsinput.css";

function ProfileForm({ onSuccess, updateUser }) {
  var tagOptions = [
    "Beginner",
    "Intermediate",
    "Advanced",
    "Prenatal",
    "Wellness",
    "Pain",
    "Health Conditions",
  ];
  const [showAll, setShowAll] = React.useState(false);
  const { callApi, loading, formErrors } = useApi(UserApi.updateProfile);

  const storedProfile = {
    chosenTags: [],
    years_of_exp: "",
    comment: "",
  };
  if (window.localStorage) {
    try {
      const localData = JSON.parse(localStorage.getItem("YOGA_USER"));
      if (localData && localData.user && localData.user.profile) {
        console.log(`Stored: ${JSON.stringify(localData)}`);
        if (localData.user.profile.yoga_categories)
          storedProfile.chosenTags =
            localData.user.profile.yoga_categories.split(",");
        storedProfile.years_of_exp = localData.user.profile.years_of_experience;
        storedProfile.comment = localData.user.profile.comment;
        console.log(`stored retrieve ${JSON.stringify(storedProfile)}`);
        tagOptions = tagOptions.filter((tagName) => {
          return !storedProfile.chosenTags.includes(tagName);
        });
        console.log(`tag opts ${tagOptions}`);
      }
    } catch (e) {}
  }

  const [state, setState] = React.useState({
    chosenTags: storedProfile.chosenTags,
    years_of_exp: storedProfile.years_of_exp,
    comment: storedProfile.comment,
    suggestedTags: tagOptions,
  });

  console.log(`store prof ${JSON.stringify(storedProfile)}`);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      years_of_experience: storedProfile.years_of_exp,
      comment: storedProfile.comment,
    },
  });

  function onSubmit(data) {
    data.yoga_categories = state.chosenTags.join();
    console.log(`Profile update request: ${JSON.stringify(data)}`);
    callApi(data)
      .then((response) => {
        console.log(`Profile update response: ${JSON.stringify(response)}`);
        let userData = JSON.parse(
          window.localStorage.getItem("YOGA_USER")
        ).user;
        console.log(`My old user data: ${JSON.stringify(userData)}`);
        userData.profile = response.data.data;
        updateUser(userData);
        onSuccess();
      })
      .catch((e) => {
        console.log(
          `Some error occurred in profile updat: ${JSON.stringify(e.data.data)}`
        );
      });
  }

  function onTagClicked(tag) {
    console.log(`handling tag click: ${tag}`);
    const newChosenTags = [...state.chosenTags];
    newChosenTags.push(tag);
    let newSuggestedTags = [...state.suggestedTags];
    const index = state.suggestedTags.indexOf(tag);
    console.log("clicked ind ", index);
    if (index > -1) {
      newSuggestedTags.splice(index, 1);
    }
    console.log({ newChosenTags, newSuggestedTags });
    setState({ chosenTags: newChosenTags, suggestedTags: newSuggestedTags });
  }

  let tagsSection = [];
  state.suggestedTags.forEach((opt, optIndex) => {
    // Show only first 3 tags if showAll is false
    if (showAll || optIndex < 3) {
      tagsSection.push(<Tag value={opt} onTagClicked={onTagClicked} />);
    }
  });
  if (!showAll) {
    tagsSection.push(
      <div
        className=" text-sm cursor-pointer underline py-3 px-2"
        onClick={() => setShowAll(true)}
      >
        More..
      </div>
    );
  }

  return (
    <div style={{ marginBottom: 50 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            name="years_of_experience"
            label="Years of experience in Yoga"
            type="number"
            inputRef={register({ required: false })}
          />
        </div>

        <div className="mt-3">
          <div>
            <div className="border-transparent border">
              <div className="rounded-lg px-1 pb-2 relative border border-gray-300 pt-3">
                <div className="absolute font-light text-sm px-1 mr-3 top-0 bg-white transform -translate-y-2.5 translate-x-1 text-y-gray">
                  What kind of yoga are you looking for?
                </div>
                {state.chosenTags.length === 0 ? (
                  <div className="px-2 py-1 text-gray-300 font-thin">
                    Select tags
                  </div>
                ) : (
                  <div className="px-2 py-1">{state.chosenTags.join(", ")}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-1">{tagsSection}</div>

        <div className="mt-7">
          <Input
            name="comment"
            label="Do you have any health conditions?"
            type="text"
            inputRef={register({ required: false })}
          />
        </div>

        {formErrors.form && (
          <div className="mt-5 text-red-500 text-sm">{formErrors.form}</div>
        )}

        <div className="text-right mt-8">
          <Button loading={loading} type="submit">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ProfileForm;
