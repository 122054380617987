import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import advancedFormat from "dayjs/plugin/advancedFormat";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import CourseService from "../../Services/CourseService";
import TextService from "../../Services/TextService";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import UserSelectors from "../../Selectors/user";
import CourseSelectors from "../../Selectors/course";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export default function CourseCard({
  course,
  onDetails,
  link,
  homeComponent = false,
  homeDesktop = false,
  currentVal,
}) {
  const schoolName = course.teacher?.school_name;
  const teacherName = course.teacher?.first_name;
  const image = _.get(course, "images[0].file");

  const teacherId = course?.teacher?.id;

  const teacherData = useSelector(CourseSelectors.streamDatas);

  const [dateRow, setDateRow] = useState("");
  const [startTime, setStartTime] = useState("");

  let metaLines = [];
  if (course.level) {
    metaLines.push(`for ${course.level}`);
  }

  const teacherMeta = [teacherName, schoolName].filter((o) => !!o).join(" | ");

  if (teacherMeta) {
    metaLines.push(`by ${teacherMeta}`);
  }
  const repeatSchedule = CourseService.repeatScheduleText(course);

  const userTimeZone = useSelector(UserSelectors.userTimeZone);

  useEffect(() => {
    const dateFormated = CourseService.startEndDateTextWithTimeZone(
      course,
      userTimeZone
    );

    const startTimeFormat = CourseService.startTimeTextWithTimeZone(
      course,
      userTimeZone
    );

    setDateRow(dateFormated);
    setStartTime(startTimeFormat);
  }, [userTimeZone, currentVal]);

  let altTag =
    image ===
    "https://do-yoga.s3.amazonaws.com/43a83517-781a-488c-9d34-58f65584aed3.jpg"
      ? "Do Yoga"
      : image ===
        "https://do-yoga.s3.amazonaws.com/7c4b34ac-bad5-477e-b252-2e04f38615bf.jpg"
      ? "Online yoga classes"
      : image ===
        "https://do-yoga.s3.amazonaws.com/67306aef-1463-4e24-b71d-1716747c69d9.jpg"
      ? "Yoga"
      : "Placeholder";

  return (
    <Link to={link} onClick={onDetails}>
      {/* <ReactTooltip className="testPurple" html={true} multiline={true} /> */}
      <div
        className="bg-white p-4 rounded-md shadow-card-cream-sm min-w-sm max-w-sm cursor-pointer"
        style={{ width: homeComponent ? "130%" : homeDesktop ? "96%" : "" }}
      >
        <div className="flex flex-row justify-between">
          <div className="pr-5 flex-1">
            <h2
              //data-tip={course.title.length > 45 ? course.title : ''}
              className="text-wrap-title"
            >
              {course.title}
            </h2>
            <div className="text-xs text-gray-500 mt-1 leading-5">
              {metaLines.map((l) => (
                <p className="meta-wrap" key={l}>
                  {l}
                </p>
              ))}
            </div>

            {(repeatSchedule || dateRow) && (
              <div className="flex flex-row text-xs items-center text-gray-500 mt-3 w-full">
                <svg
                  width="14"
                  height="13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.453.938H9.375V.375a.375.375 0 10-.75 0v.563h-5.25V.375a.375.375 0 10-.75 0v.563H1.547C.694.938 0 1.63 0 2.484v7.97C0 11.305.694 12 1.547 12h8.906c.853 0 1.547-.694 1.547-1.547V2.484c0-.853-.694-1.546-1.547-1.546zm-8.906.75h1.078v.375a.375.375 0 00.75 0v-.376h5.25v.375a.375.375 0 00.75 0v-.374h1.078c.44 0 .797.357.797.796v.891H.75v-.89c0-.44.357-.797.797-.797zm8.906 9.562H1.547a.798.798 0 01-.797-.797V4.125h10.5v6.328c0 .44-.357.797-.797.797z"
                    fill="#919390"
                  />
                </svg>
                <div className="ml-2" style={{}}>
                  {dateRow && <p>{dateRow}</p>}
                  {repeatSchedule && (
                    <p className="pt-1">
                      {repeatSchedule &&
                        repeatSchedule.split("-").map((ele) => <p>{ele}</p>)}
                    </p>
                  )}
                </div>
              </div>
            )}
          </div>
          <div style={{ width: 100 }}>
            <div className="rounded overflow-hidden">
              <img
                style={{ height: "100px" }}
                className="object-cover w-full"
                src={image || "/img/yoga-placeholder-01.jpg"}
                alt={course.title}
              />
            </div>
            <div className="mt-2 text-gray-500">
              {teacherData[teacherId] && (
                <>
                  {teacherData[teacherId].avgReviewsCount && (
                    <p className="pt-0 flex text-xs items-center">
                      <div>
                        <svg
                          viewBox="0 0 51 48"
                          class="widget-svg widget-selected"
                          style={{
                            width: "16px",
                            height: "16px",
                            transition: "transform 0.2s ease-in-out 0s",
                          }}
                        >
                          <path
                            class="widget"
                            d="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
                            style={{
                              fill: "orange",
                              transition: "fill 0.2s ease-in-out 0s",
                            }}
                          ></path>
                        </svg>
                      </div>
                      <p className="pl-1 pt-1">
                        {teacherData[teacherId].avgReviewsCount}
                      </p>
                    </p>
                  )}
                  {teacherData[teacherId].total && (
                    <p className="pt-3 text-xs">
                      {teacherData[teacherId].total} streams
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center pt-5">
          <div className="flex flex-row justify-between items-center flex-1">
            <p className="text-sm flex flex-col">
              <p className="flex flex-row justify-between items-center">
                <span className="text-green-500 mr-1">{startTime}</span>{" "}
                <span className="text-black mx-2">
                  <svg
                    width="12"
                    height="12"
                    fill="none"
                    viewBox="0 0 12 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 0C2.692 0 0 2.692 0 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6zm0 11.25A5.256 5.256 0 01.75 6 5.256 5.256 0 016 .75 5.256 5.256 0 0111.25 6 5.256 5.256 0 016 11.25z"
                      fill="currentColor"
                    />
                    <path
                      d="M6.375 2.25h-.75v3.905l2.36 2.36.53-.53-2.14-2.14V2.25z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                <span className="text-gray-400">{course.duration} min</span>
              </p>
              <p className="py-2">
                {TextService.formatCurrencyFee(course.period_currency_fee)}{" "}
                {link && link.includes("monthly") && "/month"}
              </p>
            </p>
          </div>
          <div style={{ width: 96 }}>
            <Button py="py-3">Join</Button>
          </div>
        </div>
      </div>
    </Link>
  );
}
