import get from "lodash/get";
import React from "react";
import { useParams } from "react-router-dom";
import { UserApi } from "../../Api/UserApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import Teacher from "../../Components/SessionDetailsComponents/Teacher";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import NotFound from "../NotFound";
import Courses from "./Courses";
import OneOnOne from "./OneOnOne";
import Sessions from "./Sessions";
import Upcoming from "./Upcoming";
import UserSelectors from "../../Selectors/user";
import { useSelector } from "react-redux";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";
import Reviews from "./ReviewList";

export default function TeacherDomainPage({ domain }) {
  const user = useSelector(UserSelectors.user);
  const params = useParams();
  const { callApi, loading, errorMessage, error, data } = useApi(
    UserApi.teacherByDomain
  );

  React.useEffect(() => {
    const finalDomain = domain || params.domain;
    if (finalDomain === "www") {
      window.location.replace("https://do.yoga");
      return;
    }

    callApi(finalDomain);
    pageTracking();
    ReactPixel.pageView();
  }, [domain, params.domain, callApi]);

  if (loading) {
    return null;
  }

  const code = get(error, "data.code");
  if (code === "INVALID_DOMAIN") {
    return (
      <div>
        <NotFound
          title="Teacher not found"
          subtitle="The teacher you're looking for does not exist. View more teachers on Do Yoga"
        />
      </div>
    );
  }

  if (errorMessage) {
    return (
      <div>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  const teacher = data?.data;

  return (
    <div className="sm:flex flex-row p-5">
      <Helmet>
        <title>Do Yoga with {teacher.full_name}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={teacher.full_name}></meta>
        <meta name="description" content={teacher.full_name} />
        <meta property="og:title" content={teacher.full_name}></meta>
        <meta property="twitter:title" content={teacher.full_name}></meta>
        <meta property="og:description" content={teacher.full_name}></meta>
        <meta property="twitter:description" content={teacher.full_name}></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="w-full sm:max-w-sm sm:mr-16">
        <Teacher hideTitle teacher={teacher} />
      </div>
      <div className="sm:flex-1 pt-8 sm:pt-0" style={{ width: "100%" }}>
        {user ? <Upcoming domain={domain} params={params} /> : null}
        <Sessions sessions={data.sessions} />
        <Courses courses={data.courses} fixedCourses={data.fixed_courses} />
        <OneOnOne one={data.one_on_one} />
        {teacher?.id && <Reviews teacher={teacher} />}
      </div>
    </div>
  );
}
