import React from 'react';


export default function CoursesGraphic({ width = 153, height = 121 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 153 121" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M134.525 99.013c8.319-6.785 13.177-17.522 13.094-28.257-.083-10.736-5.01-21.282-12.98-28.473-6.545-5.906-13.199-10.656-17.274-18.474-5.633-10.804-8.311-22.254-21.977-23.64-12.686-1.285-20.344 4.836-24.933 14.683-6.38 13.695-11.82 25.394-22.263 33.67-8.03 6.362-17.39 11.686-21.838 20.915-5.412 11.231-1.416 24.664 1.462 29.576h106.709z"
        fill="#FFF4DF" />
      <path d="M67.553 98.712h45.488V76.544H67.553v22.168z" fill="#F5D3A6" />
      <path
        d="M69.641 84.015h19.575v-5.37H69.64v5.37zM91.378 84.015h19.574v-5.37H91.378v5.37zM69.641 90.351h19.575v-5.37H69.64v5.37zM91.378 90.351h19.574v-5.37H91.378v5.37zM59.642 96.687h19.574v-5.369H59.642v5.37zM81.378 96.687h19.574v-5.369H81.378v5.37z"
        fill="#F0C792" />
      <path
        d="M79.748 81.337a.32.32 0 11-.64 0 .32.32 0 01.64 0zM69.748 93.966a.32.32 0 11-.64 0 .32.32 0 01.64 0zM79.748 87.667a.32.32 0 11-.64 0 .32.32 0 01.64 0zM101.561 81.337a.32.32 0 11-.64 0 .32.32 0 01.64 0zM91.561 93.966a.32.32 0 11-.64 0 .32.32 0 01.64 0zM101.561 87.667a.32.32 0 11-.64 0 .32.32 0 01.64 0z"
        fill="#EEA886" />
      <path
        d="M38.934 73.581c-.96-1.048-2.719-2.262-3.754-3.237a14.337 14.337 0 01-3.32-4.734c-1.85 4.3-.953 10.012 1.463 12.034 2.989 2.504 5.4 1.97 7.223 3.047 0 0 1.115-4.134-1.612-7.11z"
        fill="#99D76D" />
      <path
        d="M33.696 71.375c-1.095-2.001-1.681-3.758-1.802-5.69l-.033-.075c-1.85 4.3-.954 10.012 1.462 12.034 2.989 2.504 5.4 1.97 7.223 3.047v-.003c-1.893-4.294-5.694-7.198-6.85-9.313z"
        fill="#72BD6B" />
      <path
        d="M34.453 72.704c1.991 2.173 3.995 4.367 5.396 6.937 1.58 2.9 2.325 6.207 2.325 9.522 0 1.363-.126 2.727-.371 4.064a.124.124 0 00.244.045c.249-1.352.376-2.731.376-4.109 0-3.352-.754-6.7-2.356-9.64-1.42-2.606-3.44-4.814-5.43-6.987a.124.124 0 10-.184.168z"
        fill="#357838" />
      <path
        d="M43.41 67.56c1.553-1.12 2.432-3.658 1.904-5.498-.685-2.383-2.635-3.973-3.66-5.513-.828-1.242-1.544-3.03-1.49-4.182-.308 1.737-.849 3.425-1.2 5.154-.35 1.729-.604 4.66.019 6.31 1.446 3.83 4.428 3.728 4.428 3.728z"
        fill="#99D76D" />
      <path
        d="M40.171 52.724a3.046 3.046 0 01-.008-.357c-.308 1.737-.848 3.425-1.198 5.154s-.605 4.66.018 6.31c1.371 3.633 4.123 3.729 4.403 3.729-1.618-3.632-2.544-7.007-2.813-9.086-.233-1.81-.458-4.228-.402-5.75z"
        fill="#72BD6B" />
      <path
        d="M40.235 56.937c.252 3.047 1.234 6.476 3.029 10.684.882 2.069 1.515 4.19 1.74 7.1.07.92.104 1.838.104 2.757 0 4.766-.894 9.51-2.057 14.166a.124.124 0 10.241.06c1.165-4.666 2.064-9.429 2.064-14.226 0-.925-.033-1.85-.105-2.777-.225-2.935-.868-5.092-1.758-7.178-1.79-4.193-2.762-7.598-3.01-10.606a.124.124 0 00-.248.02z"
        fill="#357838" />
      <path
        d="M44.513 68.572c3.216-1.883 4.989-3.267 5.679-5.783.893-3.256.348-8.437.957-10.264-.548.758-1.23.97-2.617 2.129-1.884 1.575-3.882 3.192-4.954 5.403-1.072 2.21-1.408 4.982.935 8.515z"
        fill="#99D76D" />
      <path
        d="M48.532 54.654c-1.884 1.575-3.882 3.192-4.954 5.403-1.072 2.21-1.408 4.982.935 8.515l.078-.046c.869-3.569 2.402-8.874 6.514-15.944-.54.71-1.221.942-2.572 2.072z"
        fill="#72BD6B" />
      <path
        d="M44.184 86.712c-.456-2.59-.68-5.22-.68-7.85 0-6.955 1.574-13.912 4.623-20.164a.124.124 0 10-.223-.109c-3.067 6.287-4.65 13.281-4.65 20.273 0 2.644.227 5.288.685 7.893a.124.124 0 00.245-.043"
        fill="#357838" />
      <path
        d="M46.665 76.754c2.171-.764 3.409-1.395 4.123-2.84.924-1.87 1.201-5.075 1.785-6.112-.421.395-.86.443-1.837.984-1.328.734-2.729 1.48-3.639 2.695-.91 1.215-1.438 2.856-.432 5.273z"
        fill="#99D76D" />
      <path
        d="M50.736 68.786c-1.328.734-2.729 1.48-3.639 2.695-.91 1.215-1.438 2.856-.432 5.273l.053-.018c.945-2.062 2.498-5.1 5.822-8.905-.411.368-.852.428-1.804.955z"
        fill="#72BD6B" />
      <path
        d="M44.336 87.717c.101-5.92 2.12-11.794 5.68-16.526a.077.077 0 10-.123-.093 28.666 28.666 0 00-5.71 16.616c0 .042.033.077.076.078a.077.077 0 00.078-.075"
        fill="#357838" />
      <path
        d="M42.992 77.218c-1.913.045-4.147-1.447-4.837-3.232-.895-2.312-.301-4.758-.414-6.605-.092-1.489-.6-3.347-1.34-4.232 1.295 1.198 2.746 2.216 4.07 3.383 1.323 1.166 3.297 3.347 3.797 5.039 1.162 3.926-1.276 5.647-1.276 5.647z"
        fill="#99D76D" />
      <path
        d="M36.611 63.438a3.05 3.05 0 00-.21-.29c1.295 1.2 2.746 2.217 4.07 3.384 1.323 1.166 3.297 3.348 3.797 5.04 1.102 3.722-1.032 5.461-1.256 5.63-.905-3.87-2.205-7.12-3.247-8.938-.908-1.584-2.19-3.647-3.154-4.826z"
        fill="#72BD6B" />
      <path
        d="M38.896 66.968c1.62 2.547 2.902 5.848 4.01 10.27.353 1.413.765 3.09.765 5.538 0 1.346-.124 2.923-.453 4.815a.125.125 0 00.1.144.125.125 0 00.145-.101c.33-1.905.456-3.497.456-4.858 0-2.475-.418-4.184-.772-5.598-1.112-4.438-2.401-7.764-4.041-10.344a.125.125 0 00-.21.133z"
        fill="#357838" />
      <path d="M49.436 98.693h-12.58a.635.635 0 01-.63-.557l-1.333-10.802H51.4l-1.332 10.802a.635.635 0 01-.63.557z"
            fill="#C3841D" />
      <path d="M51.4 87.334H34.892l.446 3.614h15.614l.446-3.614z" fill="#B36A1D" />
      <path d="M52.31 87.94H33.892a1.16 1.16 0 110-2.32H52.31a1.16 1.16 0 010 2.32z" fill="#C3841D" />
      <path d="M153 121H11c-6.075 0-11-4.925-11-11s4.925-11 11-11h142v22z" fill="#FCEFCC" />
      <path d="M86.237 110.955H23.85l5.416-5.531H80.82l5.417 5.531z" fill="#EADBB4" />
      <path
        d="M142.759 104.526c0 .93-15.574 1.684-34.786 1.684-19.212 0-34.787-.754-34.787-1.684 0-.929 15.575-1.683 34.787-1.683s34.786.754 34.786 1.683z"
        fill="#EADBB4" />
      <path
        d="M89.867 30.651c.146-1.974.065-13.928.065-13.928.982-.724 2.224.103 2.224.103-.07 2.143 1.904 11.3 1.278 14.986-.305 1.797-3.765 1.53-3.567-1.16z"
        fill="#FDE1CA" />
      <path
        d="M93.528 31.75c-.006.968-1.297 14.44-1.546 15.808-.47 2.57-4.37 2.512-4.055-.57.228-2.233 1.756-13.958 1.898-15.205.207-1.807 3.715-1.947 3.703-.033zM79.954 17.059c-.275-3.802-.432-4.396-.454-4.717-.022-.33.109-1.74.15-2.075.026-.222.367-.307.344.21a7.106 7.106 0 00.7 3.365c.343.72.72.558.801-.085.101-.8.099-1.18.196-1.566.114-.449.556-.458.499.16a6.064 6.064 0 00.101 1.872c.223 1.048.076 2.158-.12 2.663-.194.506-2.218.173-2.218.173zM89.486 69.96c.145 1.974.064 13.929.064 13.929.982.724 2.224-.104 2.224-.104-.07-2.143 1.904-11.3 1.278-14.986-.305-1.797-3.765-1.53-3.567 1.16z"
        fill="#FDE1CA" />
      <path
        d="M93.146 68.862c-.005-.968-1.296-14.44-1.546-15.809-.47-2.57-4.37-2.512-4.055.571.228 2.232 1.756 13.957 1.898 15.204.206 1.807 3.715 1.948 3.703.034zM89.572 83.553c-.275 3.802-.432 4.395-.454 4.716-.022.33.109 1.741.15 2.075.026.222.367.307.343-.21a7.104 7.104 0 01.701-3.365c.343-.72.72-.557.801.086.101.8.099 1.18.196 1.565.114.449.556.458.499-.16a6.064 6.064 0 01.101-1.872c.223-1.047.076-2.157-.12-2.663-.194-.506-2.217-.172-2.217-.172zM78.262 103.107c1.482-.359 2.542-1.256 2.988-1.961.458-.725.797-1.523.797-1.523l2.015.31s-.407 1.1-.433 1.441c-.051.663.892 1.593.748 2.753l-7.772-.001s-.37-.527 1.657-1.019zM135.67 100.075c.255.584.969 2.159 1.323 2.721.584.923.459 1.375.459 1.375h-5.474s-.102-.328.374-1.375c.262-.577.712-2.751.712-2.751l2.606.03z"
        fill="#FDE1CA" />
      <path
        d="M102.575 76.424c-1.298 3.434-9.043 19.527-11.028 23.288 1.04.699 2.577.452 2.577.452s3.8-4.552 6.772-8.503c2.972-3.95 5.638-9.574 6.077-12.946.438-3.373-3.387-3.881-4.398-2.291z"
        fill="#CA2832" />
      <path
        d="M126.734 78.025c2.449 2.732 13.261 16.92 16.111 22.139h3.017s-4.354-9.911-6.813-15.787c-2.778-6.635-4.151-8.695-6.824-10.377-2.966-1.866-7.039 1.736-5.491 4.025z"
        fill="#F03B46" />
      <path
        d="M109.552 58.344c-1.96 3.838-5.754 13.916-7.488 19.33-.988 3.086 2.833 4.324 4.684 2.16 4.319-5.05 12.501-15.756 12.501-15.756l-9.697-5.734z"
        fill="#CA2832" />
      <path
        d="M113.448 49.145c3.81.147 6.613 1.314 8.618 4.492 3.666 5.807 7.809 13.542 10.538 20.052 1.646 3.925-1.54 8.161-5.87 4.336-2.924-2.582-13.506-13.563-16.51-16.983-2.38-2.71-2.989-12.138 3.224-11.897z"
        fill="#F03B46" />
      <path
        d="M97.731 43.772c-2.22-.079-6.015 2.362-9.813 3.628-.406.467-.598 1.352-.723 2.675-.13 1.367.177 2.672.458 3.369 4.499 3.125 5.702 5.225 7.55 5.825 1.364.444 2.547-.14 3.645-.543 1.097-.404 1.699-.976 3.424-.748 1.102.203 3.819 2.153 6.508 5.53 4.854-3.738 5.754-11.235 5.403-14.395-3.402-.29-5.725-.508-9.481-1.536-1.938-.531-2.814-1.384-3.421-2.017-1.001-1.042-1.791-1.726-3.55-1.788z"
        fill="#F8A486" />
      <path
        d="M87.68 52.04l-4.082.15.504-3.298s1.833-.064 2.762-.119c1.006-.06 2.17-.475 3.606-1.243 1.137-.607 2.484-.323 2.212 2.367-.2 1.976-.626 3.322-2.231 2.787-.804-.268-1.616-.638-2.771-.644z"
        fill="#FDE1CA" />
      <path d="M83.598 52.19l2.698-.099c.398-.874.6-2.273.568-3.318l-2.762.119-.504 3.299z" fill="#ECA96B" />
      <path
        d="M83.247 53.54c2.833-2.015 3.042-6.16 2.806-7.748-.102-.687-1.65-1.107-3.037-1.31-2.334-.343-5.35-.374-7.276.37-3.18 1.228-5.371 5.275-3.934 8.29 1.438 3.015 8.792 2.282 11.44.398z"
        fill="#FDE1CA" />
      <path
        d="M78.8 45.906l-.007.016-.43-.122c.877-.954 1.459-2.037 2.095-3.038 2.065 1.717.592 3.834-1.723 3.621a1.257 1.257 0 01.066-.477c.032-.099.05-.118 0 0z"
        fill="#FDE1CA" />
      <path d="M77.533 45.395c.104 1.091.63 2.118.427.958-.205-1.184-.491-1.636-.427-.958z" fill="#481A1A" />
      <path
        d="M79.698 46.176c-.33-.1-.451-.305-.453-.493.001-.203.145-.397.406-.434a.086.086 0 00-.023-.17.616.616 0 00-.554.604c-.002.269.19.545.575.657a.086.086 0 10.049-.164z"
        fill="#352734" />
      <path
        d="M82.855 47.379c.074.02.148.021.22.013a.832.832 0 00.209-.037.651.651 0 00.328-.209.751.751 0 00.152-.367c.028-.14.023-.288.032-.443.031.07.055.144.079.219.009.077.031.154.027.236a.699.699 0 01-.151.47.647.647 0 01-.441.23c-.16.012-.333-.007-.456-.112z"
        fill="#8D4320" />
      <path
        d="M70.234 48.58c-2.094 1.922-.266 7.728 5.048 9.903 5.866 2.402 8.855-3.034 11.006-7.298-3.183 2.165-7.746 1.906-9.198 1.548-2.862-.704-4.947-1.154-6.856-4.153z"
        fill="#481A1A" />
      <path
        d="M70.234 48.58c-.238-2.474.36-4.474 2.347-4.58 2.53-.134 3.228-1.01 3.41-1.764.844 1.074 1.221 3.419-.421 5.354.923-.117 1.523-1.04 1.523-1.04s.483 2.393-1.725 2.919c3.357 1.984 6.805 1.381 8.58.657-1.247 1.513-4.221 2.244-4.221 2.244s2.696-.185 3.768-.688c-4.425 2.283-5.986 1.886-7.972 1.45-1.985-.435-4.47-1.884-5.289-4.552z"
        fill="#481A1A" />
      <path
        d="M80.055 54.27c.728-.242 1.2-.794 1.053-1.233-.146-.438-.854-.597-1.581-.355-.728.242-1.2.794-1.053 1.233.146.438.854.597 1.581.355z"
        fill="#FDE1CA" />
      <path d="M75.62 58.856a3.567 3.567 0 11-7.135 0 3.567 3.567 0 017.135 0z" fill="#481A1A" />
      <path
        d="M33.646 107.218a.546.546 0 01-.516-.722l4.478-13.116h1.153l-4.599 13.469a.547.547 0 01-.516.369zM76.781 107.218a.546.546 0 00.516-.722L72.82 93.38h-1.153l4.599 13.469c.075.22.283.369.516.369z"
        fill="#663A27" />
      <path
        d="M31.506 109.071a.546.546 0 01-.517-.722l4.478-13.116h1.154l-4.6 13.468a.545.545 0 01-.515.37zM78.956 109.071a.545.545 0 00.516-.722l-4.478-13.116h-1.153l4.599 13.468c.075.222.283.37.516.37z"
        fill="#7E4730" />
      <path d="M77.903 93.06H32.226a1.184 1.184 0 100 2.37h45.677a1.184 1.184 0 100-2.37z" fill="#7E4730" />
      <path d="M68.797 93.06h-12.1v-2.084h12.1a1.042 1.042 0 010 2.083z" fill="#809096" />
      <path
        d="M68.826 92.565H49.998a.633.633 0 01-.62-.505l-3.885-14.599c-.081-.392 1.022-.996 1.423-.996h18.828c.3 0 .559.21.62.504l3.082 14.834a.634.634 0 01-.62.762z"
        fill="#809096" />
      <path
        d="M67.987 93.06H49.16c-.3 0-.56-.212-.62-.505L45.458 77.72a.633.633 0 01.62-.762h18.827c.3 0 .56.21.62.504l3.082 14.834a.633.633 0 01-.62.762z"
        fill="#CCCDCB" />
      <path
        d="M58.417 85.118c.132 1.1-.634 2.097-1.71 2.227-1.078.129-2.058-.657-2.19-1.757-.133-1.1.633-2.096 1.71-2.225 1.077-.13 2.057.656 2.19 1.755z"
        fill="#809096" />
      <path d="M136.954 27.082h-15.9v-16.99h15.9v16.99z" fill="#FFF2DB" />
      <path d="M124.657 24.627h-11.305v-12.08h11.305v12.08z" fill="#F5D3A6" />
      <path d="M149.202 30.116h-8.137V21.42h8.137v8.695z" fill="#FFF2DB" />
      <path d="M148.026 28.86h-5.786v-6.183h5.786v6.182z" fill="#F5D3A6" />
      <path d="M135.962 45.88h-13.899V31.029h13.899V45.88z" fill="#FFF2DB" />
      <path d="M133.954 43.734h-9.883v-10.56h9.883v10.56z" fill="#F5D3A6" />
      <path d="M61.326 20.058a9.445 9.445 0 11-9.445-9.446 9.445 9.445 0 019.445 9.446z" fill="#FFF2DB" />
      <path d="M59.256 20.058a7.375 7.375 0 11-14.75 0 7.375 7.375 0 0114.75 0z" fill="#F5D3A6" />
      <path d="M42.398 20.058a.517.517 0 11-1.034 0 .517.517 0 011.034 0z" fill="#E56F2C" />
      <path d="M42.02 19.9v-5.05a.153.153 0 10-.306 0v5.05a.153.153 0 10.306 0z" fill="#E56F2C" />
      <path d="M41.953 20.223h2.91a.153.153 0 000-.306h-2.91a.153.153 0 000 .306z" fill="#E56F2C" />
    </svg>
  );
}
