import { post, get } from "./api";

export const SubscriptionApi = {
  list: () => get("/subscriptions/"),
  subscriptionListTeacher: () => get("/subscriptions/teacher/"),
  createSubscription: (data) => post("/subscriptions/", data),
  subscriptionDetails: (id) => post(`/subscriptions/${id}/details/`),
  bookSubscription: (id) => post(`/subscriptions/${id}/book/`),
  bookSubscriptionPayCheck: (id, data) =>
    post(`/subscriptions/${id}/success/`, data),
  bookSubscriptionPayFailure: (id) => post(`/subscriptions/${id}/fail/`),
  userBookings: () => get("/subscriptions/bookings/user/"),
  teacherBookings: () => get("/subscriptions/bookings/teacher/"),
  bookingDetails: (id) => post(`/subscriptions/${id}/booking-details/`),
  bookingDetailsUser: (id) =>
    post(`/subscriptions/${id}/booking-details/user/`),
  bookingUpdateUser: (id) => post(`/subscriptions/${id}/booking-update/`),
};
