import cx from 'classnames';
import React from 'react';


function TextLink({ children, onClick, className, color = 'text-y-yellow' }) {
  return (
    <span className={cx(`${color} hover:underline cursor-pointer`, className)} onClick={onClick}>
      {children}
    </span>
  );
}

export default TextLink;
