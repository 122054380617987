import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import UserSelectors from "../../Selectors/user";

export function useLoggedInSection(redirect) {
  const isLoggedIn = useSelector(UserSelectors.isLoggedIn);
  const history = useHistory();

  React.useEffect(() => {
    if (!isLoggedIn) {
      history.push(redirect);
    }
  }, [isLoggedIn, history, redirect])
}
