import React from "react";
import "../Storefront/store.css";

const Faq = () => {
  const sessions = [
    {
      name: "What is Yoga Nidra?",
      content:
        "Yoga Nidra, often referred to as yogic sleep, is a state of conscious relaxation achieved through a guided meditation technique. It involves deep relaxation while maintaining awareness and is known for its rejuvenating effects on the body and mind.",
    },
    {
      name: "How does Yoga Nidra differ from traditional meditation practices?",
      content:
        "Yoga Nidra differs from traditional meditation as it involves systematic relaxation techniques that guide practitioners through different stages of consciousness, promoting profound relaxation while keeping the mind alert.",
    },
    {
      name: "What are the main benefits of practicing Yoga Nidra?",
      content:
        "Practicing Yoga Nidra offers a range of benefits, including stress reduction, improved sleep quality, enhanced mental clarity, and a deep sense of relaxation. It is also known to alleviate anxiety and promote overall well-being.",
    },
    {
      name: "Can anyone practice Yoga Nidra, regardless of their experience level?",
      content:
        "Yes, Yoga Nidra is accessible to individuals of all experience levels, including beginners. Its guided nature makes it suitable for those who may find traditional meditation challenging.",
    },
    {
      name: "How long does a typical Yoga Nidra session last?",
      content:
        "A typical Yoga Nidra session can last anywhere from 20 minutes to an hour, with the duration depending on the specific guided meditation or the practitioner's preference.",
    },
    {
      name: "Is Yoga Nidra a form of sleep or hypnosis?",
      content:
        "While Yoga Nidra induces a state of deep relaxation resembling sleep, practitioners maintain a level of awareness. It is not considered hypnosis but rather a conscious state of relaxation.",
    },
    {
      name: "Can Yoga Nidra help with insomnia?",
      content:
        "Yes, Yoga Nidra is known to be effective in managing insomnia by promoting relaxation and creating an optimal environment for better sleep. Regular practice can contribute to improved sleep patterns.",
    },
    {
      name: "Are there specific postures required for practicing Yoga Nidra?",
      content:
        "Yoga Nidra is typically practiced in a comfortable lying down position, such as Savasana (Corpse Pose). The focus is on relaxation, making it accessible for individuals with various physical abilities.",
    },
    {
      name: "How often should one practice Yoga Nidra to experience its benefits?",
      content:
        "Practicing Yoga Nidra a few times per week can bring about noticeable benefits. Consistency is key, and individuals can adjust the frequency based on personal preferences and schedules.",
    },
    {
      name: "Can Yoga Nidra be used as a tool for stress management?",
      content:
        "Yes, Yoga Nidra is considered an excellent tool for stress management. Its deep relaxation techniques help reduce stress hormones, calm the nervous system, and promote a sense of inner peace.",
    },
    {
      name: "Is Yoga Nidra suitable for individuals with anxiety disorders?",
      content:
        "Yes, Yoga Nidra can be beneficial for individuals with anxiety disorders. Its calming effects on the nervous system and focus on relaxation make it a valuable practice for managing anxiety.",
    },
    {
      name: "Can pregnant women practice Yoga Nidra?",
      content:
        "Yes, pregnant women can practice Yoga Nidra. It is a gentle practice that can promote relaxation during pregnancy, but it's advisable to consult with a healthcare provider before starting any new practice.",
    },
    {
      name: "Can Yoga Nidra improve concentration and focus?",
      content:
        "Yes, regular practice of Yoga Nidra has been associated with improved concentration and focus. The practice enhances mindfulness and mental clarity, contributing to better cognitive function.",
    },
    {
      name: "How does Yoga Nidra contribute to overall mental well-being?",
      content:
        "Yoga Nidra contributes to overall mental well-being by reducing stress, promoting relaxation, and fostering a deep sense of inner peace. Its holistic approach addresses both physical and mental aspects, enhancing the overall quality of life.",
    },
    {
      name: "Is there a specific time of day recommended for practicing Yoga Nidra?",
      content:
        "While Yoga Nidra can be practiced at any time, it is often recommended to do so in the afternoon or evening for relaxation. However, individuals can choose a time that best fits their schedule and when they can fully engage in the practice.",
    },
    {
      name: "Can Yoga Nidra be used as a tool for pain management?",
      content:
        "Yes, Yoga Nidra can be used as a complementary tool for pain management. The deep relaxation it induces may help alleviate discomfort and promote a more positive perception of pain.",
    },
    {
      name: "How does Yoga Nidra impact the mind-body connection?",
      content:
        "Yoga Nidra enhances the mind-body connection by promoting awareness of bodily sensations, emotions, and thoughts. This heightened awareness contributes to a greater sense of self-understanding and holistic well-being.",
    },
    {
      name: "Can Yoga Nidra be practiced in a group setting?",
      content:
        "Yes, Yoga Nidra can be practiced in a group setting, making it a suitable activity for workshops, classes, or retreats. Practicing together can enhance the sense of shared relaxation and community.",
    },
  ];

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {sessions &&
        sessions.map((curr, i) => (
          <div className="mb-4">
            <h2 id={`accordion-collapse-heading-${i}`}>
              <button
                type="button"
                class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 rounded-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                data-accordion-target={`#accordion-collapse-body-${i}`}
                aria-expanded="true"
                aria-controls={`accordion-collapse-body-${i}`}
              >
                <span>{curr.name}</span>
                <svg
                  data-accordion-icon
                  class="w-3 h-3 rotate-90 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id={`accordion-collapse-body-${i}`}
              class="hidden"
              aria-labelledby={`accordion-collapse-heading-${i}`}
            >
              <div class="p-5 border border-t-0 rounded-xl border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  {curr.content}
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Faq;
