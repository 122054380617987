import Vent from "../vent";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

let initialState = {
  user: null,
  token: null,
  payment: false,
  timeZone: "Asia/Kolkata",
  time: "",
  showMenu: false,
  showBottom: 0,
};

dayjs.extend(utc);
dayjs.extend(timezone);
const zone = dayjs.tz.guess();

if (window.localStorage) {
  try {
    const localData = localStorage.getItem("YOGA_TIME");
    const localUser = JSON.parse(localStorage.getItem("YOGA_USER"));

    if (localUser) {
      initialState = {
        ...initialState,
        ...localUser,
      };
    }

    if (localData) {
      const convertedDate = dayjs().tz(localData).format("h:mm a");

      initialState = {
        ...initialState,
        timeZone: localData,
        time: dayjs().tz(localData).format("h:mm a"),
      };
    } else {
      initialState = {
        ...initialState,
        timeZone: zone === "Asia/Calcutta" ? "Asia/Kolkata" : zone,
        time: dayjs()
          .tz(zone === "Asia/Calcutta" ? "Asia/Kolkata" : zone)
          .format("h:mm a"),
      };
    }

    if (localUser && localUser.user && localUser.user.id) {
      Vent.onUserIdentified(localUser.user);
    }
  } catch (e) {
    console.log("e", e);
    // initialState = {
    //   ...initialState,
    //   timeZone: zone,
    //   time: dayjs().tz(zone).format("h:mm a"),
    // };
  }
}

function syncLocalStorage(payload) {
  if (window.localStorage) {
    localStorage.setItem("YOGA_USER", JSON.stringify(payload));
  }

  return payload;
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "user/set":
      Vent.onUserIdentified(action.payload.user || state.user);

      return syncLocalStorage({
        ...state,
        user: action.payload.user || state.user,
        token: action.payload.token || state.token,
      });

    case "user/showMenu":
      return syncLocalStorage({
        ...state,
        showMenu: action.payload.val,
      });

    case "user/time":
      localStorage.setItem("YOGA_TIME", action.payload.timeZone);
      return syncLocalStorage({
        ...state,
        timeZone: action.payload.timeZone,
        time: dayjs().tz(action.payload.timeZone).format("h:mm a"),
      });

    case "user/pay":
      return {
        ...state,
        payment: action.payload.val,
      };

    case "user/showBottom":
      return {
        ...state,
        showBottom: action.payload.val,
      };

    case "user/logout":
      Vent.onUserLogout();

      return syncLocalStorage({
        ...state,
        user: null,
        token: null,
      });

    case "user/update":
      Vent.onUserIdentified(action.payload.user);

      return syncLocalStorage({
        ...state,
        user: action.payload.user,
      });

    default:
      return state;
  }
}
