import React from 'react';
import { useForm } from "react-hook-form";
import Button from "../../../Atoms/Button/Button";
import DayPeriodSelectorField from "../../../Atoms/DayPeriodSelectorField";
import Form from "../../../Atoms/Form";
import FormText from "../../../Atoms/FormText";
import Select from "../../../Atoms/Select";

export default function TimingsStep({ data, onNext, formErrors }) {
  const { register, control, handleSubmit, setError, errors } = useForm({
    defaultValues: data,
  });

  const duration = [];
  for (let i = 30; i <= 120; i += 15) {
    duration.push({ value: i, label: `${i} min` })
  }

  const slots = [];
  for (let i = 5; i <= 21; i++) {
    slots.push(i);
  }

  function _onNext(values) {
    if (!values.slots || values.slots.length === 0) {
      setError('slots', {
        type: 'manual',
        message: 'At least one slot is required',
      });

      return;
    }

    onNext(values);
  }

  return (
    <div className="mt-8">
      <Form onSubmit={handleSubmit(_onNext)} errors={formErrors}>
        <FormText>
          Your teacher profile and availability will be shown to students
          looking to book 1:1 yoga sessions.
        </FormText>

        <div className="flex flex-row justify-between mt-8">
          <FormText>
            How long is your yoga class?
          </FormText>
          <Select
            name="duration"
            label="Duration"
            options={duration}
            required
            inputWidth="w-40 sm:w-48"
            inputRef={register({ required: true })}
          />
        </div>

        <FormText>Select slots for your typical daily availability to take up 1:1 yoga sessions.</FormText>
        <div className="mt-5 mb-8">
          <div className="rounded-md shadow-md p-5">
            <DayPeriodSelectorField
              slots={slots}
              name="slots"
              control={control}
              formErrors={errors}
            />
          </div>

          <FormText className="mt-5">You can confirm or reschedule session after the booking is done.</FormText>
        </div>

        <div className="mt-2 flex flex-row justify-end">
          <Button type="submit">Next</Button>
        </div>
      </Form>
    </div>
  );
}
