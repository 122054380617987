import dayjs from "dayjs";

const DateHelpers = {
  /**
   * Given a date string, convert it into human friendly date label
   * (Today, Tomorrow, or date)
   *
   * @param {string} date
   */
  humanizedDateText(date) {
    const oDay = dayjs(date);
    const now = dayjs();

    let dayLabel = oDay.format('D MMM')
    if (oDay.isSame(now, 'day')) {
      dayLabel = 'Today';

    } else if (oDay.isSame(now.add(1, 'day'), 'day')) {
      dayLabel = 'Tomorrow';
    }

    return dayLabel;
  }
};

export default DateHelpers;
