import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../../Atoms/Button/Button";
import Form from "../../../Atoms/Form";
import FormError from "../../../Atoms/FormError";
import FormText from "../../../Atoms/FormText";
import Input from "../../../Atoms/Input/Input";
import Select from "../../../Atoms/Select";

export default function VideoStep({
  data,
  onNext,
  loading,
  formErrors,
  isEdit,
}) {
  const { register, handleSubmit } = useForm({
    defaultValues: data,
  });

  return (
    <div className="mt-8">
      <Form onSubmit={handleSubmit(onNext)} errors={formErrors}>
        <FormText className="mb-5">
          Video link for students to join the class
        </FormText>
        <div className="mt-8">
          <Select
            name="video_platform"
            label="Streaming platform"
            options={[
              { value: "zoom", label: "Zoom" },
              { value: "meet", label: "Google Meet" },
              { value: "skype", label: "Skype" },
              { value: "youtube", label: "Youtube" },
            ]}
            required
            inputRef={register({ required: true })}
          />
        </div>

        <div className="mt-5">
          <Input
            name="video_link"
            label="Video link (url)"
            required
            inputRef={register({ required: true })}
          />
        </div>

        <div className="mt-5">
          <Input name="meeting_id" label="Meeting ID" inputRef={register()} />
        </div>

        <div className="mt-5">
          <Input
            name="video_password"
            label="Password (if any)"
            inputRef={register()}
          />
        </div>

        <FormError className="my-5" error={formErrors} alignRight />

        <div className="mt-2 flex flex-row justify-end">
          <Button loading={loading} type="submit">
            {isEdit ? "Update" : "Done"}
          </Button>
        </div>
      </Form>
    </div>
  );
}
