import React from "react";

import HoveringGirl from "../../Graphics/HoveringGirl";
import Logo from "../../Graphics/Logo";
import Button from "../../Atoms/Button/Button";
import { useHistory } from "react-router-dom";

import { Helmet } from "react-helmet";

export default function JoinClass(props) {
  const history = useHistory();

  let teacher = {};

  // const formatData = data.map((c) => {
  //   teacher[c.teacher.id] = c.teacher;
  //   return `<url><loc>${`https://do.yoga/monthly-courses/${c.slug}-${c.id}`}</loc></url>`;
  // });

  // const formatTeacherData = Object.keys(teacher)
  //   .map((c) => teacher[c])
  //   .filter((c) => c.domain)
  //   .map((c) => {
  //     return `<url><loc>${`https://${c.domain}.do.yoga`}</loc></url>`;
  //   });

  return (
    <div className="bg-y-cream-600 min-h-screen flex flex-col justify-center items-center p-5">
      <Helmet>
        <title>Not Found</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Not Found"></meta>
        <meta name="description" content="Not Found" />
        <meta property="og:title" content="Not Found"></meta>
        <meta property="twitter:title" content="Not Found"></meta>
        <meta property="og:description" content="Not Found"></meta>
        <meta property="twitter:description" content="Not Found"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <Logo className="mb-24" />
      <HoveringGirl width={250} height={250} />
      <h2 className="text-xl text-center mt-20 text-y-yellow-dark">
        Page Not Found!
      </h2>
      <div className="flex pt- mt-4">
        <Button
          py="py-3"
          className="whitespace-nowrap"
          type="submit"
          onClick={() => {
            history.push("/");
          }}
        >
          Go To Home
        </Button>
      </div>
    </div>
  );
}
