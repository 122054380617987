const UserAction = {
  setPaymentLogin: (val) => ({
    type: "user/pay",
    payload: {
      val,
    },
  }),

  setShowMenu: (val) => {
    //console.log("val", val);
    return {
      type: "user/showMenu",
      payload: {
        val,
      },
    };
  },

  setShowBootom: (val) => {
    return {
      type: "user/showBottom",
      payload: {
        val,
      },
    };
  },

  setTimeZone: (val) => ({
    type: "user/time",
    payload: {
      timeZone: val,
    },
  }),

  /**
   * Set a user
   */
  set: (user, token) => ({
    type: "user/set",
    payload: {
      user,
      token,
    },
  }),

  /**
   * Logout user
   */
  logout: () => ({
    type: "user/logout",
  }),

  /**
   * Update user data
   */
  updateUser(user) {
    return {
      type: "user/update",
      payload: {
        user,
      },
    };
  },
};

export default UserAction;
