import "./style.css";

const HomeFoot = () => {
  return (
    <div className="pt-4">
      <h2 className="head-text">
        Empower Your Journey: Online Yoga Classes for PCOS/PCOD Wellness
      </h2>
      <div className="body-text">
        In the bustling realm of digital wellness, where every click brings a
        new avenue to explore, finding the right fit for your needs can be
        overwhelming. For those navigating the challenges of Polycystic Ovary
        Syndrome (PCOS), the quest for holistic well-being often involves a
        multifaceted approach. Amidst the plethora of options, online yoga
        classes emerge as a beacon, offering tailored practices to manage PCOS
        symptoms effectively.
      </div>
      <h2 className="head-text">What is PCOD/PCOS?</h2>
      <div className="body-text">
        PCOD/PCOS is a complex endocrine disorder characterized by a cluster of
        symptoms that collectively impact various aspects of a person's
        well-being. The key features include irregular menstrual cycles,
        elevated levels of androgens (male hormones), and the formation of small
        cysts on the ovaries. These cysts are not harmful but can contribute to
        hormonal imbalances.
      </div>
      <h2 className="head-text">How and Why PCOD/PCOS Happens</h2>
      <div className="body-text">
        The exact cause of PCOD/PCOS is not fully understood, and its
        development involves a combination of genetic, hormonal, and lifestyle
        factors. Let's delve into the intricacies of how and why PCOD/PCOS
        occurs:
      </div>
      <div className="body-text">
        <span className="head-text">Genetic Predisposition:</span> There is a
        strong genetic component associated with PCOD/PCOS. Individuals with a
        family history of the condition are more likely to develop it
        themselves. Certain genes may influence the way the body produces and
        processes hormones, contributing to the manifestation of PCOS.
      </div>
      <div className="body-text">
        <span className="head-text">Hormonal Imbalances:</span> Hormonal
        irregularities play a central role in the development of PCOD/PCOS.
        Insulin resistance, a condition where the body's cells don't respond
        effectively to insulin, is common in individuals with PCOS. This can
        lead to an overproduction of insulin, which in turn triggers an increase
        in androgen production by the ovaries.
      </div>
      <div className="body-text">
        Elevated levels of androgens, such as testosterone, disrupt the normal
        menstrual cycle and contribute to the development of cysts on the
        ovaries. This hormonal imbalance not only affects the reproductive
        system but can also have systemic effects on the body.
      </div>
      <div className="body-text">
        <span className="head-text">Insulin Resistance:</span> Insulin
        resistance, a key factor in PCOS, leads to higher levels of insulin in
        the bloodstream. Insulin resistance can stimulate the ovaries to produce
        more androgens, exacerbating hormonal imbalances and contributing to the
        characteristic symptoms of PCOD/PCOS.
      </div>

      <div className="body-text">
        Additionally, elevated insulin levels can impact glucose metabolism,
        increasing the risk of developing type 2 diabetes. The interplay between
        insulin resistance and hormonal disturbances creates a complex web of
        factors contributing to PCOS.
      </div>
      <div className="body-text">
        <span className="head-text">Inflammation:</span> Chronic inflammation is
        another factor linked to PCOD/PCOS. Inflammation disrupts normal ovarian
        function and contributes to insulin resistance. This inflammatory state
        can be influenced by factors such as obesity, which is often associated
        with PCOS.
      </div>
      <div className="body-text">
        <span className="head-text">Lifestyle Factors:</span> While genetic and
        hormonal factors play a significant role, lifestyle choices can also
        contribute to the development and exacerbation of PCOD/PCOS. Poor
        dietary habits, sedentary lifestyles, and stress can amplify insulin
        resistance and hormonal imbalances, worsening the symptoms of PCOS.
      </div>
      <h2 className="head-text">
        Tailored Yoga Poses for Managing PCOS/PCOD Symptoms
      </h2>
      <h2 className="head-text">1. Seated Twist: Bharadvajasana</h2>
      <div className="body-text">
        Bharadvajasana, or Seated Twist, gently rotates the spine, providing a
        soothing massage to the abdominal organs. This twist aids in promoting
        hormonal balance and improving digestion – essential aspects for
        managing PCOS/PCOD symptoms. The gentle compression on the abdomen helps
        stimulate the organs, fostering a conducive environment for reproductive
        health.
      </div>
      <h2 className="head-text">2. Bridge Pose: Setu Bandhasana</h2>
      <div className="body-text">
        Setu Bandhasana, commonly known as Bridge Pose, is a powerful asana that
        engages the thyroid and abdominal organs. This engagement supports the
        regulation of hormonal imbalances associated with PCOS/PCOD. The pose
        also aids in strengthening the pelvic muscles and promotes better blood
        circulation in the pelvic region, contributing to overall reproductive
        well-being.
      </div>
      <h2 className="head-text">3. Legs Up the Wall: Viparita Karani</h2>
      <div className="body-text">
        Viparita Karani, or Legs Up the Wall Pose, is an inversion that
        facilitates improved blood flow to the pelvic area. By stimulating the
        endocrine system, this pose can positively impact hormonal balance and
        enhance metabolic functions. Its relaxing nature also makes it
        beneficial for reducing stress, a significant factor in managing
        PCOS/PCOD.
      </div>
      <h2 className="head-text">4. Butterfly Pose: Baddha Konasana</h2>
      <div className="body-text">
        Baddha Konasana, or Butterfly Pose, involves a gentle stretch in the
        pelvic region, making it an ideal posture for reproductive health. This
        asana supports the organs in the pelvic area, promoting flexibility and
        reducing tension. Regular practice can ease menstrual discomfort and
        contribute to overall well-being in individuals with PCOS/PCOD.
      </div>
      <h2 className="head-text">
        5. Reclining Butterfly Pose: Supta Baddha Konasana
      </h2>
      <div className="body-text">
        Supta Baddha Konasana, a variation of Butterfly Pose, is a restorative
        posture that enhances relaxation. By encouraging blood flow to the
        pelvic region, this pose supports reproductive health and aids in
        hormonal balance. Its calming effects make it particularly beneficial
        for individuals managing the stress associated with PCOS/PCOD.
      </div>
      <div className="body-text">
        Incorporating these yoga asanas into your routine can be a
        transformative step in your PCOS/PCOD wellness journey. Each pose is
        thoughtfully chosen to address the specific needs of individuals facing
        hormonal imbalances. As you flow through these postures mindfully,
        remember to embrace the holistic approach of yoga, focusing not only on
        the physical aspect but also on nurturing your mental and emotional
        well-being.
      </div>
      <h2 className="head-text">Beginner-Friendly Yoga Routines for PCOS</h2>
      <div className="body-text">
        For those taking their initial steps into the world of yoga for PCOS, a
        beginner-friendly routine offers a gentle introduction. These routines
        are designed with accessibility in mind, ensuring that individuals of
        all fitness levels can embark on their wellness journey.
      </div>
      <div className="body-text">
        <span className="head-text">1. Sun Salutations (Surya Namaskar):</span>{" "}
        A series of flowing postures that build strength, flexibility, and
        focus, Sun Salutations serve as a foundational element for beginners.
      </div>
      <div className="body-text">
        <span className="head-text">2. Gentle Seated Poses:</span> Incorporating
        easy-to-follow seated poses allows beginners to cultivate mindfulness
        and flexibility without the demands of standing postures.
      </div>
      <div className="body-text">
        <span className="head-text">
          3. Deep Breathing Exercises (Pranayama):
        </span>{" "}
        Simple breathing exercises enhance lung capacity, reduce stress, and lay
        the groundwork for more advanced practices as individuals progress.
      </div>
      <div className="body-text">
        <span className="head-text">4. Yin Yoga for Relaxation:</span>{" "}
        Slow-paced and meditative, Yin Yoga involves holding poses for extended
        periods, promoting relaxation and releasing tension in the muscles and
        connective tissues.
      </div>
      <h2 className="head-text">
        Dos and Don'ts: Nurturing Your Body and Managing PCOS/PCOD
      </h2>
      <div className="body-text">
        Embarking on the journey to manage Polycystic Ovary Syndrome (PCOS) or
        Polycystic Ovarian Disorder (PCOD) involves embracing a holistic
        lifestyle that encompasses not just medical interventions but also
        mindful choices in diet and daily practices. Here's a guide to the dos
        and don'ts that can contribute to nurturing your body and enhancing your
        PCOS/PCOD management:
      </div>

      <h2 className="head-text">Dos:</h2>
      <div className="body-text">
        <span className="head-text">
          Prioritize Whole Foods for PCOS/PCOD Management:
        </span>{" "}
        Opt for a diet rich in whole, unprocessed foods such as fruits,
        vegetables, whole grains, and lean proteins. These nutrient-dense
        choices support overall health and contribute to PCOS/PCOD management.
      </div>
      <div className="body-text">
        <span className="head-text">
          Include Aahara for Personalized Nutrition:
        </span>{" "}
        Integrate Aahara's personalized nutrition coaching into your routine.
        Tailored meal plans from certified nutritionists can align with your
        health goals, providing essential support in managing PCOS/PCOD through
        a well-balanced diet.
      </div>
      <div className="body-text">
        <span className="head-text">Embrace Mindful Eating Practices:</span>{" "}
        Cultivate mindful eating habits to build a healthy relationship with
        food. Mindful eating not only aids digestion but also fosters an
        awareness of your body's nutritional needs, supporting PCOS/PCOD
        management.
      </div>
      <h2 className="head-text">Don'ts:</h2>
      <div className="body-text">
        <span className="head-text">
          Avoid Industrially Grown Meat, Especially Broiler Chicken:
        </span>{" "}
        Steer clear of industrially grown meat, particularly broiler chicken.
        Such meats often contain injected hormones that can disrupt hormonal
        balance, exacerbating symptoms of PCOS/PCOD.
      </div>
      <div className="body-text">
        <span className="head-text">
          Limit Processed Foods for PCOS/PCOD Wellness:
        </span>{" "}
        Minimize the consumption of processed foods, which may contain additives
        and preservatives. These additives can contribute to hormonal
        imbalances, impacting the management of PCOS/PCOD.
      </div>
      <div className="body-text">
        <span className="head-text">Moderate Caffeine and Sugar Intake:</span>{" "}
        Exercise moderation when it comes to caffeine and sugar consumption.
        Excessive intake of these substances can contribute to hormonal
        fluctuations, potentially affecting PCOS/PCOD symptoms.
      </div>
      <div className="body-text">
        These dos and don'ts form a foundation for a lifestyle that complements
        medical interventions and supports the holistic management of PCOS/PCOD.
      </div>
      <h2 className="head-text">
        Nutrition and Holistic Diet: Aahara by Do Yoga
      </h2>
      <div className="body-text">
        In conjunction with yoga and mindfulness, nutrition plays a pivotal role
        in managing PCOS. Recognizing this, Do Yoga introduces "Aahara," our
        personalized nutrition coaching service, exclusively designed to elevate
        your well-being through tailored daily diet plans.
      </div>
      <div className="body-text">
        <span className="head-text">
          Nutrition Plans Tailored to Your Goals:
        </span>{" "}
        Aahara, derived from Sanskrit meaning "food," offers customized meal
        plans crafted by certified nutritionists. These plans are designed to
        support various health goals, including diabetes management. The
        emphasis lies on mindful and nourishing eating habits that align with
        your unique health objectives.
      </div>
      <div className="body-text">
        <span className="head-text">Pricing for Nutrition Coaching:</span>{" "}
        Invest in your well-being with Aahara at an affordable cost of ₹2500 per
        month. Opt for a 3-month plan, and you'll enjoy a 20% discount. Aahara
        brings a wealth of experience, with nutrition coaches boasting 5 to 15
        years of expertise. Let our team guide you on your health journey,
        transforming your well-being from the inside out.
      </div>
      <h2 className="head-text">
        Integrating Yoga, Mindfulness, and Nutrition with Professional Guidance
      </h2>
      <div className="body-text">
        While incorporating yoga, mindfulness, and a tailored diet into your
        PCOS/POCD management routine, it's essential to keep your healthcare
        provider informed. Doctors now recognize these holistic approaches as
        valuable complements to traditional medical interventions. Open
        communication ensures that your healthcare team remains well-informed,
        allowing for collaborative and comprehensive care.
      </div>
      <div className="body-text">
        In conclusion, the evolving landscape of PCOS/PCOD management is marked
        by a harmonious integration of conventional medical approaches and
        holistic practices. With doctors now recommending yoga, mindfulness, and
        personalized nutrition coaching, individuals navigating the complexities
        of PCOS can embark on a journey of holistic well-being. As you explore
        these avenues, remember that your healthcare provider is your ultimate
        expert, guiding you toward a balanced and empowered life.
      </div>
      <h2 className="head-text">
        Online Yoga Community Support for PCOS/PCOD Wellness: Connect,
        Transform, Thrive!
      </h2>
      <div className="body-text">
        Discover the empowering camaraderie within the Do Yoga community as you
        embark on a transformative journey managing PCOS/PCOD through our live
        online yoga classes. Connect with like-minded individuals, share your
        progress, and seek advice in this supportive space tailored for those
        navigating the complexities of PCOS/PCOD. Our live sessions bring
        real-time interactions, fostering accountability and encouragement.{" "}
      </div>
      <div className="body-text">
        Do Yoga goes beyond being a platform; it's a nurturing haven where the
        synergy of personalized yoga practices and community support propels
        your holistic wellness journey. Start your transformative path with us
        today, embracing strength, resilience, and shared purpose in the company
        of Do Yoga's supportive community.
      </div>
    </div>
  );
};

export default HomeFoot;
