import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UserApi } from "../../Api/UserApi";
import Button from "../../Atoms/Button/Button";
import ErrorMessage from "../../Atoms/ErrorMessage";
import Form from "../../Atoms/Form";
import Input from "../../Atoms/Input/Input";
import TextLink from "../../Atoms/TextLink/TextLink";
import useTemporaryMessage from "../../Hooks/useTemporaryMessage";
import { useApi } from "../../Utilities/Hooks/ApiHook";

import Modal from "../../Atoms/Modal/Modal";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { Passers } from "prop-passer";

export default function Website() {
  const { callApi, loading, data, errorMessage, setSuccessResponse } = useApi(
    UserApi.teacherProfile
  );

  React.useEffect(() => {
    callApi();
  }, []);

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (!data) {
    return "Loading Teacher Profile..";
  }

  return (
    <div className="bg-white shadow-card-cream sm:rounded-md px-7 sm:px-10">
      <WebsiteStage data={data?.data} />;
    </div>
  );
}

function WebsiteStage({ data }) {
  const { message, showMessage } = useTemporaryMessage({
    className: "text-green-500 mr-5",
  });
  const { callApi, formErrors, loading, errorMessage } = useApi(
    UserApi.updateTeacherProfile
  );
  const [savedDomain, setSavedDomain] = React.useState(true);

  const { register, handleSubmit, watch } = useForm({
    defaultValues: data || {},
  });

  const title = `Namaste. You can do yoga with me. Look forward to helping you build a regular yoga practice.`;

  const size = "2.5rem";

  const [showModal, setShowModal] = useState(false);

  const ShareList = Passers({
    url: "",
    className: "network__share-button",
  })({
    className: "network cursor-pointer hover transition--default",
  })("li");

  React.useEffect(() => {
    setSavedDomain(data?.domain);
  }, [data, setSavedDomain]);

  const watchDomain = watch("domain");

  function onSave(d) {
    callApi(d).then((response) => {
      showMessage("Saved!");
    });
  }

  const canPreview = watchDomain === savedDomain;

  const url = `https://${watchDomain}.do.yoga/`;

  function onPreview() {
    if (!canPreview) {
      return;
    }

    window.open(`https://${savedDomain}.do.yoga/`);
  }

  return (
    <>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(!showModal);
        }}
        className="sm:w-full sm:max-w-md"
      >
        <div className="flex flex-wrap p-10">
          <div>
            <WhatsappShareButton
              url={url}
              title={title}
              separator="     "
              className="m-5"
            >
              <WhatsappIcon size={size} />
            </WhatsappShareButton>
          </div>
          <div>
            <ShareList>
              <FacebookShareButton quote={title} className="m-5">
                <FacebookIcon size={size} />
              </FacebookShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TwitterShareButton title={title} className="m-5">
                <TwitterIcon size={size} />
              </TwitterShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <LinkedinShareButton title={title} className="m-5">
                <LinkedinIcon size={size} />
              </LinkedinShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TelegramShareButton title={title} className="m-5">
                <TelegramIcon size={size} />
              </TelegramShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <EmailShareButton subject={title} body={title} className="m-5">
                <EmailIcon size={size} />
              </EmailShareButton>
            </ShareList>
          </div>
          <div></div>
          <div></div>
        </div>
      </Modal>
      <Form onSubmit={handleSubmit(onSave)} errors={formErrors}>
        <div className="mt-4 text-sm bg-y-cream p-2 rounded-md">
          Set up your own website where students can join your yoga classes.
        </div>
        <div className="mt-8">
          <Input
            required
            prefix="https://"
            suffix=".do.yoga"
            prefixWidth="pl-20"
            name="domain"
            label="Your website"
            inputRef={register({ required: true })}
          />

          {watchDomain && (
            <div className="mt-2 text-sm rounded-md p-5 bg-gray-100">
              <div>Share your yoga with the world</div>
              <div className="mt-1">
                {canPreview ? (
                  <TextLink onClick={onPreview} className="text-y-yellow">
                    https://{watchDomain}.do.yoga
                  </TextLink>
                ) : (
                  <div className="text-gray-500">
                    Save changes to enable your website
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <ErrorMessage>{errorMessage}</ErrorMessage>

        <div className="mt-4 flex flex-row justify-start items-center">
          {message}
          <Button type="submit" loading={loading}>
            Save
          </Button>

          <div className="flex pt-4 pb-4 mb-0 ml-5">
            <Button
              className="whitespace-nowrap"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              Share
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}
