import React from 'react';
import { useForm } from "react-hook-form";
import Button from "../../../Atoms/Button/Button";
import Checkbox from "../../../Atoms/Checkbox";
import Form from "../../../Atoms/Form";
import FormText from "../../../Atoms/FormText";
import Input from "../../../Atoms/Input/Input";

export default function FeeStep({ data, onNext, loading, formErrors, isCourse }) {
  const { register, handleSubmit } = useForm({
    defaultValues: data,
  });

  function _onNext(values) {
    if (isCourse) {
      values.allow_drop_in = !!values.allow_drop_in;
    }

    const numericFields = ['period_fee_india', 'period_fee_intl', 'single_fee_india', 'single_fee_intl'];

    // cast invalid numbers to null
    Object.keys(values).forEach(k => {
      if (numericFields.includes(k) && !values[k]) {
        values[k] = null;
      }
    });

    onNext(values);
  }

  return (
    <div className="mt-8">
      <Form onSubmit={handleSubmit(_onNext)} errors={formErrors}>
        <FormText className="mb-5">
          {isCourse ? 'Course fees - Students join the entire course' : 'Monthly class fees - Students join for the entire month'}
        </FormText>
        <div className="flex flex-row justify-between">
          <Input
            name="period_fee_india"
            label="For India"
            inputWidth="w-40 sm:w-48"
            prefix="Rs."
            required={isCourse}
            inputRef={register({ required: isCourse })}
          />
          <Input
            name="period_fee_intl"
            label="For International"
            inputWidth="w-40 sm:w-48"
            prefix="$"
            inputRef={register()}
          />
        </div>

        <FormText className="my-5">
          {isCourse ? 'Per class fees - Students join a single class' : 'Per class fees - Students join a single class'}
        </FormText>
        <div className="flex flex-row justify-between">
          <Input
            name="single_fee_india"
            label="For India"
            inputWidth="w-40 sm:w-48"
            prefix="Rs."
            required={!isCourse}
            inputRef={register({ required: !isCourse })}
          />
          <Input
            name="single_fee_intl"
            label="For International"
            inputWidth="w-40 sm:w-48"
            prefix="$"
            inputRef={register()}
          />
        </div>
        <Checkbox
          name="no_drop_in"
          label="Do not allow drop in"
          inputRef={register()}
        />

        <div className="mt-2 flex flex-row justify-end">
          <Button type="submit">Next</Button>
        </div>
      </Form>
    </div>
  );
}
