import { post } from "./api";

export const LoginApi = {
  login: data => post('/authentication/login/', data),
  signup: data => post('/authentication/signup/', data),
  sendOtp: data => post('/authentication/send-otp/', data),
  verifyOtp: data => post('/authentication/verify-otp/', data),
  // dummy apis
  otpLogin: data => post('/authentication/otp-login/', data),
  otpSignup: data => post('/authentication/otp-signup/', data),
};
