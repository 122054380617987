import cx from "classnames";
import dayjs from "dayjs";
import React from "react";
import SessionCard from "../../Atoms/SessionCard";
import UserSelectors from "../../Selectors/user";
import { useDispatch, useSelector } from "react-redux";

export default function Sessions({ sessions }) {
  const [date, setDate] = React.useState(null);

  const userTimeZone = useSelector(UserSelectors.userTimeZone);

  if (sessions.length === 0) {
    return null;
  }

  return (
    <div>
      <h2 className="font-semibold text-xl mb-2">Upcoming Classes</h2>
      <DaySelector sessions={sessions} date={date} setDate={setDate} />
      <DaySessions sessions={sessions} date={date} />
    </div>
  );
}

function DaySessions({ date, sessions }) {
  const userTimeZone = useSelector(UserSelectors.userTimeZone);
  const sessionsFiltered = sessions.filter(
    (s) =>
      dayjs(s.date + " " + s.course.start_time)
        .tz(userTimeZone)
        .format("YYYY-MM-DD") === date
  );

  return (
    <div className="mt-2 flex flex-col flex-wrap justify-center sm:justify-start">
      {sessionsFiltered.map((s) => (
        <div key={s.id} className="mb-5 sm:mr-5">
          <SessionCard session={s} />
        </div>
      ))}
    </div>
  );
}

function DaySelector({ date, setDate, sessions }) {
  const userTimeZone = useSelector(UserSelectors.userTimeZone);
  const dates = React.useMemo(() => {
    setDate(null);
    const out = [];
    for (let i = 0; i < 6; i++) {
      const day = dayjs().tz(userTimeZone).add(i, "day");
      const dayFormatted = day.format("YYYY-MM-DD");

      if (
        !sessions.filter(
          (s) =>
            dayjs(s.date + " " + s.course.start_time)
              .tz(userTimeZone)
              .format("YYYY-MM-DD") === dayFormatted
        ).length
      ) {
        // no classes on this date
        continue;
      }

      const now = dayjs().tz(userTimeZone);

      out.push({
        date: dayFormatted,
        label: day.isSame(now, "day") ? "Today" : day.format("D MMM"),
      });
    }

    return out;
  }, [sessions, userTimeZone]);

  React.useEffect(() => {
    if (!date && dates && dates.length > 0) {
      if (dates[0].date) {
        setDate(dates[0].date);
      }
    }
  }, [date, dates, setDate]);

  return (
    <div className="flex flex-row overflow-x-auto py-3">
      {dates.map((d) => (
        <div
          key={d.date}
          onClick={() => setDate(d.date)}
          className={cx(
            "mr-1 whitespace-nowrap rounded-full px-2 py-1 cursor-pointer",
            d.date === date ? "bg-y-yellow text-white" : "text-y-yellow"
          )}
        >
          {d.label}
        </div>
      ))}
    </div>
  );
}
