import cx from "classnames";
import React from 'react';
import InputWrapper from "../InputWrapper/InputWrapper";
import { Controller } from "react-hook-form";


export default function DaySelectField({ label, name, required, control, errors }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => (
        <ControlledComponent
          label={label}
          name={name}
          required={required}
          errors={errors}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
}

function ControlledComponent({ label, name, required, errors, onChange, value }) {
  const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  function onToggle(day) {
    let newValue = [...(value || [])];
    if (newValue.includes(day)) {
      newValue = newValue.filter(d => d !== day);
    } else {
      newValue.push(day);
    }

    onChange(newValue);
  }

  return (
    <InputWrapper label={label} name={name} required={required} errors={errors}>
      {() => (
        <div className="flex flex-row pt-3 py-3 px-2">
          {days.map(d => (
            <DayCircle
              key={d}
              day={d}
              selected={(value || []).includes(d)}
              onClick={() => onToggle(d)}
            />
          ))}
        </div>
      )}
    </InputWrapper>
  );
}

function DayCircle({ day, selected, onClick }) {
  return (
    <div onClick={onClick} className={cx(
      selected ? 'bg-y-yellow text-white' : 'border-y-yellow border text-y-yellow',
      "mx-3 rounded-full w-8 h-8 flex justify-center items-center text-sm cursor-pointer"
    )}>
      {day.charAt(0).toUpperCase()}
    </div>
  );
}
