import GlowingPicture from "../../Atoms/GlowingPicture";
import React, { useState } from "react";
import Ratings from "react-ratings-declarative";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { ReviewsApi } from "../../Api/ReviewApi";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import useIsMobile from "../../Hooks/useMobile";
import Slider from "react-slick";
import Loader from "../../Atoms/Loader";
import CourseAction from "../../Actions/course";
import CourseSelectors from "../../Selectors/course";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../Atoms/ErrorMessage";
import dayjs from "dayjs";

const Reviews = ({ teacher }) => {
  const { callApi, data, loading, errorMessage } = useApi(ReviewsApi.list);
  const dispatch = useDispatch();

  const reviewsData = useSelector(CourseSelectors.reviewData);
  const indexData = useSelector(CourseSelectors.indexData);

  const isMobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    //initialSlide: indexData.review,
    beforeChange: (current, next) => {
      dispatch(CourseAction.setIndex("review", next));
    },
  };

  useState(() => {
    if (reviewsData.length === 0) {
      callApi(teacher.id)
        .then((res) => {
          const newVal = res.data.data.reverse();
          dispatch(CourseAction.SetReviewData(newVal));
        })
        .catch((err) => console.log(err));
    }
  }, [callApi]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (errorMessage) {
    return <div></div>;
  }

  if (reviewsData.length === 0) {
    return <div></div>;
  }
  return (
    <>
      <h2 className="font-semibold text-xl mb-4 mt-4">Reviews</h2>
      {isMobile ? (
        <>
          <div className="flex flex-nowrap w-full overflow-auto">
            {reviewsData &&
              reviewsData.map((ele) => (
                <div
                  className="p-5 mr-10 px-5 bg-white lg:rounded-md lg:shadow-card-cream-sm"
                  style={{ paddingRight: 50 }}
                >
                  <div>
                    <div>
                      <div className="flex-col md:flex-row items-center">
                        <div className="flex items-center ">
                          <div>
                            {ele?.user?.picture ? (
                              <GlowingPicture imgUrl={ele?.user?.picture} />
                            ) : (
                              <GlowingPicture imgUrl={""} />
                            )}
                          </div>
                          <div className="font-xs pl-3 pr-5 whitespace-nowrap">
                            <div>
                              {ele?.user?.full_name
                                ? ele?.user?.full_name
                                : ele?.booking?.student?.full_name}
                            </div>
                            <div style={{ fontSize: 12, color: "grey  " }}>
                              {dayjs(ele.created_at).format("DD-MMM-YYYY")}
                            </div>
                          </div>
                        </div>
                        <div className="flex pl-3 md:pl-0 items-center whitespace-nowrap">
                          <div className="whitespace-nowrap">
                            <Ratings
                              rating={Number(ele.rating)}
                              widgetDimensions="25px"
                              widgetSpacings="3px"
                              widgetRatedColor="green"
                            >
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                              <Ratings.Widget widgetRatedColor="orange" />
                            </Ratings>
                          </div>
                          <div className="pt-1 pl-4 text-gray-700">
                            ( {ele.rating} )
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-4 pl-3 md:pl-0 pb-5 text-sm">
                      {ele.comment}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </>
      ) : (
        <>
          <div>
            <Slider {...settings}>
              {reviewsData &&
                reviewsData.map((ele) => (
                  <div className="p-5 px-10 bg-white lg:rounded-md lg:shadow-card-cream-sm sm:max-w-xs w-full h-60">
                    <div>
                      <div>
                        <div className="flex-col md:flex-row items-center">
                          <div className="flex items-center ">
                            <div>
                              {ele?.user?.picture ? (
                                <GlowingPicture imgUrl={ele?.user?.picture} />
                              ) : (
                                <GlowingPicture imgUrl={""} />
                              )}
                            </div>
                            <div className="font-xs pl-3 pr-5 whitespace-nowrap">
                              <div>
                                {ele?.user?.full_name
                                  ? ele?.user?.full_name
                                  : ele?.booking?.student?.full_name}
                              </div>
                              <div style={{ fontSize: 12, color: "grey  " }}>
                                {dayjs(ele.created_at).format("DD-MMM-YYYY")}
                              </div>
                            </div>
                          </div>
                          <div className="flex pl-3 md:pl-0 items-center">
                            <div>
                              <Ratings
                                rating={Number(ele.rating)}
                                widgetDimensions="25px"
                                widgetSpacings="3px"
                                widgetRatedColor="green"
                              >
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                                <Ratings.Widget widgetRatedColor="orange" />
                              </Ratings>
                            </div>
                            <div className="pt-1 pl-4 text-gray-700">
                              ( {ele.rating} )
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pt-4 pl-3 md:pl-0 pb-5 text-sm">
                        <div class="text-wrap tooltip">{ele.comment}</div>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );
};

export default Reviews;
