import React from 'react';

export default function MonthlyClassesGraphic({ width = 182, height = 118 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 182 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M137.68 3.126c5.254 3.802 7.756 11.327 10.341 17.98 2.669 6.655 5.504 12.358 11.675 21.546 6.255 9.189 15.845 21.704 14.094 29.863-1.668 8.158-14.677 11.96-26.186 9.663-11.425-2.376-21.349-10.693-31.94-9.584-10.591 1.03-21.765 11.565-30.522 12.357-8.756.792-14.927-8.08-20.18-17.347-5.255-9.189-9.508-18.773-5.171-25.902 4.42-7.209 17.512-11.961 25.518-16.635 8.006-4.752 10.925-9.426 15.261-13.703 4.337-4.277 10.007-8.238 16.929-10.139 6.922-1.901 14.928-1.822 20.181 1.901z"
        fill="#FCF3E2" />
      <path d="M155.964 60.97h-34.018a.892.892 0 00-.87.696l-5.367 23.982h35.845l5.28-23.593a.892.892 0 00-.87-1.086z"
            fill="#F7B38B" />
      <path d="M155.566 60.97h-34.018a.892.892 0 00-.87.696l-5.367 23.982h35.845l5.28-23.593a.892.892 0 00-.87-1.086z"
            fill="#FDE1CA" />
      <path d="M155.359 62.048h-33.688l-5.04 22.521h33.688l5.04-22.521z" fill="#fff" />
      <path d="M117.114 82.41h33.707l-.502 2.16h-33.688l.483-2.16z" fill="#EEA886" />
      <path
        d="M137.961 85.398h-35.565c-.428 0-.775.369-.775.825 0 .455.347.824.775.824h35.565c.427 0 .774-.37.774-.825 0-.455-.347-.824-.774-.824z"
        fill="#F7CDAF" />
      <path d="M131.567 85.398a.825.825 0 000 1.649h19.254c.706 0 .789-.352.789-.825v-.824h-20.043z" fill="#F7B38B" />
      <path
        d="M150.878 86.516h-1.406a.288.288 0 010-.576h1.406a.288.288 0 110 .576zM148.13 86.464a.236.236 0 110-.471.236.236 0 010 .47zM146.84 86.464a.237.237 0 110-.473.237.237 0 010 .473z"
        fill="#F03B46" />
      <path d="M152.861 66.037h-29.614l-3.2 15.668h29.614l3.2-15.668z" fill="#FFF2DB" />
      <path d="M153.253 64.122h-29.615l-.391 1.915h29.614l.392-1.915z" fill="#FDE1CA" />
      <path d="M152.299 65.475h-29.614l-3.201 15.667h29.615l3.2-15.667z" fill="#FAF7DF" />
      <path d="M152.69 63.56h-29.614l-.391 1.915h29.614l.391-1.915z" fill="#EEA886" />
      <path d="M119.93 78.96h29.636l-.446 2.182h-29.636l.446-2.182z" fill="#E3DFB9" />
      <path
        d="M124.843 74.457c-.078-.247-.326-.383-.647-.392l-.037.531-.164-.522a1.81 1.81 0 00-.546.162l-.043.022.042.672-.243-.558c-.083.054-.16.112-.23.174l.13.413-.247-.303c-.271.284-.405.617-.321.884.079.252.336.388.665.392l.04-.363.123.356a1.8 1.8 0 00.683-.224l-.158-.635.308.543c.09-.06.173-.125.248-.194l-.206-.487.339.348c.232-.269.342-.572.264-.819z"
        fill="#58BD9F" />
      <path
        d="M124.421 72.08c-.272.128-.463.403-.538.723l.361.015-.392.184a1.35 1.35 0 00.057.51l.014.038.705-.178-.627.355c.038.072.083.136.133.193l.454-.213-.364.307c.239.212.557.278.85.14.278-.13.471-.413.542-.741l-.497.045.523-.206a1.347 1.347 0 00-.094-.634l-.689.286.625-.416a1.103 1.103 0 00-.151-.207l-.546.303.429-.407a.754.754 0 00-.795-.096zM128.333 75.672a.454.454 0 00-.047-.492l-.256.265.181-.348a.733.733 0 00-.265-.155l-.025-.007-.293.374.17-.402a.895.895 0 00-.16-.01l-.144.277.053-.27c-.225.032-.425.149-.518.328a.458.458 0 00.053.5l.198-.191-.137.26a.728.728 0 00.342.18l.235-.405-.141.423c.059.008.118.01.176.006l.151-.348-.041.333c.204-.04.382-.152.468-.318z"
        fill="#80D3B7" />
      <path
        d="M128.447 73.974c.03.266-.129.556-.398.79l-.238-.425.062.56c-.155.104-.333.19-.527.248l-.045.012-.226-.602.01.647a1.53 1.53 0 01-.263.015l-.05-.444-.092.432c-.342-.05-.585-.238-.618-.525-.03-.27.136-.568.414-.804l.36.575-.218-.684a1.936 1.936 0 01.668-.292l.113.652.051-.68c.1-.013.196-.016.287-.01l.013.56.153-.536c.303.065.514.245.544.511z"
        fill="#58BD9F" />
      <path
        d="M127.846 71.62c.269.157.365.492.296.883l-.628-.087.567.33a2.28 2.28 0 01-.324.623l-.036.047-.773-.218.59.434c-.083.088-.17.166-.259.237l-.449-.261.291.375c-.398.26-.818.34-1.109.171-.274-.16-.368-.504-.292-.906l.528.05-.478-.247a2.27 2.27 0 01.43-.773l.7.35-.556-.51c.092-.094.189-.18.288-.253l.515.37-.321-.497c.371-.215.751-.274 1.02-.117z"
        fill="#80D3B7" />
      <path
        d="M125.35 76.978l-.12-.022c.003-.016.284-1.6.1-2.053-.168-.416-.562-.286-.579-.28l-.04-.116c.006-.001.522-.172.733.35.196.485-.082 2.054-.094 2.12z"
        fill="#58BD9F" />
      <path
        d="M125.55 76.735l-.121-.01c0-.006.05-.546.191-1.446a3.89 3.89 0 01.659-1.601l.097.074a3.843 3.843 0 00-.635 1.546c-.141.895-.19 1.432-.191 1.437zM125.07 76.736l-.122-.013c.2-1.88.002-2.693 0-2.7l.118-.031c.009.033.207.837.004 2.744z"
        fill="#80D3B7" />
      <path d="M125.227 76.742l-.12-.025c.348-1.702 1.106-2.197 1.138-2.217l.065.104c-.007.004-.747.493-1.083 2.138z"
            fill="#58BD9F" />
      <path
        d="M125.674 76.742l-.119-.025c.144-.71.425-1.185.833-1.413.46-.258.907-.1.926-.093l-.042.115a1.08 1.08 0 00-.826.086c-.375.21-.634.658-.772 1.33z"
        fill="#80D3B7" />
      <path d="M127.033 76.54h-3.217l-.017.082s-.29 1.681.392 2.338h1.47c.963-.672 1.372-2.42 1.372-2.42z"
            fill="#fff" />
      <path d="M125.009 80.624h14.132l-.49-.961h-12.702l-.94.96z" fill="#7C4340" />
      <path d="M132.186 73.636l1.838.022a2.476 2.476 0 01-.135-.652h-1.594a2.299 2.299 0 01-.109.63z" fill="#F5D3A6" />
      <path
        d="M133.937 72.541l.024-.142.07-.424.078-.469-.008-.741-.674-.127h-.655l-.678.127v.741l.078.473.055.42.039.142c.025.154.034.31.029.465h1.594a1.302 1.302 0 01.048-.465z"
        fill="#7F8B4B" />
      <path
        d="M134.024 73.658l-1.838-.022s-.136.267-.257.354l-.407.29-.185.09a3.15 3.15 0 00-.96.73l-.315.352a.332.332 0 00.107.52l.07.033a.33.33 0 00.161.03c.123-.008.246-.024.367-.049l2.252-.918.098 1.229.057.684-.032.158c.176.14.397.21.621.196h.036l.099-.51.226-2.407a2.276 2.276 0 00-.1-.76z"
        fill="#514654" />
      <path
        d="M133.142 77.14l-.041.205c-.04.2-.065.402-.074.606l-.046.972-.169.35-.054.838a.097.097 0 00.097.103h.267c.025 0 .05-.01.068-.028l.174-.174a.096.096 0 00.016-.115l-.098-.178-.036-.422.346-1.076a4.66 4.66 0 00.143-.557l.064-.33-.037.001a.916.916 0 01-.62-.196z"
        fill="#F5D3A6" />
      <path
        d="M133.101 68.553c-.369 0-.668.351-.668.784 0 .056.005.113.015.171.067-.349.336-.606.653-.606.318 0 .589.237.657.586a1.12 1.12 0 00.011-.15c0-.434-.299-.785-.668-.785z"
        fill="#704C0F" />
      <path d="M132.79 70.136v.596a.311.311 0 10.623 0v-.596h-.623z" fill="#F0C792" />
      <path
        d="M132.713 68.7a.603.603 0 01.388-.147c.146 0 .281.055.39.148h.001c.006-.028.016-.06.016-.09a.407.407 0 00-.813 0c0 .033.011.058.018.088z"
        fill="#704C0F" />
      <path
        d="M133.101 68.901c-.317 0-.586.258-.653.607.063.395.332.816.653.816.327 0 .599-.435.657-.836-.068-.349-.339-.587-.657-.587z"
        fill="#F5D3A6" />
      <path
        d="M132.84 68.615a.585.585 0 01-.02.01.889.889 0 00-.088.405l.013-.01a.865.865 0 01.095-.405zM132.895 68.591a.924.924 0 00-.015.006 1.01 1.01 0 00-.009.352.18.18 0 00.013-.005.953.953 0 01.011-.353zM133.368 68.618c.078.124.168.301.179.47l.012.01a.008.008 0 00.002-.005c-.009-.164-.093-.337-.17-.463a.308.308 0 00-.023-.012zM133.382 68.968l.015.008-.001-.005c-.028-.142-.13-.312-.194-.41a.499.499 0 00-.017-.002c.062.094.167.266.197.41z"
        fill="#5E400D" />
      <path
        d="M133.491 68.7h.001l.012-.053a.604.604 0 00-.403-.159.603.603 0 00-.402.158l.014.053a.603.603 0 01.388-.146c.146 0 .281.055.39.148z"
        fill="#5E400D" />
      <path
        d="M132.682 74.826c-.209.132-.884.14-1.458.256-.573.116-.535.256-.535.256l.409-.108.437.443 1.484-.605.098 1.229.045.537.117-.294.07-1.042c.025-.371-.07-.74-.271-1.052-.001 0-.187.248-.396.38z"
        fill="#403742" />
      <path
        d="M133.147 74.862a.279.279 0 00-.306-.022l-.162.091-.711.063a2.56 2.56 0 00-.87.236c-.273.129-.349.491-.349.76l.018-.004a2.63 2.63 0 00.201-.05l1.699-.486.266.59v.172a.111.111 0 00.222.01l.101-1.113a.278.278 0 00-.109-.247zM132.064 69.182c-.09.567.167 1.087.276 1.398 0 0 .092.207-.005.62-.052.218-.245.514-.245.514s-.654-2.28-.457-2.743c.196-.463 1.116-1.578 1.116-1.578l.325-.773s.048.363-.051.775c-.1.413-.856 1.134-.959 1.787z"
        fill="#F5D3A6" />
      <path
        d="M134.115 69.182c.09.567-.167 1.087-.276 1.398 0 0-.093.207.005.62.052.218.245.514.245.514s.654-2.28.457-2.743c-.196-.463-1.116-1.578-1.116-1.578l-.328-.772s-.044.362.056.774c.1.413.854 1.134.957 1.787z"
        fill="#F5D3A6" />
      <path d="M143.532 65.475h8.767l-3.2 15.667h-8.767l3.2-15.667z" fill="#fff" />
      <path
        d="M150.598 66.663h-5.851a.413.413 0 00-.386.321l-.258 1.266-.106.519.625-.52h5.652c.177 0 .35-.143.386-.32l.193-.945c.036-.177-.078-.32-.255-.32zM148.692 75.993h-5.85a.415.415 0 00-.387.32l-.258 1.266-.106.52.625-.52h5.652c.177 0 .35-.144.387-.32l.193-.945c.036-.177-.079-.321-.256-.321z"
        fill="#C2DCEA" />
      <path
        d="M143.4 73.26h5.85c.177 0 .292.144.256.321l-.259 1.266-.106.519-.413-.52h-5.652c-.177 0-.291-.143-.255-.32l.193-.945a.413.413 0 01.386-.32z"
        fill="#80B0D3" />
      <path
        d="M150.065 69.274h-5.851a.413.413 0 00-.386.32l-.54 2.642-.106.52.625-.52h5.652a.413.413 0 00.387-.32l.474-2.321c.036-.177-.078-.321-.255-.321z"
        fill="#C2DCEA" />
      <path
        d="M149.217 64.517c-.037.181.079.328.26.328a.422.422 0 00.394-.328c.037-.18-.079-.327-.26-.327a.422.422 0 00-.394.327zM151.168 64.517c-.037.181.079.328.26.328a.422.422 0 00.394-.328c.037-.18-.079-.327-.26-.327a.422.422 0 00-.394.327zM150.192 64.517c-.037.181.08.328.261.328.18 0 .357-.147.394-.328.037-.18-.08-.327-.261-.327a.423.423 0 00-.394.327z"
        fill="#C57C59" />
      <path opacity=".1"
            d="M144.971 67.209h5.01M148.097 67.645H144.9M144.285 69.775h5.01M147.411 70.212h-3.197M148.703 73.835h-5.009M145.359 74.272h3.198M147.987 76.515h-5.01M144.053 76.975h-1.151M144.125 70.685h3.878M147.251 71.121h-3.198M106.439 68.37s-.146 1.134-.017 2.268"
            stroke="#1C3A34" strokeWidth=".318" strokeMiterlimit="10" strokeLinecap="round"
            strokeLinejoin="round" />
      <path
        d="M123.504 66.858a.888.888 0 01.878-.751h4.983a.89.89 0 01.884.981l-.151 1.438a.889.889 0 01-.884.797h-5.058a.889.889 0 01-.878-1.027l.226-1.438z"
        fill="#FB2424" />
      <path
        d="M124.412 68.509l.3-1.432h.294l-.249 1.193h.729l-.05.239h-1.024zm1.202 0l.3-1.432h.294l-.299 1.432h-.295zm1.286 0h-.315l-.257-1.432h.294l.182 1.083.593-1.083h.292l-.789 1.432zm.618 0l.299-1.432h1.061l-.05.24h-.766l-.069.325h.742l-.05.239h-.741l-.09.389h.833l-.051.239h-1.118z"
        fill="#fff" />
      <path
        d="M84.757 84.187c-.323-.458-.284-1.07-.239-1.628.045-.558.067-1.175-.278-1.616.238.086.392.315.508.54.296.577.446 1.215.594 1.846l.171.734c.03.126.19.501.111.619-.156.231-.76-.344-.867-.495z"
        fill="#F68A4C" />
      <path d="M96 84H85l.071.167C85.82 85.923 87.178 87 88.645 87h3.71c1.467 0 2.825-1.077 3.574-2.833L96 84z"
            fill="#F14182" />
      <path
        d="M166.934 71.581c-.961-1.048-2.719-2.262-3.754-3.237a14.335 14.335 0 01-3.319-4.734c-1.851 4.3-.954 10.012 1.462 12.034 2.989 2.504 5.401 1.97 7.223 3.047 0 0 1.115-4.133-1.612-7.11z"
        fill="#99D76D" />
      <path
        d="M161.696 69.375c-1.095-2.001-1.681-3.758-1.802-5.69l-.033-.075c-1.851 4.3-.954 10.012 1.462 12.034 2.989 2.504 5.401 1.97 7.223 3.047l.001-.003c-1.894-4.294-5.695-7.198-6.851-9.313z"
        fill="#72BD6B" />
      <path
        d="M162.453 70.704c1.991 2.173 3.995 4.367 5.396 6.937 1.58 2.9 2.325 6.207 2.325 9.522 0 1.363-.125 2.727-.371 4.064a.124.124 0 00.244.045c.249-1.352.376-2.731.376-4.109 0-3.352-.754-6.7-2.356-9.64-1.42-2.606-3.44-4.814-5.431-6.987a.124.124 0 10-.183.168z"
        fill="#357838" />
      <path
        d="M171.411 65.56c1.552-1.12 2.431-3.658 1.903-5.498-.685-2.383-2.635-3.973-3.661-5.513-.826-1.242-1.543-3.03-1.49-4.182-.307 1.737-.848 3.425-1.198 5.154-.351 1.729-.605 4.66.018 6.31 1.446 3.83 4.428 3.729 4.428 3.729z"
        fill="#99D76D" />
      <path
        d="M168.171 50.724a3.092 3.092 0 01-.008-.357c-.308 1.737-.848 3.425-1.198 5.154-.351 1.729-.605 4.66.018 6.31 1.371 3.633 4.122 3.729 4.403 3.729-1.618-3.632-2.544-7.007-2.813-9.086-.233-1.81-.457-4.228-.402-5.75z"
        fill="#72BD6B" />
      <path
        d="M168.235 54.937c.252 3.047 1.234 6.476 3.029 10.684.882 2.069 1.515 4.19 1.739 7.1.071.92.105 1.838.105 2.757 0 4.766-.894 9.51-2.057 14.166a.124.124 0 00.091.15.123.123 0 00.15-.09c1.165-4.666 2.064-9.429 2.064-14.226 0-.924-.033-1.85-.104-2.776-.226-2.936-.869-5.093-1.759-7.178-1.79-4.194-2.762-7.599-3.01-10.607a.125.125 0 00-.135-.114.124.124 0 00-.113.134z"
        fill="#357838" />
      <path
        d="M172.513 66.572c3.216-1.883 4.989-3.267 5.679-5.783.893-3.256.348-8.437.957-10.264-.548.758-1.229.97-2.616 2.129-1.886 1.575-3.883 3.192-4.955 5.403-1.072 2.21-1.408 4.982.935 8.515z"
        fill="#99D76D" />
      <path
        d="M176.533 52.654c-1.886 1.575-3.883 3.192-4.955 5.403-1.072 2.21-1.408 4.982.935 8.515l.078-.046c.869-3.569 2.403-8.874 6.514-15.944-.54.71-1.221.942-2.572 2.072z"
        fill="#72BD6B" />
      <path
        d="M172.184 84.712a45.301 45.301 0 01-.681-7.85c0-6.955 1.575-13.912 4.624-20.164a.124.124 0 10-.223-.109c-3.067 6.287-4.649 13.281-4.649 20.273 0 2.644.226 5.288.684 7.893a.124.124 0 10.245-.043"
        fill="#357838" />
      <path
        d="M174.665 74.754c2.171-.764 3.409-1.395 4.123-2.84.924-1.87 1.201-5.075 1.785-6.112-.421.395-.859.443-1.837.984-1.328.734-2.729 1.48-3.639 2.695-.909 1.215-1.438 2.856-.432 5.273z"
        fill="#99D76D" />
      <path
        d="M178.736 66.786c-1.328.734-2.729 1.48-3.639 2.695-.909 1.215-1.438 2.856-.432 5.273l.053-.018c.945-2.062 2.498-5.1 5.822-8.905-.411.368-.852.428-1.804.955z"
        fill="#72BD6B" />
      <path
        d="M172.336 85.717a28.517 28.517 0 015.68-16.526.078.078 0 00-.015-.108.077.077 0 00-.108.016 28.662 28.662 0 00-5.71 16.615.077.077 0 10.154.003"
        fill="#357838" />
      <path
        d="M170.992 75.218c-1.913.045-4.147-1.447-4.837-3.232-.895-2.312-.301-4.758-.414-6.605-.092-1.489-.601-3.347-1.339-4.232 1.294 1.198 2.745 2.216 4.068 3.383 1.324 1.166 3.298 3.348 3.798 5.04 1.162 3.925-1.276 5.646-1.276 5.646z"
        fill="#99D76D" />
      <path
        d="M164.611 61.438a3.158 3.158 0 00-.209-.29c1.294 1.2 2.745 2.217 4.068 3.384 1.324 1.166 3.298 3.348 3.798 5.04 1.102 3.722-1.032 5.461-1.256 5.63-.905-3.87-2.205-7.12-3.247-8.938-.908-1.584-2.191-3.647-3.154-4.826z"
        fill="#72BD6B" />
      <path
        d="M166.896 64.968c1.62 2.547 2.902 5.848 4.009 10.27.354 1.413.766 3.09.766 5.538 0 1.346-.125 2.923-.453 4.815a.125.125 0 00.245.043c.331-1.905.457-3.497.457-4.858 0-2.475-.419-4.184-.774-5.598-1.111-4.438-2.4-7.764-4.04-10.344a.124.124 0 10-.21.134z"
        fill="#357838" />
      <path d="M177.436 96.693h-12.58a.635.635 0 01-.63-.557l-1.333-10.802h16.506l-1.332 10.802a.636.636 0 01-.631.557z"
            fill="#C3841D" />
      <path d="M179.399 85.334h-16.506l.446 3.614h15.614l.446-3.614z" fill="#B36A1D" />
      <path d="M180.309 85.94h-18.416a1.16 1.16 0 010-2.32h18.416a1.16 1.16 0 110 2.32z" fill="#C3841D" />
      <path d="M56.034 63.743l-15.667.172c.013-.04.977-2.48 1.145-5.268h13.592c.064 1.725.37 3.44.93 5.096z"
            fill="#F5D3A6" />
      <path
        d="M41.105 54.89l-.202-1.153-.601-3.425-.665-3.788.067-5.997 5.747-1.024 5.587-.006 5.784 1.03v5.997l-.67 3.818-.47 3.394-.328 1.154a17.878 17.878 0 00-.25 3.756H41.512c.075-1.243-.008-2.556-.407-3.755z"
        fill="#F8A486" />
      <path
        d="M40.367 63.916l15.667-.173s1.16 2.159 2.194 2.857l3.47 2.346 1.577.734a26.784 26.784 0 018.184 5.892l2.684 2.85a2.6 2.6 0 01.718 1.783c0 1.042-.637 1.99-1.634 2.428l-.592.261c-.429.188-.9.272-1.37.243a24.207 24.207 0 01-3.137-.399L48.935 75.32l-.836 9.93-.488 5.538.27 1.275a8.081 8.081 0 01-5.289 1.585l-.309-.004-.842-4.124-1.932-19.457c-.083-2.08.208-4.159.858-6.146z"
        fill="#F03B46" />
      <path
        d="M47.88 92.062l.35 1.662a31.25 31.25 0 01.635 4.897l.391 7.864 1.44 2.828.458 6.776c.03.449-.346.83-.821.83h-2.278a.848.848 0 01-.582-.229l-1.481-1.405a.75.75 0 01-.14-.927l.834-1.437.308-3.413-2.95-8.704c-.5-1.476-.907-2.98-1.218-4.502l-.543-2.659.31.005a8.082 8.082 0 005.287-1.586z"
        fill="#F5D3A6" />
      <path d="M50.809 37.29v3.667c0 1.06-1.154 1.918-2.578 1.918-1.423 0-2.577-.859-2.577-1.918V37.29h5.155z"
            fill="#F0C792" />
      <path
        d="M57.166 25.619c.806 4.837-1.498 9.259-2.482 11.909 0 0-.83 1.768.049 5.283.466 1.863 2.201 4.384 2.201 4.384s5.87-19.422 4.106-23.37c-1.766-3.947-10.034-13.442-10.034-13.442l-2.94-6.586s-.394 3.085.503 6.6c.897 3.515 7.67 9.66 8.597 15.222z"
        fill="#F5D3A6" />
      <path
        d="M39.4 25.619c-.806 4.837 1.499 9.259 2.483 11.909 0 0 .83 1.768-.05 5.283-.465 1.863-2.2 4.384-2.2 4.384s-5.871-19.422-4.106-23.37c1.765-3.947 10.033-13.442 10.033-13.442l2.94-6.586s.395 3.085-.502 6.6c-.897 3.515-7.671 9.66-8.598 15.222z"
        fill="#F5D3A6" />
      <path
        d="M53.924 31.081c0-3.5-2.55-6.338-5.694-6.338-3.145 0-5.695 2.837-5.695 6.339-.185 2.771 1.278 4.811-1.299 8.302 1.271 1.638 5.805-.32 6.995-.349 2.495.156 6.176 1.95 6.996 0-1.473-1.745-1.317-6.727-1.303-7.954z"
        fill="#481A1A" />
      <path
        d="M50.455 25.245c.058.027.114.056.17.086.654 1.247.767 2.485.753 3.27a7.028 7.028 0 00-.11-.078c.006-.804-.128-2.045-.813-3.278zM49.99 25.052c.04.015.082.03.123.047a7.484 7.484 0 01.077 2.841l-.106-.04a7.362 7.362 0 00-.095-2.848zM45.952 25.271c-.662 1-1.431 2.432-1.526 3.79l-.095.088a.049.049 0 01-.02-.04c.075-1.328.795-2.727 1.449-3.741a5.49 5.49 0 01.192-.097zM45.835 28.098c-.044.02-.085.045-.128.067l.01-.047c.239-1.14 1.105-2.516 1.654-3.304.048-.008.098-.014.147-.02-.533.755-1.427 2.149-1.683 3.304z"
        fill="#5E400D" />
      <path
        d="M51.805 73.361c1.784 1.066 7.536 1.128 12.428 2.069 4.891.94 4.56 2.068 4.56 2.068l-3.483-.875-3.727 3.586-12.648-4.89-.836 9.93-.384 4.347-.996-2.382-.601-8.424c-.214-2.996.598-5.977 2.315-8.5 0 0 1.587 2.005 3.372 3.071z"
        fill="#CA2832" />
      <path
        d="M47.838 73.648a2.491 2.491 0 012.612-.171l1.377.734 6.07.508c2.573.215 5.082.86 7.413 1.904 2.331 1.044 2.98 3.977 2.974 6.145l-.156-.03a23.955 23.955 0 01-1.713-.398L51.93 78.404l-2.263 4.778v1.385c0 .518-.459.93-1.004.899-.47-.026-.848-.373-.89-.817l-.865-8.998a2.212 2.212 0 01.93-2.003z"
        fill="#F5D3A6" />
      <path
        d="M89.745 112.2c-.647 0-1.103-.67-.894-1.315L96.605 87H98.6l-7.96 24.528a.949.949 0 01-.895.672zM164.855 112.2c.647 0 1.103-.67.894-1.315L157.996 87H156l7.961 24.528c.13.402.49.672.894.672z"
        fill="#663A27" />
      <path
        d="M85.545 115a.94.94 0 01-.894-1.242L92.404 91.2H94.4l-7.96 23.165a.945.945 0 01-.895.635zM168.92 115c.74 0 1.261-.633 1.021-1.242l-8.86-22.558H158.8l9.098 23.165c.149.38.56.635 1.022.635zM166.529 87H86.671c-1.144 0-2.071.94-2.071 2.1 0 1.16.927 2.1 2.07 2.1h79.859c1.144 0 2.071-.94 2.071-2.1 0-1.16-.927-2.1-2.071-2.1z"
        fill="#7E4730" />
    </svg>
  );
}
