import { post, get, put, destroy } from "./api";

export const CourseApi = {
  list: () => get('/courses/'),
  create: data => post('/courses/', data),
  update: (id, data) => put(`/courses/${id}/`, data),
  deleteCourse: id => destroy(`/courses/${id}/`),
  details: id => get(`/courses/${id}/`),
  createOneOnOne: data => post('/courses/1-1/', data),
};
