import cx from "classnames";
import dayjs from "dayjs";
import React from "react";
import Button from "../../Atoms/Button/Button";
import DayPeriodSelector from "../../Atoms/DayPeriodSelector";
import FormText from "../../Atoms/FormText";

export default function DateAndTimeCard({ one, domRef, onJoin }) {
  const [date, setDate] = React.useState(dayjs().format("YYYY-MM-DD"));
  const [slot, setSlot] = React.useState(null);

  //console.log("one slot", one.slots);

  function onClick() {
    if (!slot) {
      return;
    }

    onJoin({ date, slot });
  }

  return (
    <div ref={domRef}>
      <h2 className="font-semibold text-xl">Choose a date & time</h2>

      <DaySelector date={date} setDate={setDate} />

      <div className="bg-white shadow-card-cream-sm rounded-lg mt-3 p-5 max-w-md">
        <FormText className="mb-8">
          Select a timeslot for your 1:1 session
        </FormText>

        <DayPeriodSelector
          slots={[
            5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
          ]}
          value={slot ? [slot] : null}
          onChange={(s) => setSlot(s[0])}
          selectOne={true}
        />

        <Button className="mt-1 mb-4 w-full" onClick={onClick} disabled={!slot}>
          Book
        </Button>
        <p className="text-y-gray text-xs">
          Note: Teacher will confirm availability post booking
        </p>
      </div>
    </div>
  );
}

function DaySelector({ date, setDate }) {
  const dates = [];
  for (let i = 0; i < 7; i++) {
    const day = dayjs().add(i, "day");

    dates.push({
      date: day.format("YYYY-MM-DD"),
      label: day.format("D MMM"),
    });
  }

  return (
    <div className="flex flex-row overflow-x-auto py-3">
      {dates.map((d) => (
        <div
          key={d.date}
          onClick={() => setDate(d.date)}
          className={cx(
            "mr-1 whitespace-nowrap rounded-full px-3 py-1 cursor-pointer",
            d.date === date ? "bg-y-yellow text-white" : "text-y-yellow"
          )}
        >
          {d.label}
        </div>
      ))}
    </div>
  );
}
