import { get, post } from "./api";


export default class BookingApi {
  static teacherBookings() {
    return get('/bookings/');
  }

  static teacherCancelBooking(id) {
    return post(`/bookings/${id}/teacher-cancel/`)
  }

  static teacherConfirmBooking(id, data) {
    return post(`/bookings/${id}/teacher-confirm/`, data);
  }
}
