import dayjs from "dayjs";
import _ from "lodash";
import React, { useEffect } from "react";
import { StorefrontApi } from "../../Api/StorefrontApi";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import Modal from "../../Atoms/Modal/Modal";
import CourseService from "../../Services/CourseService";
import UrlService from "../../Services/UrlService";
import DateHelpers from "../../Utilities/DateHelpers";
import Vent from "../../vent";
import BookingSuccess from "./BookingSuccess";
import NewBooking from "./NewBooking";
import SuccessCallback from "./SuccessCallback";
import RazorPay from "../../Pages/RazorPay";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

function getBookingParameters(
  session,
  course,
  one,
  subscription,
  bookingProps = {},
  bookThreeMonths = false,
  book12Months = false
) {
  if (subscription) {
    return {
      type: "subscription",
      id: subscription.id,
      objectId: subscription.id,
      title: subscription.title,
      expired: subscription.expiry_date,
      classes: subscription.classes_count,
      fee_india: subscription.fee_india,
      joinText: "Join Course",
      api: SubscriptionApi.bookSubscription,
      currency_fee: "",
      headerProps: {
        title: subscription.title,
        expired: subscription.expiry_date,
        classes: subscription.classes_count,
        //image: _.get(session, `course.images[0].file`),
        image:
          "https://do-yoga.s3.amazonaws.com/temp/c22bec73-3304-4cbb-87e4-53dc74780abc.jpg",
      },
    };
  }
  if (session) {
    const datetime = dayjs.unix(session.utc_start_at);

    return {
      type: "session",
      urlType: "sessions",
      trackType: Vent.TYPE_LIVE_CLASS,
      slug: session.course.slug,
      api: StorefrontApi.createSessionBooking,
      objectId: session.id,
      currency_fee: session.course.single_currency_fee,
      joinText: "Join Class",
      headerProps: {
        title: session.course.title,
        //image: _.get(session, `course.images[0].file`),
        image:
          "https://do-yoga.s3.amazonaws.com/temp/c22bec73-3304-4cbb-87e4-53dc74780abc.jpg",
        dateText: DateHelpers.humanizedDateText(datetime.format("YYYY-MM-DD")),
        timeText: (
          <span className="text-green-500">
            {datetime.format("h:mm a")}
            <span className="text-gray-400 pl-1 font-light">
              ({session.course.duration} min)
            </span>
          </span>
        ),
      },
    };
  }

  if (course) {
    const startTime = CourseService.startTimeText(course);

    return {
      type: "course",
      trackType:
        course.type === "course" ? Vent.TYPE_COURSE : Vent.TYPE_MONTHLY_CLASS,
      urlType: course.type === "course" ? "fixed-courses" : "monthly-courses",
      slug: course.slug,
      api: StorefrontApi.createCourseBooking,
      objectId: course.id,
      currency_fee: course.period_currency_fee,
      joinText: course.type === "course" ? "Join Course" : "Join Monthly Class",
      bookThreeMonths: bookThreeMonths,
      book12Months: book12Months,
      headerProps: {
        title: course.title,
        image: _.get(course, "images[0].file"),
        dateText:
          course.type === "course"
            ? CourseService.startEndDateText(course)
            : CourseService.repeatDaysFullText(course),
        timeText: (
          <span className="text-green-500">
            {startTime}
            <span className="text-gray-400 pl-1 font-light">
              ({course.duration} min)
            </span>
          </span>
        ),
      },
    };
  }

  if (one) {
    const date = dayjs(`${bookingProps.date} ${bookingProps.time}`);

    return {
      type: "one",
      urlType: "1-1",
      slug: one.slug,
      trackType: Vent.TYPE_ONE_ON_ONE,
      api: (id) => StorefrontApi.createOneOnOneBooking(id, { ...bookingProps }),
      objectId: one.id,
      currency_fee: one.currency_fee,
      joinText: "Request 1:1 Session",
      headerProps: {
        title: one.teacher.full_name,
        image: one.teacher.profile_picture,
        dateText: date.format("MMM, D"),
        timeText: (
          <span className="text-green-500">
            {date.format("h:mm a")}
            <span className="text-gray-400 pl-1 font-light">
              ({one.duration} min)
            </span>
          </span>
        ),
      },
    };
  }

  return {};
}

export default function BookingModal({
  show,
  onClose,
  session,
  course,
  one,
  bookingProps,
  subscription,
  bookThreeMonths = false,
  book12Months = false,
}) {
  const params = getBookingParameters(
    session,
    course,
    one,
    subscription,
    bookingProps,
    bookThreeMonths,
    book12Months
  );
  const [booking, setBooking] = React.useState(null);
  const isSuccessCallback = UrlService.isBookingSuccessCallback();
  const razorPayCall = UrlService.getParam("pay") === "true" ? true : false;

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  }, []);

  // if (!params.type) {
  //   return null;
  // }

  let view;
  if (booking) {
    view = (
      <>
        <Helmet>
          <title>Booking Success</title>
          <link rel="canonical" href={window.location.href} />
          <meta name="title" content="Booking Success" />
          <meta name="description" content="Booking Success" />
          <meta property="og:title" content="Booking Success"></meta>
          <meta property="twitter:title" content="Booking Success"></meta>
          <meta property="og:description" content="Booking Success"></meta>
          <meta property="twitter:description" content="Booking Success"></meta>
          <meta property="og:url" content={window.location.href}></meta>
          <meta property="twitter:url" content={window.location.href}></meta>
        </Helmet>
        <BookingSuccess session={session} booking={booking} onClose={onClose} />
      </>
    );
  } else {
    if (isSuccessCallback) {
      view = <SuccessCallback setBooking={setBooking} />;
    } else {
      view = <NewBooking params={params} setBooking={setBooking} />;
    }
  }

  if (razorPayCall) {
    view = <RazorPay />;
  }

  if (isSuccessCallback || booking) {
    return <>{view}</>;
  }

  return (
    <>{view}</>
    // <Modal show={show} onClose={onClose} className="sm:w-full sm:max-w-md">
    //   {view}
    // </Modal>
  );
}
