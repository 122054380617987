import cx from "classnames";
import React from "react";
import ImageGallery from "react-image-gallery/src/ImageGallery";
import "./style.css";

let mapData = {
  "https://do-yoga.s3.amazonaws.com/f805d00b-ab35-41ef-9691-7fb0451dd112.jpg":
    "Online yoga classes",
  "https://do-yoga.s3.amazonaws.com/01ddf1b1-716e-498c-8382-edd141cb10f9.jpg":
    "yoga classes for kids",
  "https://do-yoga.s3.amazonaws.com/ddce0a93-bdae-48b7-bc73-6f68660b3d91.jpg":
    "Yogaclasses",
  "https://do-yoga.s3.amazonaws.com/dc4c04b4-5c0e-4338-b09c-e05d61179b1f.jpg":
    "yoga asanas for diabetes",
  "https://do-yoga.s3.amazonaws.com/ed07763e-31d9-42f9-9d6f-7e2a24250016.jpg":
    "Yoga for PCOD",
  "https://do-yoga.s3.amazonaws.com/9bb08899-0a7b-4914-80e0-55d160278c19.jpg":
    "Yoga classes for women",
  "https://do-yoga.s3.amazonaws.com/10ebc3e1-0ef2-473b-8732-e3c667adc10d.jpg":
    "Women yoga classes",
  "https://do-yoga.s3.amazonaws.com/c35d3058-b944-4b60-b013-1f902f20093c.jpg":
    "Yoga asanas for women",
  "https://do-yoga.s3.amazonaws.com/c32d4a3e-5b82-44d1-82a0-d6467d211f20.jpg":
    "Women yoga",

  "https://do-yoga.s3.amazonaws.com/45349971-d2f2-45d7-a745-91b4243ee930.jpg":
    "yoga poses for pcod",
  "https://do-yoga.s3.amazonaws.com/a8853e3b-9ccb-42af-9821-30aaf2fb2912.jpg":
    "yoga for pcos and pcod",
  "https://do-yoga.s3.amazonaws.com/c2ca501d-e407-4547-b9bf-0d2ca5c89f52.jpg":
    "pcod yoga",
  "https://do-yoga.s3.amazonaws.com/a5974d71-8b1c-4a7f-a2ef-0e470de24d4d.jpg":
    "women yoga",
  "https://do-yoga.s3.amazonaws.com/temp/84ae9ff8-7684-4f28-9680-244ea8c71155.jpg":
    "Yoga for Back Pain",
  "https://do-yoga.s3.amazonaws.com/ff616542-004f-4c6e-8162-97ec8d045440.jpg":
    "yoga for lower back pain",
  "https://do-yoga.s3.amazonaws.com/1df9067a-8e3a-4a9c-9266-529265cb4460.jpg":
    "yoga for back pain relief",
};

export default function CoverImage({ images, title = "" }) {
  if (!images || images.length === 0) {
    return null;
  }

  return (
    <div
      className={cx(
        "bg-white max-w-xl w-full shadow-lg shadow-card-cream-lg rounded-md overflow-hidden z-10 cover-image"
      )}
    >
      <ImageGallery
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        showBullets={true}
        renderItem={(item) => {
          let altTag = mapData[item.original] || "Placeholder";

          return (
            <img
              src={item.original}
              className="object-fill w-full p-2 cover-image"
              alt={title}
            />
          );
        }}
        items={images.map((i) => ({
          original: i.file,
        }))}
      />
    </div>
  );
}
