export default class Vent {
  static TYPE_LIVE_CLASS = 'Live Class';
  static TYPE_MONTHLY_CLASS = 'Monthly Class';
  static TYPE_COURSE = 'Course';
  static TYPE_ONE_ON_ONE = '1-1';

  /**
   * User has been identified (on page reload, login, etc)
   */
  static onUserIdentified(user) {
    window.mixpanel.people.set({
      '$name': user.full_name,
      '$email': user.email,
    });

    window.mixpanel.identify(`${user.id}`);
  }

  /**
   * New user has signed up
   */
  static onUserSignup(user) {
    window.mixpanel.track('Signup');

    // Event snippet for Sign-up conversion page
    window.gtag('event', 'conversion', { 'send_to': 'AW-363567908/9UfYCKXwjMECEKS2rq0B' });
  }

  /**
   * User has requested otp send
   */
  static onUserSendOtp(user) {
    // TODO: track user send otp event
  }

  /**
   * On User logout
   */
  static onUserLogout() {
    window.mixpanel.reset();
  }

  /**
   * On class details
   */
  static onClassDetails(type, name) {
    window.mixpanel.track('Class Details', {
      type,
      name,
    });
  }

  /**
   * On booking completed
   */
  static onBooking(type, amount) {
    window.mixpanel.track('Booking', {
      type,
      amount,
    });

    // Event snippet for Join class conversion page
    window.gtag('event', 'conversion', { 'send_to': 'AW-363567908/U2sqCIvfqckCEKS2rq0B' });
  }

  /**
   * Get track type from a booking
   *
   * @param booking
   * @return {string|string}
   */
  static getTrackTypeFromBooking(booking) {
    if (booking.session) {
      return Vent.TYPE_LIVE_CLASS;
    }

    if (booking.one_on_one) {
      return Vent.TYPE_ONE_ON_ONE;
    }

    if (!booking.course) {
      return "Unknown";
    }

    return booking.course.type === 'course' ? Vent.TYPE_COURSE : Vent.TYPE_MONTHLY_CLASS;
  }
}
