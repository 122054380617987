export default class TextService {
  /**
   * Get text for currency_fee object
   */
  static formatCurrencyFee(feeObject, fallbackFeeObj) {
    feeObject = feeObject || fallbackFeeObj;
    if (!feeObject) {
      return null;
    }

    let currency = feeObject.currency;
    if (currency === 'inr') {
      currency = '₹';
    } else if (currency === 'usd') {
      currency = '$';
    }

    return `${currency} ${feeObject.fee}`;
  }
}
