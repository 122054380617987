import React from 'react';

function Tag({
  value,
  onTagClicked
}) {

  function hello(){
    onTagClicked(value);
  }

  return (
    <div className="border-transparent border inline-block" onClick={hello}>
      <div className="border rounded-full border-gray-300 p-2 w-max inline-block bg-gray-100">
        {value} +
      </div>
    </div>
  );
}

export default Tag;