import "./style.css";

const HomeFoot = () => {
  return (
    <div className="pt-4">
      <h2 className="head-text">
        Unroll Your Mat, Unwind Your Mind: Embrace the World of Online Yoga
        Classes
      </h2>
      <div className="body-text">
        Imagine this: sun streaming through your window, your favorite playlist
        gently humming, and you, flowing through a yoga sequence, guided by an
        expert instructor, all from the comfort of your own living room. Sounds
        pretty dreamy, right? Well, welcome to the glorious world of online yoga
        classes – your passport to a journey of holistic wellness, accessible
        anytime, anywhere. <br /> <br /> Forget the days of battling gym
        timetables and crowded studios. Online yoga classes are a revolution in
        convenience, empowering you to cultivate your practice on your own
        terms. Whether you're a seasoned yogi seeking the familiar embrace of
        asanas or a curious newbie stepping onto the mat for the first time,
        these virtual studios have something for everyone.
      </div>

      <h2 className="head-text">Why Choose Online Yoga Classes?</h2>

      <div className="body-text">
        <span className="head-text">1. Unleash Your Inner Guru: </span> Forget
        rigid schedules and embrace flexibility! Online yoga classes bend to
        your life, not the other way around. Squeeze in a pre-work energizer,
        unwind after a busy day, or practice under the moon's glow – your
        perfect time is always the right time.
      </div>
      <div className="body-text">
        <span className="head-text">
          2. A Universe of Yoga at Your Fingertips:
        </span>{" "}
        Vinyasa flow, Yin , Hatha, Ashtanga, Kundalini – online yoga classes
        offer an encyclopedia of styles to explore. Whether you seek strength,
        flexibility, deep relaxation, or a playful challenge, there's a sequence
        waiting to ignite your inner spark.
      </div>
      <div className="body-text">
        <span className="head-text">3. Your Home, Your Sanctuary:</span> Forget
        feeling self-conscious under fluorescent lights. Online yoga classes let
        you practice in your sacred space, surrounded by familiar comfort. This
        inward focus deepens the connection between your body and mind, allowing
        you to truly go within.
      </div>
      <div className="body-text">
        <span className="head-text">
          4. Expert Guidance, Just a Click Away:
        </span>{" "}
        Renowned instructors become your virtual mentors, guiding you through
        intricate postures, mindful breaths, and profound wisdom. Learn from the
        best, receive real-time feedback, and let their expertise illuminate
        your practice, wherever you are in the world.{" "}
      </div>
      <div className="body-text">
        <span className="head-text">5. Find Your Tribe, Even Virtually:</span>{" "}
        Online yoga classes aren't solitary journeys. Live sessions foster a
        sense of community, connecting you with like-minded yogis through shared
        poses and virtual smiles. Chat, share experiences, and build a
        supportive network, all within the cozy embrace of your virtual studio.
      </div>

      <h2 className="head-text">
        Your Guide to Mastering Online Yoga Classes:
      </h2>

      <h2 className="head-text">1. Setting the Stage for Inner Bliss:</h2>

      <div className="body-text">
        <span className="head-text">Create your zen zone:</span> Transform a
        corner of your home into a yoga sanctuary. Spread out your mat, light
        some candles, and let the soothing smells of essential oils set the mood
        for inward exploration.
      </div>

      <div className="body-text">
        <span className="head-text">Tech check for tranquility:</span> Ensure a
        seamless session by testing your internet connection and familiarizing
        yourself with the platform beforehand. A glitch-free connection is key
        to staying present and immersed in the flow.
      </div>

      <div className="body-text">
        <span className="head-text">Dress for the flow:</span> Ditch the
        restrictive attire and embrace comfort! Breathable clothes that move
        with you are your allies in achieving optimal flexibility and focus.
      </div>

      <h2 className="head-text">2. Embracing the Practice with Grace:</h2>

      <div className="body-text">
        <span className="head-text">Listen to your body, always:</span> Yoga is
        a conversation with yourself, not a competition. If you feel discomfort,
        simply modify the pose or take a well-deserved rest. Remember, it's your
        practice, your pace.
      </div>

      <div className="body-text">
        <span className="head-text">Engage mindfully:</span> Be an active
        participant in your online yoga class. Ask questions, seek
        clarification, and connect with your virtual community. Sharing and
        interaction amplify the benefits of your practice and create a richer
        experience.
      </div>

      <div className="body-text">
        <span className="head-text">Consistency is key:</span> Treat your online
        yoga classes like a cherished appointment with yourself. Regular
        practice unlocks the full potential of yoga, nurturing your physical and
        mental well-being in profound ways.
      </div>

      <h2 className="head-text">
        3. Can Everyone Join the Online Yoga Revolution?
      </h2>

      <div className="body-text">
        The beauty of online yoga classes lies in their inclusivity. From
        complete beginners to seasoned yogis, people of all ages, shapes, and
        fitness levels can find their perfect practice. Even pregnant women,
        with guidance from a prenatal-focused instructor, can safely explore the
        transformative power of yoga.
      </div>

      <div className="body-text">
        However, if you are recovering from surgery, have significant injuries,
        or are experiencing serious illness, it's crucial to consult with your
        doctor before embarking on any physical activity, including online yoga
        classes. Remember, safety is paramount, and listening to your body is
        always the wisest guide.
      </div>

      <h2 className="head-text">What to Expect in Your Online Yoga Classes?</h2>

      <div className="body-text">
        Live sessions are the heartbeat of our online yoga classes. Immerse
        yourself in real-time flows, feel the energy of fellow yogis, and
        interact with your instructor to personalize your practice. Ask
        questions, receive feedback, and experience the supportive presence of a
        virtual community.
      </div>

      <div className="body-text">
        Forget the limitations of recorded sessions. Our live online yoga
        classes offer dynamic interaction, a sense of shared presence, and the
        opportunity to adjust sequences based on your unique needs and energy
        levels.
      </div>

      <div className="body-text">Here's your ultimate call to action:</div>
      <div className="body-text">
        <span className="head-text">1. Explore our website:</span> Dive into the
        diverse world of online yoga classes we offer. Browse through a
        multitude of styles, discover renowned instructors, and find the perfect
        session to ignite your inner spark.
      </div>
      <div className="body-text">
        <span className="head-text">2. Take a $5 or ₹50 trial:</span> We know
        you deserve to experience the magic firsthand. Sign up for a trial
        session and experience the joy of live online yoga classes, the
        supportive community, and the profound sense of connection you'll
        cultivate within yourself.
      </div>
      <div className="body-text">
        <span className="head-text">3. Join our vibrant community:</span> We're
        more than just a platform; we're a thriving family of yogis connected by
        a shared passion for well-being. Connect with fellow students, share
        your experiences, and find inspiration in each other's journeys.
      </div>
      <div className="body-text">
        <span className="head-text">4. Invest in your wellness:</span> Beyond
        the physical benefits, online yoga classes offer a pathway to mental
        clarity, emotional balance, and a deeper sense of self-awareness. Invest
        in your holistic well-being with a flexible and affordable membership
        plan tailored to your needs.
      </div>
      <div className="body-text">
        <span className="head-text">5. Unroll your potential, today:</span>{" "}
        Remember, you are your own guru. You have the power to unlock your inner
        strength, find peace in the present moment, and create a life filled
        with well-being and joy. Start your online yoga journey today, and
        witness the transformative power of one breath, one pose, one step at a
        time.
      </div>
      <h2 className="head-text">Breathe Away Your Worries: </h2>
      <div className="body-text">
        While Vinyasa flows and Hatha sequences ignite your inner fire, online
        yoga classes offer a sanctuary for deep relaxation and inner peace.
        Imagine candlelight flickering, diya lamps painting warm shadows, and
        the gentle scent of agarbatti mingling with your mindful breaths. In
        this space, online yoga helps you melt away tension, soothe your nervous
        system, and cultivate profound stillness.
      </div>
      <h2 className="head-text">
        How can online yoga classes be your ultimate ally?
      </h2>
      <div className="body-text">
        <span className="head-text">1. A Haven for Stress Relief:</span> Unplug
        from the world's demands and dive into a cocoon of calm. yoga poses,
        supported by props like bolsters and blankets, cradle your body in
        gentle release, allowing stress to melt away like snow under the midday
        sun.
      </div>
      <div className="body-text">
        <span className="head-text">2. Rejuvenate Your Body and Mind:</span> Let
        online yoga guide you into poses that support deep tissue release,
        improve joint mobility, and quiet internal chatter. With each supported
        breath, you'll feel physical tension ease and mental clarity emerge.
      </div>

      <div className="body-text">
        <span className="head-text">3. The Perfect Antidote to Busy Days:</span>{" "}
        Don't wait for vacation days to prioritize self-care. Online yoga
        classes fit seamlessly into your schedule, offering pockets of
        tranquility even on hectic days. A quick 30-minute session can leave you
        feeling rejuvenated and ready to face anything.
      </div>
      <div className="body-text">
        <span className="head-text">4. Embrace Deep Sleep:</span> Struggling
        with restless nights? Online yoga can be your gateway to restful
        slumber. By releasing physical and mental tension before bed, you'll
        create the perfect conditions for deep, sleep.
      </div>
      <div className="body-text">
        <span className="head-text">5. Accessible Wellness for All:</span> yoga
        poses are gentle and adaptable, making online yoga accessible to
        everyone, regardless of age, fitness level, or experience. Whether
        you're a seasoned yogi seeking deeper relaxation or a curious beginner,
        there's a pose waiting to embrace you.
      </div>
      <h2 className="head-text">
        Embrace Your Inner Strength: Online Yoga for Fitness and Strength
        Building
      </h2>
      <div className="body-text">
        While yoga is often associated with relaxation and flexibility, online
        yoga classes also offer a powerful path to building strength and
        enhancing fitness. Imagine defying gravity in inversions, sculpting
        toned muscles in warrior poses, and discovering a newfound sense of
        physical empowerment—all from the comfort of your home.
      </div>
      <h2 className="head-text">
        Why Choose Online Yoga for Fitness and Strength Building?
      </h2>
      <div className="body-text">
        <span className="head-text">1. Build Strength without Bulk:</span> Yoga
        utilizes your bodyweight and gravity to challenge muscles in unique
        ways, leading to lean, defined strength without excessive bulk. Online
        yoga classes guide you through poses that target every muscle group,
        sculpting a strong and balanced physique.
      </div>
      <div className="body-text">
        <span className="head-text">2. Improve Endurance and Flexibility:</span>{" "}
        Dynamic yoga flows boost cardiovascular health, increase stamina, and
        enhance flexibility. You'll move with greater ease, power through
        challenging workouts, and reduce your risk of injuries.
      </div>
      <div className="body-text">
        <span className="head-text">
          3. Challenge Yourself, Embrace Growth:
        </span>{" "}
        Online yoga offers a variety of classes designed to challenge your
        physical limits and push you beyond your comfort zone. Embrace the
        thrill of mastering new poses, building strength, and discovering your
        body's incredible potential.
      </div>
      <div className="body-text">
        <span className="head-text">4. Complement Your Fitness Routine:</span>{" "}
        Yoga seamlessly integrates with other fitness activities, enhancing
        performance and recovery. Online yoga classes can improve your running
        form, increase flexibility for weightlifting, and promote faster muscle
        recovery.
      </div>
      <div className="body-text">
        <span className="head-text">5. Find Joy in Movement:</span> Online yoga
        encourages you to revel in the joy of movement, celebrating every
        progress and embracing the journey. You'll learn to appreciate your
        body's strength and resilience, cultivating a positive relationship with
        fitness.
      </div>
      <h2 className="head-text">
        Enhance Your Practice: Find the Best Online Yoga Classes for Your Needs
      </h2>

      <h2 className="head-text">1. Know Yourself, Find Your Flow:</h2>
      <div className="body-text">
        <span className="head-text">Fitness Level:</span> Are you a seasoned
        practitioner seeking advanced vinyasa sequences or a curious beginner
        just starting your yoga journey? Choose classes designed for your skill
        level to ensure a safe and effective practice.
      </div>
      <div className="body-text">
        <span className="head-text">Focus and Intention:</span> Do you crave a
        high-energy workout, gentle stretching for stress relief, or a deeply
        spiritual practice? Identify your goals and seek classes that cater to
        your specific needs.
      </div>
      <h2 className="head-text">2. Explore the Diverse Yogaverse:</h2>
      <div className="body-text">
        <span className="head-text">Style Safari:</span> From the dynamic flow
        of Vinyasa to the grounding poses of Hatha, the world of yoga offers a
        smorgasbord of styles. Experiment with different types to discover what
        ignites your inner spark.
      </div>
      <div className="body-text">
        <span className="head-text">Meet Your Teacher:</span> Every instructor
        brings their unique energy and teaching style to the mat. Check out
        instructor profiles and sample classes to find someone who resonates
        with you and inspires your practice.
      </div>
      <h2 className="head-text">3. Unlocking the Best Deals and Resources:</h2>
      <div className="body-text">
        <span className="head-text"> Trial Times:</span> Our platform, “Do Yoga”
        offers discounted trials for $5 or ₹50 per session. Take advantage of
        these trials to explore different classes and instructors before
        committing to a membership.
      </div>
      <div className="body-text">
        <span className="head-text">Community Connection:</span> Look for
        platforms with active online communities where you can connect with
        fellow yogis, share experiences, and stay motivated.
      </div>
    </div>
  );
};

export default HomeFoot;
