const stripe = window.Stripe(
  process.env.NODE_ENV === 'production'
    ? 'pk_live_51IeQDSSE9gKqg65ZZaimjTy8JnpRdofaVfXB6Wgpzt87N8qcS8pzaf8cICIMQwIhEAROT7OqHWmjh9KHWO9bwfVE001vMIO878'
    : 'pk_test_51IeQDSSE9gKqg65Z5n9Q5rrWGavY2DnGngywhYEkxnzpGKxgXu21rJojcbexcI0mm17zBuK6sh50ZOnSfOFqHSbb00ldlMnlPg'
);

export default class StripeService {
  static checkout(stripeCheckoutId) {
    stripe.redirectToCheckout({ sessionId: stripeCheckoutId })
      .then(result => {
        if (result.error) {
          alert(result.error.message);
        }
      });
  }
}
