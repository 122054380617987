import React, { useEffect } from "react";
import { CourseApi } from "../../Api/CourseApi";
import useApiPage from "../../Hooks/useApiPage";
import CourseCard from "./CourseCard";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function MyCourses({ deleted = false, ...props }) {
  const { data, view } = useApiPage(CourseApi.list);

  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);

  if (view) {
    return <div className="px-7 sm:px-0">{view}</div>;
  }

  if (data.data.length === 0) {
    return (
      <div className="px-7 sm:px-0 mt-2">You have not created any classes</div>
    );
  }

  return (
    <div className="w-full max-w-3xl">
      <Helmet>
        <title>My Courses</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="My Courses"></meta>
        <meta name="description" content="My Courses" />
        <meta property="og:title" content="My Courses"></meta>
        <meta property="twitter:title" content="My Courses"></meta>
        <meta property="og:description" content="My Courses"></meta>
        <meta property="twitter:description" content="My Courses"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="mt-0">
        {data.data.map((o) => (
          <>
            {deleted && o.deleted_at ? (
              <CourseCard key={o.id} course={o} deleted={deleted} />
            ) : !deleted && !o.deleted_at ? (
              <CourseCard key={o.id} course={o} deleted={deleted} />
            ) : (
              ""
            )}
          </>
        ))}
      </div>
    </div>
  );
}
