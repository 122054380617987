const initialState = {
  showLoading: false,
  liveData: [],
  monthlyData: [],
  personalData: [],
  beginnerData: [],
  kidsData: [],
  weightLossData: [],
  pregnancyData: [],
  pcosData: [],
  raData: [],
  advancedData: [],
  termData: [],
  subscriptionData: [],
  bannersData: [],
  blogData: [],
  blogAsanaData: [],
  reviewData: [],
  indexData: {
    monthly: 0,
    beginner: 0,
    kids: 0,
    advanced: 0,
    weightLoss: 0,
    pregnancy: 0,
    term: 0,
    live: 0,
    onoOnOne: 0,
    subscription: 0,
    blog: 0,
    blogAsana: 0,
    review: 0,
  },
  viewAll: [],
  selectedTime: "",
  //streamDatas: {},
  streamDatas: {
    3: {
      teacher: 3,
      courseCount: 133,
      courseStream: 2660,
      sessionCount: 84,
      total: 2744,
      reviewsCount: 10,
      avgReviewsCount: "4.4",
    },
    15: {
      teacher: 15,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 5,
      total: 45,
    },
    24: {
      teacher: 24,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 6,
      total: 46,
    },
    30: {
      teacher: 30,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 2,
      total: 2,
    },
    31: {
      teacher: 31,
      courseCount: 11,
      courseStream: 220,
      sessionCount: 14,
      total: 234,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    35: {
      teacher: 35,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 4,
      total: 4,
    },
    36: {
      teacher: 36,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 1,
      total: 21,
    },
    37: {
      teacher: 37,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
      reviewsCount: 1,
      avgReviewsCount: "4.0",
    },
    38: {
      teacher: 38,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
      reviewsCount: 4,
      avgReviewsCount: "5.0",
    },
    41: {
      teacher: 41,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 3,
      total: 43,
    },
    42: {
      teacher: 42,
      courseCount: 3,
      courseStream: 60,
      sessionCount: 11,
      total: 71,
      reviewsCount: 2,
      avgReviewsCount: "5.0",
    },
    43: {
      teacher: 43,
      courseCount: 6,
      courseStream: 120,
      sessionCount: 2,
      total: 122,
    },
    44: {
      teacher: 44,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
      reviewsCount: 1,
      avgReviewsCount: "4.0",
    },
    57: {
      teacher: 57,
      courseCount: 119,
      courseStream: 2380,
      sessionCount: 3,
      total: 2383,
      reviewsCount: 2,
      avgReviewsCount: "5.0",
    },
    59: {
      teacher: 59,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    60: {
      teacher: 60,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 5,
      total: 25,
    },
    61: {
      teacher: 61,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    62: {
      teacher: 62,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 6,
      total: 26,
    },
    90: {
      teacher: 90,
      courseCount: 4,
      courseStream: 80,
      sessionCount: 2,
      total: 82,
      reviewsCount: 2,
      avgReviewsCount: "5.0",
    },
    162: {
      teacher: 162,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 0,
      total: 0,
    },
    219: {
      teacher: 219,
      reviewsCount: 1,
      avgReviewsCount: "0.0",
    },
    247: {
      teacher: 247,
      courseCount: 5,
      courseStream: 100,
      sessionCount: 0,
      total: 100,
    },
    249: {
      teacher: 249,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 0,
      total: 20,
    },
    298: {
      teacher: 298,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 9,
      total: 9,
    },
    301: {
      teacher: 301,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 2,
      total: 42,
    },
    338: {
      teacher: 338,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 2,
      total: 22,
    },
    339: {
      teacher: 339,
      courseCount: 92,
      courseStream: 1840,
      sessionCount: 67,
      total: 1907,
      reviewsCount: 7,
      avgReviewsCount: "4.9",
    },
    348: {
      teacher: 348,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 0,
      total: 0,
    },
    438: {
      teacher: 438,
      courseCount: 13,
      courseStream: 260,
      sessionCount: 1,
      total: 261,
    },
    439: {
      teacher: 439,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 3,
      total: 23,
    },
    476: {
      teacher: 476,
      courseCount: 10,
      courseStream: 200,
      sessionCount: 14,
      total: 214,
    },
    596: {
      teacher: 596,
      courseCount: 15,
      courseStream: 300,
      sessionCount: 4,
      total: 304,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    599: {
      teacher: 599,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 7,
      total: 7,
    },
    634: {
      teacher: 634,
      courseCount: 12,
      courseStream: 240,
      sessionCount: 0,
      total: 240,
    },
    638: {
      teacher: 638,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 0,
      total: 20,
    },
    641: {
      teacher: 641,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 3,
      total: 3,
    },
    650: {
      teacher: 650,
      courseCount: 63,
      courseStream: 1260,
      sessionCount: 187,
      total: 1447,
      reviewsCount: 23,
      avgReviewsCount: "5.0",
    },
    674: {
      teacher: 674,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    676: {
      teacher: 676,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    706: {
      teacher: 706,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 4,
      total: 4,
    },
    715: {
      teacher: 715,
      courseCount: 9,
      courseStream: 180,
      sessionCount: 4,
      total: 184,
    },
    721: {
      teacher: 721,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 2,
      total: 2,
    },
    743: {
      teacher: 743,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 0,
      total: 20,
    },
    779: {
      teacher: 779,
      courseCount: 4,
      courseStream: 80,
      sessionCount: 1,
      total: 81,
    },
    822: {
      teacher: 822,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 3,
      total: 3,
    },
    826: {
      teacher: 826,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 13,
      total: 33,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    834: {
      teacher: 834,
      courseCount: 14,
      courseStream: 280,
      sessionCount: 16,
      total: 296,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    839: {
      teacher: 839,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 5,
      total: 5,
    },
    852: {
      teacher: 852,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 0,
      total: 20,
      reviewsCount: 1,
      avgReviewsCount: "0.0",
    },
    867: {
      teacher: 867,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 2,
      total: 22,
    },
    878: {
      teacher: 878,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 0,
      total: 0,
    },
    892: {
      teacher: 892,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 0,
      total: 40,
    },
    897: {
      teacher: 897,
      courseCount: 14,
      courseStream: 280,
      sessionCount: 26,
      total: 306,
      reviewsCount: 2,
      avgReviewsCount: "5.0",
    },
    920: {
      teacher: 920,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 13,
      total: 13,
    },
    931: {
      teacher: 931,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 2,
      total: 22,
    },
    932: {
      teacher: 932,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    975: {
      teacher: 975,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    989: {
      teacher: 989,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 2,
      total: 42,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    996: {
      teacher: 996,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 4,
      total: 44,
    },
    998: {
      teacher: 998,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 1,
      total: 21,
    },
    1004: {
      teacher: 1004,
      courseCount: 5,
      courseStream: 100,
      sessionCount: 9,
      total: 109,
    },
    1019: {
      teacher: 1019,
      courseCount: 30,
      courseStream: 600,
      sessionCount: 27,
      total: 627,
      reviewsCount: 3,
      avgReviewsCount: "5.0",
    },
    1029: {
      teacher: 1029,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 2,
      total: 2,
    },
    1105: {
      teacher: 1105,
      courseCount: 10,
      courseStream: 200,
      sessionCount: 11,
      total: 211,
    },
    1116: {
      teacher: 1116,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1118: {
      teacher: 1118,
      courseCount: 210,
      courseStream: 4200,
      sessionCount: 172,
      total: 4372,
      reviewsCount: 13,
      avgReviewsCount: "5.0",
    },
    1119: {
      teacher: 1119,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 4,
      total: 44,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    1124: {
      teacher: 1124,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 7,
      total: 47,
    },
    1132: {
      teacher: 1132,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 9,
      total: 49,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    1149: {
      teacher: 1149,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 0,
      total: 20,
    },
    1220: {
      teacher: 1220,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1308: {
      teacher: 1308,
      courseCount: 11,
      courseStream: 220,
      sessionCount: 0,
      total: 220,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    1337: {
      teacher: 1337,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    1360: {
      teacher: 1360,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 3,
      total: 23,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    1373: {
      teacher: 1373,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1379: {
      teacher: 1379,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1647: {
      teacher: 1647,
      courseCount: 26,
      courseStream: 520,
      sessionCount: 38,
      total: 558,
      reviewsCount: 4,
      avgReviewsCount: "4.5",
    },
    1715: {
      teacher: 1715,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1726: {
      teacher: 1726,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 3,
      total: 23,
    },
    1734: {
      teacher: 1734,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1773: {
      teacher: 1773,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1847: {
      teacher: 1847,
      courseCount: 12,
      courseStream: 240,
      sessionCount: 13,
      total: 253,
    },
    1850: {
      teacher: 1850,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 3,
      total: 43,
    },
    1857: {
      teacher: 1857,
      courseCount: 14,
      courseStream: 280,
      sessionCount: 18,
      total: 298,
      reviewsCount: 1,
      avgReviewsCount: "4.0",
    },
    1861: {
      teacher: 1861,
      courseCount: 7,
      courseStream: 140,
      sessionCount: 22,
      total: 162,
      reviewsCount: 2,
      avgReviewsCount: "5.0",
    },
    1891: {
      teacher: 1891,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    1948: {
      teacher: 1948,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 2,
      total: 2,
    },
    2026: {
      teacher: 2026,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 1,
      total: 21,
    },
    2043: {
      teacher: 2043,
      courseCount: 1,
      courseStream: 20,
      sessionCount: 1,
      total: 21,
    },
    2077: {
      teacher: 2077,
      courseCount: 75,
      courseStream: 1500,
      sessionCount: 103,
      total: 1603,
      reviewsCount: 4,
      avgReviewsCount: "4.8",
    },
    2078: {
      teacher: 2078,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 0,
      total: 40,
    },
    2127: {
      teacher: 2127,
      courseCount: 12,
      courseStream: 240,
      sessionCount: 12,
      total: 252,
      reviewsCount: 2,
      avgReviewsCount: "5.0",
    },
    2160: {
      teacher: 2160,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 2,
      total: 2,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    2169: {
      teacher: 2169,
      courseCount: 61,
      courseStream: 1220,
      sessionCount: 51,
      total: 1271,
      reviewsCount: 6,
      avgReviewsCount: "4.8",
    },
    2252: {
      teacher: 2252,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 2,
      total: 2,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    2283: {
      teacher: 2283,
      courseCount: 4,
      courseStream: 80,
      sessionCount: 6,
      total: 86,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    2311: {
      teacher: 2311,
      courseCount: 36,
      courseStream: 720,
      sessionCount: 2,
      total: 722,
      reviewsCount: 2,
      avgReviewsCount: "4.5",
    },
    2443: {
      teacher: 2443,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    2493: {
      teacher: 2493,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 4,
      total: 4,
      reviewsCount: 1,
      avgReviewsCount: "5.0",
    },
    2572: {
      teacher: 2572,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    2765: {
      teacher: 2765,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    3132: {
      teacher: 3132,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    3208: {
      teacher: 3208,
      courseCount: 2,
      courseStream: 40,
      sessionCount: 6,
      total: 46,
    },
    3274: {
      teacher: 3274,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
    3433: {
      teacher: 3433,
      courseCount: 0,
      courseStream: 0,
      sessionCount: 1,
      total: 1,
    },
  },
};

export default function courseReducer(state = initialState, action) {
  switch (action.type) {
    case "course/index":
      return {
        ...state,
        indexData: {
          ...state.indexData,
          [action.payload.key]: action.payload.value,
        },
      };

    case "live/set":
      return {
        ...state,
        liveData: action.payload.data,
      };

    case "monthly/set":
      return {
        ...state,
        monthlyData: action.payload.data,
      };

    case "personal/set":
      return {
        ...state,
        personalData: action.payload.data,
      };

    case "beginner/set":
      return {
        ...state,
        beginnerData: action.payload.data,
      };

    case "kids/set":
      return {
        ...state,
        kidsData: action.payload.data,
      };

    case "weight/set":
      return {
        ...state,
        weightLossData: action.payload.data,
      };

    case "pregnancy/set":
      return {
        ...state,
        pregnancyData: action.payload.data,
      };

    case "pcos/set":
      return {
        ...state,
        pcosData: action.payload.data,
      };

    case "ra/set":
      return {
        ...state,
        raData: action.payload.data,
      };

    case "advanced/set":
      return {
        ...state,
        advancedData: action.payload.data,
      };

    case "term/set":
      return {
        ...state,
        termData: action.payload.data,
      };

    case "subscription/set":
      return {
        ...state,
        subscriptionData: action.payload.data,
      };

    case "featured/set":
      return {
        ...state,
        bannersData: action.payload.data,
      };

    case "viewall/set":
      return {
        ...state,
        viewAll: action.payload.data,
      };

    case "blog/set":
      return {
        ...state,
        blogData: action.payload.data,
      };

    case "blogasana/set":
      return {
        ...state,
        blogAsanaData: action.payload.data,
      };

    case "review/set":
      return {
        ...state,
        reviewData: action.payload.data,
      };

    case "selectedTime/set":
      return {
        ...state,
        selectedTime: action.payload.data,
      };

    case "streamDatas/set":
      return {
        ...state,
        streamDatas: action.payload.data,
      };

    case "loading/set":
      return {
        ...state,
        showLoading: action.payload.data,
      };

    default:
      return state;
  }
}
