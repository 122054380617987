import React, { useEffect } from "react";
import UnderlineButton from "../../Atoms/UnderlineButton/UnderlineButton";

export default function StageCard({ stages, loading, flow = false }) {
  //console.log(stages);
  const [selected, setSelected] = React.useState("");
  //console.log(selected);

  const selectedStage = stages.find((s) => s.title === selected);
  //console.log(selectedStage);
  const selectedProps = selectedStage?.props || {};
  const SelectedComponent = selectedStage?.component;

  useEffect(() => {
    setSelected(stages[0].title);
  }, [stages]);

  return (
    <div
      className={`px-7 sm:px-10 ${
        flow ? "py-0 pb-10" : "py-10"
      } bg-white shadow-card-cream sm:rounded-md`}
    >
      {loading ? (
        <div>Loading..</div>
      ) : (
        <>
          {!flow && (
            <div className="flex flex-row flex-nowrap overflow-x-auto">
              {stages.map((stage) => (
                <div key={stage.title} className="mr-7 whitespace-nowrap pb-3">
                  <UnderlineButton
                    yellow
                    selected={selected === stage.title}
                    onClick={() => setSelected(stage.title)}
                  >
                    {stage.title}
                  </UnderlineButton>
                </div>
              ))}
            </div>
          )}

          {SelectedComponent && <SelectedComponent {...selectedProps} />}
        </>
      )}
    </div>
  );
}
