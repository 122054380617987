import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import axios from 'axios';
import UrlService from "../Services/UrlService";
import store from '../store';

dayjs.extend(utc)
dayjs.extend(timezone)

const userTimezone = dayjs.tz.guess();

const instance = axios.create({
  baseURL: '/api/',
})

export function get(url, data) {
  return makeRequest('get', url, null, data);
}

export function post(url, data, params = null, headers = {}) {
  return makeRequest('post', url, data, params, headers)
}

export function put(url, data, params = null) {
  return makeRequest('put', url, data, params);
}

export function patch(url, data, params = null) {
  return makeRequest('patch', url, data, params);
}

export function destroy(url, data, params = null, headers = {}) {
  return makeRequest('delete', url, data, params, headers)
}

function makeRequest(method, url, data, params = null, headers = {}) {
  const user = store.getState().user;

  const config = {
    method,
    url,
    params,
    data,
    headers,
  };

  if (user && user.token) {
    config.headers['Authorization'] = `Token ${user.token.key}`;
  }

  const subdomain = UrlService.getTeacherDomain();
  if (subdomain) {
    config.headers['X-Yoga-Subdomain'] = subdomain;
  }

  if (timezone) {
    config.headers['X-Yoga-Timezone'] = userTimezone;
  }

  if (window.COUNTRY_CODE) {
    config.headers['X-Yoga-Country'] = window.COUNTRY_CODE;
  }

  return instance.request(config);
}
