import cx from "classnames";
import _ from "lodash";
import React from "react";
import DayPeriodLogo from "../../Graphics/DayPeriodLogo";
import useIsMobile from "../../Hooks/useMobile";


export default function DayPeriodSelector({ slots, onChange, value, selectOne }) {
  const formattedSlots = slots
    .sort((a, b) => a - b)
    .map(s => ({
      start_hour: s,
      label: s === 12 ? '12 PM' : (s >= 12 ? `${s % 12} PM` : `${s} AM`),
    }));

  function onSlot(slot) {
    let newValue = value || [];

    if (newValue.includes(slot.start_hour)) {
      newValue = newValue.filter(v => v !== slot.start_hour);
    } else {
      newValue = [...(selectOne ? [] : newValue), slot.start_hour];
    }

    newValue.sort();

    if (onChange) {
      onChange(newValue);
    }
  }

  const periods = ['morning', 'afternoon', 'evening'];

  return (
    <div>
      {periods.map(p => (
        <AvailabilityPeriod
          key={p}
          period={p}
          slots={formattedSlots}
          onSlot={onSlot}
          value={value || []}
        />
      ))}
    </div>
  );
}

function AvailabilityPeriod({ period, slots, onSlot, value }) {
  const isMobile = useIsMobile();

  const periodSlots = slots.filter(s => {
    if (period === 'morning') {
      return s.start_hour >= 0 && s.start_hour <= 11;
    }

    if (period === 'afternoon') {
      return s.start_hour >= 12 && s.start_hour <= 16;
    }

    if (period === 'evening') {
      return s.start_hour >= 17 && s.start_hour <= 24;
    }

    return false;
  });

  if (periodSlots.length === 0) {
    return null;
  }

  const slotRows = _.chunk(periodSlots, isMobile ? 3 : 4);

  return (
    <div className="flex flex-row mb-5">
      <div className="w-16 h-16 flex items-center justify-center">
        <DayPeriodLogo period={period} />
      </div>
      <div className="flex-1 ml-3">
        {slotRows.map((row, i) => (
          <div className="flex flex-row" key={i}>
            {row.map(s => (
              <PeriodBox
                key={s.start_hour}
                label={s.label}
                onClick={() => onSlot(s)}
                selected={value.includes(s.start_hour)}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function PeriodBox({ label, onClick, selected }) {
  return (
    <div onClick={onClick} className={cx(
      "border text-sm px-2 py-1 w-16 ",
      "mr-2 mb-2 flex justify-center items-center",
      "cursor-pointer",
      selected ? 'bg-y-yellow border-y-yellow-dark text-white' : 'bg-white text-gray-700 border-gray-200'
    )}>
      {label}
    </div>
  );
}
