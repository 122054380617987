import React from "react";
import { useForm } from "react-hook-form";
import Input from "../../Atoms/Input/Input";
import Button from "../../Atoms/Button/Button";
import { LoginApi } from "../../Api/LoginApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import TextLink from "../../Atoms/TextLink/TextLink";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function OtpLoginForm({ onSignup, onLogin, classes }) {
  const { register, handleSubmit, errors } = useForm();
  const { callApi, loading, formErrors } = useApi(LoginApi.otpLogin);
  const [state, setState] = React.useState("otpLoginSendOtp");
  const [phone, setPhone] = React.useState("");
  const [apiErrors, setApiErrors] = React.useState("");

  function onSubmit(data) {
    setApiErrors("");
    if (state === "otpLoginSendOtp") {
      // send otp
      data.mobile_number = "+" + phone;

      callApi(data)
        .then((response) => {
          setState("otpLoginVerifyOtp");
        })
        .catch((e) => {
          let reason = e.data.data.mobile_number[0];
          if (e.status === 400 && reason) {
            setApiErrors(reason);
            setPhone("");
          }
        });
    } else {
      data.mobile_number = "+" + phone;

      callApi(data)
        .then((response) => {
          let profile = response.data.data.profile;
          let token = response.data.token;
          onLogin(response.data.data, response.data.token);
          //window.location.reload();
        })
        .catch((e) => {
          if (e.data.data.mobile_number) {
            setApiErrors(e.data.data.mobile_number[0]);
          }
        });
    }
  }

  let otpField = null;
  if (state === "otpLoginVerifyOtp") {
    otpField = (
      <div className="mt-3">
        <Input
          required
          name="otp"
          label="Otp"
          type="text"
          inputRef={register({ required: true })}
          errors={[errors.otp && "Field is required", formErrors.otp]}
        />
      </div>
    );
  }

  let submitButton = null;
  if (state === "otpLoginSendOtp") {
    submitButton = (
      <Button loading={loading} type="submit">
        Send Otp
      </Button>
    );
  } else if (state === "otpLoginVerifyOtp") {
    submitButton = (
      <Button loading={loading} type="submit" className={classes + "login"}>
        Login
      </Button>
    );
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ marginBottom: 40 }}>
          <PhoneInput
            country={"in"}
            onChange={(phone) => setPhone(phone)}
            preferredCountries={["in", "us"]}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
              innerHeight: 100,
              outerHeight: 500,
            }}
            inputStyle={{
              fontSize: 16,
              lineHeight: "20px",
              fontFamily: [
                "Poppins",
                "ui-sans-serif",
                "system-ui",
                '"Segoe UI"',
                "Roboto",
              ],
              borderRadius: 9,
              paddingTop: 28,
              paddingBottom: 28,
              marginLeft: 10,
            }}
            buttonStyle={{
              borderTopLeftRadius: 9,
              borderBottomLeftRadius: 9,
              paddingLeft: 5,
              paddingRight: 5,
              backgroundColor: "#fff",
            }}
          />
        </div>

        {otpField}

        {formErrors.form && (
          <div className="mt-5 text-red-500 text-sm">{formErrors.form}</div>
        )}
        {apiErrors && (
          <div className="mt-5 text-red-500 text-sm">{apiErrors}</div>
        )}

        <div className="text-right mt-8">{submitButton}</div>

        <div className="mt-8">
          Don't have an account yet?{" "}
          <TextLink onClick={onSignup}>Signup</TextLink>
        </div>
      </form>
    </div>
  );
}

export default OtpLoginForm;
