import React from "react";
import { useParams } from "react-router-dom";
import { StorefrontApi } from "../../Api/StorefrontApi";
import HoveringGirl from "../../Graphics/HoveringGirl";
import Logo from "../../Graphics/Logo";
import ReactPixel from "react-facebook-pixel";
import pageTracking from "../../Services/AnalyticsService";
import { Helmet } from "react-helmet";

export default function JoinClass(props) {
  const { id } = useParams();
  const [message, setMessage] = React.useState("Joining Class...");

  React.useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
    StorefrontApi.joinClass(id)
      .then((response) => {
        const { data } = response.data;

        setMessage(data.message);
        if (data.link) {
          window.location.replace(data.link);
        }
      })
      .catch(() => {
        setMessage("Could not join class. Please try again");
      });
  }, [id]);

  return (
    <div className="bg-y-cream-600 min-h-screen flex flex-col justify-center items-center p-5">
      <Helmet>
        <title>Join Class</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Join Class"></meta>
        <meta name="description" content="Join Class" />
        <meta property="og:title" content="Join Class"></meta>
        <meta property="twitter:title" content="Join Class"></meta>
        <meta property="og:description" content="Join Class"></meta>
        <meta property="twitter:description" content="Join Class"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <Logo className="mb-24" />
      <HoveringGirl width={250} height={250} />
      <h2 className="text-xl text-center mt-20 text-y-yellow-dark">
        {message}
      </h2>
    </div>
  );
}
