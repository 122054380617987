import React from "react";
import InputWrapper from "../InputWrapper/InputWrapper";

export default function TextArea({
  label,
  name,
  autoFocus,
  inputRef,
  required,
  errors,
  rows = 5,
  wrapperStyle = "hover-label",
}) {
  return (
    <InputWrapper
      label={label}
      name={name}
      required={required}
      errors={errors}
      wrapperStyle={wrapperStyle}
    >
      {({ ...props }) => (
        <textarea
          {...props}
          name={name}
          rows={rows}
          autoFocus={autoFocus}
          ref={inputRef}
        />
      )}
    </InputWrapper>
  );
}
