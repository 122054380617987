import React from "react";
import "./store.css";

const Faq = () => {
  const sessions = [
    {
      name: "How do I book a yoga class on Do Yoga?",
      content:
        "Each course page gives you option to book trial class, book monthly class or book for 3 months at 15% off. You can use either of these options to book the class. Alternatively you can book a trial class directly from the home page by telling us your name and phone number and paying the trial class fees. Our yoga counselor will reach you to assign the right class for your health needs.",
    },
    {
      name: "How do I join the online yoga class?",
      content:
        "Once you book a yoga class with us, you will get an email to confirm the booking which has details about the session you booked, timings and the link for the online yoga class. You will also receive a whatsapp message with the details. Further our yoga counselor will reach you and also connect you to the yoga teacher on whatsapp so that you have access to the yoga teacher for any queries before or after the yoga class.",
    },
    {
      name: "How do I prepare for an online yoga class?",
      content:
        "Find a well lit, well ventilated and clean spot in your house to practice yoga. Lay down your mat and ensure there is enough space for you to move about. Keep the phone/laptop at about 5 feet distance from you so that your entire form is visible on the screen for the yoga teacher to monitor your movements. Download Zoom and Google Meet on your phone/ laptop as the yoga session will be streamed on one of these platforms. Please be ready for the yoga session atleast 5 minutes before the start. \n \n Yoga is sacred time you spend with your body, mind and soul. Wash your hands, feet and face before the yoga session. It is best to have a shower before the yoga class. Wear loose comfortable clothes.",
    },
    {
      name: "Can I eat before the yoga class?",
      content:
        "You should not do yoga with a heavy stomach. There should be atleast 2 hours gap between your meals and the yoga session. You can have a fruit or tea/coffee about 30 mins before the session. Drinking water is ok.",
    },
    {
      name: "Can I reschedule the yoga class that I have booked on Do Yoga?",
      content:
        "Yes, you can reschedule the class you have booked to the next or any subsequent session by the same or even different teachers. You will have to inform us of the same atleast 12 hours before your yoga session.",
    },
    {
      name: "What type of yoga is available on Do Yoga?",
      content:
        "On Do Yoga, we aim to offer yoga classes across styles, for different levels, and for all your health needs. We have specific yoga sessions for beginner, intermediate and advanced practitioners. Our teachers offer yoga sessions in different styles of yoga - Ashtanga, Vinyasa, Sivananda, Satyananda, Iyengar, Yin, Restorative etc. We also have yoga classes for various health needs - weight loss, PCOD, Diabetes, Hypertension, Thyroid, Arthritis, Spondylitis etc.",
    },
    {
      name: "What are the timings of the online yoga classes?",
      content: "We have yoga classes from 4am to 9pm IST.",
    },
    {
      name: "What time should I do yoga?",
      content:
        "It is best that you fix a time to do yoga regularly. Yoga is best done in the morning after you have freshened up. The energising effect of the yoga practice then sustains you throughout the day. You can also do yoga in the evening after your work especially restorative practices like Yoga Nidra and meditation. Sunrise and Sunset are the best times for yoga practice. Yoga should ideally not be done at night as that will disturb your sleep cycle.",
    },
    {
      name: "How many days in the week should I do yoga?",
      content:
        "It is best to do yoga 5-6 times a week with a break of 1-2 days. If you are practicing yoga as a regular spiritual Sadhana, then you should practice all 7 days a week. You may practice with a teacher for some days, and practice on your own the other days. Self practice is key.",
    },
    {
      name: "Can I do yoga during my periods?",
      content:
        "Each body type is different. Always listen to your body. It is best to avoid intense practice during your periods. Restorative yoga practices like Yin Yoga or Yoga Nidra are ideal to relax during your periods. Some yoga poses help relieve pain and can be specifically practiced during periods.",
    },
    {
      name: "What should I do if I find any pain or discomfort during the yoga practice?",
      content:
        "You should stop the practice in case you feel any pain or discomfort. Always listen to your body and know your limitations. Inform the teacher if you are practicing yoga with a teacher and relax in Shavasana for sometime. If you feel ok again, you may resume the practice, else skip the remainder of the practice that day. If the pain persists, set up time with the teacher to discuss about it and also reach out to a doctor immediately.",
    },
    {
      name: "Who should not do yoga?",
      content:
        "Ideally everyone can and should do yoga. But always listen to your body and understand your limitations while doing yoga. If you have hypertension or hernia or have just been operated, you should avoid inversions, holding the breath or other intense practices. But otherwise, if you feel you can do yoga, please go ahead. Progress gradually and do not try to do a lot right as you begin.",
    },
    {
      name: "What should I do if I did not like my yoga class?",
      content:
        "If you did not like the yoga class, please let us know of the same and the reasons why that yoga class did not work out for you. We will assign a different yoga class that meets your requirements.",
    },
    {
      name: "Can I take 2 weeks off during the membership period and avail that later?",
      content:
        "No. It is important to maintain your rhythm while practicing yoga. We do not allow members to take breaks and avail the break time with an extension of the monthly package. However under exceptional circumstances if the yoga teacher feels the reason is valid, we make few exceptions.",
    },
    {
      name: "How can I reach support?",
      content:
        "You can reach Do Yoga Support on Whatsapp/Call at +91 7975322831. You can also email us at hi@do.yoga for any queries.",
    },
  ];

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {sessions &&
        sessions.map((curr, i) => (
          <div className="mb-4">
            <h2 id={`accordion-collapse-heading-${i}`}>
              <button
                type="button"
                class="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 rounded-xl dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800"
                data-accordion-target={`#accordion-collapse-body-${i}`}
                aria-expanded="true"
                aria-controls={`accordion-collapse-body-${i}`}
              >
                <span>{curr.name}</span>
                <svg
                  data-accordion-icon
                  class="w-3 h-3 rotate-90 shrink-0"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
            <div
              id={`accordion-collapse-body-${i}`}
              class="hidden"
              aria-labelledby={`accordion-collapse-heading-${i}`}
            >
              <div class="p-5 border border-t-0 rounded-xl border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  {curr.content}
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Faq;
