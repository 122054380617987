import React from 'react';
import { connect } from "react-redux";
import OtpLoginModal from '../LoginModal/OtpLoginModal';
import { bindActionCreators } from "redux";
import UiAction from "../../Actions/ui";

function ReduxOtpLoginModal({ showOtpLoginModal, onClose }) {
  return (
    <OtpLoginModal show={showOtpLoginModal} onClose={onClose} />
  );
}

function mapStateToProps({ ui }) {
  return {
    showOtpLoginModal: ui.showOtpLoginModal,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    onClose: UiAction.hideOtpLogin,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReduxOtpLoginModal);
