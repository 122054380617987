import React from "react";
import { UserApi } from "../../Api/UserApi";
import ErrorMessage from "../../Atoms/ErrorMessage";
import StageCard from "../../Components/StageCard";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import ExperienceStage from "./Stages/ExperienceStage";
//import ProfilePicStage from "./Stages/ProfilePicStage";
import ProfileStage from "./Stages/ProfileStage";
import YogaSchoolStage from "./Stages/YogaSchoolStage";
import WebsiteStage from "./Stages/WebsiteStage";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function TeacherProfile() {
  const { callApi, loading, data, errorMessage, setSuccessResponse } = useApi(
    UserApi.teacherProfile
  );

  React.useEffect(() => {
    callApi();
    pageTracking();
    ReactPixel.pageView();
  }, [callApi]);

  if (errorMessage) {
    return <ErrorMessage>{errorMessage}</ErrorMessage>;
  }

  if (!data) {
    return "Loading Teacher Profile..";
  }

  function onSuccess(data) {
    setSuccessResponse(data);
  }

  return (
    <div>
      <Helmet>
        <title>Profile</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Profile"></meta>
        <meta name="description" content="Profile" />
        <meta property="og:title" content="Profile"></meta>
        <meta property="twitter:title" content="Profile"></meta>
        <meta property="og:description" content="Profile"></meta>
        <meta property="twitter:description" content="Profile"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div className="max-w-lg px-7 sm:px-0">
        Before you post your first lesson, tell us a little about your yoga
        teaching experience.
      </div>
      <div className="w-screen max-w-xl mt-5">
        <StageCard
          loading={loading}
          stages={[
            {
              title: "Profile",
              component: ProfileStage,
              props: {
                data: data.data,
                onSuccess,
              },
            },
            {
              title: "Experience",
              component: ExperienceStage,
              props: {
                data: data.data,
                onSuccess,
              },
            },

            {
              title: "Yoga School",
              component: YogaSchoolStage,
              props: {
                data: data.data,
                onSuccess,
              },
            },
            {
              title: "Website",
              component: WebsiteStage,
              props: {
                data: data.data,
                onSuccess,
              },
            },
          ]}
        />
      </div>
    </div>
  );
}
