import React, { useEffect } from "react";
import Button from "../../Atoms/Button/Button";
import HoveringGirl from "../../Graphics/HoveringGirl";
import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from "react-helmet";

export default function NotFound({ title, subtitle, ...props }) {
  useEffect(() => {
    pageTracking(props);
    ReactPixel.pageView();
  }, []);
  return (
    <div className="bg-y-cream-600 flex flex-col justify-center items-center pt-24">
      <Helmet>
        <title>Not Found</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Not Found"></meta>
        <meta name="description" content="Not Found" />
        <meta property="og:title" content="Not Found"></meta>
        <meta property="twitter:title" content="Not Found"></meta>
        <meta property="og:description" content="Not Found"></meta>
        <meta property="twitter:description" content="Not Found"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <HoveringGirl width={250} height={250} />
      <div className="px-5 text-center">
        <h1 className="text-lg font-semibold pt-8 font-weight-bold">
          {title || "Look within"}
        </h1>
        <p className="text-y-gray">
          {subtitle || "The page you're looking for does not exist"}
        </p>
      </div>

      <Button className="mt-8" py="py-3" link="/">
        Go Home
      </Button>
    </div>
  );
}
