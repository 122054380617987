import cx from "classnames";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StorefrontApi } from "../../Api/StorefrontApi";
import Button from "../../Atoms/Button/Button";
import ErrorMessage from "../../Atoms/ErrorMessage";
import SessionDetailsComponents from "../../Components/SessionDetailsComponents";
import CourseService from "../../Services/CourseService";
import TextService from "../../Services/TextService";
import UrlService from "../../Services/UrlService";
import { useApi } from "../../Utilities/Hooks/ApiHook";
import { Helmet } from "react-helmet";
import Whatsapp from "../Whatsapp";
import UserSelectors from "../../Selectors/user";
import CourseSelectors from "../../Selectors/course";
import { useDispatch, useSelector } from "react-redux";
import CourseAction from "../../Actions/course";

import Reviews from "../Reviews";
import { Passers } from "prop-passer";
import Modal from "../../Atoms/Modal/Modal";
import "./style.css";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const { CoverImage, Teacher, School } = SessionDetailsComponents;

export default function CourseDetailsPage({
  id,
  backText,
  backUrl,
  isMonthlyCourse,
  currentSession,
  setLoadData,
}) {
  const dispatch = useDispatch();
  const userTimeZone = useSelector(UserSelectors.userTimeZone);
  const { callApi, data, errorMessage } = useApi(StorefrontApi.courseDetails);
  const [showBooking, setShowBooking] = React.useState(
    UrlService.getParam("booking_status") === "success"
  );
  const history = useHistory();
  const showLoading = useSelector(CourseSelectors.showLoading);

  React.useEffect(() => {
    dispatch(CourseAction.setShowLoading(false));

    callApi(id)
      .then(() => (setLoadData ? setLoadData(false) : null))
      .catch(() => (setLoadData ? setLoadData(false) : null));
  }, [callApi, id]);

  if (errorMessage) {
    return (
      <div className="p-8 pt-5">
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </div>
    );
  }

  if (!data) {
    return null;
  }

  function onJoin(bookThreeMonths = false) {
    if (bookThreeMonths) {
      if (bookThreeMonths === 12) {
        history.push(`/monthly/${id}/payment?book12Months=true`);
      } else {
        history.push(`/monthly/${id}/payment?bookThreeMonths=true`);
      }
    } else {
      history.push(`/monthly/${id}/payment`);
    }

    //setShowBooking(true);
  }

  const course = data.data;

  const courseDescription =
    course.description[0] === "["
      ? JSON.parse(course.description)
      : course.description;

  const timingObj = (val) => {
    let value = 0;
    value = val.includes("am")
      ? Number(val[0]) > 7
        ? 1
        : 0
      : Number(val[0]) > 4
      ? 3
      : 2;
    switch (value) {
      case 0:
        return "Early Morning";
      case 1:
        return "Morning";
      case 2:
        return "Afternoon";
      case 3:
        return "Evening";
    }
  };

  let startTime = "";

  if (course.start_time) {
    startTime = CourseService.startTimeTextWithTimeZone(course, userTimeZone);
  }

  const descText = `Best ${timingObj(startTime)} yoga class for ${
    course.level
  } at ${startTime} ${course.title} by ${course.teacher.full_name} from ${
    course.teacher.location
  }. Join the yoga class on Do Yoga today`;

  const titleText = `${course.title} | Do Yoga | Online Yoga Classes`;

  const teacherId = course?.teacher?.id;

  const articleStructuredData = {
    "@context": "https://schema.org",
    "@type": "Yoga Class",
    headline: course.title,
    description: descText,
    image: course.images.length > 0 ? course.images[0].file : "",
    datePublished: new Date().toISOString(),
    mainEntityOfPage: { "@type": "Webpage", "@id": window.location.href },
    author: {
      "@type": "Person",
      name: course.teacher.full_name ? course.teacher.full_name : "",
      url: course.teacher.domain
        ? `https://${course.teacher.domain}.do.yoga/`
        : "",
    },
  };

  return (
    <div className="relative overflow-hidden 2xl:overflow-auto">
      <Helmet>
        <title>{titleText}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content={titleText}></meta>
        <meta name="description" content={descText} />
        <meta property="og:title" content={titleText}></meta>
        <meta property="twitter:title" content={titleText}></meta>
        <meta property="og:description" content={descText}></meta>
        <meta property="twitter:description" content={descText}></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
        <meta
          property="og:image"
          content={course.images.length > 0 ? course.images[0].file : ""}
        ></meta>
        <meta
          property="twitter:image"
          content={course.images.length > 0 ? course.images[0].file : ""}
        ></meta>

        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(articleStructuredData)}
        </script>
      </Helmet>

      {showLoading && <div class="loading">Loading&#8230;</div>}
      <div className="max-w-screen-lg mx-auto py-8 relative">
        <div className="z-50">
          {backText && (
            <div
              onClick={() => {
                dispatch(CourseAction.setShowLoading(true));
                history.goBack();
              }}
              className="text-y-yellow-dark font-semibold text-sm flex flex-row items-center cursor-pointer"
            >
              <svg
                className="mr-2"
                width="16"
                height="8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.375 3.25H2.139L4.33 1.068A.625.625 0 103.45.182L.184 3.432a.626.626 0 000 .885v.001l3.266 3.25a.625.625 0 00.881-.886L2.14 4.5h13.236a.625.625 0 100-1.25z"
                  fill="currentColor"
                />
              </svg>
              {backText}
            </div>
          )}

          <div className="lg:flex flex-row justify-between mt-5">
            <CoverImage images={course.images} title={course.title} />
            <DetailsCard
              course={course}
              onJoin={onJoin}
              isMonthlyCourse={isMonthlyCourse}
              onJoinSession={() => {
                if (currentSession?.id)
                  history.push(`/session/${currentSession?.id}/payment`);
              }}
              currentSession={currentSession}
            />
          </div>

          <div className="max-w-xl">
            <div className="mt-8 sm:mt-0">
              {courseDescription &&
              courseDescription.length > 0 &&
              (courseDescription[0]["title"] !== undefined ||
                courseDescription[0]["description"] !== undefined) ? (
                <>
                  {courseDescription.map((ele) => (
                    <div className="pb-4">
                      <div className="font-bold">{ele.title}</div>
                      {ele.description && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: ele.description.replaceAll(
                              "<p></p>",
                              `<p style="padding:15px 0;"></p>`
                            ),
                          }}
                        ></div>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <div>{courseDescription}</div>
              )}
            </div>

            {course.videos && (
              <div className="mt-5">
                <video
                  className="object-cover w-full h-full"
                  controls
                  style={{
                    height: "30vh",
                  }}
                >
                  <source src={course.videos} type="video/mp4" />
                </video>
              </div>
            )}
          </div>

          <div class="max-w-screen-lg mx-auto py-8 relative">
            {teacherId && <Reviews id={teacherId} />}
          </div>

          <div className="max-w-xl">
            <div className="mt-10">
              <Teacher teacher={course.teacher} />
            </div>

            <div className="mt-10">
              <School teacher={course.teacher} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DetailsCard({
  course,
  onJoin,
  isMonthlyCourse,
  onJoinSession,
  currentSession,
}) {
  const userTimeZone = useSelector(UserSelectors.userTimeZone);

  const teacherData = useSelector(CourseSelectors.streamDatas);

  const [courseFee, setCourseFee] = useState("");

  const [dateFields, setDateFields] = useState([]);

  const [fields, setFields] = useState([]);

  const url = window.location.href;

  const title = `Hi. Check out this yoga course. Doing yoga regularly can transform you.`;

  const size = "2.5rem";

  const [showModal, setShowModal] = useState(false);

  const ShareList = Passers({
    url: "",
    className: "network__share-button",
  })({
    className: "network cursor-pointer hover transition--default",
  })("li");

  useEffect(() => {
    const dateFieldsFromat = [
      CourseService.startEndDateTextWithTimeZone(course, userTimeZone),
      CourseService.repeatScheduleText(course, false),
      CourseService.repeatDaysFullText(course),
    ].filter((o) => o);

    let fieldsData = [];

    if (course.level) {
      fieldsData.push({ key: "LEVEL", value: course.level });
    }

    if (course.style) {
      fieldsData.push({ key: "STYLE", value: course.style });
    }

    if (dateFieldsFromat.length > 0) {
      fieldsData.push({
        key: "DATE",
        value: dateFieldsFromat,
      });
    }

    if (course.start_time) {
      const startTime = CourseService.startTimeTextWithTimeZone(
        course,
        userTimeZone
      );
      fieldsData.push({
        key: "TIME",
        value: `${startTime} (${course.duration} min)`,
      });
    }

    if (course.period_currency_fee || course.single_currency_fee) {
      setCourseFee(
        TextService.formatCurrencyFee(
          course.period_currency_fee,
          course.single_currency_fee
        )
      );
      fieldsData.push({
        key: "FEE",
        value:
          TextService.formatCurrencyFee(
            course.period_currency_fee,
            course.single_currency_fee
          ) + `${isMonthlyCourse ? " per month" : ""}`,
      });
    }

    const teacherId = course?.teacher?.id;

    if (teacherData[teacherId]) {
      if (teacherData[teacherId].avgReviewsCount) {
        fieldsData.push({
          key: "Rating",
          value: teacherData[teacherId].avgReviewsCount,
        });
      }

      if (teacherData[teacherId].total) {
        fieldsData.push({
          key: "Streams",
          value: teacherData[teacherId].total,
        });
      }
    }

    setDateFields([...dateFieldsFromat]);
    setFields([...fieldsData]);
  }, [userTimeZone]);

  return (
    <>
      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(!showModal);
        }}
        className="sm:w-full sm:max-w-md"
      >
        <div className="flex flex-wrap p-10">
          <div>
            <WhatsappShareButton
              url={url}
              title={title}
              separator="     "
              className="m-5"
            >
              <WhatsappIcon size={size} />
            </WhatsappShareButton>
          </div>
          <div>
            <ShareList>
              <FacebookShareButton quote={title} className="m-5">
                <FacebookIcon size={size} />
              </FacebookShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TwitterShareButton title={title} className="m-5">
                <TwitterIcon size={size} />
              </TwitterShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <LinkedinShareButton title={title} className="m-5">
                <LinkedinIcon size={size} />
              </LinkedinShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <TelegramShareButton title={title} className="m-5">
                <TelegramIcon size={size} />
              </TelegramShareButton>
            </ShareList>
          </div>
          <div>
            <ShareList>
              <EmailShareButton subject={title} body={title} className="m-5">
                <EmailIcon size={size} />
              </EmailShareButton>
            </ShareList>
          </div>
          <div></div>
          <div></div>
        </div>
      </Modal>
      <div
        className={cx(
          "mt-5 lg:mt-0",
          "sm:max-w-xs h-full w-full lg:bg-white lg:p-8 lg:rounded-md lg:shadow-card-cream-sm z-10"
        )}
      >
        <h1 className="font-semibold text-xl">{course.title}</h1>
        <div className="mt-5 w-full">
          {fields.map((f) => (
            <div
              key={f.key}
              className="flex flex-row justify-start text-sm mb-2"
            >
              <p
                className="text-gray-400 tracking-wide"
                style={{ minWidth: 70 }}
              >
                {f.key}:
              </p>
              {f.key === "DATE" && dateFields.length > 0 ? (
                <p>
                  <p className="w-full">{f.value[0]}</p>
                  <p className="w-full">{f.value[1]}</p>
                </p>
              ) : (
                <p className="w-full">{f.value}</p>
              )}
            </div>
          ))}
        </div>

        {currentSession?.id && (
          <Button className="mt-4 w-full" onClick={onJoinSession}>
            Book trial at{" "}
            {course.period_currency_fee.currency === "inr" ? "₹50" : "$5"}
          </Button>
        )}

        <Button className="mt-4 w-full" onClick={onJoin}>
          Join Course at {courseFee}
        </Button>

        {course.type === "regular-class" && (
          <Button className="mt-4 w-full" onClick={() => onJoin(3)}>
            Join 3 Months at 15% off
          </Button>
        )}

        {course.type === "regular-class" && (
          <Button className="mt-4 w-full" onClick={() => onJoin(12)}>
            Join 1 year at 33% off
          </Button>
        )}

        <button
          className={cx(
            "w-full mt-5 focus:outline-none",
            `tracking-wide inline-block py-3 px-8 rounded shadow-md text-center font-semibold text-white text-sm`,
            "cursor-pointer text-y-yellow-dark flex items-center justify-center"
          )}
          style={{ backgroundColor: "#ffffff", border: "1px solid" }}
          onClick={() => {
            setShowModal(!showModal);
          }}
        >
          <img
            src="/img/share.png"
            height={30}
            width={30}
            alt="whatspp"
            style={{ marginRight: 20 }}
          />
          Share
        </button>

        <Whatsapp name={course.title} teacher={course.teacher.full_name} />
      </div>
    </>
  );
}
