import cx from "classnames";
import React from "react";
import InputWrapper from "../InputWrapper/InputWrapper";
import "./Style.css";

export default function Checkbox({
  label,
  name,
  errors,
  inputRef,
  onChange,
  value,
  checked,
}) {
  return (
    <InputWrapper
      name={name}
      label={label}
      errors={errors}
      wrapperStyle="checkbox"
    >
      {({ className, ...props }) => (
        <input
          {...props}
          name={name}
          value={value}
          type="checkbox"
          onChange={onChange}
          className={cx(
            className,
            "appearance-none checkBox cursor-pointer ticked w-5 h-5 rounded-sm border border-gray-600 checked:border-transparent checked:bg-y-yellow"
          )}
          ref={inputRef}
          checked={checked}
        />
      )}
    </InputWrapper>
  );
}
