import MonthlyClassesGraphic from "../../../Graphics/MonthlyClassesGraphic";
import FilterClasses from "./Search";
import useIsMobile from "../../../Hooks/useMobile";
import cx from "classnames";
import pageTracking from "../../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import FilterDatasEnum from "../../../Utilities/FilterData";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

export default function Live(props) {
  const isMobile = useIsMobile();

  const filterArrData = {
    levels: [],
    price: [],
    healthConditions: [],
    time: [],
    style: [],
    sort: [],
    queryText: "",
  };

  useEffect(() => {
    pageTracking();
    ReactPixel.pageView();
  });

  return (
    <div className="px-0 sm:px-2 flex flex-col w-full">
      <Helmet>
        <title>Live Yoga Courses</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="title" content="Live Yoga Courses"></meta>
        <meta
          name="description"
          content="Explore different yoga styles. Connect with various teachers. Find the yoga practice that works for you."
        />
        <meta property="og:title" content="Live Yoga Courses"></meta>
        <meta property="twitter:title" content="Live Yoga Courses"></meta>
        <meta
          property="og:description"
          content="Explore different yoga styles. Connect with various teachers. Find the yoga practice that works for you."
        ></meta>
        <meta
          property="twitter:description"
          content="Explore different yoga styles. Connect with various teachers. Find the yoga practice that works for you."
        ></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="twitter:url" content={window.location.href}></meta>
      </Helmet>
      <div
        className={cx(
          "flex flex-row justify-between items-center overflow-x-auto",
          "h-40 sm:h-full sm:w-4/5"
        )}
      >
        <div className="text-sm sm:text-lg text-gray-600 font-semibold pr-2 pt-0 sm:pt-5 sm:w-2/4">
          <p className="mt-2">Explore different yoga styles.</p>
          <p className="mt-2">Connect with various teachers.</p>
          <p className="mt-2">Find the yoga practice that works for you.</p>
        </div>
        <div>
          <MonthlyClassesGraphic width={isMobile ? 170 : 300} height={130} />
        </div>
      </div>
      <FilterClasses
        name=""
        type="live"
        courseName="Live Yoga Courses"
        {...props}
      />
    </div>
  );
}
