import cx from "classnames";
import React, { useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import Logo from "../../Graphics/Logo";
import useIsMobile from "../../Hooks/useMobile";
import "../StudioTemplate/sideMenu.css";

function SideMenu({ history, expanded, toggleExpanded, setExpanded }) {
  const [overlay, setOverlay] = React.useState(expanded);

  const data = [
    {
      name: "Levels",
      items: [
        {
          value: "beginner",
          label: "Beginner",
          checked: false,
          link: "/courses/beginner-courses",
        },
        {
          value: "intermediate",
          label: "Intermediate",
          checked: false,
          link: "/courses/intermediate",
        },
        {
          value: "advanced",
          label: "Advanced",
          checked: false,
          link: "/courses/advanced",
        },
      ],
    },
    {
      name: "Price",
      items: [
        {
          type: "to",
          minValue: "1000",
          maxValue: "2000",
          label: "1000 to 2000",
          value: "1000 to 2000",
          checked: false,
          link: "/courses/price-1000-to-2000",
        },
        {
          type: "to",
          minValue: "2000",
          maxValue: "3000",
          label: "2000 to 3000",
          value: "2000 to 3000",
          checked: false,
          link: "/courses/price-2000-to-3000",
        },
        {
          type: "less",
          minValue: "1000",
          label: "<1000",
          value: "<1000",
          checked: false,
          link: "/courses/price-less-than-1000",
        },
        {
          type: "plus",
          minValue: "3000",
          label: ">3000",
          value: ">3000",
          checked: false,
          link: "/courses/price-greater-than-3000",
        },
      ],
    },
    {
      name: "Health Conditions",
      items: [
        {
          value: "pregnancy",
          label: "Pregnancy",
          checked: false,
          link: "/courses/health/pregnancy",
        },
        {
          value: "diabetes",
          label: "Diabetes",
          checked: false,
          link: "/courses/health/diabetes",
        },
        {
          value: "PCOS",
          label: "PCOS",
          checked: false,
          link: "/courses/health/PCOS",
        },
        {
          value: "Blood Pressure",
          label: "Blood Pressure",
          checked: false,
          link: "/courses/health/Blood Pressure",
        },
        {
          value: "Back Pain",
          label: "Back Pain",
          checked: false,
          link: "/courses/health/Back Pain",
        },
        {
          value: "Hypertension",
          label: "Hypertension",
          checked: false,
          link: "/courses/health/:Hypertension",
        },
        {
          value: "Arthritis",
          label: "Arthritis",
          checked: false,
          link: "/courses/health/Arthritis",
        },
      ],
    },
    {
      name: "Style",
      items: [
        {
          value: "Hatha Yoga",
          label: "Hatha Yoga",
          checked: false,
          link: "/courses/style/Hatha Yoga",
        },
        {
          value: "Power Yoga",
          label: "Power Yoga",
          checked: false,
          link: "/courses/style/Power Yoga",
        },
        {
          value: "Ashtanga Yoga",
          label: "Ashtanga Yoga",
          checked: false,
          link: "/courses/style/Ashtanga Yoga",
        },
        {
          value: "Sivananda Yoga",
          label: "Sivananda Yoga",
          checked: false,
          link: "/courses/style/Sivananda Yoga",
        },
        {
          value: "Iyengar Yoga",
          label: "Iyengar Yoga",
          checked: false,
          link: "/courses/style/Iyengar Yoga",
        },
        {
          value: "Yin Yoga",
          label: "Yin Yoga",
          checked: false,
          link: "/courses/style/Yin Yoga",
        },
        {
          value: "Satyananda Yoga",
          label: "Satyananda Yoga",
          checked: false,
          link: "/courses/style/Satyananda Yoga",
        },
      ],
    },
    {
      name: "Time",
      items: [
        {
          start: "05:00",
          end: "07:30",
          label: "Early Morning(05:00AM - 07:30AM)",
          value: "Early Morning(05:00AM - 07:30AM)",
          checked: false,
          link: "/courses/early-morning",
        },
        {
          start: "08:00",
          end: "11:30",
          label: "Morning(08:00AM - 11:30AM)",
          value: "Morning(08:00AM - 11:30AM)",
          checked: false,
          link: "/courses/morning",
        },
        {
          start: "12:00",
          end: "16:30",
          label: "Afternoon(12:00PM - 04:30PM)",
          value: "Afternoon(12:00PM - 04:30PM)",
          checked: false,
          link: "/courses/afternoon",
        },
        {
          start: "17:00",
          end: "21:00",
          label: "Evening(05:00PM - 09:00PM)",
          value: "Evening(05:00PM - 09:00PM)",
          checked: false,
          link: "/courses/evening",
        },
      ],
    },
  ];

  const [index, setIndex] = useState({});
  const [showName, setShowName] = useState("");

  React.useEffect(() => {
    let timeout;

    if (expanded) {
      timeout = setTimeout(() => {
        setOverlay(true);
      }, 50);
    } else {
      timeout = setTimeout(() => {
        setOverlay(false);
      }, 500);
    }

    return () => clearTimeout(timeout);
  }, [expanded, setOverlay]);

  React.useEffect(() => {
    setExpanded(false);
  }, [history.location.key, setExpanded]);

  const isMobile = useIsMobile();

  return (
    <div className="sm:w-2/6 x-side-menu">
      {(expanded || overlay) && (
        <div
          style={{ zIndex: 10000 }}
          className={cx(
            "fixed inset-0 bg-black sm:hidden",
            "transition duration-500 ease-in-out",
            expanded && overlay ? "opacity-70" : "opacity-0",
            !isMobile && "hidden"
          )}
          onClick={toggleExpanded}
        />
      )}

      <div
        style={{ zIndex: 10001 }}
        className={cx(
          "fixed sm:static top-0 left-0 w-9/12 sm:w-full h-full overflow-auto",
          "bg-white p-3",
          "transform transition duration-500 ease-in-out",
          !isMobile && "shadow-right-cream",
          isMobile &&
            (expanded ? "translate-x-0 shadow-lg" : "-translate-x-full")
        )}
      >
        <div className="mt-5">
          <Logo height={60} />
          <div className="mt-16">
            {data &&
              data.map((ele) => (
                <>
                  <div className="mb-5 mr-8">
                    <div
                      onClick={() => {
                        setIndex(ele);
                        setShowName(ele.name);
                      }}
                      className="ml-8 font-semibold text-gray-800 pb-3 flex justify-between cursor-pointer"
                    >
                      <div>{ele.name}</div>
                      <div className="pt-2 block md:hidden">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25px"
                          height="25px"
                          viewBox="0 0 50 50"
                          style={{ fill: "grey" }}
                        >
                          <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <div>
                    {ele.name === showName &&
                      index &&
                      index.items &&
                      index.items.map((item) => (
                        <div
                          className="flex items-center justify-between mb-4 pb-4 shadow-md rounded px-5 hover-cont"
                          role="button"
                          onClick={() => {
                            history.push(item.link);
                          }}
                        >
                          <div className="pt-3 pr-5">{item.label}</div>
                        </div>
                      ))}
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function MenuButton({ children, link, className }) {
  const history = useHistory();
  const selected = history.location.pathname.indexOf(link) === 0;

  return (
    <div
      className={cx(
        "block my-7 text-y-yellow text-right cursor-pointer flex justify-end items-center",
        selected && "font-semibold",
        className
      )}
      onClick={() => history.push(link)}
    >
      {children}

      <div
        className={cx(
          "ml-5 bg-y-yellow h-full",
          selected ? "opacity-100" : "opacity-0"
        )}
        style={{ width: 6, height: 16, borderRadius: 20 }}
      >
        &nbsp;
      </div>
    </div>
  );
}

export default withRouter(SideMenu);
