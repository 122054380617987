import cx from "classnames";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Styles from "../../Constants/styles";
import UiAction from "../../Actions/ui";
import UserSelectors from "../../Selectors/user";
import { useHistory, useParams } from "react-router-dom";
import OtpLoginModal from "../../Components/LoginModal/OtpLoginModal";

import SubscriptionJoinModal from "../../Components/SubscriptionJoinModal";
import BookingModal from "../../Components/BookingModal";

import { StorefrontApi } from "../../Api/StorefrontApi";
import { SubscriptionApi } from "../../Api/SubscriptionApi";
import { useApi } from "../../Utilities/Hooks/ApiHook";

import pageTracking from "../../Services/AnalyticsService";
import ReactPixel from "react-facebook-pixel";
import UrlService from "../../Services/UrlService";
import { Helmet } from "react-helmet";

export default function PaymentFlow(props) {
  const isLoggedIn = useSelector(UserSelectors.isLoggedIn);
  const history = useHistory();
  const dispatch = useDispatch();

  const { id, type } = useParams();

  const {
    callApi: sessionDetails,
    data: sessionData,
    errorMessage: sessionError,
  } = useApi(StorefrontApi.sessionDetails);
  const {
    callApi: monthlyDetails,
    data: monthlyData,
    errorMessage: monthlyError,
  } = useApi(StorefrontApi.courseDetails);

  const {
    callApi: subscriptionDetails,
    data: subscriptionData,
    errorMessage: subscriptionError,
  } = useApi(SubscriptionApi.subscriptionDetails);

  const {
    callApi: oneOnOneDetails,
    data: oneOnOneData,
    errorMessage: oneOnOneError,
  } = useApi(StorefrontApi.oneOnOneDetails);

  const { callApi: bookingDetailsUser } = useApi(
    SubscriptionApi.bookingDetailsUser
  );

  const { callApi: bookingUpdateUser } = useApi(
    SubscriptionApi.bookingUpdateUser
  );

  const [showBooking, setShowBooking] = React.useState(false);
  const [showJoin, setShowJoin] = React.useState(false);
  const [bookingObj, setBookingObj] = useState({});
  const [joinError, setJoinError] = useState("");

  const search = props?.location?.search;
  const params = new URLSearchParams(search);
  const date = params.get("date");
  const slot = params.get("slot");
  const bookThreeMonths = params.get("bookThreeMonths") ? true : false;
  const book12Months = params.get("book12Months") ? true : false;

  const formatData = { date: date, time: `${slot}:00` };

  const session = sessionData?.data;

  function joinClass() {
    const now = Math.floor(new Date() / 1000);
    const courseTime = session.course.utc_start_at;
    const result = Math.ceil((courseTime - now) / 60);
    const today = new Date().toISOString().slice(0, 10);
    const startTime = dayjs.unix(session.utc_start_at).format("MMM D, h:mm a");

    if (result >= 0 && result <= 10) {
      bookingUpdateUser(session.course.teacher.id);
      if (session.course.video_link) {
        window.location.replace(session.course.video_link);
      }
    } else if (result < 0) {
      const val = session.course.duration + result;

      if (val >= 15) {
        bookingUpdateUser(session.course.teacher.id);

        if (session.course.video_link) {
          window.location.replace(session.course.video_link);
        }
      } else if (today === session.date) {
        setJoinError("Meeting Has been ended");
      } else {
        setJoinError(`Meeting will start at ${session.date} ${startTime}`);
      }
    } else {
      if (result > 10) {
        setJoinError("Meeting will be available before 10 mins of start time");
      }
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      if (type === "session" && sessionData?.data) {
        bookingDetailsUser(sessionData?.data.course.teacher.id)
          .then((res) => {
            setShowJoin(true);
            setBookingObj(res.data.data);
          })
          .catch((err) => setShowBooking(true));
      } else if (type === "monthly" && monthlyData?.data) {
        setShowBooking(true);
      } else if (type === "subscription" && subscriptionData?.data) {
        setShowBooking(true);
      } else if (type === "1-1" && oneOnOneData?.data) {
        setShowBooking(true);
      }
    }
  }, [isLoggedIn, sessionData, monthlyData, subscriptionData, oneOnOneData]);

  useEffect(() => {
    if (type === "session") {
      sessionDetails(id);
    } else if (type === "monthly") {
      monthlyDetails(id);
    } else if (type === "subscription") {
      subscriptionDetails(id);
    } else if (type === "1-1") {
      oneOnOneDetails(id);
    }
    pageTracking();
    ReactPixel.pageView();
  }, [
    sessionDetails,
    monthlyDetails,
    id,
    subscriptionDetails,
    oneOnOneDetails,
  ]);

  return (
    <div>
      {isLoggedIn ? (
        <div>
          {showBooking && (
            <>
              <h2 className="text-center">Payment processing</h2>
              {type === "session" ? (
                <BookingModal
                  show={UrlService.isBookingSuccessCallback() || showBooking}
                  onClose={() => setShowBooking(false)}
                  session={sessionData?.data}
                />
              ) : type === "monthly" ? (
                <BookingModal
                  show={UrlService.isBookingSuccessCallback() || showBooking}
                  onClose={() => setShowBooking(false)}
                  course={monthlyData?.data}
                  bookThreeMonths={bookThreeMonths}
                  book12Months={book12Months}
                />
              ) : type === "subscription" ? (
                <BookingModal
                  show={UrlService.isBookingSuccessCallback() || showBooking}
                  onClose={() => setShowBooking(false)}
                  subscription={subscriptionData?.data}
                />
              ) : type === "1-1" ? (
                <BookingModal
                  show={UrlService.isBookingSuccessCallback() || showBooking}
                  onClose={() => setShowBooking(false)}
                  one={oneOnOneData?.data}
                  bookingProps={formatData}
                />
              ) : (
                <></>
              )}
            </>
          )}

          {showJoin && (
            <SubscriptionJoinModal
              show={showJoin}
              onClose={() => setShowJoin(false)}
              data={bookingObj}
              onJoin={joinClass}
              error={joinError}
              headarObj={{
                title: session?.course?.title,
                image: session?.course?.images[0]?.file,
                expired: bookingObj?.subscription?.expiry_date,
                classes: bookingObj?.remaining_class_count,
              }}
            />
          )}
        </div>
      ) : (
        <div style={{ position: "relative", height: "80vh" }}>
          <OtpLoginModal payModal={true} classes="booking-" />
        </div>
      )}
    </div>
  );
}
