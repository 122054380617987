import React from "react";

export default function LiveClassesGraphic({
  width = 155,
  height = 108,
  className = "",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 155 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M113.984 3.715c5.933 4.517 8.758 13.459 11.678 21.365 3.014 7.906 6.216 14.683 13.185 25.6 7.063 10.919 17.893 25.79 15.916 35.484-1.884 9.695-16.576 14.213-29.572 11.483-12.902-2.824-24.109-12.706-36.07-11.389-11.96 1.224-24.58 13.742-34.469 14.683-9.889.942-16.858-9.6-22.79-20.612-5.934-10.918-10.737-22.307-5.84-30.778C31.014 40.986 45.8 35.34 54.841 29.786c9.04-5.647 12.337-11.2 17.234-16.283 4.897-5.082 11.301-9.788 19.118-12.047 7.817-2.26 16.858-2.165 22.791 2.259z"
        fill="#FCF3E2"
      />
      <path
        d="M142.721 42.218H58.087c-1.038 0-1.937.72-2.164 1.732l-13.352 59.666h89.178l13.136-58.697a2.217 2.217 0 00-2.164-2.701z"
        fill="#F7B38B"
      />
      <path
        d="M141.731 42.218H57.097c-1.038 0-1.937.72-2.164 1.732l-13.352 59.666h89.177l13.137-58.697a2.218 2.218 0 00-2.164-2.701z"
        fill="#FDE1CA"
      />
      <path
        d="M141.215 44.901H57.404l-12.54 56.032h83.812l12.539-56.032z"
        fill="#fff"
      />
      <path
        d="M46.067 95.56h83.86l-1.251 5.373H44.864l1.203-5.373z"
        fill="#EEA886"
      />
      <path
        d="M97.93 102.994H9.45c-1.065 0-1.928.919-1.928 2.052s.863 2.051 1.927 2.051h88.482c1.064 0 1.927-.918 1.927-2.051s-.863-2.052-1.927-2.052z"
        fill="#F7CDAF"
      />
      <path
        d="M82.024 102.994a2.051 2.051 0 100 4.103h47.902c1.757 0 1.962-.874 1.962-2.051v-2.052H82.024z"
        fill="#F7B38B"
      />
      <path
        d="M130.069 105.776h-3.499a.717.717 0 110-1.434h3.499a.717.717 0 110 1.434zM123.23 105.646a.587.587 0 110-1.174.587.587 0 010 1.174zM120.02 105.646a.587.587 0 110-1.174.587.587 0 010 1.174z"
        fill="#F03B46"
      />
      <path
        d="M135.002 54.826H61.324l-7.962 38.98h73.678l7.962-38.98z"
        fill="#FFF2DB"
      />
      <path
        d="M135.975 50.062H62.297l-.973 4.764h73.678l.973-4.764z"
        fill="#FDE1CA"
      />
      <path
        d="M133.603 53.427H59.925l-7.961 38.98h73.677l7.962-38.98z"
        fill="#FAF7DF"
      />
      <path
        d="M134.576 48.663H60.899l-.974 4.764h73.678l.973-4.764z"
        fill="#EEA886"
      />
      <path
        d="M53.072 86.978h73.731l-1.108 5.429H51.964l1.108-5.429z"
        fill="#E3DFB9"
      />
      <path
        d="M65.294 75.774c-.194-.615-.811-.952-1.608-.975l-.092 1.322-.41-1.298c-.429.052-.89.183-1.356.402-.037.017-.072.037-.109.055l.105 1.672-.605-1.388a4.89 4.89 0 00-.572.432l.324 1.028-.613-.754c-.676.706-1.01 1.534-.8 2.198.197.628.835.965 1.655.977l.1-.905.306.886a4.47 4.47 0 001.405-.408c.1-.047.197-.097.293-.15l-.393-1.578.766 1.35c.224-.149.432-.31.617-.483l-.512-1.21.842.866c.578-.669.851-1.423.657-2.039z"
        fill="#58BD9F"
      />
      <path
        d="M64.245 69.862c-.677.318-1.15 1-1.337 1.797l.897.037-.974.457c-.033.417.008.85.14 1.268.01.033.024.064.035.097l1.754-.444-1.56.885c.095.177.207.337.33.48l1.132-.531-.906.763c.595.529 1.384.693 2.115.35.69-.325 1.17-1.028 1.348-1.845l-1.236.113 1.3-.514a3.353 3.353 0 00-.233-1.575l-1.716.712 1.555-1.037a2.657 2.657 0 00-.375-.515l-1.358.755 1.068-1.014c-.576-.439-1.302-.557-1.979-.239zM73.978 78.798c.214-.412.151-.862-.116-1.224l-.639.66.452-.869a1.83 1.83 0 00-.659-.383l-.063-.019-.73.932.424-1.002a2.21 2.21 0 00-.398-.023l-.358.688.13-.67c-.56.078-1.055.369-1.287.813-.218.42-.15.88.132 1.246l.493-.477-.342.646c.177.17.406.31.679.401.057.02.114.036.171.05l.586-1.009-.35 1.052c.146.02.293.026.437.016l.375-.867-.102.83c.509-.1.95-.38 1.165-.791z"
        fill="#80D3B7"
      />
      <path
        d="M74.26 74.573c.075.661-.32 1.384-.989 1.967l-.593-1.06.157 1.395c-.387.259-.83.474-1.312.617l-.114.03-.561-1.499.024 1.61a3.817 3.817 0 01-.654.037l-.124-1.105-.23 1.076c-.85-.124-1.455-.592-1.535-1.306-.076-.674.336-1.413 1.03-2.002l.894 1.43-.541-1.702a4.826 4.826 0 011.662-.726l.28 1.623.127-1.691c.248-.032.488-.041.713-.026l.034 1.393.38-1.333c.753.162 1.279.61 1.353 1.272z"
        fill="#58BD9F"
      />
      <path
        d="M72.766 68.717c.669.389.907 1.223.737 2.197l-1.563-.216 1.41.82a5.707 5.707 0 01-.804 1.548c-.03.041-.06.079-.091.119l-1.922-.543 1.466 1.08a6.014 6.014 0 01-.644.588l-1.117-.649.725.933c-.99.646-2.036.846-2.759.427-.682-.397-.916-1.256-.726-2.255l1.313.125-1.19-.614c.165-.526.44-1.075.824-1.606.08-.11.162-.216.246-.319l1.741.87-1.382-1.266c.23-.236.47-.448.716-.63l1.282.922-.8-1.239c.923-.535 1.87-.68 2.538-.292z"
        fill="#80D3B7"
      />
      <path
        d="M66.556 82.046l-.299-.054c.007-.04.706-3.983.25-5.108-.418-1.035-1.399-.711-1.44-.697l-.1-.287c.014-.004 1.297-.428 1.822.87.489 1.206-.203 5.11-.233 5.276z"
        fill="#58BD9F"
      />
      <path
        d="M67.055 81.442l-.303-.027c.002-.014.125-1.358.477-3.596.356-2.27 1.586-3.914 1.638-3.983l.242.183c-.012.016-1.236 1.655-1.58 3.848-.35 2.226-.473 3.562-.474 3.575zM65.86 81.444l-.303-.032c.498-4.68.004-6.7 0-6.72l.294-.074c.02.082.514 2.081.009 6.826z"
        fill="#80D3B7"
      />
      <path
        d="M66.25 81.459l-.298-.061c.865-4.236 2.75-5.466 2.831-5.516l.162.257c-.018.012-1.86 1.226-2.695 5.32z"
        fill="#58BD9F"
      />
      <path
        d="M67.363 81.459l-.297-.061c.36-1.765 1.058-2.948 2.073-3.517a2.955 2.955 0 012.304-.231l-.104.286c-.01-.004-1.034-.361-2.056.213-.932.524-1.578 1.638-1.92 3.31z"
        fill="#80D3B7"
      />
      <path
        d="M70.744 80.959H62.74l-.041.203s-.724 4.181.973 5.816h3.658c2.395-1.672 3.414-6.02 3.414-6.02z"
        fill="#fff"
      />
      <path
        d="M65.707 91.116h35.161l-1.22-2.39H68.046l-2.339 2.39z"
        fill="#7C4340"
      />
      <path
        d="M85.043 60.837s-1.183 0-1.981 1.127c-.799 1.127-1.063 2.29-1.063 2.29h4.655s.954-3.417-1.611-3.417z"
        fill="#418074"
      />
      <path
        d="M84.72 65.605l4.037-.892 4.27-2.19.445.724s-2.636 2.008-3.904 2.725c-1.986 1.124-5.056 1.632-5.056 1.632l.207-2zM82.78 65.854l-3.112-1.27-.68-3.323-.443.136s-.67 3.204.097 4.205c1.147 1.497 3.533 2.445 4.15 2.087.4-.23.443-.558.492-.907.06-.419-.142-.78-.504-.928z"
        fill="#F5757A"
      />
      <path
        d="M95.121 63.041l-.771.687.579 5.398s-2.531.486-4.91 1.958c0 0-2.059 1.304-1.36 3.686 1.4-.4 3.44-1.08 4.996-2 2.62-1.548 3.085-2.342 3.085-2.342 1.828-1.925-1.618-7.387-1.618-7.387z"
        fill="#F8A486"
      />
      <path
        d="M84.971 73.963c-.007.72-.049 5.37.075 7.493.133 2.304-.345 8.157-.345 8.157h.907s2.139-5.664 1.674-8.195c.58-2.45 1.117-5.245 1.377-6.648 0 0-2.165.461-3.688-.807z"
        fill="#F8A486"
      />
      <path
        d="M84.207 65.594c-1.926 0-2.385.752-2.736 2.115-.257.998-.224 2.082.877 3.585a16.14 16.14 0 003.71-2.276c-.703-1.98.044-3.424-1.85-3.424z"
        fill="#F5757A"
      />
      <path
        d="M90.02 71.084c-.824-.471-1.542-.553-2.16-.409-.024-.008-.048-.016-.073-.027-.966-.379-1.622-1.198-1.85-2.219l-.24-1.07s-.647-.465-.837-1.785h-.094s-.27 1.9-1.859 2.49c-.76-1.045-.032-2.285-.032-2.285l-.142.056s-.833 1.007-1.307 1.77c-.474.762-1.106 2.012 1.33 4.206h.001c.535.634 1.247 1.338 2.194 2.137l.02-.017v.032c1.523 1.268 3.689.807 3.689.807-.7-2.382 1.36-3.686 1.36-3.686z"
        fill="#F03B46"
      />
      <path
        d="M84.737 65.716c-.495.273-1.076.327-1.746.16l.332-1.622h1.712l-.298 1.462z"
        fill="#F5757A"
      />
      <path
        d="M82.272 90.005c.212-.088 2.473-.951 2.473-.951h1.064s.043 1.24-.407 1.24h-2.986c-.313 0-.356-.2-.144-.288z"
        fill="#F8A486"
      />
      <path
        d="M94.862 62.293c-.03-.07-.18-.018-.18-.018s.137-.147.1-.241c-.038-.094-.242.01-.242.01s.138-.179.093-.276c-.046-.098-.24.023-.24.023s.157-.148.049-.258c-.028-.028-.075-.02-.13.008-.024-.055-.112-.126-.457.02-.453.193-.663.71-.663.71-.148.223-.265.405-.265.405l.305.695s.674-.34 1.122-.624c.45-.284.538-.384.508-.454z"
        fill="#F5757A"
      />
      <path
        d="M93.835 61.213c.117.153 1.287 1.828 1.287 1.828l-.694 1.42s-.367.278-.43-.146l-.427-2.813c-.045-.296.146-.442.264-.289z"
        fill="#F8A486"
      />
      <path
        d="M85.62 64.147c1.078-.997.971-2.585-.185-3.034a1.109 1.109 0 00-.027-.01c-.924-.348-2.124.35-2.43 1.85-.307 1.5-.25 2.025.76 2.025.508 0 1.356-.347 1.881-.831zM78.545 61.397s.059-.74.315-1.179c.256-.439.9-.918.962-.928.063-.01.028.171-.579.762-.184.303-.09.514-.09.514s.35-.56.432-.56c.119 0-.321 1.053-.724 1.391"
        fill="#F5757A"
      />
      <path
        d="M84.365 61.015s.14 1.471 1.388 1.727c.162.734-.666 1.512-.666 1.512h1.081s1.525-3.83-1.803-3.239z"
        fill="#418074"
      />
      <path
        d="M94.271 61.562s-.547.383-.483.546c.064.162.583-.272.583-.272l-.1-.274z"
        fill="#F5757A"
      />
      <path
        d="M94.398 61.797s-.547.384-.484.546c.065.163.573-.3.573-.3l-.09-.246z"
        fill="#F5757A"
      />
      <path
        d="M94.5 62.036s-.547.384-.483.547c.064.162.572-.3.572-.3l-.089-.247z"
        fill="#F5757A"
      />
      <path
        d="M94.652 62.232s-.514.36-.454.513c.06.153.536-.258.536-.258l-.082-.255z"
        fill="#F5757A"
      />
      <path
        d="M111.791 53.427h21.812l-7.962 38.98h-21.812l7.962-38.98z"
        fill="#fff"
      />
      <path
        d="M129.371 56.385h-14.555c-.441 0-.872.357-.962.798l-.643 3.148-.263 1.292 1.555-1.292h14.062c.44 0 .871-.358.961-.798l.48-2.35c.09-.441-.194-.798-.635-.798zM124.63 79.595h-14.556c-.44 0-.871.358-.961.799l-.643 3.148-.264 1.291 1.556-1.291h14.062c.44 0 .871-.358.961-.799l.48-2.35c.09-.44-.195-.798-.635-.798z"
        fill="#C2DCEA"
      />
      <path
        d="M111.463 72.797h14.555c.441 0 .725.358.635.799l-.643 3.148-.264 1.292-1.027-1.292h-14.062c-.441 0-.725-.358-.635-.798l.48-2.35c.09-.441.52-.799.961-.799z"
        fill="#80B0D3"
      />
      <path
        d="M128.044 62.88h-14.555c-.441 0-.871.356-.961.797l-1.343 6.572-.263 1.292 1.555-1.292h14.062c.44 0 .871-.357.961-.798l1.179-5.774c.09-.44-.194-.798-.635-.798z"
        fill="#C2DCEA"
      />
      <path
        d="M125.934 51.045c-.091.45.199.815.648.815.45 0 .889-.365.981-.815.092-.45-.198-.814-.648-.814s-.889.364-.981.814zM130.788 51.045c-.092.45.199.815.648.815.45 0 .889-.365.981-.815.092-.45-.198-.814-.648-.814s-.889.364-.981.814zM128.361 51.045c-.092.45.199.815.648.815.45 0 .889-.365.981-.815.092-.45-.198-.814-.648-.814s-.889.364-.981.814z"
        fill="#C57C59"
      />
      <path
        opacity=".1"
        d="M115.373 57.742h12.463M123.15 58.828h-7.955M113.666 64.127h12.463M121.443 65.213h-7.955M124.657 74.227h-12.462M116.338 75.313h7.954M122.874 80.894h-12.463M113.089 82.038h-2.865M113.267 66.39h9.648M121.044 67.476h-7.955"
        stroke="#1C3A34"
        strokeWidth=".358"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.125 83.014c-6.351-10.884 1.427-22.386 1.427-22.386l2.743 1.245c-.232-3.053-1.866-9.616-11.95-5.992-5.55-3.935-12.048-5.234-12.048-5.234l4.493-14.422-1.688-1.836s-12.19 14.593-8.356 19.74c0 0 .94 3.181 7.096 7.319 3.122 2.098 7.281 3.729 10.624 4.826.276 3.895.741 10.976.947 17.22-2.624 6.762.608 21.899.608 21.899h2.422s1.917-15.641 3.532-21.797l.15-.582z"
        fill="#F03B46"
      />
      <path
        d="M27.125 83.014c1.627-6.418 4.19-19.702 4.19-19.702s.045-.581-.02-1.439l-2.743-1.245s-7.778 11.502-1.427 22.386z"
        fill="#F24E62"
      />
      <path
        d="M36.5 41.24l-10.304-2.384-10.213-5.853-1.585 1.936s5.948 5.364 8.944 7.282c4.694 3.004 12.62 4.361 12.62 4.361l.537-5.342z"
        fill="#F5757A"
      />
      <path
        d="M44.03 46.863c-.194-3.642-1.01-5.651-6.156-5.651-5.147 0-3.805 3.982-6.953 9.404-1.357 2.339-4.13 3.708-5.731 4.224.898 2.19 2.7 5.723 6.137 8.693C42.6 55.42 44.243 50.88 44.03 46.863z"
        fill="#F8A486"
      />
      <path
        d="M36.393 41.536c1.172.731 2.697.874 4.576.43v-4.337h-4.576v3.907zM41.546 41.906l9.006-3.393 3.633-8.88 2.07.469s-.92 8.455-3.515 11.13c-3.881 4-10.775 6.534-12.23 5.578-.941-.618-.877-1.493-.818-2.425a2.856 2.856 0 011.854-2.48zM29.721 106.441c-.518-.238-6.089-2.543-6.089-2.543l-2.843.003s-.792 3.313.41 3.313h7.977c.84 0 1.063-.535.545-.773zM14.539 29.504c-.398.409-4.437 4.885-4.437 4.885l1.077 3.796s.83.74 1.233-.393l2.673-7.515c.281-.79-.148-1.18-.546-.773z"
        fill="#F5757A"
      />
      <path
        d="M54.273 29.732s-1.25-3.084-.777-3.24c.474-.157.488 1.566.838 1.364.194-.111.022-3.64.453-3.691.431-.051.088 1.864.408 1.969.272.09.38-2.165.788-2.134.408.032-.334 2.205.04 2.295.373.09.572-2.179.925-2.036.352.143-.326 2.176-.127 2.267.199.09.55-1.792.912-1.625.363.168-1.478 5.2-1.478 5.2l-1.982-.37zM12.313 33.603c-1.045-.758-1.228-1.026-1.11-1.213.117-.187.494-.048.494-.048s-.289-.392-.137-.644c.152-.253.64.026.64.026s-.271-.477-.096-.739c.176-.26.629.064.629.064s-.342-.395.008-.688c.35-.294 1.185.863 1.373.871-.146-.873.178-.806.49-.58.286.207.505.493.66.81.447.908.9 1.951.9 1.951l-1.193 1.858s-1.614-.91-2.658-1.668zM34.89 37.342c-2.34-2.663-1.188-6.904 2.148-8.105l.079-.027c2.658-.929 5.484.936 5.484 4.944 0 4.007-.886 5.18-3.586 5.18-1.355 0-2.986-.696-4.125-1.992z"
        fill="#F5757A"
      />
      <path
        d="M34.406 38.159s1.575 1.079 3.532.745c1.957-.334 2.482-1.098 3.031-2.769.55-1.67 2.363-1.861 2.554-3.866.191-2.005-2.625-4.487-5.68-4.487-3.055 0-4.821 2.612-5.299 5.482-.477 2.871 1.862 4.895 1.862 4.895z"
        fill="#418074"
      />
      <path
        d="M36.03 37.876s1.967.493.826 3.009c-1.141 2.515 1.586 3.604.557 6.659-.649 1.926-3.664 4.01-7.884 1.435-4.22-2.576-3.827-6.283-2.092-7.767 1.735-1.485 4.01-.64 5.032-1.662 1.02-1.021 1.801-2.515 3.562-1.674z"
        fill="#418074"
      />
      <path
        opacity=".1"
        d="M19.507 60.628s-.362 2.823-.041 5.646"
        stroke="#1C3A34"
        strokeWidth=".358"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.144 37.902s1.504-1.266 2.893.795"
        stroke="#1C3A34"
        strokeWidth=".358"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 107.097h143.939"
        stroke="#7E4730"
        strokeWidth=".715"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
