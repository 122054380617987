import React from "react";
import { bindActionCreators } from "redux";
import { useDispatch, connect } from "react-redux";
import Modal from "../../Atoms/Modal/Modal";
import UnderlineButton from "../../Atoms/UnderlineButton/UnderlineButton";
import OtpLoginForm from "./OtpLoginForm";
import OtpSignupForm from "./OtpSignupForm";
import HoveringGirl from "../../Graphics/HoveringGirl";
import "./styles.css";
import UserAction from "../../Actions/user";
import { useHistory } from "react-router-dom";
import UiAction from "../../Actions/ui";

function OtpLoginModal({
  show,
  onClose,
  setUser,
  otpLoginNext,
  setPayState,
  payModal = false,
  classes = "",
}) {
  const dispatch = useDispatch();
  const [state, setState] = React.useState("otpSignup");
  const history = useHistory();

  function onSuccess(data) {
    if (otpLoginNext) {
      history.push(otpLoginNext);
    }
    if (!payModal) {
      onClose();
    }
  }

  function onOtpVerified(data, token) {
    setUser(data, token);
    setPayState(true);
    // show profile section if name or email is not present in profile
    const userProfileData = data.profile;
    onSuccess(data);
    // if (
    //   !userProfileData.years_of_experience ||
    //   !userProfileData.yoga_categories
    // ) {
    //   console.log(
    //     `Profile not complete with exp: ${userProfileData.years_of_experience} and categories: ${userProfileData.yoga_categories}`
    //   );
    //   onSuccess(data);
    //   //dispatch(UiAction.showProfile());
    //   //history.push("/studio/profile");
    // } else {
    //   onSuccess(data);
    // }
  }

  function onSkip() {
    onClose();
  }

  let heading = null;
  let otpLoginSection = null;
  let otpSignupSection = null;

  let sendOtpSection = null;
  let verifyOtpSection = null;

  if (state === "otpLogin" || state === "otpSignup") {
    heading = [];
    heading[0] = (
      <UnderlineButton
        onClick={() => setState("otpLogin")}
        className="mr-8"
        selected={state === "otpLogin"}
      >
        Login
      </UnderlineButton>
    );
    heading[1] = (
      <UnderlineButton
        onClick={() => setState("otpSignup")}
        selected={state === "otpSignup"}
      >
        Signup
      </UnderlineButton>
    );
  }

  if (state === "otpLogin") {
    otpLoginSection = (
      <OtpLoginForm
        onSignup={() => setState("otpSignup")}
        onLogin={onOtpVerified}
        classes={classes}
      ></OtpLoginForm>
    );
  }

  if (state === "otpSignup") {
    otpSignupSection = (
      <OtpSignupForm
        onLogin={() => setState("otpLogin")}
        onSignup={onOtpVerified}
        classes={classes}
      ></OtpSignupForm>
    );
  }

  const ViewCont = () => {
    return (
      <div className="flex flex-row">
        <div className="hidden sm:block bg-y-cream flex-1 flex flex-row justify-center p-8">
          <HoveringGirl width="100%" />
        </div>
        <div className="x-login-modal p-8 flex-1">
          <div className="pb-12">{heading}</div>
          {otpLoginSection}
          {otpSignupSection}

          {sendOtpSection}
          {verifyOtpSection}
        </div>
      </div>
    );
  };

  if (payModal) {
    return (
      <>
        <ViewCont />
      </>
    );
  }

  return (
    <Modal show={show} onClose={onSkip} className="sm:w-full sm:max-w-3xl">
      <ViewCont />
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setUser: UserAction.set,
      setPayState: UserAction.setPaymentLogin,
    },
    dispatch
  );
}

function mapStateToProps({ ui }) {
  return {
    otpLoginNext: ui.otpLoginNext,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpLoginModal);
